import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, SxProps } from '@mui/material';
import { PROFILE_PATH } from '../../../constants/constants';
import { RootState } from '../../../redux/store';
import { commonConfig } from '../../../config';
import { getNameRoute } from '../../../utils/stepsRouting';
import { setActiveStep } from '../../../redux/reducers/additional';
import Preferences from '../FormSections/Step4/Preferences';

type TProps = {
  id: string;
  submissionId?: string | null;
};

const Step4: React.FC<TProps> = ({ id, submissionId }) => {
  const navigate = useNavigate();

  const { candidate } = useSelector((state: RootState) => state.candidate);
  const { isCandidate } = useSelector((state: RootState) => state.user);
  const { activeStep } = useSelector((state: RootState) => state.additional);

  const ocpHost = commonConfig.ocpHost;

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.success === 1) {
        navigate(
          `/${PROFILE_PATH}/${getNameRoute(activeStep + 1, isCandidate)}?id=${id}${
            submissionId ? `&submissionId=${submissionId}` : ''
          }`,
        );
        setActiveStep(activeStep + 1);
      }
    };
    window.addEventListener('message', handleMessage, false);
    return ()=>{window.removeEventListener('message', handleMessage, false);};
  }, []);

  return !!submissionId ? (
    <Preferences id={id} submissionId={submissionId} />
  ) : (
    <Card sx={styleCardSection}>
      <iframe
        src={`${ocpHost}?type=iframe&email=${candidate?.user?.email}&first_name=${candidate?.user?.first_name}&last_name=${candidate?.user?.last_name}`}
        title="findingclarity"
        width="100%"
        height="800px"
      />
    </Card>
  );
};

export default memo(Step4);

const centerMargin = -(window.innerWidth - 1080) / 2;

const styleCardSection: SxProps = {
  background: '#f5f8fa',
  boxShadow: 'none',
  border: 'none',
  padding: '0 20px',
  width: window.innerWidth,
  position: 'relative',
  marginLeft: window.innerWidth > 1080 ? `${centerMargin}px` : 0,
};
