import { green, grey } from '@mui/material/colors';
import { THEMES } from '../constants';

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: 'light',
    primary: {
      main: customBlue[700],
      contrastText: '#FFF',
    },
    secondary: {
      main: customBlue[500],
      contrastText: '#FFF',
    },
    background: {
      default: '#F7F9FC',
      paper: '#FFF',
    },
    common: {
      oxfordBlue: '#002855',
      skyBlue: '#2681DB',
      lightestBlue: '#2681DB',
      jadeGreen: '#00B192',
      roseRed: '#DC2547',
      yellow: '#FCC501',
      darkGray: '#757678',
      mediumGray: '#B7B8B9',
      actionableItem: '#2681DB',
      offWhite: '#F8F8F8',
      darkestGrey: '#212121',
      jadeGreenLight: '#E6F7F4',
      yellowLight: '#FFFBED',
    },
    oxfordBlue: { main: '#002855' },
    skyBlue: { main: '#2681DB' },
    lightestBlue: { main: '#2681DB' },
    jadeGreen: { main: '#00B192' },
    roseRed: { main: '#DC2547' },
    yellow: { main: '#FCC501' },
    darkGray: { main: '#757678' },
    mediumGray: { main: '#B7B8B9' },
    actionableItem: { main: '#2681DB' },
    offWhite: { main: '#F8F8F8' },
    darkestGrey: { main: '#212121' },
    jadeGreenLight: { main: '#E6F7F4' },
    yellowLight: { main: '#FFFBED' },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: '#FFF',
  },
  sidebar: {
    color: grey[200],
    background: '#233044',
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: customBlue[500],
    },
  },
};

const variants: Array<VariantType> = [defaultVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
