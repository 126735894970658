import { SubmissionItemType } from '../types/candidate';
import { objToId } from '../utils/formatData';

class SubmissionDataModel {
  id: number;
  about: string | null;
  availability_additional: string;
  comment: string | null;
  designations: Array<any>;
  interviewAvailabilities: Array<number> | null;
  location_id: number | null;
  is_workplace_location: number | null;
  notice_period_id: number | null;
  notice_period_other: string | null;
  rate_min: number | null;
  rate_max: number | null;
  salary_min: number | null;
  salary_max: number | null;
  salary_comment: string | null;
  submissionRequirements: any;
  years_of_experience?: number;
  candidate_id: number;
  company_name: string;
  created_at: string;
  include_schedule: boolean;
  invite_status: string;
  is_hidden: boolean;
  job_order_id: number;
  job_title: string;
  location: {id: number; title: string} | null;
  noticePeriod: {id: number; title: string} | null;
  order: number | null;
  schedule_link: string | null;
  status: string;
  updated_at: string;
  employee_id: number | null;
  industries: Array<any>| null;
  ownership_id: number | null;
  revenue_id: number | null;

  constructor(data: SubmissionItemType) {
    this.id = data?.id;
    this.about = data?.about || '';
    this.availability_additional = data?.availability_additional;
    this.comment = data?.comment || null;
    this.designations = data?.designations;
    this.interviewAvailabilities = data?.interviewAvailabilities && objToId(data?.interviewAvailabilities) || [];
    this.location_id = data?.location_id || null;
    this.is_workplace_location = data?.is_workplace_location !== null ? Number(data?.is_workplace_location) : null;
    this.notice_period_id = Number(data?.notice_period_id) || null;
    this.notice_period_other = data?.notice_period_other || null;
    this.rate_min = data?.rate_min || null;
    this.rate_max = data?. rate_max || null;
    this.salary_min = data?.salary_min || null;
    this.salary_max = data?.salary_max || null;
    this.salary_comment= data?.salary_comment || null;
    this.rate_min = data?.rate_min || null;
    this.submissionRequirements = data?.submissionRequirements || [];
    this.years_of_experience = Number(data?.years_of_experience) || undefined;
    this.candidate_id = data?.candidate_id;
    this.company_name = data?.company_name;
    this.created_at = data?.created_at;
    this.include_schedule = data?.include_schedule;
    this.invite_status = data?.invite_status;
    this.is_hidden = data?.is_hidden;
    this.job_order_id = data?.job_order_id;
    this.job_title = data?.job_title;
    this.location = data?.location;
    this.noticePeriod = data?.noticePeriod;
    this.order = data?.order;
    this.schedule_link = data?.schedule_link;
    this.status = data?.status;
    this.updated_at = data?.updated_at;
    this.employee_id = data?.employee_id;
    this.industries = data?.industries && objToId(data?.industries) || [];
    this.ownership_id = data?.ownership_id;
    this.revenue_id = data?.revenue_id;
  }
}

export default SubmissionDataModel;
