import React, { memo, CSSProperties, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import styled from 'styled-components/macro';

import Comment from './Comment';
import VerifyIcon from './VerifyIcon';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';

import { ScorecardRespType } from '../../../../types/candidate';
import { RootState } from '../../../../redux/store';

import { TD, TR } from '../../FormSections/StylesTable';
import { COLORS } from '../../../../theme';

type TProps = {
  data: ScorecardRespType;
  active?: boolean;
  onChangeValue: (values: ScorecardRespType) => void;
};

const RowTable: React.FC<TProps> = ({ data, active = false, onChangeValue }) => {
  const { scorecardSavedData } = useSelector((state: RootState) => state.candidate);

  const currentValue = useMemo(() => {
    const savedData = scorecardSavedData.find(item => item.id === data.id);
    return !!savedData ? savedData.value : data.value;
  }, [scorecardSavedData, data]);

  return (
    <TR style={active ? show : hidden}>
      <TD width="40%" style={{ paddingLeft: '38px' }}>
        {data.requirement}
      </TD>

      <TD width="40%" style={{ paddingLeft: '5px', textAlign: 'right' }}>
        <Box display="flex" justifyContent={!!data.experience ? 'space-between' : 'end'} alignItems="center">
          {data.experience}
          {data.is_approved === 0 && <VerifyIcon data={data} />}
        </Box>
      </TD>

      <TD width="5%">
        <Cell
          onClick={() => onChangeValue({ ...data, value: 'Yes' })}
          style={{
            backgroundColor: currentValue === 'Yes' ? COLORS.successLight : COLORS.disabledLight,
            border: currentValue === 'Yes' ? 'solid 1px #00B192' : '',
          }}
        >
          <CheckTwoToneIcon color={currentValue === 'Yes' ? 'success' : 'disabled'} />
        </Cell>
      </TD>

      <TD width="5%">
        <Cell
          onClick={() => onChangeValue({ ...data, value: 'Some' })}
          style={{
            backgroundColor: currentValue === 'Some' ? COLORS.warningLight : COLORS.disabledLight,
            border: currentValue === 'Some' ? 'solid 1px #FCC501' : '',
          }}
        >
          <RemoveIcon color={currentValue === 'Some' ? 'warning' : 'disabled'} />
        </Cell>
      </TD>

      <TD width="5%">
        <Cell
          onClick={() => onChangeValue({ ...data, value: 'No' })}
          style={{
            backgroundColor: currentValue === 'No' ? COLORS.errorLight : COLORS.disabledLight,
            border: currentValue === 'No' ? 'solid 1px #DC2547' : '',
          }}
        >
          <ClearIcon color={currentValue === 'No' ? 'error' : 'disabled'} />
        </Cell>
      </TD>

      <TD width="5%">
        <Comment data={data} onChangeValue={onChangeValue} />
      </TD>
    </TR>
  );
};

export default memo(RowTable);

const Cell = styled(Box)`
  display: flex;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  background: #f5f8fa;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  svg {
    width: 15px;
  }
`;

const hidden: CSSProperties = {
  height: 0,
  display: 'block',
  overflow: 'hidden',
  borderBottom: 'none',
  transition: 'height .5s',
};

const show: CSSProperties = {
  height: 'inherit',
  transition: 'height .5s',
};
