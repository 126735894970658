import React, { memo, useCallback, CSSProperties, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card as MuiCard } from '@mui/material';
import { spacing, styled } from '@mui/system';
import { format } from 'date-fns';
import { Table, THead, TH, TR, TBody, TD } from '../FormSections/StylesTable';
import StatusInvite from '../../StatusInvite';
import IconButton from '../../IconButton';
import {
  FormatDate,
  PROFILE_PATH,
  INVITE_PATH,
  ROUTES,
  InviteStatus,
  ROUTES_SPECIFIC,
} from '../../../constants/constants';
import { mailIcon, eyeIcon, editIcon, disabledMailIcon } from '../../../constants/icons';
import { setCandidateState, setShowProfileDetails } from '../../../redux/reducers/candidate';
import { RootState } from '../../../redux/store';
import { SubmissionItemType } from '../../../types/candidate';

type TProps = {
  isDisabledMail?: boolean;
  showAlert?: () => void;
};

const CandidateTable: React.FC<TProps> = ({ isDisabledMail = false, showAlert }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const candidateId = params.get('candidate_id') || params.get('id');
  const { isCandidate } = useSelector((state: RootState) => state.user);

  const { candidate } = useSelector((state: RootState) => state.candidate);

  const onInviteCandidate = useCallback(
    (submissionId: number | null) => {
      candidateId &&
        navigate(
          `/${INVITE_PATH}${candidateId ? `?candidate_id=${candidateId}` : ''}${
            submissionId ? `&submissionId=${submissionId}` : ''
          }`,
        );
    },
    [navigate, candidateId],
  );

  const onEditProfile = useCallback(
    (submissionId: number | null) => {
      if (candidateId) {
        dispatch(setCandidateState({ type: 'isFirstEmailAlert', data: false }));
        navigate(
          `/${PROFILE_PATH}/${submissionId && isCandidate ? ROUTES_SPECIFIC.GENERAL : ROUTES.GENERAL}?${
            candidateId ? `id=${candidateId}` : ''
          }${submissionId ? `&submissionId=${submissionId}` : ''}`,
        );
      }
    },
    [candidateId, dispatch, navigate],
  );

  const showProfile = useCallback(
    (submissionId: number | null) => {
      dispatch(setShowProfileDetails(true));
      candidateId &&
        navigate(
          `/${PROFILE_PATH}/profile-details?candidate_id=${candidateId}${
            !!submissionId ? `&submissionId=${submissionId}` : ''
          }`,
        );
    },
    [dispatch, candidateId, navigate],
  );

  const isInvited = useMemo(
    () => candidate?.invite_status === InviteStatus.accepted || candidate?.invite_status === InviteStatus.completed,
    [candidate],
  );

  const getDate = (updated_at: string | undefined) =>
    format(updated_at ? new Date(updated_at || '') : new Date(), FormatDate.statusInvite);

  return (
    <Card sx={stylesWrapper}>
      <Table>
        <THead>
          <TR>
            <TH width="60%" style={stylesHeader}>
              PROFILE TYPE
            </TH>

            <TH width="10%" style={stylesHeaderCenter}>
              STATUS
            </TH>

            <TH width="15%" style={stylesHeader} />

            <TH width="15%" style={stylesHeaderCenter}>
              ACTION
            </TH>
          </TR>
        </THead>

        <TBody>
          <TR>
            <TD width="60%" style={stylesHeader}>
              General Profile
            </TD>

            <TD width="10%" style={stylesHeader}>
              <StatusInvite status={candidate?.invite_status} />
            </TD>

            <TD width="15%" style={stylesDate}>
              {candidate?.invite_status === InviteStatus.notSent ? '' : `on ${getDate(candidate?.updated_at)}`}
            </TD>

            <TD width="15%" style={stylesIconsWrap}>
              {isInvited && (
                <IconButton toolTip="View profile" onClick={() => showProfile(null)}>
                  {eyeIcon}
                </IconButton>
              )}

              {isDisabledMail ? (
                <IconButton toolTip="Send invite (disabled)" onClick={showAlert}>
                  {disabledMailIcon}
                </IconButton>
              ) : (
                <IconButton toolTip="Send invite" onClick={() => onInviteCandidate(null)}>
                  {mailIcon}
                </IconButton>
              )}

              <IconButton toolTip="Edit profile" onClick={() => onEditProfile(null)}>
                {editIcon}
              </IconButton>
            </TD>
          </TR>
          {!!candidate?.submissions &&
            candidate?.submissions.map((item: SubmissionItemType, index: number) => {
              return (
                <TR>
                  <TD width="60%" style={stylesHeader}>
                    {item.job_title} at {item.company_name}
                  </TD>

                  <TD width="10%" style={stylesHeader}>
                    <StatusInvite status={item?.invite_status} />
                  </TD>

                  <TD width="15%" style={stylesDate}>
                    {item?.invite_status === InviteStatus.notSent ? '' : `on ${getDate(item?.updated_at)}`}
                  </TD>

                  <TD width="15%" style={stylesIconsWrap}>
                    {(item?.invite_status === InviteStatus.accepted ||
                      item?.invite_status === InviteStatus.completed) && (
                      <IconButton toolTip="View profile" onClick={() => showProfile(item.id)}>
                        {eyeIcon}
                      </IconButton>
                    )}

                    {isDisabledMail ? (
                      <IconButton toolTip="Send invite (disabled)" onClick={showAlert}>
                        {disabledMailIcon}
                      </IconButton>
                    ) : (
                      <IconButton toolTip="Send invite" onClick={() => onInviteCandidate(item.id)}>
                        {mailIcon}
                      </IconButton>
                    )}

                    <IconButton toolTip="Edit profile" onClick={() => onEditProfile(item.id)}>
                      {editIcon}
                    </IconButton>
                  </TD>
                </TR>
              );
            })}
        </TBody>
      </Table>
    </Card>
  );
};

export default memo(CandidateTable);

const Card = styled(MuiCard)(spacing);

const stylesWrapper = {
  margin: 'auto',
  padding: '15px',
  borderColor: 'transparent',
  width: '100%',
  maxWidth: '1080px',
  overflow: 'visible',
};

const stylesHeader: CSSProperties = {
  color: '#202020',
  textAlign: 'left',
  padding: 10,
};

const stylesHeaderCenter: CSSProperties = {
  color: '#202020',
  textAlign: 'center',
  padding: 10,
};
const stylesIconsWrap: CSSProperties = {
  color: '#202020',
  textAlign: 'end',
  padding: '10px 15px 10px',
};

const stylesDate: CSSProperties = {
  color: '#202020',
  textAlign: 'left',
  padding: 10,
  fontSize: 14,
};
