import { withStyles } from '@mui/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';

export const Accordion = withStyles({
  root: {
    borderRadius: '10px !important',
    marginBottom: '15px',
    backgroundColor: '#fff',
    padding: 0,
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '20px',
    height: '54px',
    paddingLeft: '16px',
    paddingRight: '16px',
    margin: 0,
  },
  expanded: {
    margin: '0 !important',
    minHeight: 0,
  },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing('0'),
    '& .MuiCardContent-root': {
      paddingTop: '0 !important',
    },
  },
}))(MuiAccordionDetails);
