import { commonConfig } from '../config';

const RobotsTXT = () => {
  const indexableRobotsTxt = 'User-agent: *\nDisallow: ';
  const nonIndexableRobotsTxt = 'User-agent: *\nDisallow: /';

  const robotsTxt = commonConfig.buildVersion === 'production' ? indexableRobotsTxt : nonIndexableRobotsTxt;

  return <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>{robotsTxt}</div>;
};

export default RobotsTXT;
