import React from 'react';
import styled from 'styled-components/macro';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline, Typography as MuiTypography, Paper as MuiPaper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import GlobalStyle from '../components/GlobalStyle';
import LogoLumaFi from '../images/logo/LogoFooter.svg';

const drawerWidth = 258;
const AppContentWidth = window.innerWidth - drawerWidth;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  background: #fff;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  max-width: 100%;
  min-width: ${AppContentWidth}px;
  `;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: #fff;
  width: 100%;
  /* max-width: 2048px; */
  margin: 0;
  padding: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }

  #pageFooter:after {
    counter-increment: page;
    content: ' ' counter(page);
  }
`;

const Subtitle1 = styled(MuiTypography)`
  font-size: 16px;
  letter-spacing: 0.09px;
  line-height: 22px;
  color: #002855;
  font-weight: 400;
`;

interface TProps {
  children?: React.ReactNode;
}

const PDF: React.FC<TProps> = ({ children }) => {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <MainContent p={isLgUp ? 12 : 0}>
          <footer style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 9, width: '100%', height: '95px' }}>
            <Box>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  background: '#fff',
                  height: '40px',
                  fontWeight: 600,
                  color: '#757678',
                  padding: '0 40px',
                }}
              >
                <div>
                  Created: {new Date().toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}
                </div>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: '#f5f8fa',
                  padding: '0 40px',
                }}
              >
                <Subtitle1>Copyright @ {new Date().getFullYear()} Clarity Recruitment. All rights reserved.</Subtitle1>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: '#9E9E9E',
                    height: '55px',
                  }}
                >
                  <span>Powered by</span>
                  <img src={LogoLumaFi} alt="logo clarity" width={100} height={35} />
                </div>
              </div>
            </Box>
          </footer>
          <div
            style={{
              position: 'fixed',
              bottom: -25,
              zIndex: 9,
              width: '100%',
              height: '25px',
              background: '#fff',
            }}
            className={'pdf-header'}
          />

          {children}
            
          <div style={{width: '100%', padding: '0 20px', minWidth: '1350px'}}>
          <Outlet />
          </div>
        </MainContent>
      </AppContent>
      <div style={{ height: '100vh', position: 'fixed', bottom: 0, zIndex: '-2', width: '100%', background: '#fff' }} />
    </Root>
  );
};

export default PDF;
