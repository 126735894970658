import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import InvitationCandidate from '../../components/CandidateProfile/Components/InvitationCandidate';
import Loader from '../../components/Loader';

import { InviteRequestType, SubmissionItemType } from '../../types/candidate';
import { ALERT_MESSAGES, InviteStatus, ROUTES } from '../../constants/constants';
import { inviteCandidate, getCandidate } from '../../redux/actions/candidate';
import { setCandidateState, setCandidateSubmissionInviteStatus } from '../../redux/reducers/candidate';
import { setAlert } from '../../redux/reducers/alert';
import { RootState } from '../../redux/store';

const InviteCandidatePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const candidateId = params.get('candidate_id') || params.get('id');
  const submissionId = params.get('submissionId');

  const { candidate, isInvitedCandidate, isLoading, errors } = useSelector((state: RootState) => state.candidate);
  const { profile } = useSelector((state: RootState) => state.user);

  const submission = candidate?.submissions?.find(
    (item: SubmissionItemType) => item.id && item.id.toString() === submissionId,
  );
  const inviteStatus = submissionId ? submission?.invite_status : candidate?.invite_status;

  useEffect(() => {
    if (Object.keys(profile).length) {
      !!candidateId && dispatch(getCandidate(+candidateId));
    }
  }, [candidateId, profile, dispatch]);

  useEffect(() => {
    if (isInvitedCandidate && !submissionId) {
      dispatch(setAlert({ type: 'success', message: ALERT_MESSAGES.INVITED_SUCCESS }));
      dispatch(setCandidateState({ type: 'candidate', data: { ...candidate, invite_status: InviteStatus.invited } }));
      dispatch(setCandidateState({ type: 'isInvitedCandidate', data: false }));
      navigate(`/candidate-profile?candidate_id=${candidateId}`);
    }
  }, [isInvitedCandidate, candidate, dispatch, submissionId, navigate, candidateId]);

  useEffect(() => {
    if (isInvitedCandidate && submissionId && submission) {
      dispatch(setAlert({ type: 'success', message: ALERT_MESSAGES.INVITED_SUCCESS }));
      dispatch(
        setCandidateSubmissionInviteStatus({
          ...submission,
          invite_status: InviteStatus.invited,
          updated_at: new Date(Date.now()).toISOString(),
        }),
      );
      dispatch(setCandidateState({ type: 'isInvitedCandidate', data: false }));
      navigate(`/candidate-profile?candidate_id=${candidateId}`);
    }
  }, [candidateId, dispatch, isInvitedCandidate, navigate, submission, submissionId]);

  useEffect(() => {
    errors && dispatch(setAlert({ type: 'error', message: ALERT_MESSAGES.INVITED_FAIL }));
  }, [errors, dispatch]);

  const onSendInvite = useCallback(
    (values: InviteRequestType) => {
      candidateId &&
        dispatch(
          inviteCandidate({
            candidate_id: +candidateId,
            job_id: submission?.job_order_id || null,
            message: values.invitation,
            include_schedule: values.include_schedule,
          }),
        );
    },
    [candidateId, dispatch, submission],
  );

  const inviteCandidatePage = useMemo(() => {
    switch (inviteStatus) {
      case InviteStatus.completed:
        navigate(`/candidate-profile?candidate_id=${candidateId}`);
        return null;
      default:
        return <InvitationCandidate onSendInvite={onSendInvite} goBack={() => navigate(-1)} submission={submission} />;
    }
  }, [candidateId, inviteStatus, navigate, onSendInvite, submission]);

  return isLoading ? <Loader /> : inviteCandidatePage;
};

export default memo(InviteCandidatePage);
