import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const candidateId = params.get('candidate_id') || params.get('id');
  const submissionId = params.get('submissionId');

  if (isInitialized && !isAuthenticated) {
    return (
      <Navigate
        to={`/auth/sign-in${!!candidateId ? `?candidate_id=${candidateId}` : ''}${
          !!submissionId ? `&submissionId=${submissionId}` : ''
        }`}
      />
    );
  }

  return <>{children}</>;
}

export default AuthGuard;
