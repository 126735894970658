import React from 'react';
import { Card as MuiCard, Grid } from '@mui/material';
import { styled, spacing } from '@mui/system';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

import HeaderContent from '../HeaderContent';
import HistoryCompanies from './HistoryCompanies';
import RelevantExperience from './RelevantExperience';
import AdditionalDetails from './AdditionalDetails';
import MostImportantList from '../MostImportantList';
import PreferencesReport from './PreferencesReport';

import { calculatingOverallFit, getCurrentJobTitle } from '../../../../../utils/functions';
import { SubmissionItemType } from '../../../../../types/candidate';

type TProps = {
  candidateId: string;
  submissionId: string;
  ocp_answers: { top: { [key: string]: string }; bottom: { [key: string]: string } } | null;
  toPdf: boolean;
  PdfFilters: {
    showCompanyName: boolean | undefined;
    showCandidateName: boolean | undefined;
    showTargetSalary: boolean | undefined;
    showDesiredCulture: boolean | undefined;
    showAvailability: boolean | undefined;
  };
};

const JobSpecificProfileDetails: React.FC<TProps> = ({ candidateId, submissionId, ocp_answers, toPdf, PdfFilters }) => {
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const currentSubmission = candidate?.submissions.find((item: SubmissionItemType) => item.id === +submissionId);

  const overallFit = currentSubmission?.submissionRequirements
    ? calculatingOverallFit(currentSubmission?.submissionRequirements, 3)
    : 5;

  return (
    <Card>
      <HeaderContent
        toPdf={toPdf}
        showCompanyName={PdfFilters.showCompanyName}
        showTargetSalary={PdfFilters.showTargetSalary}
        submissionId={+submissionId}
        values={{
          first_name: candidate?.first_name || '',
          last_name: candidate?.last_name || '',
          designation: currentSubmission?.designation || '',
          job_title: getCurrentJobTitle(candidate),
          salary_min: currentSubmission?.salary_min || null,
          salary_max: currentSubmission?.salary_max || null,
          location: currentSubmission?.location || null,
          user: currentSubmission?.user || null,
          about: currentSubmission?.about || '',
          why_interested: currentSubmission?.why_interested || '',
          overallFit: overallFit,
        }}
      />
      <Grid container sx={stylesWrapper}>
        <Grid item sm={4} mb={2}>
          <HistoryCompanies currentSubmission={currentSubmission} />
          {(!toPdf || PdfFilters.showDesiredCulture) &&
            ((candidate?.is_full_culture && ocp_answers?.top && !!Object.keys(ocp_answers?.top).length) ||
              (!candidate?.is_full_culture && !!candidate?.companyTraits.length)) && (
              <MostImportantList
                mostImportant={ocp_answers?.top}
                companyTraits={candidate?.companyTraits}
                isFullCulture={candidate?.is_full_culture}
              />
            )}
        </Grid>

        <Grid item px={5} sm={8}>
          <RelevantExperience submissionRequirements={currentSubmission?.submissionRequirements} toPdf={toPdf} />
          {currentSubmission?.submissionCategories.some(el => !!el.comment) && (
            <AdditionalDetails submissionCategories={currentSubmission?.submissionCategories} toPdf={toPdf} />
          )}
        </Grid>
      </Grid>

      {(!toPdf || PdfFilters.showAvailability) && (
        <Grid container sx={stylesWrapper}>
          <Grid item xs={12} mb={2}>
            <PreferencesReport submission={currentSubmission} />
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

const Card = styled(MuiCard)(spacing);

const stylesWrapper = {
  // margin: 'auto',
  borderColor: 'transparent',
  width: '100%',
  // maxWidth: '2480px',
  overflow: 'visible',
};

export default JobSpecificProfileDetails;
