import React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent } from '@mui/material';
import NewPasswordComponent from '../../components/Auth/NewPasswordComponent';
import { HeaderText, Text } from '../../styles/TextStyles';
import { Wrapper, Brand } from '../../styles/pages/AuthPages/SignInStyles';

function NewPassword() {
  const params = useParams();
  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      <Card>
        <CardContent>
          <Brand />
          <HeaderText style={{ textAlign: 'center' }}>Reset new password</HeaderText>
          <Text style={{ textAlign: 'center' }}>Enter new password and then repeat it</Text>
          <Box sx={{ padding: '0px 38px' }}>
            <NewPasswordComponent token={params.token} />
          </Box>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default NewPassword;
