import { Box, Card, Link, Typography } from '@mui/material';
import { styleCardSection, styleFinishCard } from '../StylesCandidateProfile';
import SuccessIcon from '../../../images/modal/SuccessIcon.svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import CustomButton from '../../CustomButton';

const CompleteForm = () => {
  const { candidate } = useSelector((state: RootState) => state.candidate);
  return (
    <Card sx={styleCardSection}>
      <Box sx={styleFinishCard}>
        <img src={SuccessIcon} alt={''} />
        <Typography variant={'h2'}>All Done!</Typography>
        <Typography variant={'body2'}>Thanks for creating your profile with us!</Typography>
        {candidate?.schedule_link ? (
          <Typography variant={'body2'}>Schedule a time to meet with your Clarity representative below.</Typography>
        ) : (
          <Typography variant={'body2'}>Your Clarity representative will be in touch.</Typography>
        )}
        {candidate?.schedule_link && (
          <Box mt={'20px'}>
            <Link href={candidate?.schedule_link} underline={'none'} target={'_blank'}>
              <CustomButton>SCHEDULE A TIME TO MEET</CustomButton>
            </Link>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default CompleteForm;
