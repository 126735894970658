import React from 'react';

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import MainLayout from './layouts/MainLayoutNew';
import MainLayoutRecruiter from './layouts/MainLayoutRecruiter';
import PDFLayout from './layouts/PDFLayout';

// Guards
import AuthGuard from './components/guards/AuthGuard';
import GuestGuard from './components/guards/GuestGuard';

// Auth components
import SignIn from './pages/AuthPages/SignIn';
import SignUp from './pages/AuthPages/SignUp';
import ResetPassword from './pages/AuthPages/ResetPassword';
import NewPassword from './pages/AuthPages/NewPassword';
import Page404 from './pages/AuthPages/Page404';
import Page500 from './pages/AuthPages/Page500';

// Protected routes
import CandidateProfilePage from './pages/CandidateProfilePages/CandidateProfilePage';
import DashboardPage from './pages/CandidateProfilePages/DashboardPage';
import InviteCandidatePage from './pages/CandidateProfilePages/InviteCandidatePage';
import Settings from './pages/UserPages/Settings';
import CandidateGuard from './components/guards/CandidateGuard';
import CandidatePDF from './components/pdf/CandidatePDF';
import CompleteForm from './components/CandidateProfile/FormSections/CompleteForm';
import RobotsTXT from './utils/RobotsTXT';
import JobsHomepage from "./pages/JobDetail/JobsHomepage";
import JobCard from "./pages/JobDetail/JobCard";

const routes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '500',
        element: <Page500 />,
      },
    ],
  },
  {
    path: 'password',
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: 'reset/:token',
        element: <NewPassword />,
      },
    ],
  },
  {
    path: 'invite',
    element: (
      <AuthGuard>
        <MainLayoutRecruiter />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <InviteCandidatePage />,
      },
    ],
  },
  {
    path: 'candidate-profile',
    element: (
      <AuthGuard>
        <CandidateGuard>
          <MainLayoutRecruiter />
        </CandidateGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <DashboardPage />,
      },
      {
        path: 'profile-details',
        element: <DashboardPage />,
      },
    ],
  },
  {
    path: 'candidate-profile',
    element: (
      <AuthGuard>
        <CandidateGuard>
          <MainLayout />
        </CandidateGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: ':category',
        element: <CandidateProfilePage />,
      },
      {
        path: 'complete',
        element: <CompleteForm />,
      },
    ],
  },
  {
    path: 'user',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'profile',
        element: <Settings />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
  {
    path: '',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <JobsHomepage />,
      },
      {
        path: 'details',
        element: <JobCard />,
      },
      {
        path: '*',
        element: <JobsHomepage />,
      },
    ],
  },
  {
    path: 'pdf',
    element: <PDFLayout />,
    children: [
      {
        path: 'candidate',
        element: <CandidatePDF />,
      },
    ],
  },
  {
    path: 'robots.txt',
    element: <RobotsTXT />
  },
];

export default routes;
