import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid } from '@mui/material';

import CustomInput from '../../../CustomInput';
import CustomSelect from '../../../CustomSelect';
import CustomPhoneInput from '../../../CustomPhoneInput';

import { RootState } from '../../../../redux/store';

import { typePhoneOptions } from '../../../../utils/staticValues';
import { styleCard } from '../StylesFormSections';

type TProps = {
  formik: any;
  disabledEmailInput?: boolean
};

const ContactInformation: React.FC<TProps> = ({
  formik: { values, handleChange, setFieldValue, errors, touched },
  disabledEmailInput = false,
}) => {
  const { general } = useSelector((state: RootState) => state.dictionaries.dictionaries);

  return (
    <Card sx={styleCard}>
      <Grid container spacing={3} mb={5}>
        <Grid item md={6} xs={12}>
          <CustomInput
            name="first_name"
            label="First Name"
            value={values.first_name}
            onChange={handleChange}
            error={touched.first_name && errors.first_name}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInput
            name="last_name"
            label="Last Name"
            value={values.last_name}
            onChange={handleChange}
            error={touched.last_name && errors.last_name}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mb={5}>
        <Grid item md={6} xs={12}>
          <CustomPhoneInput
            name="phone"
            label="Phone"
            type="tel"
            value={values.phone}
            onChange={(selectValue: 'mobile_phone' | 'landline_phone', inputValue: string) => {
              let phone = { ...values.phone };
              phone[selectValue] = inputValue;
              // TODO: 'make regexp phone number for some counries
              // /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/       regexp phone number
              setFieldValue('phone', phone);
            }}
            error={touched.phone && errors.phone}
            nameSelect="phoneType"
            data={typePhoneOptions}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInput
            name="email"
            value={values.email}
            label="Email"
            onChange={handleChange}
            error={touched.email && errors.email}
            disabled={disabledEmailInput}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mb={5}>
        <Grid item md={6} xs={12}>
          <CustomSelect
            labelId="country_id"
            name="country_id"
            label="Country"
            value={values.country_id}
            onChange={handleChange}
            data={general?.countries}
            error={Boolean(touched.country_id && errors.country_id)}
            errorText={errors.country_id}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <CustomInput
                name="city"
                label="City"
                value={values.city}
                onChange={handleChange}
                error={touched.city && errors.city}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <CustomSelect
                labelId="province_id"
                name="province_id"
                label="Province"
                value={values.province_id}
                onChange={handleChange}
                data={general?.provinces}
                error={Boolean(touched.province_id && errors.province_id)}
                errorText={errors.province_id}
                disabled={values.country_id !== 40 && values.country_id !== 239}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(ContactInformation);
