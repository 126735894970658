import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid, Typography, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { FormikProps } from 'formik';

import CustomInput from '../../../CustomInput';
import CustomSelect from '../../../CustomSelect';
import CustomMultipleSelect from '../../../CustomMultipleSelect';

import RoleValuesModel from '../../../../models/roleValues.model';
import { RootState } from '../../../../redux/store';

import { styleCard } from '../StylesFormSections';
import CustomDatePicker from '../../../CustomDatePickerNew';
import CustomCheckbox from '../../../CustomCheckbox';
import { ReactComponent as DeleteIcon } from '../../../../images/icons/ic_delete_48px.svg';

type TProps = {
  name: string;
  role: RoleValuesModel;
  index: number;
  formik: FormikProps<any>;
  idUnknownValue: number;
  isJobSpecific?: boolean;
  onDelete?: ({ id, index }: { id: number | undefined; index: number }) => void;
};

const Environment: React.FC<TProps> = ({
  name,
  role,
  index,
  idUnknownValue,
  isJobSpecific = false,
  onDelete = () => {},
  formik: { handleChange, setFieldValue, values, errors, touched },
}) => {
  const { companyAndFunctions } = useSelector((state: RootState) => state.dictionaries.dictionaries);

  let valuesUnknownPossible = {
    employees: [{ id: idUnknownValue, title: 'Unknown' }],
    ownerships: [{ id: idUnknownValue, title: 'Unknown' }],
    revenues: [{ id: idUnknownValue, title: 'Unknown' }],
  };

  if (companyAndFunctions) {
    valuesUnknownPossible = {
      employees: [...valuesUnknownPossible.employees, ...companyAndFunctions?.employees],
      ownerships: [...valuesUnknownPossible.ownerships, ...companyAndFunctions?.ownerships],
      revenues: [...valuesUnknownPossible.revenues, ...companyAndFunctions?.revenues],
    };
  }

  return (
    <Card sx={{...styleCard, paddingTop: 0}}>
          <Box pl={2} mb={2} sx={{ textAlign: 'end', '& label': { marginRight: 0 } }}>
            <CustomCheckbox
              name={'is_current'}
              label={'I am currently working in this role'}
              checked={role.is_current}
              onChange={e => {
                setFieldValue(`${name}.is_current`, e.target.checked);
              }}
            />
          </Box>
      <Grid container spacing={3} mb={5}>
        <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomInput
            name={`${name}.title`}
            value={role?.title}
            label="Job Title"
            onChange={handleChange}
            error={
              touched.histories &&
              //@ts-ignore
              touched.histories[index]?.title &&
              errors.histories &&
              //@ts-ignore
              errors.histories[index]?.title
            }
            maxlength={60}
          />
        </Grid>

        <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomDatePicker
            name={`${name}.start_date`}
            value={role?.start_date}
            label="Start date"
            onChange={value => setFieldValue(`${name}.start_date`, value)}
            error={Boolean(
              touched.histories &&
                //@ts-ignore
                touched.histories[index]?.start_date &&
                errors.histories &&
                //@ts-ignore
                errors.histories[index]?.start_date,
            )}
            errorText={
              touched.histories &&
              //@ts-ignore
              touched.histories[index]?.start_date &&
              errors.histories &&
              //@ts-ignore
              errors.histories[index.toString()]?.start_date
            }
          />
        </Grid>

        <Grid item md={4} xs={12}  maxHeight={'65px'}>
          <CustomDatePicker
            name={`${name}.end_date`}
            value={role?.end_date}
            disabled={role.is_current}
            label="End date"
            onChange={value => setFieldValue(`${name}.end_date`, value)}
            error={Boolean(touched[`${name}.end_date`] && errors[`${name}.end_date`])}
            errorText={touched[`${name}.end_date`] && errors[`${name}.end_date`]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mb={6}>
        <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomInput
            name={`${name}.company`}
            value={role?.company}
            label="Company"
            onChange={handleChange}
            error={
              touched.histories &&
              //@ts-ignore
              touched.histories[index]?.company &&
              errors.histories &&
              //@ts-ignore
              errors.histories[index]?.company
            }
            maxlength={60}
          />
        </Grid>

        <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomInput
            name={`${name}.reports_to`}
            value={role?.reports_to}
            label="Reporting To (Job Title) "
            onChange={handleChange}
            error={touched[`${name}.reports_to`] && errors[`${name}.reports_to`]}
            maxlength={60}
          />
        </Grid>

        <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomInput
            name={`${name}.direct_reports`}
            value={role?.direct_reports}
            label="# of Direct Reports"
            onChange={handleChange}
            error={touched[`${name}.direct_reports`] && errors[`${name}.direct_reports`]}
            onlyNumber
            maxlength={5}
          />
        </Grid>
      </Grid>

        <Box mb={2}>
          <Typography variant="subtitle1" color="secondary">
            About the Environment
          </Typography>
        </Box>

      <Grid container spacing={3} mb={5}>
        <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomMultipleSelect
            name={`${name}.industries`}
            value={role?.industries}
            label="Industry"
            onChange={value => {
              setFieldValue(`${name}.industries`, value);
            }}
            data={companyAndFunctions?.industries}
            error={Boolean(touched[`${name}.industries`] && errors[`${name}.industries`])}
            creatable
            dataWithoutCustom
          />
        </Grid>

        <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomSelect
            labelId="revenue_id"
            name={`${name}.revenue_id`}
            value={role?.revenue_id}
            label="Revenue"
            onChange={handleChange}
            data={valuesUnknownPossible?.revenues}
            error={Boolean(touched[`${name}.revenue_id`] && errors[`${name}.revenue_id`])}
            errorText={touched[`${name}.revenue_id`] && errors[`${name}.revenue_id`]}
          />
        </Grid>

        <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomSelect
            labelId="ownership_id"
            name={`${name}.ownership_id`}
            value={role?.ownership_id}
            label="Ownership Structure"
            onChange={handleChange}
            data={valuesUnknownPossible?.ownerships}
            error={Boolean(touched[`${name}.ownership_id`] && errors[`${name}.ownership_id`])}
            errorText={touched[`${name}.ownership_id`] && errors[`${name}.ownership_id`]}
          />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <CustomSelect
            labelId="employee_id"
            name={`${name}.employee_id`}
            value={role?.employee_id}
            label="Number of Employees"
            onChange={handleChange}
            data={valuesUnknownPossible?.employees}
            error={Boolean(touched[`${name}.employee_id`] && errors[`${name}.employee_id`])}
            errorText={touched[`${name}.employee_id`] && errors[`${name}.employee_id`]}
          />
        </Grid>
        {!isJobSpecific && (<>
          <Grid item md={4} xs={12} maxHeight={'65px'}>
          <CustomMultipleSelect
            name={`${name}.departments`}
            label="Function"
            value={role?.departments}
            onChange={value => {
              setFieldValue(`${name}.departments`, value);
            }}
            data={companyAndFunctions?.functions}
            error={Boolean(touched.departments && errors.departments)}
            creatable
            dataWithoutCustom
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <CustomSelect
            labelId="level_id"
            name={`${name}.level_id`}
            value={role?.level_id}
            label="Level"
            onChange={handleChange}
            data={companyAndFunctions?.levels}
            error={Boolean(touched[`${name}.level_id`] && errors[`${name}.level_id`])}
            errorText={touched[`${name}.level_id`] && errors[`${name}.level_id`]}
          />
        </Grid>
        </>
        )}
        </Grid>

      <Grid container spacing={3} alignItems="end" flexDirection="row-reverse">
        {!!isJobSpecific && (
          <Grid item xs={1} display="flex" alignItems="end" justifyContent={'flex-end'}>
            <Tooltip title={'Delete Role'}>
              <StyledDeleteButton type="button" onClick={() => onDelete({ id: values.histories[index].id, index })} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const StyledDeleteButton = styled(DeleteIcon)`
  height: 32px;
  width: 32px;
  fill: #b7b8b9;

  &:hover {
    fill: #2681db;
  }
`;

export default memo(Environment);
