import React from 'react';
import { Box, Typography } from '@mui/material';
import { ImgWrapper, TextBox } from '../../styles/components/CategoryFullDetailsStyles';

type TProps = {
  type: string;
  title: string;
  text: any;
};

const CategoryIcon: React.FC<TProps> = ({ type, title, text }) => {
  return (
    <Box sx={{ display: 'flex' }} key={title}>
      <ImgWrapper>
        <img src={require(`../../images/icons/ic_${type}_48px.svg`).default || ''} alt="" />
        <Typography variant={'body1'} fontWeight={600}>
          {title}
        </Typography>
      </ImgWrapper>
      <TextBox>{text}</TextBox>
    </Box>
  );
};

export default CategoryIcon;
