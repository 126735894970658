import { Box, Grid, styled, Typography } from '@mui/material';
import { SubmissionItemType } from '../../../../../types/candidate';
import ToggleButtonGroupCheck from '../../../../ToggleButtonGroupCheck';

type TProps = {
  submission: SubmissionItemType | undefined;
};

const PreferencesReport: React.FC<TProps> = ({ submission }) => {
  const isShowSection =
    (!!submission?.location?.title && submission?.is_workplace_location !== null) ||
    !!submission?.noticePeriod ||
    !!submission?.salary_comment ||
    !!submission?.interviewAvailabilities?.length ||
    !!submission?.availability_additional;
  return (isShowSection ? 
    <Box>
      <Typography sx={stylesSectionTitle} color="secondary">
        Preferences
      </Typography>
      <BoxBorder>
        <Grid container>
          {submission?.location?.title && submission?.is_workplace_location !== null && (
            <Grid item xs={6} px={4} mb={2}>
              <Typography sx={stylesSectionSubTitle} color="secondary">
                Workplace Location
              </Typography>
              <Box sx={{ fontSize: '16px', lineHeight: 1.5, fontWeight: 400 }}>
                {submission?.location?.title}{' '}
                <b>{submission?.is_workplace_location ? 'Yes - confirmed' : 'No - not confirmed'}</b>
              </Box>
            </Grid>
          )}
          {submission?.noticePeriod && (
            <Grid item xs={6} px={4} mb={2}>
              <Typography sx={stylesSectionSubTitle} color="secondary">
                Notice Period
              </Typography>
             <Box style={{display: 'flex' }}>
             <Box style={{ width: 'max-content' }}> 
             <ToggleButtonGroupCheck
                name="Notice Period"
                value=""
                onChange={() => {}}
                ariaLabel="Notice Period"
                data={submission?.noticePeriod ? [submission?.noticePeriod] : []}
                disabled={true}
                style={{ fontSize: '16px' }}
                />
                </Box>
              {submission?.noticePeriod?.title === 'Other' && submission?.notice_period_other && (
                <Box sx={{ fontSize: '16px', lineHeight: 1.5, fontWeight: 400 }}>{submission?.notice_period_other}</Box>
                )}
                </Box> 
            </Grid>
          )}

          {submission?.salary_comment && (
            <Grid item xs={6} px={4} mb={2}>
              <Typography sx={stylesSectionSubTitle} color="secondary">
                Other Compensation Notes
              </Typography>
              <Box sx={{ fontSize: '16px', lineHeight: 1.5, fontWeight: 400 }}>{submission?.salary_comment}</Box>
            </Grid>
          )}

          {!!submission?.interviewAvailabilities?.length && (
            <Grid item xs={6} px={4} mb={2}>
              <Typography sx={stylesSectionSubTitle} color="secondary">
                Interview Availability
              </Typography>
              <ToggleButtonGroupCheck
                name="Focus Areas"
                value=""
                onChange={() => {}}
                ariaLabel="Focus Areas"
                data={submission?.interviewAvailabilities?.length ? submission?.interviewAvailabilities : []}
                disabled={true}
                style={{ fontSize: '16px' }}
              />
            </Grid>
          )}

          {submission?.availability_additional && (
            <Grid item xs={6} pl={4} mb={2}>
              <Typography sx={stylesSectionSubTitle} color="secondary">
                Availability Notes
              </Typography>
              <Box sx={{ fontSize: '16px', lineHeight: 1.5, fontWeight: 400 }}>
                {submission?.availability_additional}
              </Box>
            </Grid>
          )}
        </Grid>
      </BoxBorder>
    </Box>
    : null
  );
};

const BoxBorder = styled(Box)`
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  padding: 20px 15px;
  margin-bottom: 10px;
  height: 100%;
`;

const stylesSectionTitle = {
  margin: '15px 0 10px',
  fontSize: '20px',
  lineHeight: '24px',
};

const stylesSectionSubTitle = {
  marginBottom: '5px',
  fontSize: '16px',
  lineHeight: '22px',
};

export default PreferencesReport;
