import { SubmissionItemType } from '../../types/candidate';
import SubmissionDataModel from '../SubmissionsData.model';
import SubmissionValuesModel from '../SubmissionsValues.model';

class SubmissionsAdapter {
  static mapSubmissionToValuesModel(submissions: Array<SubmissionItemType>): SubmissionValuesModel[] {
    return submissions.map(submission => new SubmissionValuesModel(submission));
  }

  static mapSubmissionsValuesModelToData(submissions: Array<SubmissionItemType>): SubmissionDataModel[] {
    return submissions.map(submission => new SubmissionDataModel(submission));
  }
}

export default SubmissionsAdapter;
