import { SxProps } from '@mui/system';

export const styleCard: SxProps = {
  overflow: 'visible',
  padding: 4,
};

export const styleSaveButton: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
};
