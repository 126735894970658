import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid } from '@mui/material';
import { Box, SxProps } from '@mui/system';

import CustomInput from '../../../CustomInput';
import CustomSelect from '../../../CustomSelect';
import CustomMultipleSelect from '../../../CustomMultipleSelect';

import { yaersOptions } from '../../../../utils/staticValues';
import { RootState } from '../../../../redux/store';

import { styleCard } from '../StylesFormSections';

type TProps = {
  formik: any;
  submissionId?: string | null ;
};

const Background: React.FC<TProps> = ({
  formik: { values, handleChange, setFieldValue, handleSubmit, errors, touched, isSubmitting },
  submissionId,
}) => {
  const { general } = useSelector((state: RootState) => state.dictionaries.dictionaries);
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const submissionIndex =
  candidate?.submissions && candidate?.submissions.findIndex((item: any) => item.id === Number(submissionId || 0));

  const yearsOfExperienceOptions = useMemo(() => yaersOptions(), []);

  return (
    <Card sx={styleCard}>
      {submissionId && typeof submissionIndex !== 'undefined' && submissionIndex >= 0 && (
        <>
          <Grid container spacing={3} mb={5}>
            <Grid item md={6} xs={12}>
              <CustomMultipleSelect
                name={`submissions[${submissionIndex}].designations`}
                label="Designation(s)"
                value={values.submissions[submissionIndex]?.designations || values.designations || []}
                onChange={value => {
                  setFieldValue(`submissions[${submissionIndex}].designations`, value);
                }}
                data={general?.designations}
                error={Boolean(
                  touched.submissions &&
                    touched.submissions.designations &&
                    errors.submissions &&
                    errors.submissions.designations,
                )}
                creatable
                dataWithoutCustom
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <CustomSelect
                labelId="years_of_experience"
                name={`submissions[${submissionIndex}].years_of_experience`}
                label="Which year did you start working?"
                value={values.submissions[submissionIndex]?.years_of_experience || values.years_of_experience || ''}
                onChange={handleChange}
                data={yearsOfExperienceOptions} // from 1985 to 2021
                error={Boolean(
                  touched.submissions &&
                    touched.submissions[submissionIndex]?.years_of_experience &&
                    errors.submissions &&
                    errors.submissions[submissionIndex]?.years_of_experience,
                )}
              />
            </Grid>
          </Grid>

          <Box sx={styleWrapAboutMe}>
            <CustomInput
              name={`submissions[${submissionIndex}].about`}
              label="Please provide a brief bio of yourself. 3-4 sentences is best."
              value={values.submissions[submissionIndex]?.about || values.about || ''}
              onChange={handleChange}
              multiline={true}
              error={
                touched.submissions &&
                touched.submissions[submissionIndex]?.about &&
                errors.submissions &&
                errors.submissions[submissionIndex]?.about ||
                errors.submissions
              }
            />
          </Box>
        </>
        )}
    </Card>
  );
};

export default React.memo(Background);

export const styleWrapAboutMe: SxProps = {
  position: 'relative',
};

export const styleWrapTooltip: SxProps = {
  position: 'absolute',
  top: 10,
  right: 10,
};
