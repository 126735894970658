import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { CalendarPicker } from '@mui/x-date-pickers';
import { ClickAwayListener } from '@mui/material';
import { format } from 'date-fns';

import { ReactComponent as CalendarIcon } from '../images/calendar.svg';
import { FormatDate } from '../constants/constants';
import { TextFieldC } from './../styles/InputStyles';
import ErrorText from './ErrorText';

type TProps = {
  name: string;
  label?: string;
  value: Date | null;
  onChange?: (e: string | ChangeEvent<any>) => void;
  error?: boolean;
  errorText?: any;
  disabled?: boolean;
  dateFormat?: string;
  sx?: any;
};

const CustomDatePicker: React.FC<TProps> = ({
  name,
  value = '',
  label = '',
  onChange,
  error,
  errorText,
  dateFormat = FormatDate.roleDate,
  sx,
  disabled = false,
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    !!value && !date && setDate(new Date(value));
  }, [value, date]);
  return (<>
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className="datePickerWrap">
        <CalendarIcon className="svg-icon"/>
        <TextFieldC
          value={!!date ? format(new Date(date), dateFormat) : ''}
          label={label}
          onClick={e => {
            e.preventDefault();
            if (!disabled) setIsOpen(!isOpen);
          }}
          InputLabelProps={{
            style: { fontWeight: 400 },
          }}
          InputProps={{
            readOnly: true,
            style: { color: '#000000de', fontWeight: 400 },
          }}
          onChange={() => {}}
          disabled={disabled}
          error={!!error}
        />
        {isOpen && (
          <div className="datePicker">
            <CalendarPicker 
              date={!!date ? (new Date(date)) : null} 
              onChange={(value: any) => {
                onChange && onChange(format(new Date(value), dateFormat));
                setDate(value);
              }}
              views={['month', 'year']}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
    <ErrorText error={errorText} />
</>
  );
};

export default memo(CustomDatePicker);
