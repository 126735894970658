import React from 'react';
import {JobPosting} from 'schema-dts';
import {JsonLd} from 'react-schemaorg';
import {JobsType} from "../../types/jobs";
import {employmentTypes} from "../../utils/staticValues";
import {showDuration} from "../../utils/functions";

type TProps = {
  job: JobsType;
};

const JobPostingJsonLd: React.FC<TProps> = ({ job }) => {

  function mapEmploymentType(input: string): string {
    const mapping: { [key: string]: string } = {
      "Full-Time Hire": "FULL_TIME",
      "Executive Search": "FULL_TIME",
      "Contract": "CONTRACTOR",
    };

    return mapping[input] || "FULL_TIME";
  }

  function getAmount(): string | undefined {
    if (job.employment_type === employmentTypes.CONTRACT) {
      if (job.published_rate) {
        if (job.display_compensation) {
          return String(job.published_rate);
        } else return undefined;
      }
    } else {
      if (job.salary_min || job.salary_max) {
        if (job.display_compensation) {
          return String(job.salary_max);
        } else return undefined;
      }
    }
  }

  function getDuration(): string | undefined {
    if (job.duration_id) {
      let duration = showDuration(job.duration_id);
      if (duration && duration.length) {
        return duration[0].replace(/^\s*\/\s*/, '').toUpperCase();
      }
    }
    if (job.display_compensation && (job.salary_max || job.salary_min)) {
      return 'YEAR'
    }
    return undefined;
  }

  return (
    <JsonLd<JobPosting>
      item={{
        "@context": "https://schema.org",
        "@type": "JobPosting",
        title: job.title,
        description: job.publishing_description,
        identifier: {
          "@type": "PropertyValue",
          name: "Clarity Recruitment",
          value: String(job.id),
        },
        datePosted: new Date(job.published_at).toISOString(),
        employmentType: mapEmploymentType(job.employment_type),
        hiringOrganization: {
          "@type": "Organization",
          name: "Clarity Recruitment"
        },
        jobLocation: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressLocality: job.city || "City",
            addressRegion: job.province?.title || "Region",
            addressCountry: 'Canada'
          },
        },
        jobLocationType: job.location?.title && job.location?.title === 'Fully Remote' ? "TELECOMMUTE" : undefined,
        applicantLocationRequirements: {
          "@type": "Country",
          "name": "Canada"
        },
        baseSalary: {
          "@type": "MonetaryAmount",
          currency: "CAD",
          value: {
            "@type": "QuantitativeValue",
            value: getAmount(),
            unitText: getDuration(),
          },
        },
      }}
    />
  );
};

export default JobPostingJsonLd;