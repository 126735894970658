import '@mui/lab/themeAugmentation';

import { createTheme as createMuiTheme } from '@mui/material/styles';
import variants from './variants';
import typography from './typography';
import breakpoints from './breakpoints';
import components from './components';
import shadows from './shadows';

const createTheme = (name: string) => {
  let themeConfig = variants.find(variant => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: components,
      typography: typography,
      shadows: shadows,
      palette: {
        primary: {
          main: '#2681DB',
        },
        secondary: {
          main: '#002855',
        },
        error: { main: '#DC2547' },
        warning: { main: '#FCC501' },
        info: { main: '#2681DB' },
        success: { main: '#00B192' },
      },
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    },
  );
};

export const COLORS = {
  primary: '#2681DB',
  secondary: '#002855',
  info: '#2681DB',
  success: '#00B192',
  successLight: '#E6F7F4',
  warning: '#FCC501',
  warningLight: '#FFFBED',
  error: '#DC2547',
  errorLight: '#dc25471a',
  disabled: '#B7B8B9',
  disabledLight: '#F5F8FA',
};

export default createTheme;
