import { createAsyncThunk } from '@reduxjs/toolkit';
import { jobs } from '../../api/jobs';
import { SearchType } from '../../types/jobs';

export const getCategories = createAsyncThunk('jobs/getCategories', async (_: void, thunkAPI) => {
  try {
    const { data } = await jobs.getCategories();
    return data;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getFeatured = createAsyncThunk('jobs/getFeatured', async (_: void, thunkAPI) => {
  try {
    const { data } = await jobs.getFeatured();
    return data;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const searchJob = createAsyncThunk('job/search', async (values: SearchType, thunkAPI) => {
  try {
    const { data } = await jobs.searchJob(values);
    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getJob = createAsyncThunk('jobs/getJob', async (id: number, thunkAPI) => {
  try {
    const { data } = await jobs.getJob(id);
    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getExternalId = createAsyncThunk('jobs/getExternalId', async (id: number, thunkAPI) => {
  try {
    const { data } = await jobs.getExternalId(id);
    return data;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
