import { CardActions, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ElementType, useState } from 'react';

type TProps = {
  title: string;
  experience?: string; // Only for Relevant Experience
  details?: string; // Only for Relevant Experience
  comment: string;
  numberOfCharacters?: number;
  toPdf?: boolean;
  component?: any;
};

const ListItemWithExtends: React.FC<TProps> = ({
  title,
  experience,
  details,
  comment,
  numberOfCharacters = 250,
  toPdf = true,
  component = 'li'
}) => {
  const [isExtended, setIsExtended] = useState(false);
  const commentTitle = title || 'Title of Category';
  return (
    <Typography component={component}>
      <StyledCardContent>
        <Typography component={`${!!experience ? 'b' : 'p'}`} fontSize="16px">
          {commentTitle}
          {!!experience ? <p>{` ${experience}`}</p> : ''}
          {!!details ? <p>{` ${details}`}</p> : ''}
          {!!comment && comment !== '<p><br></p>' ? (
            isExtended || toPdf || commentTitle.length + comment.length < numberOfCharacters ? (
              <>
                : <Typography component="span" dangerouslySetInnerHTML={{ __html: comment }} />{' '}
              </>
            ) : (
              <>
                :{' '}
                <Typography
                  component="span"
                  dangerouslySetInnerHTML={{ __html: `${comment.slice(0, numberOfCharacters)}...` }}
                />
              </>
            )
          ) : (
            ''
          )}
        </Typography>

        {!toPdf && !!comment && commentTitle.length + comment.length > numberOfCharacters && (
          <StyledCardActions>
            {isExtended && !toPdf ? (
              <StyledLink component="a" onClick={() => setIsExtended(!isExtended)}>
                Hide
              </StyledLink>
            ) : (
              <StyledLink component="a" onClick={() => setIsExtended(!isExtended)}>
                Show More
              </StyledLink>
            )}
          </StyledCardActions>
        )}
      </StyledCardContent>
    </Typography>
  );
};

const StyledCardActions = styled(CardActions)`
  display: inline;
  padding: 0;
`;

const StyledCardContent = styled(CardContent)`
  display: inline;
  padding: 0;

  &:last-child {
    padding: 0;
  }
`;

const StyledLink = styled(Typography)`
  display: inline;
  color: #2681db;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
`;

export default ListItemWithExtends;
