import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { create } from 'jss';
import { ThemeProvider } from 'styled-components/macro';

import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';

import './i18n';
import createTheme from './theme';
import routes from './routes';

import useTheme from './hooks/useTheme';
import { store } from './redux/store';

import { AuthProvider } from './contexts/JWTContext';
import Alert from './components/Alert/Alert';
import { PROJECT_NAME } from './constants/constants';
import { commonConfig } from './config';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')!,
});

const App: React.FC = () => {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  useEffect(()=>{
    const buildVersion = commonConfig.buildVersion;
    if (buildVersion === 'production') {
      const script = document.createElement('script');
      const content = document.createTextNode(
        "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3488522,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
        );
      script.appendChild(content);
      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet defaultTitle={`${PROJECT_NAME}`} />
        <Provider store={store}>
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <ThemeProvider theme={createTheme(theme)}>
                    <Alert />
                    <AuthProvider>{content}</AuthProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </StylesProvider>
        </Provider>
      </HelmetProvider>
    </>
  );
};

export default App;
