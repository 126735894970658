import { createAsyncThunk } from '@reduxjs/toolkit';
import { candidate } from '../../api/candidate';
import {
  ApplyCandidateType,
  SaveCandidateDataType,
  InviteCandidateRequestType,
  ScorecardRespType,
} from '../../types/candidate';
import { errorHandler } from '../../utils/functions';

export const getCandidate = createAsyncThunk('candidate/getCandidate', async (id: number, thunkAPI) => {
  try {
    const { data } = await candidate.getCandidate(id);
    return data;
  } catch (err: any) {
    return errorHandler(err, thunkAPI);
  }
});

export const saveCandidate = createAsyncThunk(
  'candidate/saveCandidate',
  async (values: SaveCandidateDataType, thunkAPI) => {
    try {
      const { data } = await candidate.saveCandidate(values);
      return data;
    } catch (err: any) {
      return errorHandler(err, thunkAPI);
    }
  },
);

export const saveRoleCandidate = createAsyncThunk(
  'candidate/saveRoleCandidate',
  async (values: SaveCandidateDataType, thunkAPI) => {
    try {
      const { data } = await candidate.saveCandidate(values);
      return data;
    } catch (err: any) {
      return errorHandler(err, thunkAPI);
    }
  },
);

export const uploadResume = createAsyncThunk('candidate/uploadResume', async (value: { file: string }, thunkAPI) => {
  try {
    const { data } = await candidate.uploadResume(value);
    return data;
  } catch (err: any) {
    return errorHandler(err, thunkAPI);
  }
});

export const applyCandidate = createAsyncThunk(
  'candidate/applyCandidate',
  async (values: ApplyCandidateType, thunkAPI) => {
    try {
      const { data } = await candidate.applyCandidate(values);
      return data;
    } catch (err: any) {
      return errorHandler(err, thunkAPI);
    }
  },
);

export const inviteCandidate = createAsyncThunk(
  'candidate/inviteCandidate',
  async (value: InviteCandidateRequestType, thunkAPI) => {
    try {
      const { success } = await candidate.inviteCandidate(value);
      return success;
    } catch (err: any) {
      return errorHandler(err, thunkAPI);
    }
  },
);

export const getScorecard = createAsyncThunk('candidate/getScorecard', async (id: string, thunkAPI) => {
  try {
    const { data } = await candidate.getScorecard(id);
    return data;
  } catch (err: any) {
    return errorHandler(err, thunkAPI);
  }
});

export const saveScorecard = createAsyncThunk(
  'candidate/saveScorecard',
  async (params: { id: number; values: Array<ScorecardRespType> }, thunkAPI) => {
    try {
      const { data } = await candidate.saveScorecard(params);
      return data;
    } catch (err: any) {
      return errorHandler(err, thunkAPI);
    }
  },
);

export const approveCandidate = createAsyncThunk('candidate/approveCandidate', async (id: number, thunkAPI) => {
  try {
    const { data } = await candidate.approveCandidate(id);
    return data;
  } catch (err: any) {
    return errorHandler(err, thunkAPI);
  }
});
