import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Dashboard from '../../components/CandidateProfile/Dashboard/Dashboard';
import Loader from '../../components/Loader';

import { ALERT_MESSAGES, PROFILE_PATH, ROUTES, ROUTES_SPECIFIC } from '../../constants/constants';
import { getCandidate } from '../../redux/actions/candidate';
import { setCandidateState } from '../../redux/reducers/candidate';
import { setAlert } from '../../redux/reducers/alert';
import { RootState } from '../../redux/store';
import { getNameRoute } from '../../utils/stepsRouting';

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);

  const { candidate, isInvitedCandidate, errors } = useSelector((state: RootState) => state.candidate);
  const { profile, isCandidate } = useSelector((state: RootState) => state.user);
  const { activeStep } = useSelector((state: RootState) => state.additional);

  const candidateId = isCandidate ? profile?.candidate_id?.toString() : params.get('candidate_id') || params.get('id');
  const submissionId = params.get('submissionId');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    !!candidate && setLoading(false);
  }, [candidate]);

  useEffect(() => {
    if (Object.keys(profile).length && !candidate) {
      !!candidateId && dispatch(getCandidate(+candidateId));
    }
  }, [candidateId, profile, candidate, dispatch]);

  // useEffect(() => {
  //   if (isInvitedCandidate) {
  //     dispatch(setAlert({ type: 'success', message: ALERT_MESSAGES.INVITED_SUCCESS }));
  //     dispatch(setCandidateState({ type: 'candidate', data: { ...candidate, invite_status: 'Sent' } }));
  //     dispatch(setCandidateState({ type: 'isInvitedCandidate', data: false }));
  //   }
  // }, [isInvitedCandidate, candidate, dispatch]);

  useEffect(() => {
    errors && dispatch(setAlert({ type: 'error', message: ALERT_MESSAGES.INVITED_FAIL }));
  }, [errors, dispatch]);

  useEffect(() => {
    if (!isCandidate && candidateId && submissionId && !pathname.includes('profile-details')) {
      navigate(
        `/${PROFILE_PATH}/${getNameRoute(activeStep, isCandidate)}?id=${
          candidateId?.toString() || ''
        }&submissionId=${submissionId}`,
      );
    }
  }, [activeStep, candidateId, isCandidate, navigate, pathname, submissionId]);

  const inviteCandidateRenderPage = useMemo(() => {
    if (isCandidate) {
      navigate(
        `/${PROFILE_PATH}/${submissionId ? ROUTES_SPECIFIC.GENERAL : ROUTES.GENERAL}?id=${candidateId}${
          submissionId ? `&submissionId=${submissionId}` : ''
        }`,
      );
      return null;
    } else {
      return <Dashboard />;
    }
  }, [candidateId, isCandidate, navigate, submissionId]);

  return loading ? <Loader /> : inviteCandidateRenderPage;
};

export default memo(DashboardPage);
