import styled from 'styled-components/macro';
import { Paper } from '@mui/material';
import { ReactComponent as Logo } from '../../../images/logo/LogoClarity.svg';

export const Wrapper = styled(Paper)`
  justify-content: center;
  width: 580px;
  text-align: center;
  box-shadow: 0 0 5px rgba(32, 39, 101, 0.05);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Brand = styled(Logo)`
  width: 200px;
  height: 100px;
`;

export const SuccessMessage = styled('div')`
  p {
    color: #00b192;
    line-height: 1.6;
    padding: 10px;
  }
  background-color: #e6f7f4;
  border-radius: 5px;
  height: 'min-content';
  min-height: 62px;
  display: flex;
  justify-content: space-between;
  vertical-align: center;
  padding: 0 30px;
`;
