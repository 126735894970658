import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, AppBar as MuiAppBar, Toolbar, Tooltip } from '@mui/material';
import styled from 'styled-components/macro';

import UserDropdown from './UserDropdown';
// import SearchComponent from '../SearchComponent';
import Logo from '../../images/logo/LogoNewClarity.svg';
import OpenInNew from '../../images/icons/ic_open_in_new_48px.svg';
import Settings from '../../images/icons/ic_settings_48px.svg';

import { createURLWithoutParam } from '../../utils/functions';
import { ADMIN_LINK } from '../../constants/constants';
import { RootState } from '../../redux/store';

const HeaderRecruiter: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.user);

  let newTabURL: string = '';

  if (window.location.href.includes('newTab')) {
    newTabURL = createURLWithoutParam('newTab');
  } else {
    newTabURL = window.location.href;
  }

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item style={{ marginLeft: '5px' }}>
            <img src={Logo} alt="logo" loading="lazy" height={50} />
          </Grid>

          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>{/* <SearchComponent /> */}</Grid>

              {profile.is_admin && (
                <Tooltip title="Settings">
                  <Grid item pt={1} pl={2}>
                    <a href={ADMIN_LINK} target="blank">
                      <img src={Settings} alt="settings" />
                    </a>
                  </Grid>
                </Tooltip>
              )}
              <Tooltip title="Open in new tab">
                <Grid item pt={1} pl={2}>
                  <a href={newTabURL} target="blank">
                    <img src={OpenInNew} alt="openInNew" />
                  </a>
                </Grid>
              </Tooltip>

              <Grid item>
                <UserDropdown />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderRecruiter;

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
`;
