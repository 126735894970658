import { createGlobalStyle } from 'styled-components/macro';
import { GlobalStyleProps } from '../types/styles';

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    //overflow-x: hidden;
  }

  body {
    background: #F5F8FA;
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

export default GlobalStyle;
