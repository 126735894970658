import { updatePassword, updateProfile } from './../actions/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALERT_MESSAGES } from '../../constants/constants';

export type AlertType = {
  open: boolean;
  type: string;
  message: string;
  length?: number;
};

const defaultState: AlertType = {
  open: false,
  type: 'success',
  message: ALERT_MESSAGES.SAVED_SUCCESS,
  length: 5000,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: defaultState,
  reducers: {
    setAlert: (state, { payload }: PayloadAction<any>) => {
      state.open = true;
      state.type = payload.type;
      state.message = payload.message;
      state.length = +payload.time >= 0 ? payload.time : 5000;
    },
    closeAlert: state => {
      state.open = false;
      state.message = '';
    },
  },
  extraReducers: {
    [updatePassword.pending.toString()]: state => {},
    [updatePassword.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = payload && payload.success ? 'success' : 'error';
      state.message =
        payload && payload.success ? payload.message ?? 'Password successfully updated!' : ALERT_MESSAGES.DEFAULT_ERROR;
    },
    [updatePassword.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.message ?? 'Something went wrong';
    },
    [updateProfile.pending.toString()]: state => {},
    [updateProfile.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = payload && payload.success ? 'success' : 'error';
      state.message =
        payload && payload.success ? payload.message ?? 'Profile successfully updated!' : ALERT_MESSAGES.DEFAULT_ERROR;
    },
    [updateProfile.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.message ?? ALERT_MESSAGES.DEFAULT_ERROR;
    },
  },
});

export const { setAlert, closeAlert } = alertSlice.actions;
export default alertSlice.reducer;

export const alertShowThunk =
  ({ type, message, time }: { type: string; message?: string; time?: number }): any =>
  async (dispatch: any) => {
    const alertData = {
      type: type || 'success',
      message: message || ALERT_MESSAGES.SAVED_SUCCESS,
      time: time || 5000,
    };
    dispatch(setAlert(alertData));
  };

export const alertCloseThunk = () => async (dispatch: any) => {
  dispatch(closeAlert());
};
