import { objToId, setEmploymentTypes } from '../utils/formatData';
import Step3ValuesModel from './step3Values.model';

class Step3DataModel {
  level_id?: number;
  location_id?: number;
  industries: Array<number>;
  employmentTypes: Array<number>;
  salary_max: number | null;
  salary_min: number | null;
  rate_max: number | null;
  rate_min: number | null;
  functions: Array<any>;
  projects: Array<any>;
  reasons: Array<any>;
  comment?: string;

  constructor(data: Step3ValuesModel) {
    this.level_id = data?.level_id;
    this.location_id = data?.location_id;
    this.industries = objToId(data?.industries) || [];
    this.employmentTypes =
      setEmploymentTypes({
        employmentTypes_fullTime: data?.employmentTypes_fullTime,
        employmentTypes_contract: data?.employmentTypes_contract,
      }) || [];
    this.salary_max = data?.salary_max;
    this.salary_min = data?.salary_min;
    this.rate_max = data?.rate_max;
    this.rate_min = data?.rate_min;
    this.functions = data?.functions;
    this.projects = data?.projects;
    this.reasons = data?.reasons;
    this.comment = data?.comment;
  }
}

export default Step3DataModel;
