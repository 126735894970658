import { CSSProperties } from 'react';
import { SxProps } from '@mui/system';
import styled from 'styled-components/macro';

export const styleCard: SxProps = {
  padding: 4,
};

export const Table = styled('table')`
  width: 100%;
  min-width: 850px;
  border-spacing: 0;
  border: 1px solid #e9e7e7;
  border-radius: 5px;
`;

export const THead = styled('thead')`
  background-color: #f8f8f8;
`;

export const TBody = styled('tbody')``;

export const TR = styled('tr')`
  border-bottom: 0.5px solid #e9e7e7;
  vertical-align: top;
`;

export const TH = styled('th')<{ width?: string; isBorder?: string; style?: CSSProperties }>(
  ({ width, isBorder }) => `
    width: ${width || ''};
    min-width: ${width || ''};
    max-width: ${width || ''};
    font-weight: 500;
    padding: 5px;
    border: 0.5px solid #e9e7e7;
    border-left: ${!!isBorder ? '0.5px solid #e9e7e7' : 'none'};
    border-right: ${!!isBorder ? '0.5px solid #e9e7e7' : 'none'};
  `,
);

export const TD = styled('td')<{ width?: string; isBorder?: string; style?: CSSProperties }>(
  ({ width, isBorder }) => `
    width: ${width || ''};
    min-width: ${width || ''};
    max-width: ${width || ''};
    font-weight: 400;
    padding: 5px;
    border: 0.5px solid #e9e7e7;
    border-left: ${!!isBorder ? '0.5px solid #e9e7e7' : 'none'};
    border-right: ${!!isBorder ? '0.5px solid #e9e7e7' : 'none'};
  `,
);
