import React from 'react';
import { Box, Typography } from '@mui/material';

import { GreenSwitch } from '../../../styles/ButtonStyles';

type TProps = {
  title: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
};

const SubFunctionsItem: React.FC<TProps> = ({ title, checked, onChange, disabled }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ height: 44 }}>
    <Box sx={{ height: 44 }} alignItems={'center'}>
      <Typography variant="subtitle1" color="secondary" gutterBottom pt={3}>
        {title}
      </Typography>
    </Box>

    <Box sx={{ height: 44 }} pt={1} alignItems="center">
      <GreenSwitch checked={checked} onChange={onChange} disabled={disabled} />
    </Box>
  </Box>
);

export default SubFunctionsItem;
