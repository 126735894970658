import React, { memo } from 'react';
import { Card as MuiCard, Typography, Box, Alert as MuiAlert } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';

import CustomButton from '../../../CustomButton';
import DeleteImg from '../../../../images/modal/DeleteModalImg.svg';

type TProps = {
  onConfirm: () => void;
};

const ModalRoleDelete: React.FC<TProps> = ({ onConfirm }) => {
   return (
    <Box sx={styleWrapper}>
      <Card p={'70px 50px 90px'}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box component="img" height={100} width={100} m={1} src={DeleteImg} />
        </Box>

        <Typography variant="h1" gutterBottom align="center" color="secondary">
          Are you sure you want to delete?
        </Typography>

        <Box display="flex" justifyContent="center" alignItems="center" padding="20px">
          <CustomButton variant="contained" onClick={onConfirm}>
           Confirm
          </CustomButton>
        </Box>
      </Card>
    </Box>
  );
};

export default memo(ModalRoleDelete);

const Card = styled(MuiCard)(spacing);

const styleWrapper = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  maxWidth: 730,
  padding: '0 100px',
};
