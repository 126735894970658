import React from 'react';

import Step1GeneralProfile from '../FormSections/Step1/Step1GeneralProfile';
import Step1JobSpecificProfile from '../FormSections/Step1/Step1JobSpecificProfile';

type TProps = {
  id: string;
  submissionId?: string | null;
  handleNext: (values: any) => void;
};

const Step1: React.FC<TProps> = ({ id, submissionId, handleNext }) => {
  return !!submissionId ?
      <Step1JobSpecificProfile id={id} submissionId={submissionId} handleNext={handleNext}/>
:
      <Step1GeneralProfile id={id} handleNext={handleNext}/>;
};

export default React.memo(Step1);
