export const PROJECT_NAME = 'Clarity';
export const COMPANY_NAME = 'Test Company';
export const ADMIN_LINK = 'https://apilumafi-talent.findingclarity.ca';
export const PROFILE_PATH = 'candidate-profile';
export const INVITE_PATH = 'invite';

export const ROUTES = {
  GENERAL: 'general',
  WORK_HISTORY: 'work-history',
  WHAT_ARE_YOU_LOOKING_FOR: 'what-are-you-looking-for',
  WORKPLACE_CULTURE: 'workplace-culture',
  COMPLETE: 'complete',
};

export const ROUTES_SPECIFIC = {
  GENERAL: 'general',
  WORK_HISTORY: 'work-history',
  RELEVANT_EXPERIENCE: 'relevant-experience',
  PREFERENCES: 'preferences',
  DESIRED_COMPANY_CULTURE: 'desired-company-culture',
};

export const ROUTES_CANDIDATE = {
  GENERAL_INFO: 'general-info',
  PROFESSIONAL_EXPERIENCE: 'professional-experiennce',
  WHAT_ARE_YOU_LOOKING_FOR_NEXT: 'what-are-you-looking-for-next',
  WHAT_IS_MOST_IMPORTANT: 'what-is-most-important',
  SCHEDULE_A_TIME_TO_MEET: 'schedule-a-time-to-meet',
};

export const OVERALL_FIT = {
  HIGH: 'High Overall Fit',
  MEDIUM: 'Medium Overall Fit',
  LOW: 'Low Overall Fit',
};

export const ALERT_MESSAGES = {
  SAVED_SUCCESS: 'Candidate saved successfully',
  SAVED_FAIL: 'Save failed',
  DEFAULT_ERROR:
    'Something went wrong. Please reach out to us at support@findingclarity.ca ' +
    'if you continue to experience this issue.',
  UPLOAD_RESUME_SUCCESS: 'Resume uploaded successfully',
  UPLOAD_RESUME_FAIL: 'Resume is uploaded failed',
  INVITED_SUCCESS: 'Invitation sent successfully',
  INVITED_FAIL: 'Invite failed',
  COMMENT_REQUIRED: 'Please add a comment for all rows you indicated “Exceeds” or “Some” experience',
  FROM_WHERE_REQUIRED: 'Please select From Where? for all rows you indicated “Exceeds”, “Meets” or “Some” experience',
  SCORE_NEEDED: 'Please score all skills',
};

export enum InviteStatus {
  notSent = 'Not Sent',
  sent = 'Sent',
  accepted = 'Accepted',
  invited = 'Invited',
  completed = 'Completed',
}

export const INVITE_STATUS = {
  NOT_SENT: InviteStatus.notSent,
  SENT: InviteStatus.sent,
  ACCEPTED: InviteStatus.accepted,
  INVITED: InviteStatus.invited,
  COMPLETED: InviteStatus.completed,
};

export const FORMAT_DATE = {
  ROLE_DATE: 'MMMM yyyy',
};

export enum FormatDate {
  statusInvite = 'MMM dd, yyyy',
  roleDate = 'MMMM yyyy',
}

export const EXPERIENCE_LEVELS = {
  MEETS: 'Meets',
  SOME: 'Some',
  NONE: 'None',
  EXCEEDS: 'Exceeds',
};

export const workplaceConfirmation = [
  { id: 1, title: 'Yes - I am comfortable with this' },
  { id: 0, title: 'No - This doesn’t work for me' },
];

export const DEFAULT_UTM_SOURCE = 'Company Website';
