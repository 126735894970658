import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { User as UserIcon, LogOut as LogOutIcon } from 'react-feather';
import styled from 'styled-components/macro';
import {
  Menu,
  Tooltip,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton as MuiIconButton,
  Divider,
} from '@mui/material';

import useAuth from '../../hooks/useAuth';
import { RootState } from '../../redux/store';

const UserDropdown = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const { profile, isCandidate } = useSelector((state: RootState) => state.user);

  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleProfile = () => {
    navigate('/user/profile');
    closeMenu();
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/auth/sign-in');
  };

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="secondary"
          size="small"
        >
          <Avatar isCandidate={isCandidate ? 'true' : undefined}>
            {profile?.first_name?.toUpperCase().substring(0, 1)}
            {profile?.last_name?.toUpperCase().substring(0, 1)}
          </Avatar>
        </IconButton>
      </Tooltip>

      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <UserIcon size={20} />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <LogOutIcon size={20} />
          </ListItemIcon>
          <ListItemText>Sign out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Avatar = styled('span')<{ isCandidate?: string }>(
  ({ isCandidate }) => `
  width: 30px;
  height: 30px;
  font-size: 12px;
  border-radius: 50%;
  background: ${!!isCandidate ? '#fff' : '#002855'};
  color: ${!!isCandidate ? '#002855' : '#fff'};
  display: flex;
  justify-content: center;
  align-items: center;
`,
);
