import React from 'react';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { GreenRadio } from '../styles/SelectStyles';

type TProps = {
  name: string;
  value: string | number | boolean;
  data: Array<any>;
  onChange: (e: any) => void;
  labelStyle?: any;
};

const CustomRadioGroup: React.FC<TProps> = ({ name, value, data = [], onChange, labelStyle = {} }) => {
  return (
    <RadioGroup row aria-label={name} name={name} value={value} onChange={onChange}>
      {data?.map((item, index) => (
        <FormControlLabel
          key={index}
          value={item.id}
          control={<GreenRadio />}
          label={item.title}
          sx={{ '> span': labelStyle }}
        />
      ))}
    </RadioGroup>
  );
};

export default React.memo(CustomRadioGroup);
