import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';

import AppliedJobsTable from './AppliedJobsTable';

const ApplicationStatus: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <Box p={5}>
      <Typography variant="h1" gutterBottom color="secondary">
        Applied Jobs
      </Typography>

      <AppliedJobsTable />
    </Box>
  );
};

export default ApplicationStatus;
