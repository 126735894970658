import { createAsyncThunk } from '@reduxjs/toolkit';
import { dictionaries } from '../../api/dictionaries';
import { CompaniesType } from '../../types/dictionaries';
import { errorHandler } from '../../utils/functions';

export const getDictionaries = createAsyncThunk('dictionaries/getDictionaries', async (type: string, thunkAPI) => {
  try {
    const { data } = await dictionaries.getDictionaries(type);
    return { type, data };
  } catch (err: any) {
    return errorHandler(err, thunkAPI);
  }
});

export const postCompanies = createAsyncThunk('dictionaries/postCompanies', async (values: CompaniesType, thunkAPI) => {
  try {
    return await dictionaries.postCompanies(values);
  } catch (err: any) {
    return errorHandler(err, thunkAPI);
  }
});

export const getCandidateDictionaries = createAsyncThunk(
  'dictionaries/getCandidateDictionaries',
  async (type: string, thunkAPI) => {
    try {
      const { data } = await dictionaries.getCandidateDictionaries(type);
      return { type, data };
    } catch (err: any) {
      return errorHandler(err, thunkAPI);
    }
  },
);
