import { ROUTES, ROUTES_SPECIFIC } from '../constants/constants';

export const sidebarRecuiterList = [
  { text: 'GENERAL', url: `${ROUTES.GENERAL}` },
  { text: 'WORK HISTORY', url: `${ROUTES.WORK_HISTORY}`, subtext: 'in previous' },
  {
    text: 'WHAT ARE YOU LOOKING FOR?',
    url: `${ROUTES.WHAT_ARE_YOU_LOOKING_FOR}`,
    subtext: 'Target role, industry and compensation',
  },
];

export const sidebarCandidateList = [
  { text: 'GENERAL', url: `${ROUTES.GENERAL}` },
  { text: 'WORK HISTORY', url: `${ROUTES.WORK_HISTORY}`, subtext: 'in previous' },
  {
    text: 'WHAT ARE YOU LOOKING FOR?',
    url: `${ROUTES.WHAT_ARE_YOU_LOOKING_FOR}`,
    subtext: 'Target role, industry and compensation',
  },
  {
    text: 'WORKPLACE CULTURE',
    url: `${ROUTES.WORKPLACE_CULTURE}`,
  },
  { text: 'COMPLETE', url: `${ROUTES.COMPLETE}` },
];

export const sidebarCandidateListSpec = [
  { text: 'GENERAL', url: `${ROUTES_SPECIFIC.GENERAL}` },
  { text: 'WORK HISTORY', url: `${ROUTES_SPECIFIC.WORK_HISTORY}`, subtext: 'in previous' },
  {
    text: 'RELEVANT EXPERIENCE',
    url: `${ROUTES_SPECIFIC.RELEVANT_EXPERIENCE}`,
  },
  {
    text: 'PREFERENCES',
    url: `${ROUTES_SPECIFIC.PREFERENCES}`,
  },
  {
    text: 'DESIRED COMPANY CULTURE',
    url: `${ROUTES_SPECIFIC.DESIRED_COMPANY_CULTURE}`,
  },
];

export const sidebarCandidateListSpecShort = [
  { text: 'GENERAL', url: `${ROUTES_SPECIFIC.GENERAL}` },
  { text: 'WORK HISTORY', url: `${ROUTES_SPECIFIC.WORK_HISTORY}`, subtext: 'in previous' },
  {
    text: 'RELEVANT EXPERIENCE',
    url: `${ROUTES_SPECIFIC.RELEVANT_EXPERIENCE}`,
  },
  {
    text: 'PREFERENCES',
    url: `${ROUTES_SPECIFIC.PREFERENCES}`,
  },
];

export const sidebarList = (
  isCandidate: boolean,
  submissionId?: boolean,
  isOCPComplete?: boolean,
  isFullCulture?: boolean,
) => {
  if (isCandidate) {
    if (submissionId) {
      if (isFullCulture) {
        if (!isOCPComplete) return sidebarCandidateListSpec;
        else return sidebarCandidateListSpecShort;
      } else return sidebarCandidateListSpec;
    } else {
      return sidebarCandidateList;
    }
  } else {
    if (submissionId) {
      if (!isFullCulture) {
        return sidebarCandidateListSpec;
      } else return sidebarCandidateListSpecShort;
    } else return sidebarRecuiterList;
  }
};

export const yesNoRadioButton = [
  { id: 1, title: 'Yes' },
  { id: 0, title: 'No' },
];

export const employmentTypes = {
  FULL_TIME: 'Full-Time',
  CONTRACT: 'Contract',
};

export const tooltipTextAboutMe =
  'Madison Blackstone is a director of brand marketing, with experience managing global teams and multi million-dollar campaigns. Her background in brand strategy, visual design, and account management inform her mindful but competitive approach.';

export const textAddComment = 'Add a comment to describe your experience in this area';

export const typePhoneOptions = [
  { id: 'mobile_phone', title: 'Mobile' },
  { id: 'landline_phone', title: 'Landline' },
];

export const yaersOptions = () => {
  const array: Array<{ id: string; title: string }> = [];
  const currentYear = new Date().getFullYear();

  for (let i = 1985; i < currentYear; i++) {
    const option = { id: i.toString(), title: i.toString() };
    array.push(option);
  }

  return array;
};

export const initRoleCandidate = {
  title: '',
  company: '',
  start_date: null,
  start_year: '',
  end_date: null,
  end_year: '',
  industry_id: undefined,
  industries: [],
  revenue_id: undefined,
  employee_id: undefined,
  ownership_id: undefined,
  departments: [],
  reports_to: '',
  level_id: undefined,
  is_manage_team: 0,
  direct_reports: undefined,
  jobFunctions: [],
  responsibilities: [],
  projects: [],
  initiatives: [],
  additional: '',
  is_current: false,
};

export const filterValues = [
  { name: 'candidateName', label: 'Candidate Name' },
  { name: 'companyName', label: 'Company Name' },
  { name: 'targetSalary', label: 'Target Salary' },
  { name: 'targetRate', label: 'Target Rate' },
  { name: 'motivators', label: 'Motivators' },
  { name: 'companyCulture', label: 'Company Culture' },
];

export const filterValuesSpecial = [
  { name: 'candidateName', label: 'Candidate Name' },
  { name: 'companyName', label: 'Company Name' },
  { name: 'targetSalary', label: 'Target Salary' },
  { name: 'desiredCulture', label: 'Desired Culture' },
  { name: 'availability', label: 'Preferences' },
];

export const durations = [
  { title: '/ hour', id: 1 },
  { title: '/ day', id: 2 },
  { title: '/ week', id: 3 },
  { title: '/ month', id: 4 },
  { title: '/ year ', id: 5 },
];
