import styled from 'styled-components/macro';
import { TableCell as MuiTableCell, TextField } from '@mui/material';

export const TableCell = styled(MuiTableCell)`
  height: 34px;
  padding: 0 10px 0 10px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.2px;
  border: 1px solid #e9e7e7;
`;

export const TableHeaderCell = styled(MuiTableCell)`
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0.2px;
  border: 1px solid #e9e7e7;
`;

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.87) !important',
    '-webkit-text-fill-color': `rgba(0, 0, 0, 0.87) !important`,
  },
  input: {
    height: '34px',
    width: '100%',
    padding: 0,
    paddingLeft: '5px',
  },
}));

export const JobsHeaderStyle = {
  height: '53px',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  letterSpacing: '0.09px',
  color: '#002855',
  display: 'flex',
  alignItems: 'center'
};

export const JobsTitleCellStyle = {
  minHeight: '53px',
  height: 'auto',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  color: '#1565C0',
  display: 'flex',
  alignItems: 'center',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  textDecoration: 'none'
};

export const JobsCellStyle = {
  minHeight: '53px',
  height: 'auto',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#002855',
  display: 'flex',
  alignItems: 'center',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const JobsCellButtonStyle = {
  height: '53px',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#002855',
  display: 'flex',
  alignItems: 'center',
};