import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';

import ToggleButtonGroupCheck from '../../../ToggleButtonGroupCheck';

import { RootState } from '../../../../redux/store';

type TProps = { formik: any };

const LikeBeDoing: React.FC<TProps> = ({ formik: { values, setFieldValue } }) => {
  const { companyAndFunctions } = useSelector((state: RootState) => state.dictionaries.dictionaries);

  return (
    <>
      <Box mb={5}>
        {/* <Typography variant="body1" gutterBottom color="secondary" mb={2}>
          Functional
        </Typography> */}

        <ToggleButtonGroupCheck
          name="functions"
          value={values.functions}
          onChange={(event: any, value: any) => {
            setFieldValue('functions', value);
          }}
          ariaLabel="functions"
          data={companyAndFunctions?.subFunctions}
        />

        <ToggleButtonGroupCheck
          name="projects"
          value={values.projects}
          onChange={(event: any, value: any) => {
            setFieldValue('projects', value);
          }}
          ariaLabel="projects"
          data={companyAndFunctions?.projects}
        />
      </Box>

      {/* <Box mb={5}>
        <Typography variant="body1" gutterBottom color="secondary" mb={2}>
          Projects & Initiatives
        </Typography>

        <ToggleButtonGroupCheck
          name="projects"
          value={values.projects}
          onChange={(event: any, value: any) => {
            setFieldValue('projects', value);
          }}
          ariaLabel="projects"
          data={companyAndFunctions?.projects}
        />
      </Box> */}
    </>
  );
};

export default memo(LikeBeDoing);
