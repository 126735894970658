import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent, Box, Typography } from '@mui/material';
import SignInComponent from '../../components/Auth/SignIn';
import { HeaderText } from '../../styles/TextStyles';
import { Wrapper, Brand } from '../../styles/pages/AuthPages/SignInStyles';

function SignIn() {
  return (
    <Wrapper>
      <Helmet title="Sign In" />
      <Card>
        <CardContent>
          <Brand />

          <HeaderText style={{ textAlign: 'center' }}>Welcome Back</HeaderText>

          <Typography variant="body2" mb={3}>
            Login to your account to continue
          </Typography>

          <Box sx={{ padding: '0px 38px' }}>
            <SignInComponent />
          </Box>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default SignIn;
