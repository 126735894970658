import { withStyles } from '@mui/styles';
import { Button as MuiButton, Switch, Tooltip, Link } from '@mui/material';
import styled from 'styled-components/macro';
import { alpha } from '@mui/material/styles';

export const BuildMenuButton = withStyles({
  root: {
    color: '#12161B',
    width: '100px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontWeight: '400',
  },
  expanded: {},
})(MuiButton);

export const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: '#E4E7EB',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#00B192',
    '&:hover': {
      backgroundColor: alpha('#00B192', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#00B192',
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: '#12161B',
    opacity: 0.2,
  },
}));

export const SubmitButton = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#2681DB',
    borderRadius: '30px',
    margin: '15px auto',

    '&:hover': {
      backgroundColor: '#2681DB',
    },
  },
})(MuiButton);

export const OutlinedButton = withStyles({
  root: {
    color: '#2681DB',
    borderRadius: '30px',
    '&:hover': {
      color: '#2681DB',
    },
  },
})(MuiButton);

export const ContainedButton = withStyles({
  root: {
    color: '#FFF',
    backgroundColor: '#2681DB',
    borderRadius: '30px',
    height: '37px',
  },
})(MuiButton);

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.14)',
    padding: 0,
    borderRadius: '2px',
  },
}))(Tooltip);

export const BuildMenuLink = withStyles({
  root: {
    color: '#12161B',
    width: '100px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontWeight: '400',
    letterSpacing: '1px',
    padding: '6px 8px',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',

    '& svg': { marginRight: 5, width: 20 },

    '&:hover': { background: 'rgba(38,129,219,0.04)', textDecoration: 'none' },
  },
  expanded: {},
})(Link);

export const MobileBackButton = styled('div')({
  display: 'none',
  width: '100%',
  padding: '10px',
  fontSize: '18px',
  fontWeight: 600,
  color: '#002855',
  boxShadow: '0 0 6px rgba(4, 25, 100, 0.08)',
  backgroundColor: '#fff',
  marginBottom: '10px',
  '@media (max-width: 768px)': {
    display: 'block',
  },
});
