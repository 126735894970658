import { Box, Typography } from '@mui/material';

import IconItem from '../IconItem';

import MustHaveIcon from '../../../../../images/icons/MustHaveNew.svg';
import PreferredIcon from '../../../../../images/icons/Preffered.svg';
import RelevantExperienceList from './RelevantExperienceList';
import { calculateCountAllUniq, calculateCountChecked } from '../../../../../utils/functions';

type TProps = {
  submissionRequirements: Array<any> | [];
  toPdf: boolean;
};

const RelevantExperience: React.FC<TProps> = ({ submissionRequirements, toPdf = true }) => {
  const comment_types = ['Exceeds', 'Meets', 'Some', 'None'];
  const countMustHave: { [key: string]: number } = {
    all: calculateCountAllUniq(submissionRequirements, 3),
    Exceeds: calculateCountChecked(submissionRequirements, 3, 'Exceeds'),
    Meets: calculateCountChecked(submissionRequirements, 3, 'Meets'),
    Some: calculateCountChecked(submissionRequirements, 3, 'Some'),
    None: calculateCountChecked(submissionRequirements, 3, 'None'),
  };
  const countPreferred: { [key: string]: number } = {
    all: calculateCountAllUniq(submissionRequirements, 2),
    Exceeds: calculateCountChecked(submissionRequirements, 2, 'Exceeds'),
    Meets: calculateCountChecked(submissionRequirements, 2, 'Meets'),
    Some: calculateCountChecked(submissionRequirements, 2, 'Some'),
    None: calculateCountChecked(submissionRequirements, 2, 'None'),
  };

  const getTopCommentType = (comment_types: Array<string>, countObj: { [key: string]: number }): string => {
    let index = -1;
  for (let i = 0; i < comment_types.length; i++) {
      if (countObj[comment_types[i]] > 0) {
        index = i;
        break;
      }
  }
  return index >= 0 ? comment_types[index] : 'None';
  };

  const mustHaveTopCommentType = getTopCommentType(comment_types, countMustHave);
  const preferredTopCommentType = getTopCommentType(comment_types, countPreferred);

  return (
    <>
      <Typography sx={stylesSectionTitle} color="secondary">
        Relevant Experience
      </Typography>
      {countMustHave['Exceeds'] + countMustHave['Meets'] + countMustHave['Some'] + countMustHave['None'] > 0 && (
        <>
          <Box sx={stylesSubtitle}>
            <IconItem icon={MustHaveIcon} sx={{ margin: '2px 3px 2px 8px' }} />
            <Typography sx={{ letterSpacing: '0.09px', fontSize: '18px' }} color="secondary">
              Must-have Requirements
            </Typography>
            <Typography sx={stylesMeetsIcon} color="secondary">
              MEETS {countMustHave['Exceeds'] + countMustHave['Meets']}/{countMustHave.all}
            </Typography>
          </Box>

          {comment_types.map((type, index) => {
            if (countMustHave[type] > 0) {
              return (
                <RelevantExperienceList
                  key={index}
                  submissionRequirements={submissionRequirements}
                  comment_type={type}
                  importance_weight_id={3}
                  toPdf={toPdf}
                  isTop = {mustHaveTopCommentType === 'None' ? false : mustHaveTopCommentType === type }
                />
              );
            }
            return null;
          })}
        </>
      )}

      {countPreferred['Exceeds'] + countPreferred['Meets'] + countPreferred['Some'] + countPreferred['None'] > 0 && (
        <>
          <Box
            sx={{ ...stylesSubtitle, marginTop: countMustHave['Exceeds'] + countMustHave['Meets'] > 0 ? '50px' : 0 }}
          >
            <IconItem icon={PreferredIcon} sx={{ margin: '2px 3px 2px 8px' }} />
            <Typography sx={{ letterSpacing: '0.09px', fontSize: '18px' }} color="secondary">
              Highly Preferred Requirements
            </Typography>
            <Typography sx={stylesMeetsIcon} color="secondary">
              MEETS {countPreferred['Exceeds'] + countPreferred['Meets']}/{countPreferred.all}
            </Typography>
          </Box>

          {comment_types.map((type, index) => {
            if (countPreferred[type] > 0) {
              return (
                <RelevantExperienceList
                  key={index}
                  submissionRequirements={submissionRequirements}
                  comment_type={type}
                  importance_weight_id={2}
                  toPdf={toPdf}
                  isTop = {preferredTopCommentType === 'None' ? false : preferredTopCommentType === type }
                />
              );
            }
            return null;
          })}
        </>
      )}
    </>
  );
};

const stylesSectionTitle = {
  margin: '15px 0 10px',
  fontSize: '20px',
  lineHeight: '24px',
};

const stylesSubtitle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '3px 5px',
  background: '#F5F8FA',
  marginBottom: '10px',
};

const stylesMeetsIcon = {
  letterSpacing: '0.09px',
  marginLeft: '16px',
  fontSize: '14px',
  lineHeight: 1.33,
  borderRadius: '20px',
  backgroundColor: '#ffffff',
  padding: '2px 8px',
};

export default RelevantExperience;
