import React, { memo } from 'react';
import { Link, Typography } from '@mui/material';

import UserDropdownProfile from './UserDropdownProfile';

import Logo from '../../images/logo/LogoClarity.svg';

import { WrapHeaderProfile } from '../../styles/layouts/HeaderStyles';

type TProps = {
  title?: string;
};

const HeaderProfile: React.FC<TProps> = ({ title = 'My Profile' }) => (
  <WrapHeaderProfile>
    <Link href="https://findingclarity.ca" target="_blank" display="block">
      <img src={Logo} alt="logo" loading="lazy" height={45} />
    </Link>

    <Typography variant="h2" color="secondary">
      {title}
    </Typography>

    <UserDropdownProfile />
  </WrapHeaderProfile>
);

export default memo(HeaderProfile);
