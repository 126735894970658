import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';

export const TextFieldC = withStyles({
  root: {
    backgroundColor: '#fff',
    // minHeight: '80px',
    '& label': {
      color: '#B8B8B8',
      fontWeight: 400,
    },
    '& label.MuiInputLabel-shrink': {
      color: '#5E5E5E',
    },
    '& label.Mui-focused': {
      color: '#2681DB',
    },
    '& .MuiOutlinedInput-root': {
      fontWeight: 400,
      '& fieldset': {
        borderColor: '#B7B8B9',
      },
      '&:hover fieldset': {
        borderColor: '#B7B8B9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2681DB',
      },
    },
  },
})(TextField);
