import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Card } from '@mui/material';
import { FieldArray } from 'formik';
import { SxProps } from '@mui/system';

import Environment from './EnvironmentNew';
import Responsibilities from './Responsibilities';
import Initiatives from './Initiatives';
import Additional from './Additional';
import circleArrow from '../../../../images/icons/circleArrow.svg';
import CustomButton from '../../../CustomButton';

import { initRoleCandidate } from '../../../../utils/staticValues';
import RoleValuesModel from '../../../../models/roleValues.model';
import { getDictionaries } from '../../../../redux/actions/dictionaries';
import { setCandidateRole } from '../../../../redux/reducers/candidate';
import { setActiveStep } from '../../../../redux/reducers/additional';
import { saveRoleCandidate } from '../../../../redux/actions/candidate';
import { rolesSelector } from '../../../../redux/selectors/candidate.selectors';
import { RootState } from '../../../../redux/store';
import { PROFILE_PATH } from '../../../../constants/constants';

import { Accordion, AccordionDetails, AccordionSummary } from '../../../../styles/AccordionStyles';
import { styleCardSection, styleAccordionArrow, styleAccordion } from '../../StylesCandidateProfile';

type TProps = {
  id: string;
  handleNext: (values: any) => void;
  formik: any;
  values: any;
  setFieldValue: any;
  errors: any;
  handleSubmit: any;
  dataPrepareToSave: any;
  idUnknownValue: number;

};

const GeneralProfileWorkHistory: React.FC<TProps> = ({
  id,
  handleNext,
  formik,
  values,
  setFieldValue,
  errors,
  handleSubmit,
  dataPrepareToSave,
  idUnknownValue
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedRole } = useSelector((state: RootState) => state.candidate);
  const { isCandidate } = useSelector((state: RootState) => state.user);
  const roles = useSelector(rolesSelector);

  const [expandedPanel, setExpandedPanel] = useState(['panel1', 'panel2', 'panel3', 'panel4']);

  useEffect(() => {
    dispatch(getDictionaries('companyAndFunctions'));
  }, [dispatch]);

  const handleAccordionChange = (panel: any) => (event: any, isExpanded: any) => {
    if (isExpanded) {
      setExpandedPanel([...expandedPanel, panel]);
    } else {
      setExpandedPanel(expandedPanel.filter(el => el !== panel));
    }

    window.scrollTo(0, isExpanded.offsetTop - 100);
  };

  const onSave = useCallback(
    (panel: string, values: any, errors?: boolean, handleSubmit?: any) => {
      const data = dataPrepareToSave(values);

      if (!errors) {
        dispatch(saveRoleCandidate({ id: +id, values: data }));
        setPanels(panel);
      } else {
        handleSubmit();
      }
    },
    [dispatch, id],
  );

  const setPanels = (panel: string) => {
    if (expandedPanel.includes(panel)) {
      setExpandedPanel([...expandedPanel, panel]);
    } else {
      setExpandedPanel(expandedPanel.filter(el => el !== panel));
    }
  };

  const onAddAnotherRole = useCallback(
    (arrayHelpers: any, values: any, errors?: boolean, handleSubmit?: any) => {
      if (!errors) {
        onSave('panel1', values);
        dispatch(setCandidateRole(roles?.length));
        arrayHelpers.push(initRoleCandidate);
      } else {
        handleSubmit();
      }
      setPanels('panel1');
      window.scrollTo(0, 0);
    },
    [roles, onSave, dispatch],
  );

  const onDone = useCallback(
    (values: any, errors?: any, handleSubmit?: any) => {
      if (!errors || !errors.length) {
        const data = dataPrepareToSave(values);
        dispatch(saveRoleCandidate({ id: +id, values: data }));
        setActiveStep(0);
        navigate(`/${PROFILE_PATH}/profile-details?candidate_id=${id}`);
      } else {
        handleSubmit();
      }
    },
    [dispatch, id, navigate],
  );

  return (
    <FieldArray
      name="histories"
      render={arrayHelpers =>
        values.histories.map(
          (role: RoleValuesModel, index: number) =>
            selectedRole === index && (
              <Card sx={styleCardSection} key={index} className="styleCardSection">
                <Typography variant="h5" gutterBottom color="secondary" mb={5}>
                  Work History
                </Typography>

                <Accordion
                  key={'panel1'}
                  expanded={expandedPanel.includes('panel1')}
                  sx={styleAccordion}
                  onChange={handleAccordionChange('panel1')}
                >
                  <AccordionSummary expandIcon={<Box component="img" sx={styleAccordionArrow} src={circleArrow} />}>
                    <Box sx={stylesEnvironment}>
                      <Typography component="div" variant="h6" color="secondary">
                        Role
                      </Typography>

                      <Box display="flex" alignItems="center">
                        {!!role.title && (
                          <Typography component="div" variant="body1" mr={1} color="primary">
                            {role.title}
                          </Typography>
                        )}

                        {!!role.company && (
                          <Typography component="div" variant="body2" mr={2} color="secondary">
                            {role.company}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Environment
                      formik={formik}
                      role={role}
                      name={`histories[${index}]`}
                      index={index}
                      idUnknownValue={idUnknownValue}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  key={'panel2'}
                  expanded={expandedPanel.includes('panel2')}
                  sx={styleAccordion}
                  onChange={handleAccordionChange('panel2')}
                >
                  <AccordionSummary expandIcon={<Box component="img" sx={styleAccordionArrow} src={circleArrow} />}>
                    <Typography component="div">
                      <Typography component="div" variant="h6" gutterBottom color="secondary" mb={0}>
                        Functional Responsibilities
                      </Typography>

                      {expandedPanel.includes('panel2') && (
                        <Typography component="div" variant="body2">
                          Select all areas and skills that you performed in your role
                        </Typography>
                      )}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Responsibilities formik={formik} role={role} name={`histories[${index}]`} />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  key={'panel3'}
                  expanded={expandedPanel.includes('panel3')}
                  sx={styleAccordion}
                  onChange={handleAccordionChange('panel3')}
                >
                  <AccordionSummary expandIcon={<Box component="img" sx={styleAccordionArrow} src={circleArrow} />}>
                    <Typography component="div">
                      <Typography component="div" variant="h6" gutterBottom color="secondary" mb={0}>
                        Project & Initiatives
                      </Typography>

                      {expandedPanel.includes('panel3') && (
                        <Typography component="div" variant="body2">
                          Select all areas and skills that you performed in your role
                        </Typography>
                      )}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Initiatives formik={formik} role={role} name={`histories[${index}]`} />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  key={'panel4'}
                  expanded={expandedPanel.includes('panel4')}
                  sx={styleAccordion}
                  onChange={handleAccordionChange('panel4')}
                >
                  <AccordionSummary expandIcon={<Box component="img" sx={styleAccordionArrow} src={circleArrow} />}>
                    <Typography variant="h6" gutterBottom color="secondary">
                      Additional
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Additional formik={formik} role={role} name={`histories[${index}].additional`} />
                  </AccordionDetails>
                </Accordion>
                <Box display="flex" gap={'15px'} justifyContent="right" alignItems="center" p={5} pr={0}>
                  {!isCandidate && (
                    <CustomButton
                      type="button"
                      variant="text"
                      onClick={() => onDone(values, errors.histories && errors.histories[index], handleSubmit)}
                    >
                      Done
                    </CustomButton>
                  )}
                  <CustomButton
                    type="button"
                    variant="outlined"
                    onClick={() =>
                      onAddAnotherRole(
                        arrayHelpers,
                        values, //@ts-ignore
                        errors.histories && errors.histories[index],
                        handleSubmit,
                      )
                    }
                  >
                    Add another role
                  </CustomButton>
                  <CustomButton variant="contained">Next</CustomButton>
                </Box>
              </Card>
            ),
        )
      }
    />
  );
};

export default React.memo(GeneralProfileWorkHistory);

const stylesEnvironment: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};
