import React, { CSSProperties, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CardContent, Card as MuiCard, Typography, Box } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing, SxProps } from '@mui/system';

import CustomButton from '../../../CustomButton';

import ModalIcon from '../../../../images/modal/LastStepIcon.svg';
import { RootState } from '../../../../redux/store';

type TProps = {
  handleNext?: () => void;
  link?: string;
};

const MostImportantInWorkPlace: React.FC<TProps> = ({ handleNext, link }) => {
  const { isCandidate } = useSelector((state: RootState) => state.user);
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const heightBox = useMemo(() => (isCandidate ? 'calc(100vh - 152px)' : '100vh'), [isCandidate]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height={heightBox}>
      <Box maxWidth={640} width="100%">
        <Card mb={6} p={5}>
          <CardContent>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box component="img" sx={styleImage} src={ModalIcon} />
            </Box>

            <Typography variant="h1" gutterBottom align="center" color="secondary">
              Thanks for completing a profile with us!
            </Typography>

            <Typography variant="body2" gutterBottom align="center">
              We'll be on the lookout for opportunities that best suit what you're looking for and be in touch.
            </Typography>

            {!!candidate?.include_schedule && (
              <Box display="flex" justifyContent="center" alignItems="center" padding="20px">
                <CustomButton type="button" variant="contained" onClick={() => {}}>
                  <a href={link} target="blank" style={styleLink}>
                    SCHEDULE A TIME TO MEET
                  </a>
                </CustomButton>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default memo(MostImportantInWorkPlace);

const Card = styled(MuiCard)(spacing);

export const styleCard: SxProps = {
  padding: 4,
  height: '100%',
  maxHeight: '100vh',

  '& .calendly-inline-widget': {
    height: '100% !important',
  },
};

export const styleImage: SxProps = {
  height: 50,
  width: 50,
};

export const styleLink: CSSProperties = {
  color: '#fff',
  textDecoration: 'none',
};
