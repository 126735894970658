import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getCandidate,
  saveCandidate,
  saveRoleCandidate,
  uploadResume,
  applyCandidate,
  inviteCandidate,
  getScorecard,
  saveScorecard,
} from '../actions/candidate';
import { CandidateType, ScorecardRespType, ScorecardType, SubmissionItemType } from '../../types/candidate';

type CandidateStateType = {
  candidate: CandidateType | null;
  selectedRole: number;
  isSavedCandidate: boolean;
  isSavedBackCandidate: boolean;
  isSavedRole: boolean;
  isUploadedResume: boolean;
  isUploading: boolean;
  uploadError: string | null;
  isLoading: boolean;
  errors: string | null;
  candidateApplied: boolean;
  isApplying: boolean;
  applyingError: string | null;
  isInvitedCandidate: boolean;
  scorecard: ScorecardType | null;
  scorecardSavedData: Array<ScorecardRespType>;
  isSaving: boolean;
  isScorecardLoading: boolean;
  openRolesMenu: boolean;
  showProfileDetails: boolean;
  showUploadResume: boolean;
  isFirstEmailAlert: boolean;
  isDownloadingPdf: boolean;
  errorPdf: string | null;
  ocp_answers: { top: { [key: string]: string; }; bottom: { [key: string]: string; }; } | null;
};

const initialState: CandidateStateType = {
  candidate: null,
  selectedRole: 0,
  isSavedCandidate: false,
  isSavedBackCandidate: false,
  isSavedRole: false,
  isUploadedResume: false,
  isUploading: false,
  uploadError: null,
  isLoading: false,
  errors: null,
  candidateApplied: false,
  isApplying: false,
  applyingError: null,
  isInvitedCandidate: false,
  scorecard: null,
  scorecardSavedData: [],
  isSaving: false,
  isScorecardLoading: false,
  openRolesMenu: false,
  showProfileDetails: false,
  showUploadResume: true,
  isFirstEmailAlert: true,
  isDownloadingPdf: false,
  errorPdf: null,
  ocp_answers: null,
};

const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setCandidateState(state, { payload }: PayloadAction<{ type: string; data: any }>) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    setCandidateRole: (state, { payload }: PayloadAction<number>) => {
      state.selectedRole = payload;
    },
    setCandidateSubmissionInviteStatus: (state, { payload }: PayloadAction<SubmissionItemType>) => {
      const submissionIndex = state.candidate
        ? state.candidate.submissions.findIndex((el: SubmissionItemType) => el.id === payload.id)
        : -1;
      if (!!state.candidate && submissionIndex >= 0) {
        state.candidate.submissions[submissionIndex] = payload;
      }
    },
    showApplicationSent: (state, { payload }: PayloadAction<boolean>) => {
      state.candidateApplied = payload;
    },
    setErrorAlert: (state, { payload }: PayloadAction<string>) => {
      state.applyingError = payload;
    },
    setScorecardSavedData: (state, { payload }: PayloadAction<ScorecardRespType>) => {
      const current = state.scorecardSavedData.findIndex(el => el.id === payload.id);

      if (current >= 0) {
        state.scorecardSavedData[current] = payload;
      } else {
        state.scorecardSavedData = [...state.scorecardSavedData, payload];
      }
    },
    setShowProfileDetails: (state, { payload }: PayloadAction<boolean>) => {
      state.showProfileDetails = payload;
    },
    setCandidate: (state, { payload }) => {
      state.candidate = payload.data;
    },
    setCandidateWorkHistoryArr: (state, { payload }) => {
      if (state.candidate) {
        state.candidate.histories = payload.data;
      }
    },
    setCandidateWorkHistory: (state, { payload }) => {
      if (!!state.candidate?.histories) {
        const currentIndex = state.candidate?.histories.findIndex(el => payload.id && el.id === payload.id);
        if (currentIndex >= 0) {
          state.candidate.histories[currentIndex] = payload;
        } else {
          state.candidate.histories = [...state.candidate?.histories, payload];
        }
      }
    },
    deleteCandidateWorkHistory: (state, { payload }) => {
      if (!!state.candidate?.histories && state.candidate?.histories?.length > 0) {
        const currentIndex = state.candidate?.histories.findIndex(el => payload.id && el.id === payload.id);
        if (currentIndex >= 0) {
          state.candidate.histories.splice(currentIndex, 1);
        }
        if (!payload.id && payload.index < state.candidate.histories.length) {
          state.candidate.histories.splice(payload.index, 1);
        }
      }
    },
  },
  extraReducers: {
    [getCandidate.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [getCandidate.fulfilled.toString()]: (state, { payload }) => {
      state.candidate = payload;
      state.isLoading = false;
    },
    [getCandidate.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [saveCandidate.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [saveCandidate.fulfilled.toString()]: (state, { payload }) => {
      state.candidate = { ...state.candidate, ...payload };
      state.isSavedCandidate = true;
      state.isLoading = false;
    },
    [saveCandidate.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [saveRoleCandidate.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [saveRoleCandidate.fulfilled.toString()]: (state, { payload }) => {
      state.candidate = { ...state.candidate, ...payload };
      state.isSavedRole = true;
      state.isLoading = false;
    },
    [saveRoleCandidate.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [uploadResume.pending.toString()]: state => {
      state.isUploading = true;
      state.uploadError = null;
    },
    [uploadResume.fulfilled.toString()]: (state, { payload }) => {
      state.isUploadedResume = true;
      state.isUploading = false;
    },
    [uploadResume.rejected.toString()]: (state, { payload }) => {
      state.isUploading = false;
      state.uploadError = payload;
    },
    [applyCandidate.pending.toString()]: state => {
      state.isApplying = true;
      state.applyingError = null;
    },
    [applyCandidate.fulfilled.toString()]: (state, { payload }) => {
      state.candidateApplied = true;
      state.isApplying = false;
    },
    [applyCandidate.rejected.toString()]: (state, { payload }) => {
      state.isApplying = false;
      state.applyingError = payload;
    },
    [inviteCandidate.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [inviteCandidate.fulfilled.toString()]: (state, { payload }) => {
      state.isInvitedCandidate = payload;
      state.isLoading = false;
    },
    [inviteCandidate.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [getScorecard.pending.toString()]: state => {
      state.isScorecardLoading = true;
      state.errors = null;
    },
    [getScorecard.fulfilled.toString()]: (state, { payload }) => {
      state.scorecard = payload;
      state.isScorecardLoading = false;
    },
    [getScorecard.rejected.toString()]: (state, { payload }) => {
      state.isScorecardLoading = false;
      state.errors = payload;
    },
    [saveScorecard.pending.toString()]: state => {
      state.isSaving = true;
      state.errors = null;
    },
    [saveScorecard.fulfilled.toString()]: state => {
      state.isSaving = false;
    },
    [saveScorecard.rejected.toString()]: (state, { payload }) => {
      state.isSaving = false;
      state.errors = payload;
    },
  },
});

export const {
  setCandidateState,
  setCandidateRole,
  setCandidateSubmissionInviteStatus,
  showApplicationSent,
  setErrorAlert,
  setScorecardSavedData,
  setShowProfileDetails,
  setCandidate,
  setCandidateWorkHistory,
  setCandidateWorkHistoryArr,
  deleteCandidateWorkHistory,
} = candidateSlice.actions;

export default candidateSlice.reducer;
