import axios from 'axios';
import { commonConfig } from '../config';

const axiosInstance = axios.create({
  baseURL: `${commonConfig.serverHost}/api/v1`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  async response => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      response.headers.Authorization = `Bearer ${token}`;
    }

    return response;
  },
  error =>
    Promise.reject(
      (error.response && error.response.data) ||
        'Something went wrong. Please reach out to us at support@findingclarity.ca if you continue to experience this issue.',
    ),
);

export default axiosInstance;
