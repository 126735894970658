import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './utils/reportWebVitals';
import App from './App';
import './index.scss';
import { ThemeProvider } from './contexts/ThemeContext';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
);

reportWebVitals();
