import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Alert as MuiAlert, Button, Box, Typography, Grid, AlertTitle } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CustomInput from '../CustomInput';
import LinkedinLoginButton from './LinkedinLoginButton';

import useAuth from '../../hooks/useAuth';

import { SubmitButton } from '../../styles/ButtonStyles';
import { ALERT_MESSAGES } from '../../constants/constants';

function SignIn() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const id = params.get('id');
  const email = params.get('email') !== null ? params.get('email') : '';
  const inviteLink = params.get('inviteLink') !== null ? params.get('inviteLink') : '';
  const submissionId = params.get('submissionId') !== null ? params.get('submissionId') : '';

  const { signIn } = useAuth();
  const [success, setSuccess] = useState(false);

  const linkSignUp = useMemo(
    () =>
      `/auth/sign-up?id=${id || ''}${!!inviteLink ? `&email=${email}&inviteLink=${inviteLink}` : ''}${
        !!submissionId ? `&submissionId=${submissionId}` : ''
      }`,
    [id, inviteLink, email, submissionId],
  );

  return (
    <Formik
      initialValues={{
        email: email || '',
        password: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password);
          setSuccess(true);
        } catch (error: any) {
          const message = error.error || ALERT_MESSAGES.DEFAULT_ERROR;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <CustomInput
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={touched.email && errors.email}
            onChange={handleChange}
            sx={{ mb: 3 }}
            forAuth
            autocomplete="username"
            disabled={!!email}
          />

          <CustomInput
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={touched.password && errors.password}
            onChange={handleChange}
            forAuth
            autocomplete="current-password"
          />

          <Box display="flex" justifyContent="end">
            <Button component={Link} to="/auth/reset-password">
              Forgot password?
            </Button>
          </Box>

          <SubmitButton type="submit" variant="contained" color="primary" disabled={isSubmitting}>
            Sign in
          </SubmitButton>

          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="body2" mt={3} mr={3}>
                or continue with
              </Typography>
            </Grid>

            <Grid item>
              <LinkedinLoginButton id={id || '1'} />
            </Grid>
          </Grid>

          {success && (
            <Alert mt={2} mb={3} p={2} severity="success">
              <AlertTitle sx={{ color: '#00B192', fontWeight: 600 }}>Thanks for registering!</AlertTitle>
              Please check your email to complete your registration
            </Alert>
          )}

          <ButtonWrapper>
            <Typography variant="body2">Don't have an account yet?</Typography>
            <Button component={Link} to={linkSignUp} style={{ color: '#2681DB' }}>
              Sign up
            </Button>
          </ButtonWrapper>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;

const Alert = styled(MuiAlert)(spacing);

const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
