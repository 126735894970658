import EyeIcon from '../images/FIcons/eye.svg';
import MailIcon from '../images/FIcons/mail.svg';
import DisabledMailIcon from '../images/FIcons/mail_disabled.svg';
import EditIcon from '../images/FIcons/edit.svg';
import CopyrightIcon from '../images/FIcons/Copyright.svg';
import CopyrightDisabledIcon from '../images/FIcons/CopyrightDisabled.svg';
import CommentAddIcon from '../images/FIcons/commentAdd.svg';
import CommentInfoIcon from '../images/FIcons/commentInfo.svg';
import AttantionIcon from '../images/FIcons/attantion.svg';
import ArrowUpOutlineIcon from '../images/FIcons/arrowUpOutline.svg';

export const eyeIcon = <img src={EyeIcon} alt="view" />;
export const mailIcon = <img src={MailIcon} alt="mail" />;
export const disabledMailIcon = <img src={DisabledMailIcon} alt="disabled mail" />;
export const editIcon = <img src={EditIcon} alt="edit" />;
export const copyrightActiveIcon = <img src={CopyrightIcon} alt="copyright" />;
export const copyrightNotActiveIcon = <img src={CopyrightDisabledIcon} alt="copyrightDisabled" />;
export const commentAddIcon = <img src={CommentAddIcon} alt="commentAdd" />;
export const commentInfoIcon = <img src={CommentInfoIcon} alt="commentInfo" />;
export const attantionIcon = <img src={AttantionIcon} alt="attantion" />;
export const arrowUpOutlineIcon = <img src={ArrowUpOutlineIcon} alt="arrowUpOutline" />;
export const arrowDownOutlineIcon = (
  <img src={ArrowUpOutlineIcon} alt="arrowUpOutline" style={{ transform: 'rotate(180deg)' }} />
);
