import React from 'react';
import styled from 'styled-components/macro';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface TProps {
  children?: React.ReactNode;
}

const Dashboard: React.FC<TProps> = ({ children }) => {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Root>
      <AppContent>
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background: #f5f8fa;
  width: 100%;
  overflow: auto;
  h1,
  h2,
  h3,
  p,
  div,
  li,
  h5,
  label,
  span {
    font-family: 'Poppins', sans-serif;
  }
`;

const MainContent = styled(Box)`
  flex-grow: 1;
  background: #f5f8fa;
  width: 100%;
  height: 100vh;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
