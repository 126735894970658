import React, { memo, useState } from 'react';
import { Card as MuiCard, Typography, Box, Alert as MuiAlert } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';

import CustomButton from '../../CustomButton';
import DragAndDropUploadFile from '../../DragAndDropUploadFile';
import BookIcon from '../../../images/modal/Resume.svg';

type TProps = {
  onClose: () => void;
  onProceed: (file: any) => void;
  loading?: boolean;
};

const ModalResume: React.FC<TProps> = ({ onProceed, onClose, loading }) => {
  const [file, setFile] = useState<any>(null);

  const fileTypes = '.doc,.docx,.pdf';

  return (
    <Box sx={styleWrapper}>
      <Card p={10}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box component="img" height={50} width={50} m={1} src={BookIcon} />
        </Box>

        <Typography variant="h1" gutterBottom align="center" color="secondary">
          Upload Your Resume
        </Typography>

        <Typography variant="body2" gutterBottom align="center">
          Save time completing your profile by uploading your resume here. If you don't one available now, that's ok,
          you can proceed without one.
        </Typography>

        {false && (
          <Alert mt={2} mb={1} severity="warning">
            error upload
          </Alert>
        )}

        <DragAndDropUploadFile file={file} setFile={setFile} fileTypes={fileTypes} />

        <Box display="flex" justifyContent="center" alignItems="center" padding="20px">
          <CustomButton variant="contained" onClick={() => onProceed(file)} loading={loading}>
            Proceed
          </CustomButton>
        </Box>
      </Card>
    </Box>
  );
};

export default memo(ModalResume);

const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);

const styleWrapper = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  maxWidth: 730,
  padding: '0 100px',
};
