import axios from '../utils/axios';
import { CompaniesType } from '../types/dictionaries';

export const dictionaries = {
  getDictionaries: async (type: string) => {
    const { data } = await axios.get(`/dictionaries/${type}`);
    return data;
  },
  postCompanies: async (values: CompaniesType) => {
    const { data } = await axios.post('/companies', values);
    return data;
  },
  getCandidateDictionaries: async (type: string) => {
    const { data } = await axios.get(`/candidate-dictionaries/${type}`);
    return data;
  },
};
