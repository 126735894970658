import React, { memo } from 'react';
import { FormControlLabel, SxProps } from '@mui/material';

import { StyledCheckbox } from '../styles/SelectStyles';

type TProps = {
  name: string;
  value?: string | number;
  checked?: boolean;
  onChange: (e: any) => void;
  label?: string;
  disabled?: boolean;
  labelPlacement?: 'start' | 'bottom' | 'end' | 'top';
};

const CustomCheckbox: React.FC<TProps> = ({
  name,
  value,
  checked,
  onChange,
  label = '',
  disabled,
  labelPlacement = 'end',
}) => {
  return (
    <FormControlLabel
      disabled={disabled}
      labelPlacement={labelPlacement}
      control={<StyledCheckbox name={name} checked={checked} onChange={onChange} />}
      label={label}
      sx={styleLabel}
    />
  );
};

export default memo(CustomCheckbox);

export const styleLabel: SxProps = { color: '#000000de', span: { fontWeight: 400 } };
