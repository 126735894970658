import { Box, Typography, Button } from '@mui/material';
import {
  ShadowContainer,
  SearchIconContainer,
  SearchSelectContainer,
} from '../../styles/components/CategoryFullDetailsStyles';
import React, { useEffect, useState } from 'react';
import { TextFieldC } from '../../styles/InputStyles';
import SearchIcon from '../../images/icons/SearchIcon.svg';
import CustomSelect from '../CustomSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { FlexBox } from '../../styles/ContainerStyles';
import { DictionaryType, SearchListType } from '../../types/dictionaries';
import { employmentTypes } from '../../utils/staticValues';
import { useNavigate } from 'react-router-dom';

interface Props {
  onSearch: (event: any) => void;
}

const SearchJobs = (props: Props) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const [isFullyRemote, setIsFullyRemote] = useState(false);
  const [location, setLocation] = useState('');
  const [level, setLevel] = useState('');
  const [functionC, setFunctionC] = useState('');
  const [type, setType] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [locationsList, setLocationsList] = useState<Array<DictionaryType>>([]);

  const { dictionaries } = useSelector((state: RootState) => state.dictionaries);
  const levelsList = dictionaries?.levels;
  const functionsList = dictionaries?.functions?.functions;
  const jobTypesList = dictionaries?.jobTypes;
  const searchLocations = dictionaries?.['search-locations'];
  
  const jobTypesListFiltered = jobTypesList
    ?.filter((item: SearchListType) => item.countOfPostedJobs !== 0)
    .map((item: SearchListType) => ({
      ...item,
      title: item.title === employmentTypes.CONTRACT ? employmentTypes.CONTRACT : employmentTypes.FULL_TIME,
    }));
  const levelsListFiltered = levelsList?.filter((item: SearchListType) => item.countOfPostedJobs !== 0);
  const functionsListFiltered = functionsList?.filter((item: SearchListType) => item.countOfPostedJobs !== 0);

  useEffect(() => {
    if (!!searchLocations && !locationsList.length) {
      const locations = searchLocations?.map((item: any, id: number) => ({
        id: id,
        title: item.city && item.province ? `${item.city}, ${item.province}` : `${item.city}`,
      }));
      !!locations.length &&
        !locations.find((item: any) => item.title === 'Fully Remote') &&
        locations.unshift({ id: locations.length, title: 'Fully Remote' });
      setLocationsList(locations);
    }
  }, [locationsList.length, searchLocations]);

    useEffect(() => {
      const onBackButtonEvent = (e: any) => {
        e.preventDefault();
        navigate(`/`);
      };
  
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        setTimeout(() => {
          window.removeEventListener('popstate', onBackButtonEvent);
        }, 0);
      };
    }, [navigate]);

  return (
    <ShadowContainer>
      <Box display={'flex'} p={'15px'}>
        <Box width={'15%'}>
          <Typography variant={'h5'} color={'#002855'}>
            Filter Jobs
          </Typography>
        </Box>

        <Box width={'85%'}>
          <Box display={'flex'} height={'54px'} mb={'10px'}>
            <Box width={'45%'} pr={'7px'}>
              <TextFieldC
                type="text"
                name="searchJob"
                placeholder="Search by job title or keyword"
                value={keyword}
                fullWidth
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                style={{ margin: '0 5px 0 0' }}
              />
            </Box>
            <Box width={'45%'} pl={'7px'}>
              <CustomSelect
                labelId={'location'}
                name={'location'}
                value={location}
                label={'Location'}
                data={locationsList}
                onChange={e => {
                  setLocation(e.target?.value);
                  if (e.target?.value === locationsList?.length - 1) {
                    setIsFullyRemote(true);
                    setCity('');
                    setProvince('');
                  } else {
                    setIsFullyRemote(false);
                    setCity(searchLocations[e.target?.value]?.city);
                    setProvince(searchLocations[e.target?.value]?.province);
                  }
                }}
              />
            </Box>
            <SearchIconContainer
              onClick={(e: any) => {
                props.onSearch({
                  event: e,
                  keyword: keyword,
                  levelId: level,
                  jobTypeId: type,
                  functionId: functionC,
                  province: province,
                  city: city,
                  location: isFullyRemote ? 'Fully Remote' : null,
                });
              }}
            >
              <img src={SearchIcon} alt={''} />
            </SearchIconContainer>
          </Box>
          <FlexBox>
            <SearchSelectContainer>
              <CustomSelect
                labelId={'level'}
                name={'level'}
                value={level}
                label={'Level'}
                data={levelsListFiltered}
                onChange={e => setLevel(e.target?.value)}
                small
              />
            </SearchSelectContainer>

            <SearchSelectContainer>
              <CustomSelect
                labelId={'functionC'}
                name={'functionC'}
                value={functionC}
                label={'Function'}
                data={functionsListFiltered}
                onChange={e => setFunctionC(e.target?.value)}
                small
              />
            </SearchSelectContainer>
            <SearchSelectContainer>
              <CustomSelect
                labelId={'type'}
                name={'type'}
                value={type}
                label={'Job Type'}
                data={jobTypesListFiltered}
                onChange={e => setType(e.target?.value)}
                small
              />
            </SearchSelectContainer>
            <Button
              onClick={() => {
                setKeyword('');
                setProvince('');
                setCity('');
                setLocation('');
                setLevel('');
                setFunctionC('');
                setType('');
                setIsFullyRemote(false);
              }}
            >
              Clear All
            </Button>
          </FlexBox>
        </Box>
      </Box>
    </ShadowContainer>
  );
};

export default SearchJobs;
