import React, { useState, memo, CSSProperties, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import RowTable from './RowTable';
import { TD, TR } from '../../FormSections/StylesTable';

import { ReactComponent as CircleArrowIcon } from '../../../../images/icons/circleArrow.svg';
import { ScorecardRespType } from '../../../../types/candidate';

type TProps = {
  title: string;
  data: Array<ScorecardRespType>;
  onChangeValue: (values: ScorecardRespType) => void;
};

const ScorecardSection: React.FC<TProps> = ({ title = '', data = [], onChangeValue }) => {
  const [active, setActive] = useState(true);

  const styleArrow = useMemo(() => (active ? show : hidden), [active]);

  return (
    <>
      <TR>
        <TD colSpan={6}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <CircleArrowIcon onClick={() => setActive(!active)} style={styleArrow} />
            <Typography variant="subtitle1" color="secondary" p={1} ml={'5px'}>
              {title}
            </Typography>
          </Box>
        </TD>
      </TR>

      {data?.map((item: ScorecardRespType) => (
        <RowTable key={item.id} data={item} active={active} onChangeValue={onChangeValue} />
      ))}
    </>
  );
};

export default memo(ScorecardSection);

const hidden: CSSProperties = {
  height: 16,
  transform: 'rotate(180deg)',
  transition: 'all .5s',
};

const show: CSSProperties = {
  height: 16,
  transition: 'all .5s',
};
