import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components/macro';

import { InviteStatus } from '../constants/constants';
import { COLORS } from '../theme';

type TProps = {
  status?: string;
};

const StatusInvite: React.FC<TProps> = ({ status }) => {
  const color = useMemo(() => {
    switch (status) {
      case InviteStatus.invited:
        return COLORS.warning;
      case InviteStatus.sent:
        return COLORS.warning;
      case InviteStatus.completed:
        return COLORS.success;
      case InviteStatus.accepted:
        return COLORS.success;
      default:
        return COLORS.disabled;
    }
  }, [status]);

  const background = useMemo(() => {
    switch (status) {
      case InviteStatus.invited:
        return COLORS.warningLight;
      case InviteStatus.sent:
        return COLORS.warningLight;
      case InviteStatus.completed:
        return COLORS.successLight;
      case InviteStatus.accepted:
        return COLORS.successLight;
      default:
        return COLORS.disabledLight;
    }
  }, [status]);

  return (
    <Status color={color} background={background}>
      {status}
    </Status>
  );
};

export default StatusInvite;

export const Status = styled(Box)<{ color?: string; background?: string }>(
  ({ color, background }) => `
  width: 100px;
  height: 24px;
  border-radius: 5px;
  color: ${color ? color : '#B7B8B9'};
  background: ${background ? background : '#F5F8FA'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`,
);
