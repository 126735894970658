import styled from 'styled-components/macro';
import { Autocomplete, Chip, Radio, Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: '100%',
  padding: 0,
  '.MuiInput-root .MuiInput-input': {
    padding: 0,
  },
}));

export const StyledChip = styled(Chip)(() => ({
  height: 24,
  borderRadius: 15,
  backgroundColor: '#EEEEEE',
  borderColor: '#EEEEEE',
  marginTop: 0,
  marginBottom: 0,
  fontWeight: 400,
}));

export const GreenRadio = withStyles({
  root: {
    color: '#B7B8B9',
    '&$checked': {
      color: '#00B192 !important',
    },
  },
  checked: {},
})(Radio);

export const StyledCheckbox = withStyles({
  root: {
    color: '#B7B8B9',
    padding: 0,
    marginRight: '5px',
    '&.Mui-checked': {
      color: '#00B192',
    },
  },
  checked: {},
})(Checkbox);

export const RemoveCircleOutlineButton = styled.button`
  border: 0;
  background-color: #fff;
  border-radius: 15px;
  height: 22px;
  width: 22px;
  padding: 0;
`;

export const RemoveButtonContainer = styled.div`
  width: 100%;
  display: flex;
  height: 54px;
  justify-content: center;
  align-items: center;
`;
