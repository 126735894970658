import styled from 'styled-components/macro';
import { Box, Chip as MuiChip } from '@mui/material';

export const Header = styled(Box)`
  font-size: 28px;
  line-height: 42px;
  font-weight: 600;
  width: 100%;
  color: #002855;
`;

export const Text = styled(Box)`
  width: 100%;
  font-size: 14px;
`;

export const BoldText = styled(Box)`
  font-weight: bold;
  width: 100%;
`;

export const HeaderText = styled(Box)`
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 5px;
  color: #002855;
`;

export const SubHeaderText = styled(Box)`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const SubText = styled(Box)`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  padding-bottom: 15px;
`;

export const Chip = styled(MuiChip)`
  border-radius: 15px;
  height: 25px;
  color: inherit;
  background-color: #eeeeee;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 10px;
`;

export const TooltipText = styled(Box)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
  letter-spacing: 0.7px;
  padding: 3px 10px 3px 10px;
`;

export const TooltipHeader = styled(Box)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
  letter-spacing: 0.7px;
  color: #fff;
  background-color: #002855;
  padding: 3px 10px 3px 10px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
`;

export const CommentField = styled('div')({
  p: {
    margin: 0,
  },
  li: {
    marginBottom: 0,
  },
});
