import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import Step1 from '../../components/CandidateProfile/Steps/Step1';
import Step2 from '../../components/CandidateProfile/Steps/Step2';
import Step3 from '../../components/CandidateProfile/Steps/Step3';
import Step4 from '../../components/CandidateProfile/Steps/Step4';
import Step5 from '../../components/CandidateProfile/Steps/Step5';
import ApplicationStatusPage from './ApplicationStatusPage';
import Alert from '../../components/Alert/Alert';
import Loader from '../../components/Loader';

import { getNameRoute, getNameRouteSpecific, getNameRouteSpecificShort } from '../../utils/stepsRouting';
import { ALERT_MESSAGES, PROFILE_PATH, PROJECT_NAME } from '../../constants/constants';
import { setCandidateState } from '../../redux/reducers/candidate';
import { getCandidate, saveCandidate } from '../../redux/actions/candidate';
import { setActiveStep } from '../../redux/reducers/additional';
import { setAlert } from '../../redux/reducers/alert';
import { RootState } from '../../redux/store';

const CandidateProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { profile, isCandidate } = useSelector((state: RootState) => state.user);
  const { activeStep, activeTab } = useSelector((state: RootState) => state.additional);
  const { isSavedCandidate, isSavedRole, isLoading, errors, isFirstEmailAlert, candidate } = useSelector(
    (state: RootState) => state.candidate,
  );
  const id = isCandidate ? profile?.candidate_id?.toString() : params.get('candidate_id') || params.get('id') || '1';
  const submissionId = params.get('submissionId') ? params.get('submissionId') : null;

  useEffect(() => {
    if (Object.keys(profile).length) {
      !!id && dispatch(getCandidate(+id));
    }
  }, [id, profile, dispatch]);

  useEffect(() => {
    if (
      !isCandidate &&
      isFirstEmailAlert &&
      !!candidate &&
      (candidate?.user?.hasSeveralCandidates || !candidate?.user?.hasValidEmail)
    ) {
      navigate(`/${PROFILE_PATH}?candidate_id=${id}`);
    }
  }, [
    candidate,
    candidate?.user?.hasSeveralCandidates,
    candidate?.user?.hasValidEmail,
    id,
    isCandidate,
    isFirstEmailAlert,
    navigate,
  ]);

  useEffect(() => {
    const getStepsNames = () => {
      if (submissionId) {
        if (candidate?.is_full_culture) {
          if (!candidate?.ocp_answers?.top?.length) {
            return getNameRouteSpecificShort(activeStep + 1);
          } else return getNameRouteSpecific(activeStep + 1);
        } else {
          return getNameRouteSpecific(activeStep + 1);
        }
      } else return getNameRoute(activeStep + 1, isCandidate);
    };

    if (isSavedCandidate) {
      // dispatch(setAlert({ type: 'success', message: ALERT_MESSAGES.SAVED_SUCCESS }));
      dispatch(setCandidateState({ type: 'isSavedCandidate', data: false }));
      if (activeStep !== 4) {
        if (
          isCandidate ||
          (!isCandidate && activeStep !== (submissionId ? 3 : 2)) ||
          (!isCandidate && activeStep === 3 && !candidate?.is_full_culture)
        ) {
          navigate(
            `/${PROFILE_PATH}/${getStepsNames()}?id=${id}${submissionId ? `&submissionId=${submissionId}` : ''}`,
          );
          setActiveStep(activeStep + 1);
        } else {
          navigate(`/${PROFILE_PATH}?candidate_id=${id}${submissionId ? `&submissionId=${submissionId}` : ''}`);
          setActiveStep(0);
        }
      } else {
        if (isCandidate) {
          dispatch(setActiveStep(5));
          navigate(
            `/${PROFILE_PATH}/complete?candidate_id=${id}${submissionId ? `&submissionId=${submissionId}` : ''}`,
          );
        } else {
          dispatch(setActiveStep(0));
          navigate(
            `/${PROFILE_PATH}/profile-details?candidate_id=${id}${submissionId ? `&submissionId=${submissionId}` : ''}`,
          );
        }
      }
    }
  }, [
    isSavedCandidate,
    activeStep,
    isCandidate,
    id,
    submissionId,
    candidate?.is_full_culture,
    candidate?.ocp_answers?.top?.length,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (isSavedRole) {
      // dispatch(setAlert({ type: 'success', message: ALERT_MESSAGES.SAVED_SUCCESS }));
      dispatch(setCandidateState({ type: 'isSavedRole', data: false }));
    }
  }, [isSavedRole, dispatch]);

  useEffect(() => {
    !!errors && dispatch(setAlert({ type: 'error', message: ALERT_MESSAGES.SAVED_FAIL }));
  }, [errors, dispatch]);

  const handleNext = useCallback(
    (values: any) => {
      !!id && dispatch(saveCandidate({ id: +id, values }));
      window.scrollTo(0, 0);
    },
    [dispatch, id],
  );

  return (
    <>
      <Helmet title={`${PROJECT_NAME} Profile`} />

      {isLoading && <Loader />}

      <Alert />

      {activeStep === 0 && activeTab === 1 && (
        <Step1 handleNext={handleNext} id={id || ''} submissionId={submissionId || ''} />
      )}
      {activeStep === 1 && activeTab === 1 && (
        <Step2 handleNext={handleNext} id={id || ''} submissionId={submissionId || ''} />
      )}
      {activeStep === 2 && activeTab === 1 && (
        <Step3 handleNext={handleNext} id={id || ''} submissionId={submissionId || ''} />
      )}
      {activeStep === 3 && activeTab === 1 && <Step4 id={id || ''} submissionId={submissionId || ''} />}
      {activeStep === 4 && activeTab === 1 && <Step5 />}
      {activeTab === 2 && <ApplicationStatusPage />}
    </>
  );
};

export default CandidateProfilePage;
