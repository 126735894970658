import { Formik } from 'formik';
import * as Yup from 'yup';
import { ALERT_MESSAGES, EXPERIENCE_LEVELS, PROFILE_PATH } from '../../../constants/constants';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Card, TextField, Typography } from '@mui/material';
import { styleCardSection, styleCardStep3 } from '../StylesCandidateProfile';
import CustomButton from '../../CustomButton';
import { Table, TBody, TD, TH, THead, TR } from '../FormSections/StylesTable';
import { SxProps } from '@mui/system';
import { ContainedButton } from '../../../styles/ButtonStyles';
import { ScorecardRespType, SubmissionItemType } from '../../../types/candidate';
import { saveCandidate } from '../../../redux/actions/candidate';
import CandidateTableAlert from '../../Alert/CandidateTableAlert';
import MustHaveNew from '../../../images/icons/MustHaveNew.svg';
import PreferredNew from '../../../images/icons/Preffered.svg';
import TooltipIcon from '../../TooltipIcon';
import ExperienceCell from '../../ExperienceCell';
import CommentNew from '../Components/Scorecard/CommentNew';
import FromWhere from '../Components/Scorecard/FromWhere';

type TProps = {
  id: string;
  handleNext: (values: any) => void;
  submissionId?: string | null;
};

const Step3Specific: React.FC<TProps> = ({ id, handleNext, submissionId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isCandidate } = useSelector((state: RootState) => state.user);
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const [newSubmissions, setNewSubmissions] = useState<Array<SubmissionItemType>>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [valErr, setValErr] = useState<Array<number>>([]);
  const [valErrFW, setValErrFW] = useState<Array<number>>([]);
  const [submissionCategories, setSubmissionCategories] = useState<Array<any>>([]);
  const [currentComment, setCurrentComment] = useState('');
  const [commentErr, setCommentErr] = useState(false);
  const [highlightComment, setHighlightComment] = useState(0);
  const [scoreSkillsErr, setScoreSkillsErr] = useState(false);
  const [isError, setIsError] = useState(false);

  const currentSubmission = newSubmissions?.find(submission => submission.id.toString() === submissionId);
  const currentSubmissionIndex = newSubmissions?.findIndex(
    (submission: SubmissionItemType) => submission.id.toString() === submissionId,
  );

  const currentRequirements = currentSubmission?.submissionRequirements;

  const uniqueCategories = currentSubmission?.submissionCategories ? currentSubmission?.submissionCategories : [];

  const requirementsByCategory = currentRequirements?.filter(
    (item: ScorecardRespType) =>
      item?.requirement?.scorecard_category?.id === uniqueCategories[pageNumber]?.scorecard_category_id,
  );

  const savedSubmissionCategories = currentSubmission?.submissionCategories;

  useEffect(() => {
    if (candidate?.submissions?.length) {
      setNewSubmissions(candidate?.submissions);
    }
  }, [candidate?.submissions]);

  useEffect(() => {
    if (savedSubmissionCategories) {
      setSubmissionCategories(savedSubmissionCategories);
    }
  }, [savedSubmissionCategories?.length]);

  useEffect(() => {
    if (submissionCategories && uniqueCategories) {
      let sub = [...submissionCategories];
      const i = sub?.findIndex(el => el.id === uniqueCategories[pageNumber]?.id);

      if (i > -1) {
        setCurrentComment(sub[i].comment);
      }
    }
  }, [pageNumber, submissionCategories?.length, uniqueCategories?.length]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        const errorTextEl = document.querySelector('[data-label="errorText"]');
        !!errorTextEl && errorTextEl.scrollIntoView({ block: 'center' });
      }, 100);
    }
  }, [isError]);

  const regexForHTML = /(<([^>]+)>)/gi;

  const onChangeValue = (values: ScorecardRespType) => {
    let newRequirements: Array<ScorecardRespType> = [];
    let newSubmissionsCopy: Array<SubmissionItemType> = [];

    if (newSubmissions && currentSubmissionIndex !== -1) {
      newSubmissionsCopy = [...newSubmissions];
      newRequirements = [...newSubmissions[currentSubmissionIndex]?.submissionRequirements];
    }
    const objIndex = newRequirements.findIndex((el: ScorecardRespType) => el.requirement_id === values.requirement_id);

    newRequirements[objIndex] = values;

    if (currentSubmissionIndex !== -1) {
      newSubmissionsCopy[currentSubmissionIndex] = {
        ...newSubmissionsCopy[currentSubmissionIndex],
        submissionRequirements: newRequirements,
      };
      setNewSubmissions(newSubmissionsCopy);
    }

    if (
      scoreSkillsErr &&
      values.value !== null &&
      !requirementsByCategory.filter(
        (item: any) => item.value === null && values.requirement_id !== item.requirement_id,
      ).length
    ) {
      setScoreSkillsErr(false);
    }

    if (
      valErr.includes(values.requirement_id) &&
      (values.comment || values.value === EXPERIENCE_LEVELS.MEETS || values.value === EXPERIENCE_LEVELS.NONE)
    ) {
      setValErr(valErr.filter(item => item !== values.requirement_id));
    }

    if (
      valErrFW.includes(values.requirement_id) &&
      (values.from_where.length || values.value === EXPERIENCE_LEVELS.NONE)
    ) {
      setValErrFW(valErr.filter(item => item !== values.requirement_id));
    }

    if (
      (values.value === EXPERIENCE_LEVELS.SOME && !values?.comment?.replace(regexForHTML, '')) ||
      (values.value === EXPERIENCE_LEVELS.EXCEEDS && !values?.comment?.replace(regexForHTML, '')) ||
      (values.value !== null && values.value !== EXPERIENCE_LEVELS.NONE &&
        !values.from_where?.length &&
        values.requirement.requirement !== 'Years Experience' &&
        values.requirement.requirement !== 'Designations')
    ) {
      setHighlightComment(values.requirement_id);
    } else {
      setHighlightComment(0);
    }
  };

  const clearErrors = () => {
    setShowErrorAlert(false);
    setCurrentComment('');
    setCommentErr(false);
    setScoreSkillsErr(false);
    setValErr([]);
    setValErrFW([]);
  };

  const isValError = commentErr || scoreSkillsErr || !!valErr?.length || !!valErrFW?.length;

  const validateValues = (backButton?: boolean) => {
    let errArr: Array<number> = [];
    let errArrFW: Array<number> = [];
    let scoreErr = false;

    requirementsByCategory?.map((item: ScorecardRespType) => {
      if (item.value === null) {
        scoreErr = true;
      }

      if (item.value === EXPERIENCE_LEVELS.SOME || item.value === EXPERIENCE_LEVELS.EXCEEDS) {
        if (!item.comment?.replace(regexForHTML, '')) {
          errArr.push(item.requirement_id);
        }
      }

      if (
        item.value !== null &&
        item.value !== EXPERIENCE_LEVELS.NONE &&
        !item.from_where.length &&
        item.requirement.requirement !== 'Years Experience' &&
        item.requirement.requirement !== 'Designations'
      ) {
        errArrFW.push(item.requirement_id);
      }
    });

    setComments();

    if (errArr.length || errArrFW.length || (!currentComment && requirementsByCategory?.length) || scoreErr) {
      if (scoreErr) {
        setScoreSkillsErr(true);
        window.scrollTo(0, 0);
      }
      if (errArr.length) {
        setValErr(errArr);
        setShowErrorAlert(true);
        window.scrollTo(0, 0);
      }
      if (errArrFW.length) {
        setValErrFW(errArrFW);
        setShowErrorAlert(true);
        window.scrollTo(0, 0);
      }
      if (!currentComment) {
        setCommentErr(true);
      }
    } else {
      backButton ? setPageNumber(pageNumber - 1) : setPageNumber(pageNumber + 1);
      clearErrors();
      window.scrollTo(0, 0);
    }
  };

  const setComments = () => {
    let submissionCategoriesCopy = [...submissionCategories];
    const i = submissionCategoriesCopy.findIndex(el => el.id === uniqueCategories[pageNumber]?.id);

    if (i > -1) submissionCategoriesCopy[i] = { ...submissionCategoriesCopy[i], comment: currentComment };
    else {
      const newObj = {
        submission_id: currentSubmission?.id,
        scorecard_category_id: uniqueCategories[pageNumber]?.scorecard_category_id,
        comment: currentComment,
        id: uniqueCategories[pageNumber]?.id,
        title: uniqueCategories[pageNumber]?.title,
      };
      submissionCategoriesCopy.push(newObj);
    }
    setSubmissionCategories(submissionCategoriesCopy);
  };

  const showImportance = (importance: number) => {
    if (importance === 3) return <TooltipIcon tooltipText={'Must Have'} iconSrc={MustHaveNew} />;
    else return <TooltipIcon tooltipText={'Highly Preferred'} iconSrc={PreferredNew} />;
  };

  const showPlaceholder = (category: string) => {
    if (category === 'Company Experience')
      return (
        'Please describe your experience working in this type of an environment. ' +
        'Where and what challenges did you overcome that may be transferrable to this role?'
      );
    else if (category === 'Professional')
      return 'Please provide any additional details about your professional background.';
    else return 'Please describe your overall experience using examples and how this may be transferrable to this role.';
  };

  const viewReport = () => {
    if (!isCandidate) {
      let newSubmissionsComplete = [...newSubmissions];
      newSubmissionsComplete[currentSubmissionIndex] = {
        ...newSubmissionsComplete[currentSubmissionIndex],
        submissionCategories: submissionCategories,
      };
      const data = { submissions: newSubmissionsComplete };
      !!id && dispatch(saveCandidate({ id: +id, values: data }));
      navigate(`/${PROFILE_PATH}/profile-details?candidate_id=${id}&submissionId=${submissionId}`);
    }
  };

  return (
    <Formik
      initialValues={{
        interestedComment: currentSubmission?.why_interested || '',
        submit: false,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        interestedComment: Yup.string().required('This field is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        let newSubmissionsComplete = [...newSubmissions];
        newSubmissionsComplete[currentSubmissionIndex] = {
          ...newSubmissionsComplete[currentSubmissionIndex],
          submissionCategories: submissionCategories,
          why_interested: values.interestedComment,
        };
        const data = {
          submissions: newSubmissionsComplete,
        };

        if (!valErr.length && !scoreSkillsErr) {
          try {
            handleNext(data);
          } catch (error: any) {
            const message = error.message || ALERT_MESSAGES.DEFAULT_ERROR;

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }
      }}
    >
      {formik => {
        const { handleSubmit, errors, handleChange, values } = formik;
        !!Object.keys(errors).length && setIsError(true);
        setTimeout(() => setIsError(false), 100);

        return (
          <form noValidate onSubmit={handleSubmit}>
            <Card sx={styleCardSection}>
              <Typography variant="h5" gutterBottom color="secondary" mb={5}>
                Relevant experience
              </Typography>

              {pageNumber === uniqueCategories?.length ? (
                <Card sx={styleCardStep3}>
                  <Typography variant="h6" gutterBottom mb={5} color="secondary">
                    Why are you interested?
                  </Typography>
                  <Typography variant="body2" gutterBottom mb={5} color="secondary">
                    Please let us know what is motivating you to make a move now and what you find interesting about
                    this new opportunity
                  </Typography>

                  <TextField
                    name={'interestedComment'}
                    value={values.interestedComment}
                    onChange={handleChange}
                    error={Boolean(errors.interestedComment)}
                    helperText={errors.interestedComment}
                    multiline={true}
                    rows={2}
                    placeholder={'Comment'}
                    style={{ width: '100%', minHeight: '100px' }}
                  />
                </Card>
              ) : (
                <Box>
                  <Card sx={styleCardStep3}>
                    <Typography component="div" gutterBottom mb={5}>
                      <Typography component="div" variant="h6" gutterBottom color="secondary" mb={0}>
                        {uniqueCategories ? uniqueCategories[pageNumber]?.title : null}
                      </Typography>
                      <Typography variant="body2" mt={'10px'}>
                        Below are our client’s specific requirements for this role. Please rank your experience level in
                        each area, where you gained the experience and add a comment if you’d like to provide further
                        examples and notes. Whenever you select Exceeds or Some, you’ll need to provide a comment to
                        provide further clarification.
                      </Typography>
                    </Typography>

                    {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
                    {showErrorAlert && !!valErr.length && (
                      <CandidateTableAlert
                        message={ALERT_MESSAGES.COMMENT_REQUIRED}
                        onClose={() => setShowErrorAlert(false)}
                      />
                    )}

                    {showErrorAlert && !!valErrFW.length && (
                      <CandidateTableAlert
                        message={ALERT_MESSAGES.FROM_WHERE_REQUIRED}
                        onClose={() => setShowErrorAlert(false)}
                      />
                    )}
                    {scoreSkillsErr && (
                      <CandidateTableAlert
                        message={ALERT_MESSAGES.SCORE_NEEDED}
                        onClose={() => setScoreSkillsErr(false)}
                      />
                    )}

                    <Box sx={styleWrapTable}>
                      <Table>
                        <THead>
                          <TR>
                            <TH width="50%" colSpan={2} style={{ ...stylesHeader, ...stylesHeaderBorderRight }}>
                              Requirements
                            </TH>
                            <TH width="20%" colSpan={4} style={{ ...stylesHeader, ...stylesHeaderBorderRight }}>
                              Experience Level
                            </TH>
                            <TH width="30%" style={stylesHeader}>
                              Comments
                            </TH>
                          </TR>

                          <TR>
                            <TH width="5%" style={stylesHeader} />
                            <TH width="45%" style={{ ...stylesHeader, ...stylesHeaderBorderRight }} />
                            <TH width="5%" style={stylesHeaderCenter}>
                              {EXPERIENCE_LEVELS.EXCEEDS}
                            </TH>
                            <TH width="5%" style={stylesHeaderCenter}>
                              {EXPERIENCE_LEVELS.MEETS}
                            </TH>
                            <TH width="5%" style={stylesHeaderCenter}>
                              {EXPERIENCE_LEVELS.SOME}
                            </TH>
                            <TH width="5%" style={{ ...stylesHeaderCenter, ...stylesHeaderBorderRight }}>
                              {EXPERIENCE_LEVELS.NONE}
                            </TH>
                            <TH width="30%" style={stylesHeaderCenter} />
                          </TR>
                        </THead>

                        <TBody>
                          {requirementsByCategory?.map((data: ScorecardRespType) => (
                            <TR key={data.id}>
                              <TD width="5%">{showImportance(data?.requirement?.importance_weight_id)}</TD>
                              <TD width="45%" style={stylesHeaderBorderRight}>
                                {data?.requirement?.experience || data?.requirement?.details ? (
                                  <div style={{ fontWeight: 600 }}>
                                    {data?.requirement?.requirement}{' '}
                                    <span style={{ fontWeight: 400 }}>{data?.requirement?.experience}</span>
                                    <span style={{ fontWeight: 400, marginLeft: '5px' }}>
                                      {data?.requirement?.details}
                                    </span>
                                  </div>
                                ) : (
                                  <div>{data?.requirement?.requirement}</div>
                                )}
                              </TD>

                              <TD width="5%">
                                <ExperienceCell
                                  type={EXPERIENCE_LEVELS.EXCEEDS}
                                  data={data}
                                  onChangeValue={obj => onChangeValue(obj)}
                                />
                              </TD>

                              <TD width="5%">
                                <ExperienceCell
                                  type={EXPERIENCE_LEVELS.MEETS}
                                  data={data}
                                  onChangeValue={obj => onChangeValue(obj)}
                                />
                              </TD>

                              <TD width="5%">
                                <ExperienceCell
                                  type={EXPERIENCE_LEVELS.SOME}
                                  data={data}
                                  onChangeValue={obj => onChangeValue(obj)}
                                />
                              </TD>

                              <TD width="5%" style={stylesHeaderBorderRight}>
                                <ExperienceCell
                                  type={EXPERIENCE_LEVELS.NONE}
                                  data={data}
                                  onChangeValue={obj => onChangeValue(obj)}
                                />
                              </TD>

                              <TD
                                width="40%"
                                style={{
                                  outline:
                                    valErr.includes(data.requirement_id) || highlightComment === data.requirement_id
                                      ? '1px solid #dc2547'
                                      : '',
                                  padding: '5px 10px',
                                }}
                              >
                                {candidate?.histories && (
                                  <FromWhere
                                    data={data}
                                    histories={candidate?.histories}
                                    onChangeValue={value => onChangeValue(value)}
                                  />
                                )}
                                <CommentNew data={data} onChangeValue={value => onChangeValue(value)} />
                              </TD>
                            </TR>
                          ))}
                        </TBody>
                      </Table>
                    </Box>
                  </Card>
                  <Card sx={styleCardStep3}>
                    {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}

                    <Typography variant="h6" gutterBottom mb={5} color="secondary">
                      Describe your experience*
                    </Typography>

                    <TextField
                      value={currentComment ? currentComment : ''}
                      onChange={e => {
                        setCurrentComment(e.target.value);
                        setCommentErr(false);
                      }}
                      multiline={true}
                      rows={2}
                      placeholder={showPlaceholder(uniqueCategories ? uniqueCategories[pageNumber]?.title : '')}
                      error={commentErr}
                      style={{ width: '100%' }}
                    />
                  </Card>
                </Box>
              )}

              <Box display="flex" justifyContent="right" alignItems="center" p={5}>
                {!isCandidate && (
                  <Box mr={'10px'}>
                    <CustomButton
                      type="button"
                      variant="text"
                      onClick={() => navigate(`/${PROFILE_PATH}?candidate_id=${id}`)}
                    >
                      Return to dashboard
                    </CustomButton>

                    <CustomButton type="submit" variant="text" onClick={() => viewReport()}>
                      View Report
                    </CustomButton>
                  </Box>
                )}
                {pageNumber > 0 && (
                  <ContainedButton
                    variant="contained"
                    onClick={() => validateValues(true)}
                    style={{ marginRight: '20px' }}
                    disabled={isValError || !!errors?.interestedComment}
                  >
                    Back
                  </ContainedButton>
                )}
                {pageNumber < uniqueCategories?.length ? (
                  <ContainedButton
                    variant="contained"
                    onClick={() => validateValues()}
                    disabled={isValError || !!errors?.interestedComment}
                  >
                    Next
                  </ContainedButton>
                ) : (
                  <>
                    <CustomButton
                      type={'submit'}
                      variant="contained"
                      onClick={() => validateValues()}
                      disabled={!!valErr.length || !!errors?.interestedComment}
                    >
                      Next
                    </CustomButton>
                  </>
                )}
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default Step3Specific;

const styleWrapTable: SxProps = {
  width: '100%',
  borderRadius: 1,
  backgroundColor: '#fff',
};

const stylesHeader: CSSProperties = {
  color: '#757678',
  textAlign: 'left',
  padding: 10,
  border: 'none',
  textTransform: 'uppercase',
  fontWeight: 600,
};

const stylesHeaderBorderRight: CSSProperties = {
  borderRight: '0.5px solid #e9e7e7',
};

const stylesHeaderCenter: CSSProperties = {
  color: '#757678',
  textAlign: 'center',
  padding: 10,
  border: 'none',
  textTransform: 'uppercase',
};
