import React, { memo } from 'react';
import { Card } from '@mui/material';
import { FormikProps } from 'formik';

import Editors from '../../../Editors';

import RoleValuesModel from '../../../../models/roleValues.model';

import { styleCard } from '../StylesFormSections';

type TProps = {
  name: string;
  role: RoleValuesModel;
  formik: FormikProps<any>;
};

const Additional: React.FC<TProps> = ({ name, role, formik: { setFieldValue } }) => (
  <Card sx={styleCard}>
    <Editors value={role.additional} onChange={value => setFieldValue(name, value)} />
  </Card>
);

export default memo(Additional);
