import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid } from '@mui/material';
import { Box, SxProps } from '@mui/system';

import CustomInput from '../../../CustomInput';
import CustomSelect from '../../../CustomSelect';
import CustomMultipleSelect from '../../../CustomMultipleSelect';
import TamplateTooltip from '../../../TamplateTooltip';

import { yaersOptions } from '../../../../utils/staticValues';
import { RootState } from '../../../../redux/store';

import { styleCard } from '../StylesFormSections';

type TProps = {
  formik: any;
};

const Background: React.FC<TProps> = ({
  formik: { values, handleChange, setFieldValue, handleSubmit, errors, touched, isSubmitting },
}) => {
  const { general } = useSelector((state: RootState) => state.dictionaries.dictionaries);

  const onAddTamplate = useCallback(({ setFieldValue, name, value }: any) => {
    setFieldValue(name, value);
  }, []);

  const yearsOfExperienceOptions = useMemo(() => yaersOptions(), []);

  return (
    <Card sx={styleCard}>
      <Grid container spacing={3} mb={5}>
        <Grid item md={6} xs={12}>
          <CustomMultipleSelect
            name="designations"
            label="Designation(s)"
            value={values.designations}
            onChange={value => {
              setFieldValue('designations', value);
            }}
            data={general?.designations}
            error={Boolean(touched.designations && errors.designations)}
            creatable
            dataWithoutCustom
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomSelect
            labelId="years_of_experience"
            name="years_of_experience"
            label="Which year did you start working?"
            value={values.years_of_experience}
            onChange={handleChange}
            data={yearsOfExperienceOptions} // from 1985 to 2021
            error={Boolean(touched.years_of_experience && errors.years_of_experience)}
          />
        </Grid>
      </Grid>

      <Box sx={styleWrapAboutMe}>
        <CustomInput
          name="about"
          label="Please provide a brief bio of yourself. 3-4 sentences is best."
          value={values.about}
          onChange={handleChange}
          multiline={true}
          error={touched.about && errors.about}
        />
      </Box>
    </Card>
  );
};

export default React.memo(Background);

export const styleWrapAboutMe: SxProps = {
  position: 'relative',
};

export const styleWrapTooltip: SxProps = {
  position: 'absolute',
  top: 10,
  right: 10,
};
