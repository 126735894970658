import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';

import ErrorText from './ErrorText';
import { TextFieldC } from '../styles/InputStyles';

type TProps = {
  name: string;
  value: any;
  label?: string;
  onChange: (e: string | ChangeEvent<any>) => void;
  error?: any;
  type?: string;
  multiline?: boolean;
  rows?: number;
  sx?: any;
  disabled?: boolean;
  placeholder?: string;
  maxlength?: number;
  onlyNumber?: boolean;
  forAuth?: boolean;
  autocomplete?: string;
};

const CustomInput: React.FC<TProps> = ({
  name,
  value = '',
  label = '',
  onChange,
  error,
  type = 'text',
  multiline = false,
  rows = 5,
  sx,
  disabled = false,
  placeholder,
  maxlength,
  onlyNumber,
  forAuth,
  autocomplete,
}) => {
  const [text, setText] = useState('');

  useEffect(() => {
    !!value && setText(value.toString());
  }, [value]);

  return (
    <Box sx={sx}>
      <TextField
        fullWidth
        multiline={multiline}
        rows={multiline ? rows : undefined}
        name={name}
        label={label}
        value={onlyNumber ? text.replace(/\D/g, '') : text}
        onChange={e => {
          setText(e.target.value);
          forAuth && onChange(e);
        }}
        onBlur={onChange}
        error={!!error}
        InputLabelProps={{ style: labelStyles }}
        InputProps={{ style: inputStyles }}
        inputProps={{ maxLength: maxlength }}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autocomplete ? autocomplete : 'off'}
      />

      <ErrorText error={error} />
    </Box>
  );
};

const labelStyles = { fontWeight: 400 };
const inputStyles = { fontWeight: 400 };

export default memo(CustomInput);

const TextField = styled(TextFieldC)<{ company?: string }>(spacing);
