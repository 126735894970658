import axios from '../utils/axios';

export const user = {
  getProfile: async () => {
    const { data } = await axios.get('/me');
    return data;
  },
  updateProfile: async (values: any) => {
    const { data } = await axios.put('/auth/update-profile', values);
    return data;
  },
  updatePassword: async (values: { old_password: string; new_password: string }) => {
    const { data } = await axios.put('/users/update-password', values);
    return data;
  },
  signOut: async () => {
    const { data } = await axios.post('/auth/logout');
    return data;
  },
  checkActiveEmail: async (value: { email: string }) => {
    const { data } = await axios.post('/candidates/is-active', value);
    return data;
  },
};
