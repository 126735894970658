import styled from 'styled-components/macro';
import { Box, Grid } from '@mui/material';

export const ModalContainer = styled(Box)({
  backgroundColor: '#FFF',
  padding: '15px 30px 15px 15px',
  boxShadow: '0 0 6px rgba(4, 25, 100, 0.08)',
  borderRadius: '5px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  minHeight: 200,
});

export const ModalWrapper = styled(Grid)({
  minWidth: 400,
  minHeight: 200,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

export const CloseIconWrapper = styled(Box)({
  width: '30px',
  height: '30px',
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
});
