import { createAsyncThunk } from '@reduxjs/toolkit';
import { user } from '../../api/user';

export const getProfile = createAsyncThunk('user/getProfile', async (_: void, thunkAPI) => {
  try {
    const { data } = await user.getProfile();
    return data;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updateProfile = createAsyncThunk('user/updateProfile', async (values: any, thunkAPI) => {
  try {
    const { data } = await user.updateProfile(values);
    return data;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }

    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updatePassword = createAsyncThunk('user/updatePassword', async (values: any, thunkAPI) => {
  try {
    const data = await user.updatePassword(values);

    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.response);
  }
});

export const signOut = createAsyncThunk('user/signOut', async (_: void, thunkAPI) => {
  try {
    const data = await user.signOut();
    localStorage.clear();
    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const checkActiveEmail = createAsyncThunk(
  'user/checkActiveEmail',
  async (value: { email: string }, thunkAPI) => {
    try {
      const data = await user.checkActiveEmail(value);
      return data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);
