import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { alertCloseThunk } from '../../redux/reducers/alert';
import styles from './Alert.module.scss';

type AlertType = {};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars: React.FC<AlertType> = (props: any) => {
  const dispatch = useDispatch();
  
  const { open, message, type, length } = useSelector((state: RootState) => state.alert);

  const handleClose = (event: Event | React.SyntheticEvent<any, Event>, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(alertCloseThunk());
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={length === 0 ? null : length}
        onClose={handleClose}
        classes={{ root: styles.wrapper }}
      >
        <Alert
          classes={{
            root: styles.root,
            icon: styles.icon,
            filledError: styles.error,
            filledWarning: styles.warning,
          }}
          onClose={e => {
            handleClose(e);
            !!props?.onClose && props.onClose();
          }}
          severity={type as AlertProps['severity']}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbars;
