import React, { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card as MuiCard, CardContent, Grid } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import { FormikProps } from 'formik';

import ResponsibilitiesItem from '../../Components/ResponsibilitiesItem';
import SubFunctionsItem from '../../Components/SubFunctionsItem';

import { objToId } from '../../../../utils/formatData';
import { setSubItemStep2 } from '../../../../utils/formatData';
import RoleValuesModel from '../../../../models/roleValues.model';
import { IsBuildTypeI, ProjectsType, SubFunctionsType } from '../../../../types/dictionaries';
import { RootState } from '../../../../redux/store';

import { styleSwitchTitle } from '../../StylesCandidateProfile';

type TProps = { name: string; role: RoleValuesModel; formik: FormikProps<any> };

const Initiatives: React.FC<TProps> = ({ name, role, formik }) => {
  const { companyAndFunctions } = useSelector((state: RootState) => state.dictionaries.dictionaries);
  const { selectedRole } = useSelector((state: RootState) => state.candidate);

  const [checkedProj, setCheckedProj] = React.useState<Array<number>>([]);
  const [checkedInit, setCheckedInit] = React.useState<Array<number>>([]);
  const [checkedInitArr, setCheckedRespArr] = React.useState<IsBuildTypeI[]>([]);

  useEffect(() => {
    if (role?.projects?.length) {
      setCheckedProj(role.projects);

      if (role.initiatives?.length) {
        setCheckedInit(role.initiatives?.map((el: any) => el.id));

        let initiatives = role.initiatives.map((el: any) => ({
          id: el.id,
          project_id: el.project_id,
          title: el.title,
          build_type_id: el.build_type_id,
        }));

        setCheckedRespArr(initiatives);
      }
    }
  }, [role]);

  const handleSwitchChange = useCallback(
    (projectId: number) => {
      let selectedProjects = checkedProj;
      const buf = selectedProjects.filter((i: number) => i === projectId);

      if (!buf.length) {
        setCheckedProj([...checkedProj, projectId]);
        formik.setFieldValue(`${name}.projects`, [...checkedProj, projectId]);
      } else {
        const filteredProjects = selectedProjects.filter((i: number) => i !== projectId);

        setCheckedProj(filteredProjects);
        formik.setFieldValue(`${name}.projects`, filteredProjects);
      }
    },
    [checkedProj, name],
  );

  const handleChangeCheckbox = useCallback(
    (Init: any, project_id: number) => {
      let selectedCB = checkedInit;
      const buf = selectedCB.filter((i: number) => i === Init.id);

      if (!buf.length) {
        setCheckedInit([...checkedInit, Init.id]);

        const newInitiative = {
          id: Init.id,
          project_id: project_id,
          title: Init.title,
          build_type_id: null,
        };

        setCheckedRespArr([...checkedInitArr, newInitiative]);

        formik.setFieldValue(`${name}.initiatives`, [...checkedInitArr, newInitiative]);
      } else {
        setCheckedInit(selectedCB.filter((i: number) => i !== Init.id));
        setCheckedRespArr(checkedInitArr.filter(item => item.id !== Init.id));

        formik.setFieldValue(
          `${name}.initiatives`,
          checkedInitArr.filter(item => item.id !== Init.id),
        );

        if (!!Init.initiatives?.length) {
          const checkArray = objToId(Init.initiatives) || [];

          let newArray = checkedInitArr.reduce((acc: Array<any>, item) => {
            if (![...checkArray, Init.id]?.includes(item.id)) acc.push(item);
            return acc;
          }, []);

          setCheckedInit(objToId(newArray) || []);

          setCheckedRespArr(newArray);
          formik.setFieldValue(`${name}.initiatives`, newArray);
        }
      }
    },
    [checkedInitArr, checkedInit, name],
  );

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        <Grid container>
          {companyAndFunctions?.projects
            ?.filter((item: SubFunctionsType) =>
              formik.values.histories[selectedRole].departments
                .map((el: SubFunctionsType) => el.id)
                .includes(item.department_id),
            )
            ?.map((item: ProjectsType) => (
              <Box key={item.id} alignItems="center" sx={styleSwitchTitle}>
                <SubFunctionsItem
                  title={item.title}
                  checked={checkedProj.includes(item.id)}
                  onChange={() => handleSwitchChange(item.id)}
                />
                {checkedProj.includes(item.id) &&
                  item.initiatives.map(subItem => {
                    const currentSubItem = setSubItemStep2({
                      arrayItems: role?.initiatives,
                      checkItems: checkedInit,
                      defaultItem: subItem,
                    });

                    if (!currentSubItem) return null;
                    return (
                      <React.Fragment key={subItem.id}>
                        <ResponsibilitiesItem
                          title={currentSubItem.title}
                          key={subItem.id}
                          handleChange={() => handleChangeCheckbox(subItem, item.id)}
                          checked={checkedInit.includes(currentSubItem.id)}
                          parent={!!subItem.initiatives?.length}
                        />
                        {checkedInit.includes(subItem.id) && subItem.initiatives.length > 0 && (
                          <Box 
                            style={{
                              paddingLeft: '30px',
                              float: subItem.initiatives.some((subItem2: any) => !subItem2.is_custom)
                                ? 'left'
                                : 'none',
                              width: '100%',
                            }}
                          >
                            {subItem.initiatives.map(subItem2 => {
                              const currentSubItem2 = setSubItemStep2({
                                arrayItems: role?.initiatives,
                                checkItems: checkedInit,
                                defaultItem: subItem2,
                              });

                              if (!currentSubItem2) return null;
                              return (
                                <React.Fragment key={subItem2.id}>
                                  <ResponsibilitiesItem
                                    title={currentSubItem2.title}
                                    key={subItem2.id}
                                    handleChange={() => handleChangeCheckbox(subItem2, item.id)}
                                    checked={checkedInit.includes(currentSubItem2.id)}
                                    parent={!!subItem2.initiatives?.length}
                                  />
                                  {checkedInit.includes(subItem2.id) && subItem2.initiatives.length > 0 && (
                                    <Box 
                                      style={{
                                        paddingLeft: '30px',
                                        float: subItem.initiatives.some((subItem2: any) => !subItem2.is_custom)
                                          ? 'left'
                                          : 'none',
                                        width: '100%',
                                      }}
                                    >
                                      {subItem2.initiatives.map(subItem3 => {
                                        const currentSubItem3 = setSubItemStep2({
                                          arrayItems: role?.initiatives,
                                          checkItems: checkedInit,
                                          defaultItem: subItem3,
                                        });

                                        if (!currentSubItem3) return null;
                                        return (
                                          <ResponsibilitiesItem
                                            title={currentSubItem3.title}
                                            key={subItem3.id}
                                            handleChange={() => handleChangeCheckbox(subItem3, item.id)}
                                            checked={checkedInit.includes(currentSubItem3.id)}
                                          />
                                        );
                                      })}
                                    </Box>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </Box>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Box>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default memo(Initiatives);

const Card = styled(MuiCard)(spacing);
