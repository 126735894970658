import React, { useEffect, useState, CSSProperties } from 'react';
import { Grid } from '@mui/material';
import ReactQuill from 'react-quill';
import CloseIcon from '@mui/icons-material/Close';

import styled from 'styled-components/macro';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

type TProps = {
  value: string;
  placeholder?: string;
  onChange: (e: string) => void;
  onClick?: () => void;
  hideClose?: boolean;
};

const Editors: React.FC<TProps> = ({ value, onChange, placeholder, hideClose }) => {
  const [values, setValues] = useState('');

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <Grid item xs={12} mt={'25px'} paddingBottom={3}>
      <Wrapper onBlur={() => onChange(values)}>
        <ReactQuill
          theme="snow"
          value={values}
          onChange={(value: string) => setValues(value)}
          placeholder={placeholder || 'Type comment...'}
        />
        {placeholder !== 'Company Description' && !hideClose && (
          <CloseIcon style={stylesCLoseIcon} onClick={() => setValues('')} />
        )}
      </Wrapper>
    </Grid>
  );
};

export default Editors;

const Wrapper = styled.div`
  position: relative;
  .ql-editor {
    min-height: 100px;
    font-weight: 400;
    &.ql-blank:before {
    }
  }

  .ql-tooltip {
  }
`;

const stylesCLoseIcon: CSSProperties = {
  cursor: 'pointer',
  position: 'absolute',
  right: '15px',
  top: '10px',
};
