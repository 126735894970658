import styled from 'styled-components/macro';
import { Box, MobileStepper, Button, Grid } from '@mui/material';

export const FinanceContainer = styled(Box)({
  minHeight: '600px',
  backgroundColor: '#F5F8FA',
  padding: '30px 50px',
  fontFamily: 'Poppins',
  '@media (max-width: 768px)': {
    padding: '10px',
  },
  h2: {
    margin: 0,
    fontSize: '32px',
    lineHeight: '38px',
    color: '#002855',
    fontWeight: 500,
    '@media (max-width: 768px)': {
      fontSize: '18px',
      fontWeight: 600,
    },
  },
});

export const CustomStepper = styled(MobileStepper)({
  maxWidth: 400,
  flexGrow: 1,
  backgroundColor: 'transparent',
  paddingLeft: 0,
  '.MuiMobileStepper-dot': {
    backgroundColor: '#FFF',
    opacity: 0.5,
  },
  '.MuiMobileStepper-dotActive': {
    backgroundColor: '#00B192',
    opacity: 1,
  },
});

export const StepperButton = styled(Button)({
  minWidth: 0,
  padding: '5px',
  '&.Mui-disabled': {
    opacity: 0.5,
  },
});

export const LookingForFormWrapper = styled(Box)({
  width: '780px',
  padding: '30px 50px',
  boxShadow: '0 0 24px rgba(0, 40, 85, 0.1)',
  borderRadius: '5px',
  backgroundColor: '#FFF',
  zIndex: 10,
});

