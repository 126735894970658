import * as Yup from 'yup';
import StepsAdapter from '../../../models/adapters/steps.adapter';
import { ALERT_MESSAGES, PROFILE_PATH } from '../../../constants/constants';
import { Alert, Box, Card, Typography } from '@mui/material';
import { styleCardSection, styleCardStep3 } from '../StylesCandidateProfile';
import LookingForNext from '../FormSections/Step3/LookingForNext';
import MotivatorsToMove from '../FormSections/Step3/MotivatorsToMove';
import LikeBeDoing from '../FormSections/Step3/LikeBeDoing';
import CustomInput from '../../CustomInput';
import CustomButton from '../../CustomButton';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { step3Selector } from '../../../redux/selectors/candidate.selectors';
import { getDictionaries } from '../../../redux/actions/dictionaries';
import { setActiveStep } from '../../../redux/reducers/additional';
import { saveCandidate } from '../../../redux/actions/candidate';

type TProps = {
  id: string;
  handleNext: (values: any) => void;
};

const Step3General: React.FC<TProps> = ({ id, handleNext }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isCandidate } = useSelector((state: RootState) => state.user);
  const { candidate } = useSelector((state: RootState) => state.candidate);
  const valuesStep3 = useSelector(step3Selector);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dispatch(getDictionaries('companyAndFunctions'));
    dispatch(getDictionaries('reasons'));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      setTimeout(()=>{
        const errorTextEl = document.querySelector('[data-label="errorText"]');
        !!errorTextEl && errorTextEl.scrollIntoView({block: 'center'}); 
      }, 100);
    }
  }, [isError]);

  const handleNextClick = () => {
    if (isCandidate) {
      if (candidate?.ocp_answers?.top || candidate?.ocp_answers?.bottom) {
        const ocpTop = candidate?.ocp_answers?.top;
        const ocpBottom = candidate?.ocp_answers?.bottom;
        if (Object.keys(ocpTop).length || Object.keys(ocpBottom).length) {
          dispatch(setActiveStep(4));
          navigate(`/${PROFILE_PATH}/complete?candidate_id=${id}`);
        }
      }
    } else {
      navigate(`/${PROFILE_PATH}/profile-details?candidate_id=${id}`);
    }
  };

  return (
    <Formik
      initialValues={{
        level_id: valuesStep3.level_id,
        location_id: valuesStep3.location_id,
        industries: valuesStep3.industries,
        employmentTypes: valuesStep3.employmentTypes,
        employmentTypes_fullTime: valuesStep3.employmentTypes_fullTime,
        employmentTypes_contract: valuesStep3.employmentTypes_contract,
        salary_min: valuesStep3.salary_min,
        salary_max: valuesStep3.salary_max,
        rate_min: valuesStep3.rate_min,
        rate_max: valuesStep3.rate_max,

        functions: valuesStep3.functions,
        projects: valuesStep3.projects,
        reasons: valuesStep3.reasons,
        comment: valuesStep3.comment,

        submit: false,
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = StepsAdapter.mapStep3ValuesDtoToStep3DataModel(values);

        try {
          /*await handleNext(data);
          if (!isCandidate) {
            navigate(`/${PROFILE_PATH}/profile-details?candidate_id=${id}`);
          }*/
          !!id && dispatch(saveCandidate({ id: +id, values: data }));
          handleNextClick();
        } catch (error: any) {
          const message = error.message || ALERT_MESSAGES.DEFAULT_ERROR;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {formik => {
        const { handleSubmit, handleChange, values, errors } = formik;
        !!Object.keys(errors).length && setIsError(true);
        setTimeout(()=>setIsError(false), 100);

        return (
          <form noValidate onSubmit={handleSubmit}>
            <Card sx={styleCardSection}>
              <Typography variant="h5" gutterBottom color="secondary" mb={5}>
                What are you looking for next?
              </Typography>

              <Card sx={styleCardStep3}>
                {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}

                <Typography component="div" gutterBottom mb={5}>
                  <Typography component="div" variant="h6" gutterBottom color="secondary" mb={0}>
                    My Preferences
                  </Typography>

                  <Typography component="div" variant="body2">
                    Select all that apply (optional)
                  </Typography>
                </Typography>

                <LookingForNext formik={formik} />

                <Typography component="div" gutterBottom mb={5}>
                  <Typography component="div" variant="h6" gutterBottom color="secondary" mb={0}>
                    Motivators to Move
                  </Typography>

                  <Typography component="div" variant="body2">
                    What are the top reasons that would motiviate you to make a move? Select up to 3
                  </Typography>
                </Typography>

                <MotivatorsToMove formik={formik} />

                <Typography variant="h6" gutterBottom mb={5} color="secondary">
                  What would you like to be doing?
                </Typography>

                <LikeBeDoing formik={formik} />
              </Card>

              <Card sx={styleCardStep3}>
                <form noValidate onSubmit={handleSubmit}>
                  {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}

                  <Typography variant="h6" gutterBottom mb={5} color="secondary">
                    Comments
                  </Typography>

                  <CustomInput
                    name="comment"
                    value={values.comment}
                    onChange={handleChange}
                    placeholder="Is there anything else you’d like to share about what you are looking for next?"
                    multiline={true}
                    rows={4}
                  />
                </form>
              </Card>

              <Box display="flex" justifyContent="right" alignItems="center" p={5}>
                <CustomButton variant="contained">{isCandidate ? 'Next' : 'Done'}</CustomButton>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default Step3General;
