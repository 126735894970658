import { ScorecardValuesType, ScorecardType, ScorecardRespType } from '../../types/candidate';
import ScorecardDataModel from '../scorecardData.model';
import ScorecardItemValuesModel from '../scorecardItemValues.model';

class ScorecardAdapter {
  static mapScorecardDtoToScorecardValues(data: ScorecardType): ScorecardValuesType {
    let newScorecardDataModel: any = {};

    for (const key in data) {
      newScorecardDataModel[key] = data[key]?.map(item => new ScorecardItemValuesModel(item));
    }

    return newScorecardDataModel;
  }

  static mapScorecardValuesDtoToScorecardDataModel(data: ScorecardValuesType): ScorecardDataModel {
    let newScorecardDataModel: any = {};

    for (const key in data) {
      newScorecardDataModel[key] = data[key]?.map(item => new ScorecardDataModel(item));
    }

    return newScorecardDataModel;
  }

  static mapScorecardSavedDataToIds(data: Array<ScorecardRespType>): Array<number> {
    return data.map(item => item.id);
  }
}

export default ScorecardAdapter;
