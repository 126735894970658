import axios from '../utils/axios';
import { SearchType } from '../types/jobs';

export const jobs = {
  getCategories: async () => {
    const { data } = await axios.get('/jobs/categories');
    return data;
  },
  getFeatured: async () => {
    const { data } = await axios.get('/jobs/featured');
    return data;
  },
  searchJob: async (values: SearchType) => {
    const { data } = await axios.post('/jobs/search', values);
    return data;
  },
  getJob: async (id: number) => {
    const { data } = await axios.get(`/jobs/${id}`);
    return data;
  },
  getExternalId: async (id: number) => {
    const { data } = await axios.get(`/jobOrders/externalId/${id}`);
    return data;
  },
};
