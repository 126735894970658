import { Grid, Typography, SxProps } from '@mui/material';

import { spacing, styled } from '@mui/system';

type TProps = {
  icon: string;
  label?: string | null;
  value?: string | number | null;
  sx?: any;
};

const IconItem: React.FC<TProps> = ({ icon: Icon, label = '', sx, value }) => {
  return (
    <StyledIconWrap sx={sx}>
      <img src={Icon} alt={''} />
      {label && (
        <Typography component="span" className={'label'} sx={stylesFontWeightNormal}>
          {label}
        </Typography>
      )}
      {value && (
        <Typography component="span" ml={2} className={'value'} sx={stylesBoldText}>
          {value}
        </Typography>
      )}
    </StyledIconWrap>
  );
};

const IconWrap = styled(Grid)(spacing);

const stylesFontWeightNormal: SxProps = {
  fontWeight: 400,
};

const stylesBoldText: SxProps = {
  fontWeight: 600,
};

const StyledIconWrap = styled(IconWrap)`
  display: flex;
  align-items: center;

  & img {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

export default IconItem;
