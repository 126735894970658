import { Box, Typography, Grid, Button } from '@mui/material';
import { ShadowContainer, FullDetailsText, UlCategory } from '../../styles/components/CategoryFullDetailsStyles';
import CategoryIcon from './CategoryIcon';
import {ContainedButton, OutlinedButton} from '../../styles/ButtonStyles';
import React, { useEffect, useState } from 'react';
import { JobsType } from '../../types/jobs';
import { useDispatch, useSelector } from 'react-redux';
import { getExternalId } from '../../redux/actions/jobs';
import { RootState } from '../../redux/store';
import { durations, employmentTypes } from '../../utils/staticValues';
import {useNavigate} from "react-router-dom";
import {postRemoveMessage} from "../../utils/functions";

interface TProps {
  item: JobsType;
  showApplyNowModal: (show: boolean) => void;
}

const JobCardFullDetails: React.FC<TProps> = ({ item, showApplyNowModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const { isSearching } = useSelector((state: RootState) => state.jobs);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (item.id && !isSearching) {
      dispatch(getExternalId(item.id));
    }
  }, [dispatch, isSearching, item?.id]);

  const { externalId } = useSelector((state: RootState) => state.jobs);
  const responsibilitiesArr = item?.responsibilitiesForStep5?.map(el => el.title);
  const initiativesArr = item?.initiativesForStep5?.map(el => el.title);
  const responsibilitiesInitiativesArr = responsibilitiesArr?.concat(initiativesArr);
  const responsibilitiesInitiatives = item?.focus?.length
    ? item?.focus?.map((i: any) => i.title)
    : responsibilitiesInitiativesArr;

  const stickyElm = document.getElementById('sticky');

  const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1), {
    threshold: [1],
  });

  useEffect(() => {
    if (stickyElm) {
      observer.observe(stickyElm);
    }
  }, [stickyElm]);

  useEffect(() => {
    const fullDetailWrap = document.querySelector('.fullDetailWrap');
    fullDetailWrap && fullDetailWrap.scrollTo({ top: 0 });
  }, [item]);

  const showMoreButton = () => {
    if (responsibilitiesInitiatives?.length > 5) {
      if (expanded) {
        return <Button onClick={handleExpandClick}>Hide</Button>;
      } else {
        return <Button onClick={handleExpandClick}>Show More</Button>;
      }
    }
  };

  const showFocus = () => {
    if (!!item?.responsibilitiesForStep5?.length || !!item?.initiativesForStep5?.length) {
      return (
        <CategoryIcon
          type={'Flag'}
          title={'Focus'}
          text={
            <Box>
              <UlCategory>
                {expanded
                  ? responsibilitiesInitiatives?.map((el: any, id: number) => <li key={id}>{el}</li>)
                  : responsibilitiesInitiatives?.slice(0, 5).map((el: any, id: number) => <li key={id}>{el}</li>)}
              </UlCategory>
              {showMoreButton()}
            </Box>
          }
        />
      );
    }
  };

  const showDuration = (duration_id: any) => {
    return durations.filter(i => i.id === duration_id).map(i => i.title);
  };

  const showSalary = () => {
    if (item?.employment_type === employmentTypes.CONTRACT) {
      if (item?.published_rate) {
        if (item?.display_compensation) {
          return (
            <CategoryIcon
              type={'salary'}
              title={'Rate'}
              text={`$${item?.published_rate?.toLocaleString('en-US', { maximumFractionDigits: 2 })} ${showDuration(
                item?.duration_id,
              )}`}
            />
          );
        } else return null;
      }
    } else {
      if (item?.salary_min || item?.salary_max) {
        if (item?.display_compensation) {
          return (
            <CategoryIcon
              type={'salary'}
              title={'Salary'}
              text={`$${item.salary_min?.toLocaleString('en-US', { maximumFractionDigits: 2 })} 
              - ${item.salary_max?.toLocaleString('en-US', { maximumFractionDigits: 2 })}`}
            />
          );
        } else return null;
      }
    }
  };
  return (
    <ShadowContainer>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        className={'stickyHeader categoryTitle'}
        id={'sticky'}
      >
        <Typography variant={'h2'} color={'#002855'}>
          {item?.title}
        </Typography>
        <ContainedButton variant={'contained'} onClick={() => showApplyNowModal(true)}>
          Apply now
        </ContainedButton>
      </Grid>

      <Grid container p={'15px'} className={'fullDetailWrap'}>
        <Grid item xs={12} md={6} pr={'5px'} pt={'5px'}>
          {!!item?.employment_type && (
            <CategoryIcon
              type={'baseline_work_outline'}
              title={'Type'}
              text={
                item.employment_type === employmentTypes.CONTRACT
                  ? `${employmentTypes.CONTRACT} ${
                      item?.contract_duration && +item?.contract_duration ? `(${item?.contract_duration} months)` : ''
                    }`
                  : employmentTypes.FULL_TIME
              }
            />
          )}

          {showSalary()}

          {!!item?.industries?.length && (
            <CategoryIcon
              type={'company'}
              title={'Industry'}
              text={item?.industries?.map((el: any, id: number) => (
                <Typography variant={'body2'} key={id}>
                  {el.title}
                </Typography>
              ))}
            />
          )}

          {(!!item?.city || !!item?.province) && (
            <CategoryIcon
              type={'location_on'}
              title={'Location'}
              text={
                item?.city && item?.province
                  ? `${item?.city}, ${item?.province?.title}`
                  : item?.city
                  ? `${item?.city}`
                  : item?.province
                  ? `${item?.province?.title}`
                  : ''
              }
            />
          )}

          {!!item?.location?.title && (
            <CategoryIcon type={'workspace'} title={'Workplace'} text={item.location?.title} />
          )}
        </Grid>
        <Grid item xs={12} md={6} pl={window.innerWidth > 768 ? '10px' : 0}>
          {showFocus()}
        </Grid>
        <Grid item xs={12}>
          <FullDetailsText dangerouslySetInnerHTML={{ __html: item?.publishing_description }} />

          {!!externalId && (
            <Typography mt={'10px'} mb={'10px'}>
              JOB ID #{externalId}
            </Typography>
          )}
          <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', mt: 2 }}>
            <OutlinedButton variant={'outlined'} onClick={() => {
              postRemoveMessage();
              navigate('/');
            }} className={'hideForMobile'}>
              View All Jobs
            </OutlinedButton>
            <ContainedButton variant={'contained'} onClick={() => showApplyNowModal(true)}>
              Apply now
            </ContainedButton>
          </Box>
        </Grid>
      </Grid>
    </ShadowContainer>
  );
};

export default JobCardFullDetails;
