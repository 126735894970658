import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListSubheader, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components/macro';
import { makeStyles } from '@mui/styles';

import ErrorText from './ErrorText';

type TProps = {
  labelId: string;
  name: string;
  value: any;
  label: string;
  data: Array<any>;
  onChange: (e: string | ChangeEvent<any> | any) => void;
  onDelete?: (e: any) => void;
  multiple?: boolean;
  error?: any;
  errorText?: any;
  group?: boolean;
  disabled?: boolean;
  placeholder?: string;
  whitCheckbox?: boolean;
  withoutSave?: boolean;
  small?: boolean;
};

const CustomSelect: React.FC<TProps> = ({ data = [], ...props }) => {
  const [label, setLabel] = useState(props.placeholder || props.label);
  const [value, setValue] = useState<any>('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLabel(props.label);
    !props.multiple &&
      setValue(
        Array.isArray(props.value)
          ? props.value.map((el: any) => {
              if (typeof el === 'object') {
                return el.id;
              } else {
                return el;
              }
            })
          : props.value,
      );
  }, [props.value]);

  const CustomIcon = () => {
    const classes = useStyles();
    return (
      <ButtonArrow open={open} onClick={() => setOpen(!open)} sx={{ top: props.small ? 0 : '12px' }}>
        <KeyboardArrowDownIcon className={classes.iconStyle} />
      </ButtonArrow>
    );
  };

  return (
    <Root disabled={props.disabled}>
      <FormControl fullWidth>
        <InputLabel
          id={props.labelId}
          sx={{
            fontWeight: 400,
            right: 0,
            left: 0,
            transform: props.small ? 'translate(14px, 2px) scale(1)' : 'translate(14px, 16px) scale(1)',
            color: '#B8B8B8',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(14px,-9px) scale(0.9);',
              color: '#5E5E5E',
            },
            '&.Mui-focused': {
              color: '#2681DB',
            },
          }}
        >
          {label}
        </InputLabel>

        <Select
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          labelId={props.labelId}
          name={props.name}
          value={
            props.multiple
              ? typeof props.value === 'object'
                ? props.value.map((el: any) => {
                    if (typeof el === 'object') {
                      return el.id;
                    } else {
                      return el;
                    }
                  })
                : props.value
              : value
          }
          label={label}
          placeholder={props.placeholder}
          onChange={(val: any) => {
            props.onChange(val);
          }}
          onFocus={() => {
            setLabel(props.label);
          }}
          onBlur={() => {
            !props.value && setLabel(props.placeholder || props.label);
          }}
          multiple={props.multiple}
          error={props.error}
          IconComponent={CustomIcon}
          disabled={props.disabled}
          style={{ fontWeight: '400' }}
          sx={
            props.small
              ? {
                  height: '25px',
                  borderRadius: '16px',

                  '.MuiSelect-select': {
                    padding: '0 14px',
                  },
                }
              : {}
          }
        >
          {props.group
            ? data?.map((item: any) => {
                return [
                  <ListSubheader>{item.title}</ListSubheader>,
                  item.subFunctions.map((subItem: any) => {
                    return (
                      <MenuItem key={subItem.id} value={subItem.id.toString()}>
                        {subItem.title}
                      </MenuItem>
                    );
                  }),
                ];
              })
            : data?.map((item: any) => (
                <MenuItem key={item.id} value={item.id} style={{ fontWeight: 400 }}>
                  {props.multiple && props.whitCheckbox && (
                    <Checkbox
                      checked={props.value.indexOf(item.id) > -1}
                      style={{
                        color: '#00B192',
                        borderColor: '#fff',
                        padding: 0,
                        height: '0.8em',
                        width: '0.8em',
                        marginRight: 5,
                        marginLeft: 5,
                      }}
                    />
                  )}
                  {item.title}
                </MenuItem>
              ))}
        </Select>

        <ErrorText error={props.errorText} />
      </FormControl>
    </Root>
  );
};

export default CustomSelect;

const Root = styled('div')<{
  disabled?: boolean;
}>`
  justify-content: center;
  align-items: center;
  display: flex;

  img {
    ${props => (props.disabled ? 'display: none' : '')};
  }
`;

const ButtonArrow = styled(Button)<{
  open?: boolean;
}>`
  position: absolute;
  right: 0;
  min-width: auto;
  border-radius: 50%;
  padding: 3px;
  margin-right: 7px;
  ${props => (props.open ? 'transform: rotate(180deg)' : '')};

  &:hover {
    background-color: transparent !important;
  }

  svg {
    fill: #19a0fb;
  }
`;

const useStyles = makeStyles({
  iconButtonStyle: {
    position: 'absolute',
    top: '15px',
    right: 0,
    minWidth: 'auto',
    borderRadius: '50%',
    padding: '2px',
    marginRight: '7px',

    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  iconStyle: {},
});
