import { Typography, SxProps, Box } from '@mui/material';

import { styled } from '@mui/system';
import { ResponsibilityType } from '../../../../types/candidate';

type TProps = {
  responsibilities: Array<ResponsibilityType>;
};

const ResponsibilitiesList: React.FC<TProps> = ({ responsibilities }) => {
  return (
    <StyledList component="ul">
      {responsibilities.map(
        (
          {
            title,
            description,
          }: {
            title: string | null;
            description: string | null;
          },
          idx: number,
        ) => {
          return (
            <Box component="li" key={idx}>
              {title && (
                <Typography component="span" sx={stylesBoldText}>
                  {title}{`${description ? ':' : ''}`}
                </Typography>
              )}
              <Typography component="span" ml={2} style={{fontWeight: 400}}>
                {description}
              </Typography>
            </Box>
          );
        },
      )}
    </StyledList>
  );
};

const stylesBoldText: SxProps = {
  fontWeight: 600,
};

const StyledList = styled(Box)`
  list-style: disc;
  margin: 0;
  padding: 0;
  padding-left: 20px;
`;

export default ResponsibilitiesList;
