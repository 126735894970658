import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { styled } from '@mui/material/styles';
import HeaderRecruiter from './Header/HeaderRecruiter';
import { getCurrentStep } from '../utils/stepsRouting';
import { setActiveStep } from '../redux/reducers/additional';
import { RootState } from '../redux/store';

interface TProps {
  children?: React.ReactNode;
}

const MainLayoutRecruiter: React.FC<TProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { category } = useParams();

  const { isCandidate } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    category && dispatch(setActiveStep(getCurrentStep(category)));
  }, [category, dispatch]);

  return (
    <Box sx={stylesMain}>
      <WrapperHeader>
        <HeaderRecruiter />
      </WrapperHeader>

      <Box sx={stylesWrapper}>
        <Box component="main" width="100%" height="100vh">
          {children}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayoutRecruiter;

export const stylesMain: SxProps = {
  height: '100%',
  width: '100%',
  background: '#f5f8fa',
};

const WrapperHeader = styled(Box)<{
  show: boolean;
}>`
  // height: 176px;
  width: 100%;
  position: sticky;
  z-index: 1250;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px;
`;

export const stylesListItemIcon: SxProps = {
  minWidth: 0,
  mr: 3,
  justifyContent: 'center',
};

export const stylesWrapper: SxProps = {
  display: 'flex',
  minHeight: '100%',
  position: 'relative',
};

export const stylesWrapMenu: SxProps = {
  width: '100%',
  height: '100%',
  position: 'relative',
  paddingRight: '28px',
  backgroundColor: '#F5F8FA',
};

export const stylesMenu: SxProps = { width: '100%', height: '100%', backgroundColor: '#fff', pl: 1 };

export const stylesListItemButton: SxProps = {
  flexDirection: 'column',
  paddingLeft: 11,
};

export const stylesAddAnotherButton: SxProps = {
  paddingLeft: 4,
};

export const stylesHeaderMenu: SxProps = {
  position: 'relative',
  justifyContent: 'flex-start',
};

export const stylesIconButton: SxProps = {
  position: 'absolute',
  top: -10,
  right: -6,
  transform: 'rotate(180deg)',
  cursor: 'pointer',
  zIndex: 9,
};

// styles of comment drawer
export const stylesWrapComment: SxProps = {
  width: '100%',
  height: '100%',
  position: 'relative',
  paddingLeft: '28px',
  backgroundColor: '#F5F8FA',
  border: 'none',
};

export const stylesIconButtonComment: SxProps = {
  position: 'absolute',
  top: -10,
  left: -6,
  cursor: 'pointer',
  zIndex: 9,
};

export const stylesComment: SxProps = {
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  p: 2,
  display: 'flex',
  flexDirection: 'column',

  'div:nth-child(2)': { flex: 1, div: { height: '100%' } },
  textarea: { height: '100% !important' },
};

export const stylesSaveButton: SxProps = {
  mt: 5,
  display: 'flex',
  width: '100%',
  justifyContent: 'end',
};
