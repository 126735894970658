import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PROFILE_PATH } from '../../constants/constants';

import useAuth from '../../hooks/useAuth';

interface GuestGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const candidateId = params.get('candidate_id') || params.get('id');
  const submissionId = params.get('submissionId');

  if (isInitialized && isAuthenticated) {
    if (user?.candidates && user?.candidates[0]?.id) {
      return (
        <Navigate
          to={`/${PROFILE_PATH}${`?id=${user?.candidates[0]?.id}`}${
            !!submissionId ? `&submissionId=${submissionId}` : ''
          }`}
        />
      );
    } else {
      return (
        <Navigate
          to={`/${PROFILE_PATH}${!!candidateId || !!submissionId ? '?' : ''}${
            !!candidateId ? `id=${candidateId}` : ''}${
            !!candidateId && !!submissionId ? '&' : ''
          }${!!submissionId ? `submissionId=${submissionId}` : ''}`}
        />
      );
    }
  }

  return <>{children}</>;
}

export default GuestGuard;
