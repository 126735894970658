import React, { memo, useCallback, useEffect, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Card } from '@mui/material';
import { SxProps } from '@mui/system';

import ScorecardSection from './Scorecard/ScorecardSection';
import CustomButton from '../../CustomButton';

import { ScorecardRespType } from '../../../types/candidate';
import { scorecardSelector } from '../../../redux/selectors/candidate.selectors';
import { setScorecardSavedData } from '../../../redux/reducers/candidate';
import { getScorecard, saveScorecard } from '../../../redux/actions/candidate';
import { RootState } from '../../../redux/store';

import { Table, THead, TBody, TH, TR, TD } from '../FormSections/StylesTable';
import { attantionIcon } from '../../../constants/icons';
import Loader from '../../Loader';

const CandidateScorecard: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const candidateId = params.get('candidate_id') || params.get('id');

  const { scorecardSavedData, isSaving, isLoading } = useSelector((state: RootState) => state.candidate);
  const scorecard = useSelector(scorecardSelector);

  useEffect(() => {
    if (!!candidateId && !!+candidateId && !scorecard) {
      dispatch(getScorecard(candidateId));
    }
  }, [candidateId, scorecard, dispatch]);

  const onChangeValue = useCallback(
    (values: ScorecardRespType) => {
      dispatch(setScorecardSavedData(values));
    },
    [dispatch],
  );

  const onSaveScorecard = useCallback(() => {
    scorecardSavedData?.length &&
      dispatch(saveScorecard({ id: scorecardSavedData[0].candidate_id, values: scorecardSavedData }));
  }, [scorecardSavedData, dispatch]);

  return (
    <Card sx={styleCard}>
      {isLoading && <Loader/>}
      <Box sx={styleWrapTable}>
        <Table>
          <THead>
            <TR>
              <TH width="80%" colSpan={2} style={stylesRequirement}>
                Requirement
              </TH>
              <TH width="20%" colSpan={4} style={stylesHeader}>
                <Box style={styleRow}>Experience Level {attantionIcon}</Box>
              </TH>
            </TR>

            <TR>
              <TH width="80%" colSpan={2} style={stylesHeader} />
              <TH width="5%" style={stylesHeaderCenter}>
                Yes
              </TH>
              <TH width="5%" style={stylesHeaderCenter}>
                Some
              </TH>
              <TH width="5%" style={stylesHeaderCenter}>
                None
              </TH>
              <TH width="5%" style={stylesHeaderCenter}>
                Comment
              </TH>
            </TR>
          </THead>

          <TBody>
            {!!scorecard ? (
              Object.entries(scorecard).map(([key, value]: any) => {
                return <ScorecardSection key={key} title={key} data={value} onChangeValue={onChangeValue} />;
              })
            ) :
              !isLoading ? (
                <TR>
                  <TD colSpan={6} style={styleEmptySrorecard}>
                    There are no any items
                  </TD>
                </TR>
              ) : null
            }
          </TBody>
        </Table>
      </Box>

      <Box display="flex" justifyContent="end" padding="20px 0">
        <CustomButton
          variant="contained"
          onClick={onSaveScorecard}
          disabled={!scorecardSavedData?.length}
          loading={isSaving}
        >
          Edit scorecard
        </CustomButton>
      </Box>
    </Card>
  );
};

export default memo(CandidateScorecard);

const styleCard: SxProps = {
  padding: 4,
  height: '100vh',
  backgroundColor: 'transparent',
};

const styleWrapTable: SxProps = {
  width: '100%',
  overflow: 'auto',
  borderRadius: 1,
  backgroundColor: '#fff',
};

const styleEmptySrorecard: CSSProperties = { textAlign: 'center', padding: 15 };

const styleRow: CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: 160,
  margin: 'auto',
};

const stylesHeader: CSSProperties = {
  color: '#757678',
  textAlign: 'left',
  padding: 10,
  border: 'none',
  textTransform: 'uppercase',
};

const stylesHeaderCenter: CSSProperties = {
  color: '#757678',
  textAlign: 'center',
  padding: 10,
  border: 'none',
  textTransform: 'uppercase',
};

const stylesRequirement: CSSProperties = {
  color: '#757678',
  textAlign: 'left',
  padding: '10px 10px 10px 35px',
  border: 'none',
  textTransform: 'uppercase',
};
