import { format } from 'date-fns';
import { InitiativeType, ResponsibilityType } from '../types/candidate';
import RoleValuesModel from './roleValues.model';
import { FormatDate } from '../constants/constants';
import { objToId } from '../utils/formatData';

class RoleDataModel {
  id?: number;
  title: string;
  company: string;
  start_date: string | null;
  end_date: string | null;
  industry_id?: number | null;
  industries: Array<number>;
  revenue_id?: number | null;
  employee_id?: number | null;
  ownership_id?: number | null;
  departments: Array<any>;
  reports_to: string;
  level_id?: number | null;
  is_manage_team: boolean;
  direct_reports?: number | null;
  jobFunctions: Array<number>;
  responsibilities: Array<ResponsibilityType>;
  projects: Array<number>;
  initiatives: Array<InitiativeType>;
  additional: string;
  is_current: boolean;

  constructor(data: RoleValuesModel) {
    this.id = data.id;
    this.title = data.title;
    this.company = data.company;
    this.start_date = data.start_date ? format(new Date(data.start_date), FormatDate.roleDate) : null;
    this.end_date = data.end_date ? format(new Date(data.end_date), FormatDate.roleDate) : null;
    this.industry_id = data.industry_id;
    this.industries = data.industries?.sort((a: any, b: any) => a.id - b.id) || [];
    this.revenue_id = data.revenue_id;
    this.employee_id = data.employee_id;
    this.ownership_id = data.ownership_id;
    this.departments = data.departments || [];
    this.reports_to = data.reports_to;
    this.level_id = data.level_id;
    this.is_manage_team = Boolean(+data.is_manage_team);
    this.direct_reports = data.direct_reports ? Number(data.direct_reports) : null;
    this.jobFunctions = data.jobFunctions;
    this.responsibilities = data.responsibilities;
    this.projects = data.projects;
    this.initiatives = data.initiatives;
    this.additional = data.additional;
    this.is_current = data.is_current;
  }
}

export default RoleDataModel;
