import React, { useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { SxProps } from '@mui/system';

import UploadIcon from '../images/modal/Upload.svg';

type TProps = {
  file: () => void;
  setFile: (file: any) => void;
  fileTypes?: string;
};

const DragAndDropUploadFile: React.FC<TProps> = ({ file, setFile, fileTypes = 'JPG, PNG, GIF' }) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const onChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <form noValidate onSubmit={e => e.preventDefault()} onDragEnter={handleDrag} onDrop={handleDrop}>
      <Box sx={fileUploader}>
        <label htmlFor="file-id">
          <input name="file" type="file" id="file-id" hidden accept={fileTypes} onChange={onChange} />

          <Typography variant="subtitle1" color="secondary">
            Upload your resume
          </Typography>

          <Grid container alignItems="cebter" justifyContent="space-between">
            <Grid item>
              <Typography component="div" variant="caption" color="desable" maxWidth={150}>
                DOC, DOCX, PDF (2 MB)
              </Typography>
            </Grid>

            <Grid item>
              {file && (
                <Typography variant="body1" color="secondary">
                  {file.name}
                </Typography>
              )}

              <Grid item display="flex" alignItems="center" justifyContent="end">
                <Typography component="div" color="primary">
                  UPLOAD
                </Typography>
                <Box component="img" m={1} src={UploadIcon} />
              </Grid>
            </Grid>
          </Grid>

          {dragActive && (
            <div
              className="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </label>
      </Box>
    </form>
  );
};

export default DragAndDropUploadFile;

export const fileUploader: SxProps = {
  backgroundColor: '#F5F8FA',
  width: '100%',
  height: 90,
  padding: '15px',
  marginTop: 5,

  label: {
    width: '100%',
    border: 'none',
    position: 'relative',
  },

  '.drag-file-element': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};
