import Step1DataModel from '../step1Data.model';
import Step1ValuesModel from '../step1Values.model';
import Step3DataModel from '../step3Data.model';
import Step3ValuesModel from '../step3Values.model';

class StepsAdapter {
  static mapStep1ValuesDtoToStep1DataModel(data: Step1ValuesModel & { submit: boolean }): Step1DataModel {
    return new Step1DataModel(data);
  }

  static mapStep3ValuesDtoToStep3DataModel(data: Step3ValuesModel & { submit: boolean }): Step3DataModel {
    return new Step3DataModel(data);
  }
}

export default StepsAdapter;
