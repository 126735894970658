import React, { useEffect } from 'react';

import ApplicationStatus from '../../components/CandidateProfile/ApplicationStatus/ApplicationStatus';

const ApplicationStatusPage: React.FC = () => {
  useEffect(() => {}, []);

  return <ApplicationStatus />;
};

export default ApplicationStatusPage;
