import styled from 'styled-components/macro';
import { Box, Chip, Grid } from '@mui/material';

export const ShadowContainer = styled(Grid)({
  width: '100%',
  backgroundColor: '#FFF',
  marginBottom: '15px',
  boxShadow: '0 0 6px rgba(4, 25, 100, 0.08)',
  borderRadius: '5px',
});

export const ShadowContainer2 = styled(Grid)({
  width: '100%',
  border: '27px solid #FFF',
  backgroundColor: '#F5F8FA',
  boxShadow: '0 0 6px rgba(4, 25, 100, 0.08)',
  borderRadius: '5px',
});

export const ImgWrapper = styled(Box)`
  margin: 0 5px 15px 0;
  display: flex;
  width: 40%;

  svg {
    margin-right: 0.5rem;
    color: #88d1af;
  }

  img {
    width: 17px;
    height: 17px;
    margin-right: 5px;
    margin-top: 3px;
  }
`;

export const CategoryItemDetails = styled('div')({
  margin: 0,
  fontSize: '14px',
  height: '61px',
  overflow: 'hidden',
  marginTop: '10px',
});

export const FullDetailsText = styled('div')({
  marginTop: '20px',
  '@media (max-width: 768px)': {
    borderTop: '1px solid #C4C4C4',
  },
  h1: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    width: '100%',
    marginTop: '25px',
    marginBottom: '20px',
    color: '#002855',
  },
  h2: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    width: '100%',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginBottom: 0,
    marginTop: '10px',
  },
  h3: {
    marginBottom: 0,
    marginTop: '10px',
  },
  p: {
    margin: 0,
    fontSize: '14px',
  },
  ul: {
    fontSize: '14px',
    fontWeight: 400,
    padding: '0 20px',
  },
  li: {
    marginBottom: 0,
  },
});

export const UlCategory = styled('ul')`
  font-size: 14px;
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 0;
  li {
    color: rgba(0, 0, 0, 0.87);
  }
`;

export const StyledChip = styled(Chip)`
  border-radius: 15px;
  height: 25px;
  color: inherit;
  background-color: #eeeeee;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 10px;
  max-width: 100%;
`;

export const SearchIconContainer = styled('div')({
  display: 'flex',
  width: '48px',
  height: '48px',
  backgroundColor: '#2681DB',
  marginLeft: '15px',
  boxShadow: '0 0 6px rgba(4, 25, 100, 0.08)',
  borderRadius: '24px',
  justifyContent: 'center',
  color: '#FFF',
  alignItems: 'center',
  cursor: 'pointer',
});

export const ModalContainer = styled(Box)({
  backgroundColor: '#FFF',
  padding: '15px 30px 15px 15px',
  boxShadow: '0 0 6px rgba(4, 25, 100, 0.08)',
  borderRadius: '5px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  '@media (max-width: 768px)': {
    width: '90%',
    padding: '0 10px 10px 0',
  },
});

export const FileInputContainer = styled(Grid)({
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '5px',
  height: '53px',
  paddingLeft: '15px',
  p: {
    color: '#B7B8B9',
    fontWeight: 400,
  },
});

export const FileInput = styled('input')({
  display: 'none',
});

export const TextBox = styled(Box)({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: 0.1,
  color: 'rgba(0, 0, 0, 0.87)',
  paddingRight: '3px',
  width: '60%',
});

export const SearchSelectContainer = styled(Box)({
  minWidth: '115px',
  marginRight: '5px',
  height: '25px',
});
