import { createSlice } from '@reduxjs/toolkit';
import { User as UserType } from '../../types/user';
import { getProfile, updateProfile, updatePassword, signOut, checkActiveEmail } from '../actions/user';

type ProfileStateType = {
  profile: UserType;
  role: 'Internal' | 'Candidate';
  isCandidate: boolean;
  isActiveEmail: boolean;
  isLoading: boolean;
  errors: any;
};

const initialState: ProfileStateType = {
  profile: {} as UserType,
  role: 'Internal',
  isCandidate: false,
  isActiveEmail: false,
  isLoading: false,
  errors: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStateUser(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
  },
  extraReducers: {
    [getProfile.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [getProfile.fulfilled.toString()]: (state, { payload }) => {
      state.profile = payload;
      state.isCandidate = payload?.type === 'Candidate';
      state.isLoading = false;
    },
    [getProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [updateProfile.pending.toString()]: state => {
      state.isLoading = true;
    },
    [updateProfile.fulfilled.toString()]: (state, { payload }) => {
      // state.profile = { ...state.profile, ...payload.data };
      state.isLoading = false;
    },
    [updateProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [updatePassword.pending.toString()]: state => {
      state.isLoading = true;
    },
    [updatePassword.fulfilled.toString()]: (state, { payload }) => {
      // state.profile = { ...state.profile, ...payload.data };
      state.isLoading = false;
    },
    [updatePassword.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [signOut.pending.toString()]: state => {
      state.isLoading = true;
    },
    [signOut.fulfilled.toString()]: (state, { payload }) => {
      localStorage.clear();
      state.profile = {} as UserType;
      state.isLoading = false;
    },
    [signOut.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [checkActiveEmail.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [checkActiveEmail.fulfilled.toString()]: (state, { payload }) => {
      state.isActiveEmail = payload.is_active;
      state.isLoading = false;
    },
    [checkActiveEmail.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
  },
});

export const { setStateUser } = userSlice.actions;

export default userSlice.reducer;
