import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';

import { MobileBackButton } from '../styles/ButtonStyles';

type TProps = {
  title: string;
  onClick?: (show: boolean) => void;
};

const BackButtonMobile: React.FC<TProps> = ({ title, onClick }) => {
  const navigate = useNavigate();
  return (
    <MobileBackButton onClick={() => (onClick ? onClick(false) : navigate('/'))}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ArrowBackIcon />
        <Box ml={'5px'}>{title}</Box>
      </div>
    </MobileBackButton>
  );
};

export default BackButtonMobile;
