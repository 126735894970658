import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import { SxProps } from '@mui/system';

type TProps = {
  variant?: 'contained' | 'text' | 'outlined' | undefined;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  type?: 'submit' | 'button' | 'reset' | undefined;
  onClick?: any;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};

const CustomButton: React.FC<TProps> = ({
  variant = 'contained',
  onClick,
  color = 'info',
  children,
  type = 'submit',
  loading,
  disabled,
}) => (
  <LoadingButton
    type={type}
    onClick={onClick}
    variant={variant}
    color={color}
    loading={loading}
    disabled={disabled}
    sx={styles}
  >
    {children}
  </LoadingButton>
);

export default CustomButton;

export const styles: SxProps = {
  borderRadius: 30,
  cursor: 'pointer',
};
