import React, { useMemo } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { styled } from '@mui/system';

import IconItem from './IconItem';
import ResponsibilitiesList from './ResponsibilitiesList';
import IndustryIcon from '../../../../images/icons/ic_company_48px.svg';
import EmployeesIcon from '../../../../images/icons/ic_people_outline_48px.svg';
import OwnershipIcon from '../../../../images/icons/ic_Flag_48px.svg';
import RevenueIcon from '../../../../images/icons/ic_attach_money_48px.svg';
import CircleIcon from '../../../../images/icons/circle.svg';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { arrayTitleToString, experienceToString } from '../../../../utils/functions';
import { RoleType } from '../../../../types/candidate';

const WorkHistoryContent: React.FC = () => {
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const sortedWorkHistoryArray = useMemo(() => {
    if (!!candidate?.histories?.length) {
      const sortedArr = JSON.parse(JSON.stringify(candidate.histories));
      sortedArr.sort((a: RoleType, b: RoleType) => {
        const firstDate = a.end_year ? new Date(a.end_date).getTime() : new Date().getTime();
        const secondDate = b.end_year ? new Date(b.end_date).getTime() : new Date().getTime();
        return secondDate - firstDate;
      });
      return sortedArr;
    }
    return [];
  }, [candidate?.histories]);

  const getCompanyArray = (arr: Array<any>) => {
    const titles = arr.map(({ company }) => company);
    const companyArray = titles.filter((item: any, index: number, itemsArr: Array<any>) => {
      return index === itemsArr.indexOf(item);
    });
    return companyArray;
  };

  const companyArray = !!candidate?.histories.length ? getCompanyArray(sortedWorkHistoryArray) : [];

  return (
    <Box>
      <Typography sx={stylesSectionTitle} color="secondary" m={'15px 0 10px'}>
        Work History
      </Typography>
      <BoxBorder>
        {companyArray?.map((companyTitle: string | null, index: number) => {
          if (companyTitle) {
            const companyHistoryArray =
              (!!sortedWorkHistoryArray?.length &&
                sortedWorkHistoryArray.filter((item: any) => item.company === companyTitle)) || [];

            if (!!companyHistoryArray.length) {
              companyHistoryArray.sort((a: RoleType, b: RoleType) => {
                const firstDate = a.end_year ? new Date(a.end_date).getTime() : new Date().getTime();
                const secondDate = b.end_year ? new Date(b.end_date).getTime() : new Date().getTime();
                return secondDate - firstDate;
              });

              return (
                <React.Fragment key={index}>
                  <StyledTitle>
                    <Typography sx={stylesSectionTitle} color="secondary">
                      {companyHistoryArray[0].company || 'No information'}
                    </Typography>
                    <StyledLine />
                    <Typography color="secondary">
                      {(companyHistoryArray[0].start_date &&
                        experienceToString({
                          startDate: companyHistoryArray[companyHistoryArray.length - 1].start_date,
                          endDate: companyHistoryArray[0].end_year ? companyHistoryArray[0].end_date : '',
                        })) ||
                        'No information'}
                    </Typography>
                  </StyledTitle>

                  {(!!companyHistoryArray[0]?.industries?.length ||
                    !!companyHistoryArray[0]?.employee?.title ||
                    companyHistoryArray[0]?.ownership?.title ||
                    companyHistoryArray[0]?.revenue?.title) && (
                    <StyledIconSet>
                      {!!companyHistoryArray[0]?.industries?.length && (
                        <IconItem
                          icon={IndustryIcon}
                          label={'Industry:'}
                          value={arrayTitleToString({ arr: companyHistoryArray[0].industries, symbol: ',' })}
                        />
                      )}
                      {!!companyHistoryArray[0]?.employee?.title && (
                        <IconItem
                          icon={EmployeesIcon}
                          label={'Employees:'}
                          value={companyHistoryArray[0].employee?.title || '-'}
                        />
                      )}
                      {companyHistoryArray[0]?.ownership?.title && (
                        <IconItem
                          icon={OwnershipIcon}
                          label={'Ownership:'}
                          value={companyHistoryArray[0].ownership?.title || '-'}
                        />
                      )}
                      {companyHistoryArray[0]?.revenue?.title && (
                        <IconItem
                          icon={RevenueIcon}
                          label={'Revenue:'}
                          value={companyHistoryArray[0].revenue?.title || '-'}
                        />
                      )}
                    </StyledIconSet>
                  )}

                  {companyHistoryArray.map((el: RoleType, idx: number) => {
                    const { company, title, start_date, end_date, end_year, responsibilities, additional } = el;
                    if (companyTitle === company) {
                      return (
                        <StyledRoleBox
                          key={idx}
                          showborder={idx === companyHistoryArray.length - 1 ? undefined : 'true'}
                        >
                          <StyledWrap>
                            <IconItem icon={CircleIcon} sx={{ width: '24px', height: '24px' }} />
                            <StyledSubTitle>
                              <Typography sx={stylesSubtitle} color="secondary">
                                {title || 'No information'}
                              </Typography>
                              <Typography color="secondary">
                                {`${start_date} - ${end_year ? end_date : 'present'}`}
                              </Typography>
                            </StyledSubTitle>
                          </StyledWrap>

                          <Box pl={7}>
                            <ResponsibilitiesList responsibilities={responsibilities} />
                            {additional && (
                              <>
                                <Typography sx={stylesSubtitle} m={'15px 0 5px'}>
                                  Additional
                                </Typography>
                                <AdditionalText dangerouslySetInnerHTML={{ __html: additional }} />
                              </>
                            )}
                          </Box>
                        </StyledRoleBox>
                      );
                    }
                    return null;
                  })}
                </React.Fragment>
              );
            }
          }
          return null;
        })}
      </BoxBorder>
    </Box>
  );
};

const stylesSectionTitle: SxProps = {
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: 0.04
};

const stylesSubtitle: SxProps = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  letterSpacing: '0.09px',
};

const StyledRoleBox = styled(Box)<{
  showborder?: string;
}>`
  position: relative;
  margin-bottom: 15px;

  &:not(:last-child) {
    &::after {
      display: ${({ showborder }) => (!!showborder ? 'block' : 'none')};
      content: '';
      position: absolute;
      top: 28px;
      left: 10px;
      height: 100%;
      border-left: 0.5px dashed #5E5E5E;
    }
  }
`;

const StyledWrap = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const StyledLine = styled(Box)`
  border-bottom: 1px solid;
  border-color: #E9E7E7;
  height: 1px;
  flex-grow: 1;
  margin: 8px;
`;

const BoxBorder = styled(Box)`
  border: 1px solid #E9E7E7;
  border-radius: 5px;
  padding: 15px 20px;
  height: 100%;
`;

const StyledTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledIconSet = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 8px;
  align-items: center;
  margin-bottom: 15px;

  > :not(last-child) {
    padding-right: 8px;
    border-right: 1px solid;
    border-color: #7A7A7A;
  }

  > :last-child {
    border-right: none;
  }
`;

const StyledSubTitle = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* margin-bottom: 15px; */
  background: #f5f8fa;
  border-radius: 5px;
`;

const AdditionalText = styled('div')`
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;

  p {
    margin: 0;
  }
  ul {
    padding: '0 20px';
  }
  li {
    margin-bottom: 0;
  }
`;

export default WorkHistoryContent;
