import { createSlice } from '@reduxjs/toolkit';
import {getCategories, getFeatured, searchJob, getExternalId, getJob} from '../actions/jobs';
import { CategoryType, JobsType } from '../../types/jobs';

type JobsStateType = {
  categories: Array<CategoryType>;
  featured: Array<JobsType>;
  searchJobResult: any;
  searchOneJobResult: any;
  isLoading: boolean;
  isSearching: boolean;
  errors: string | null;
  searchErrors: string | null;
  externalId: number;
};

const initialState: JobsStateType = {
  categories: [],
  featured: [],
  searchJobResult: [],
  searchOneJobResult: null,
  isLoading: false,
  isSearching: false,
  errors: null,
  searchErrors: null,
  externalId: 0,
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: {
    [getCategories.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [getCategories.fulfilled.toString()]: (state, { payload }) => {
      state.categories = payload;
      state.isLoading = false;
    },
    [getCategories.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [getFeatured.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [getFeatured.fulfilled.toString()]: (state, { payload }) => {
      state.featured = payload;
      state.isLoading = false;
    },
    [getFeatured.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [searchJob.pending.toString()]: state => {
      state.isSearching = true;
      state.searchErrors = null;
    },
    [searchJob.fulfilled.toString()]: (state, { payload }) => {
      state.searchJobResult = payload;
      state.isSearching = false;
    },
    [searchJob.rejected.toString()]: (state, { payload }) => {
      state.isSearching = false;
      state.searchErrors = payload;
    },
    [getJob.pending.toString()]: state => {
      state.isSearching = true;
      state.searchErrors = null;
    },
    [getJob.fulfilled.toString()]: (state, { payload }) => {
      state.searchOneJobResult = payload;
      state.isSearching = false;
    },
    [getJob.rejected.toString()]: (state, { payload }) => {
      state.isSearching = false;
      state.searchErrors = payload;
    },
    [getExternalId.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [getExternalId.fulfilled.toString()]: (state, { payload }) => {
      state.externalId = payload.externalId;
      state.isLoading = false;
    },
    [getExternalId.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
  },
});

export default jobsSlice.reducer;
