import { Alert } from '@mui/material';
import { CSSProperties } from 'react';

type TProps = {
  message: string;
  onClose: ()=>void;
};

const CandidateTableAlert: React.FC<TProps> = ({ message, onClose }) => {
      return (
        <Alert
          severity='error'
          style={stylesAlertError}
          onClose={onClose}
        >
          <div dangerouslySetInnerHTML={{__html: message}}></div>
        </Alert>
      );
};

const stylesAlertError: CSSProperties = {
  color: '#202020',
  marginBottom: '10px',
  backgroundColor: 'rgba(220,37,71,.1)',
  border: '1px solid rgb(220,37,71)',
};

export default CandidateTableAlert;
