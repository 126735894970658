import { Fragment } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';

import ListItemWithExtends from './ListItemWithExtends';
import ToggleButtonGroupCheck from '../../../../ToggleButtonGroupCheck';
import IconItem from '../IconItem';

import ExceedsIcon from '../../../../../images/icons/ic_done_all_48px.svg';
import MeetsIcon from '../../../../../images/icons/ic_check_48px.svg';
import SomeIcon from '../../../../../images/icons/ic_error_outline_48px.svg';
import NoneIcon from '../../../../../images/icons/ic_close_20px.svg';

type TProps = {
  submissionRequirements: Array<any> | [];
  comment_type: string;
  importance_weight_id: number;
  toPdf: boolean;
  isTop: boolean;
};

const RelevantExperienceList: React.FC<TProps> = ({
  submissionRequirements,
  comment_type,
  importance_weight_id,
  toPdf = true,
  isTop = false,
}) => {
  const comment_types: { [key: string]: { styleColor: string; title: string; icon: any } } = {
    Exceeds: { styleColor: '#00B192', title: 'Exceeds', icon: ExceedsIcon },
    Meets: { styleColor: '#00B192', title: 'Meets', icon: MeetsIcon },
    Some: { styleColor: '#fcc501', title: 'Some', icon: SomeIcon },
    None: { styleColor: '#7A7A7A', title: 'None', icon: NoneIcon },
  };

  const CreateRelevantExperienceList = (
    submissionRequirements: any,
    comment_type: string,
    importance_weight_id: number,
    isTop: boolean,
  ) => {
    return submissionRequirements
      .map((el: any, index: number) => {
        if (el.requirement.importance_weight_id === importance_weight_id && el.value === comment_type) {
          return (
            <Fragment key={index}>
              <StyledLi>
                <Grid container style={{ display: 'grid', gridTemplateColumns: 'auto 130px' }}>
                  <Grid item className={`${comment_type !== 'None' ? 'bordered' : ''}`}>
                    <ListItemWithExtends
                      key={index}
                      title={el.requirement.requirement}
                      experience={el.requirement.experience}
                      details={el.requirement.details}
                      comment={el.comment}
                      toPdf={toPdf}
                      numberOfCharacters={170}
                      component={'div'}
                    />
                  </Grid>
                  {!isTop && comment_type !== 'None' &&
                    <Grid item px={'10px'}>
                      <ToggleButtonGroupCheck
                        name="Companies"
                        ariaLabel="Companies"
                      onChange={() => {}}
                      value={''}
                      data={el.from_where.map((item: string, index: number) => ({ id: index, title: item }))}
                      disabled={true}
                      style={{ fontSize: '14px', lineHeight: 1.5, color: '#191919' }}
                    />
                  </Grid>}
                </Grid>
              </StyledLi>
            </Fragment>
          );
        }
        return null;
      })
      .filter(Boolean);
  };

  return submissionRequirements.some(
    el => el.requirement.importance_weight_id === importance_weight_id && el.value === comment_type,
  ) ? (
    isTop ? (
      <>
        <Grid container style={{ display: 'grid', gridTemplateColumns: 'auto 130px' }}>
          <Grid item style={{ borderBottom: `2px solid ${comment_types[comment_type].styleColor}` }}>
            <Box style={{ ...stylesGroupTitle }}>
              <IconItem icon={comment_types[comment_type].icon} />
              <Typography sx={{...stylesTitle, color: comment_types[comment_type].styleColor}}>
                {comment_types[comment_type].title}
              </Typography>
            </Box>
          </Grid>
            {comment_type !== 'None' &&
              <Grid item style={{ borderBottom: `2px solid ${comment_types[comment_type].styleColor}` }}>
                <Typography
                  sx={{ ...stylesTitle, color: comment_types[comment_type].styleColor }}
                  pl={2}
                >
                  Companies
                </Typography>
              </Grid>}
        </Grid>
        <List styledColorGray={comment_type === 'None'}>
        {CreateRelevantExperienceList(submissionRequirements, comment_type, importance_weight_id, isTop)}
        </List>
      </>
    ) : (
      <>
        <Box sx={{ ...stylesGroupTitle, borderBottom: `2px solid ${comment_types[comment_type].styleColor}` }}>
          <IconItem icon={comment_types[comment_type].icon} />
          <Typography sx={{...stylesTitle, color: comment_types[comment_type].styleColor}}>
            {comment_types[comment_type].title}
          </Typography>
        </Box>
        <List styledColorGray={comment_type === 'None'}>
          {CreateRelevantExperienceList(submissionRequirements, comment_type, importance_weight_id, isTop)}
        </List>
      </>
    )
  ) : null;
};

const stylesGroupTitle = {
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'flex-start',
  paddingLeft: '5px',
  marginBottom: '5px',
};

const stylesTitle = {
  fontSize: '16px', 
  fontWeight: 700,
  marginBottom: '5px'
};

const List = styled('ul')<{
  styledColorGray: boolean;
}>`
  padding: 0 0 10px 20px;
  li {
    position: relative;
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: ${({ styledColorGray }) => (styledColorGray ? '#7A7A7A' : '#191919')};
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    ::after {
      content: '';
      position: absolute;
      top: 100%;
      left: -20px;
      display: block;
      width: calc(100% + 20px);
      height: 1px;
      background-color: #e9e7e7;
    }
    b {
      color: ${({ styledColorGray }) => (styledColorGray ? '#7A7A7A' : '#191919')};
    }
    p {
      color: ${({ styledColorGray }) => (styledColorGray ? '#7A7A7A' : '#191919')};
      font-weight: 400;
      font-size: 16px;
      margin: 2px;
      display: inline;
    }
  }
  span,
  span > p {
    color: ${({ styledColorGray }) => (styledColorGray ? '#7A7A7A' : '#4f4f4f')};
    font-weight: 400;
    font-size: 16px;
  }
`;

const StyledLi = styled('li')<{
  styledColorGray: boolean;
}>`
  .bordered {
    display: flex;
    padding-right: 40px;
    position: relative;
    align-items: center;
    ::after {
      content: '';
      position: absolute;
      top: -5px;
      left: 100%;
      display: block;
      width: 1px;
      height: calc(100% + 10px);
      background-color: #e9e7e7;
    }
  }
`;

export default RelevantExperienceList;
