import React from 'react';
import { Tooltip } from '@mui/material';

type TProps = {
  tooltipText: string;
  iconSrc: string;
};

const TooltipIcon: React.FC<TProps> = ({ tooltipText, iconSrc }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <Tooltip title={tooltipText}>
        <img src={iconSrc} alt={''} />
      </Tooltip>
    </div>
  );
};

export default TooltipIcon;
