import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { CardContent as MuiCardContent, Card as MuiCard, Typography, Box, Alert as MuiAlert } from '@mui/material';
import styled from 'styled-components/macro';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CustomButton from '../../CustomButton';
import InviteCandidatesIcon from '../../../images/modal/InviteCandidates.svg';

import { InviteRequestType, SubmissionItemType } from '../../../types/candidate';
import { RootState } from '../../../redux/store';

import { StyledCheckbox } from '../../../styles/SelectStyles';
import { TextFieldC } from '../../../styles/InputStyles';

type TProps = {
  onSendInvite: (values: InviteRequestType) => void;
  goBack: () => void;
  submission: SubmissionItemType | undefined;
};

const InvitationCandidate: React.FC<TProps> = ({ onSendInvite, goBack, submission }) => {
  const { candidate } = useSelector((state: RootState) => state.candidate);
  const { profile } = useSelector((state: RootState) => state.user);

  const invitationText = !submission ? `Hi ${
    candidate?.first_name || ' '
  },\n \nI’d like to learn more about your professional experience, what you’re looking for next and the type of workplace culture you are most comfortable in. By setting up a profile with us, I’ll be able to be on the look out for the best opportunities for you now and in the future. \n \n Best regards,\n ${
    profile.first_name
  } ${profile.last_name}` : `Hi ${
    candidate?.first_name || ' '
  },\n \nI hope you’re doing great! I’m reaching out to better understand your relevant experience for our ${submission.job_title} opportunity at ${submission.company_name} we spoke about.\n \n${
    ''}I’d love for you to land this role!\n \nIt’s my job to find you a role you’re going to love and also help you impress our client. The more I understand your background and qualifications, the better the chance we have of landing you a great offer!\n \n${
    ''}So I’m hoping you can take 20 minutes or so to complete your personal profile. The profile will help us understand your skills and experience related to the role, what you’re looking for in a company culture, and the types of environments you thrive in.\n \n${
    ''}If you need help or have any questions, please don’t hesitate to reach out to me – I’m here to help!\n \nThanks for considering this opportunity. Chat soon!\n \n Thank you,\n ${
    profile.first_name
  } ${profile.last_name}`;

  return (
    <Box sx={stylesFlexWrap}>
      <Box maxWidth={716} width="100%">
        <Card p={'33px 50px 0'}>
          <CardContent p={0}>
            <Box sx={stylesFlexBox}>
              <img width={50} height={50} src={InviteCandidatesIcon} alt='' />
            </Box>

            <Typography variant="h1" align="center" color="secondary" lineHeight="29px">
             {`Invite Candidate${submission ? ` for ${submission.company_name} - ${submission.job_title}` : ''}`}
            </Typography>

            <Typography variant="body2" align="center">
              Send an email to the candidate to complete a Clarity profile
            </Typography>

            <Formik
              initialValues={{
                invitation: invitationText,
                include_schedule: false,
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                invitation: Yup.string().max(1255, 'Invitation must be at most 1255 characters'),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  await onSendInvite({
                    invitation: values.invitation,
                    include_schedule: values.include_schedule ? 1 : 0,
                  });
                  
                } catch (error: any) {}
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} style={{marginTop: '20px'}}>
                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}

                  <TextFieldCStyled
                    name="invitation"
                    value={values.invitation}
                    error={Boolean(touched.invitation && errors.invitation)}
                    fullWidth
                    multiline
                    rows={!submission ? 10 : 23}
                    helperText={touched.invitation && errors.invitation}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />

                  <Box mt={2}>
                    <StyledCheckbox
                      name="include_schedule"
                      value={values.include_schedule}
                      checked={values.include_schedule}
                      onChange={handleChange}
                    />

                    <Typography component="span" variant="body2" align="center">
                      Include Schedule a Time to Meet
                    </Typography>
                  </Box>

                  <Box sx={stylesWrapperButtons}>
                    <CustomButton type="button" variant="contained" onClick={goBack}>
                      Go back
                    </CustomButton>

                    <CustomButton variant="contained">Send invite</CustomButton>
                  </Box>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default memo(InvitationCandidate);

const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const stylesWrapperButtons = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  maxWidth: 300,
  padding: '20px 25px 0',
  margin: 'auto',
};

const stylesFlexWrap = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '90vh',
};

const stylesFlexBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '16px',
};

const TextFieldCStyled = withStyles({
  root: {
    '& .MuiInputBase-root': {
      padding: '20px 20px 15px',
    },
  },
})(TextFieldC);
