import Step1ValuesModel from './step1Values.model';
import { objToId } from '../utils/formatData';

class Step1DataModel {
  first_name: string;
  last_name: string;
  mobile_phone: string | null;
  landline_phone: string | null;
  email: string;
  country_id?: number;
  city: string;
  province_id?: number;
  years_of_experience?: number;
  designations: Array<any>;
  about: string;

  constructor(data: Step1ValuesModel) {
    this.first_name = data?.first_name;
    this.last_name = data?.last_name;
    this.mobile_phone = data?.phone?.mobile_phone || null;
    this.landline_phone = data?.phone?.landline_phone || null;
    this.email = data?.email;
    this.country_id = data?.country_id;
    this.city = data?.city;
    this.province_id = data?.province_id;
    this.years_of_experience = data?.years_of_experience;
    this.designations = data?.designations;
    this.about = data?.about;
  }
}

export default Step1DataModel;
