import { Box, Card, SxProps, Typography, CardContent, CardActions, Grid } from '@mui/material';
import { styled } from '@mui/system';

import IconItem from './IconItem';
import IndustryIcon from '../../../../images/icons/ic_company_48px.svg';
import LevelIcon from '../../../../images/icons/ic_level_48px.svg';
import WorkIcon from '../../../../images/icons/ic_baseline_work_outline_48px.svg';
import workplaceIcon from '../../../../images/icons/ic_workspace_48px.svg';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { arrayTitleToString } from '../../../../utils/functions';

type TProps = {
  toPdf: boolean;
  showCandidateName?: boolean;
  showTargetSalary?: boolean;
  showTargetRate?: boolean;
  showMotivators?: boolean;
};

const LookingForNext: React.FC<TProps> = ({
  toPdf,
  showCandidateName,
  showTargetSalary,
  showTargetRate,
  showMotivators,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    candidate && (
      <Box>
        <Typography sx={stylesSectionTitle} color="secondary">
          {showCandidateName ? `What ${candidate?.first_name} is Looking for next` : 'Looking for next'}
        </Typography>
        <BoxBorder>
          <StyledIconSet>
            <>
            {!!candidate?.level?.title && (
              <IconItem icon={LevelIcon} label={'Level'} value={candidate?.level?.title || '-'} />
            )}
            {!!candidate?.industries?.length && (
              <IconItem
                icon={IndustryIcon}
                label={'Target Industry'}
                value={candidate?.industries ? arrayTitleToString({ arr: candidate?.industries, symbol: ',' }) : '-'}
              />
            )}
            {!!candidate?.location?.title && (
              <IconItem icon={workplaceIcon} label={'Workplace'} value={candidate?.location?.title || '-'} />
            )}
            {!!candidate?.salary_min ||
              (!!candidate?.rate_min && (
                <Box display="flex" flexWrap="nowrap" maxWidth="25vw">
                  <IconItem icon={WorkIcon} />
                  <Box display="flex" flexWrap="nowrap">
                    <>
                    {!!candidate?.salary_min && (!toPdf || showTargetSalary) && (
                      <Box display="flex" flexWrap="nowrap">
                        <Typography component="span">Full time: </Typography>
                        <Typography
                          component="span"
                          ml={2}
                          sx={stylesBoldText}
                          >{`Target Salary $${candidate?.salary_min.toLocaleString()}`}</Typography>
                      </Box>
                    )}
                    {!!candidate?.rate_min && (!toPdf || showTargetRate) && (
                      <Box display="flex" flexWrap="nowrap">
                        <Typography component="span">Contract: </Typography>
                        <Typography
                          component="span"
                          ml={2}
                          sx={stylesBoldText}
                          >{`Target Rate $${candidate?.rate_min}/hr`}</Typography>
                      </Box>
                    )}
                    </>
                  </Box>
                </Box>
              ))}
            </>
          </StyledIconSet>

          {(!!candidate?.reasons?.length || !!candidate?.functions?.length || !!candidate?.projects?.length) && (
              <Grid container justifyContent="space-between">
                <>
                {!!candidate?.reasons?.length && (!toPdf || showMotivators) && (
                  <Grid item sm={6} sx={{ paddingRight: { sm: '20px' } }}>
                    <Typography sx={stylesSubtitle} color="secondary" my={2}>
                      Motivators to Move
                    </Typography>
                    <StyledList component="ul">
                      {candidate?.reasons.map(({ title }: { title: string | null }, idx: number) => {
                        return (
                          <Box key={idx} component="li">
                            {title}
                          </Box>
                        );
                      })}
                    </StyledList>
                  </Grid>
                )}

                {!!candidate?.functions?.length ||
                  (!!candidate?.projects?.length && (
                    <Grid item sm={6} sx={stylesBlockWrap}>
                      <Typography sx={stylesSubtitle} my={2} color="secondary">
                        Focus Areas
                      </Typography>
                      <StyledList component="ul">
                        {!!candidate?.functions?.length &&
                          candidate?.functions.map(({ title }: { title: string | null }, idx: number) => {
                            return (
                              <Box key={idx} component="li">
                                {title}
                              </Box>
                            );
                          })}
                        {!!candidate?.projects?.length &&
                          candidate?.projects.map(({ title }: { title: string | null }, idx: number) => {
                            return (
                              <Box key={idx} component="li">
                                {title}
                              </Box>
                            );
                          })}
                      </StyledList>
                    </Grid>
                  ))}
                </>
              </Grid>
          )}
          {!!candidate?.comments?.length && (
            <StyledCard>
              <StyledCardContent>
                <>
                {expanded ? (
                  <Typography component="span" sx={stylesText}>
                    {candidate?.comments}
                  </Typography>
                ) : (
                  <Typography component="span" sx={stylesText}>
                    {candidate?.comments?.length > 200
                      ? candidate?.comments?.slice(0, 200) + '...'
                      : candidate?.comments}
                  </Typography>
                )}

                {candidate?.comments.length > 200 && (
                  <StyledCardActions>
                    <>
                    {expanded ? (
                      <StyledLink component="a" onClick={handleExpandClick}>
                        Hide
                      </StyledLink>
                    ) : (
                      <StyledLink component="a" onClick={handleExpandClick}>
                        Show More
                      </StyledLink>
                    )}
                    </>
                  </StyledCardActions>
                )}
                </>
              </StyledCardContent>
            </StyledCard>
          )}
        </BoxBorder>
      </Box>
    )
  );
};

const stylesBoldText: SxProps = {
  fontWeight: 600,
};

const BoxBorder = styled(Box)`
  border: 1px solid #e9e7e7;
  border-radius: 5px;
  padding: 10px 20px 15px;
  height: 100%;
`;

const StyledIconSet = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 8px;
  align-items: start;

  > :not(last-child) {
    padding-right: 8px;
    border-right: 1px solid;
    border-color: #7a7a7a;
  }
  > :last-child {
    border-right: none;
  }
`;

const StyledList = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 0;

  & li {
    list-style: none;

    width: auto;
    height: auto;
    padding: 6px 12px;
    margin: 0;
    border: 0;
    border-radius: 20px;
    text-transform: none;
    color: #002855;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.1px;
    background-color: #f5f8fa;
  }
`;

const StyledLink = styled(Typography)`
  display: inline;
  color: #2681db;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
`;

const StyledCard = styled(Card)`
  background-color: beige;
  margin: 12px 0;
`;

const StyledCardActions = styled(CardActions)`
  display: inline;
`;

const StyledCardContent = styled(CardContent)`
  padding: 12px;

  &:last-child {
    padding: 12px;
  }
`;

const stylesSectionTitle: SxProps = {
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: 0.04,
  margin: '15px 0 10px',
};

const stylesSubtitle: SxProps = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '138%',
  letterSpacing: '0.1px',
};

const stylesText: SxProps = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  color: '#202020',
};

const stylesBlockWrap: SxProps = {
  height: 'auto',
  paddingLeft: { sm: '20px' },
  borderLeft: { sm: '1px solid #E9E7E7' },
};

export default LookingForNext;
