import React from 'react';
import { Chip as MuiChip, ToggleButtonGroup, ToggleButton as MuiToggleButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components/macro';

type Props = {
  name?: string;
  value?: any;
  onChange: any;
  ariaLabel: string;
  data: any[];
  style?: any;
  selected?: boolean;
  disabled?: boolean;
};

const ToggleButtonGroupCheck: React.FC<Props> = ({
  name = '',
  value,
  onChange,
  ariaLabel,
  data = [],
  style,
  selected,
  disabled,
}) => (
  <StyledToggleButtonGroup
    value={value}
    exclusive={false}
    onChange={onChange}
    aria-label={ariaLabel}
    fullWidth={true}
    disabled={disabled}
  >
    {data.map((item: any) => (
      <ToggleButton
        key={item.id}
        value={item.id}
        aria-label={item.title}
        selected={value?.includes(item.id)}
        disabled={name === 'reasons' && value?.length > 2 && !value?.includes(item.id)}
      >
        <Chip label={item.title} style={style} />
        {!!value?.length &&
          value.map(
            (val: any, index: number) =>
              val === item.id && (
                <CheckIcon
                  color="success"
                  fontSize="small"
                  style={{ padding: '2px', marginRight: '5px' }}
                  key={index}
                />
              ),
          )}
      </ToggleButton>
    ))}
  </StyledToggleButtonGroup>
);

export default ToggleButtonGroupCheck;

const ToggleButton = styled(MuiToggleButton)`
  width: auto;
  height: 25px;
  padding: 0;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.2px;
  border: 0;
  margin-right: 10px;
  margin-bottom: 7px;
  border-radius: 15px;
`;

const Chip = styled(MuiChip)`
  width: 100%;
  border-radius: 15px;
  height: 25px;
  color: inherit;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  span {
    color: inherit !important;
    font-size: inherit !important;
  }
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  width: '100%',
  display: 'block',
  '& .MuiToggleButtonGroup-grouped': {
    border: '1px solid transparent',
    backgroundColor: '#F5F8FA',
    color: '#002855',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '15px',
    },
    '&:first-of-type': {
      borderRadius: '15px',
    },
    '&:hover': {
      border: '1px solid #00B192 !important',
    },
  },
  '& .Mui-selected': {
    border: '1px solid #00B192 !important',
    color: '#00B192 !important',
    backgroundColor: '#F5F8FA !important',
    '&:hover': {
      backgroundColor: '#F5F8FA !important',
    },
  },
}));
