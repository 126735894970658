import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Typography, Box, Card as MuiCard, Tab, Tabs } from '@mui/material';
import { spacing, styled } from '@mui/system';
import { withStyles } from '@mui/styles';

import CandidateTable from '../Components/CandidateTable';
import CandidateScorecard from '../Components/CandidateScorecard';
import ProfileDetails from './ProfileDetails/ProfileDetails';
import IconButton from '../../IconButton';
import RefreshIcon from '../../../images/icons/ic_refresh_48px.svg';

import { RootState } from '../../../redux/store';
import { setShowProfileDetails } from '../../../redux/reducers/candidate';
import CandidateTableAlert from '../../Alert/CandidateTableAlert';
import { SubmissionItemType } from '../../../types/candidate';
import TopMenu from '../../Layouts/TopMenu';
import { getCandidate } from '../../../redux/actions/candidate';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  const isShowCandidateScorecard = process.env.REACT_APP_IS_SHOW_CANDIDATE_SCORECARD;

  const { candidate, showProfileDetails, isLoading } = useSelector((state: RootState) => state.candidate);

  const { pathname } = useLocation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const candidateId = params.get('candidate_id') || params.get('id');
  const submissionId = params.get('submissionId') || '';

  const [value, setValue] = useState(0);
  const [isShowDuplicateEmailAlert, setIsShowDuplicateEmailAlert] = useState(false);
  const [isShowInvalidEmailAlert, setIsShowInvalidEmailAlert] = useState(false);

  const currentSubmission = candidate?.submissions.find((item: SubmissionItemType) => item.id === +submissionId);

  const showAlert = () => {
    if (candidate?.user?.hasSeveralCandidates) {
      setIsShowDuplicateEmailAlert(true);
    }
    if (!candidate?.user?.hasValidEmail) {
      setIsShowInvalidEmailAlert(true);
    }
  };

  useEffect(() => {
    if (candidate?.user?.hasSeveralCandidates) {
      setIsShowDuplicateEmailAlert(true);
    }
  }, [candidate?.user?.hasSeveralCandidates]);

  useEffect(() => {
    if (!candidate?.user?.hasValidEmail) {
      setIsShowInvalidEmailAlert(true);
    }
  }, [candidate?.user?.hasValidEmail]);

  useEffect(() => {
    if (pathname.includes('profile-details')) {
      dispatch(setShowProfileDetails(true));
    }
  }, [dispatch, pathname]);

  const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );

  const handleChange = (event: any, newValue: any) => setValue(newValue);

  const a11yProps = (index: any) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const refreshDashboard = () => {
    if (!!candidateId) {
      dispatch(getCandidate(+candidateId));
    }
  };

  return (
    <>
      <Box sx={{ ...stylesWrapperProfileDetails, marginBottom: 0 }}>{showProfileDetails && <TopMenu />}</Box>
      <Box sx={stylesAlertWrapper}>
        {isShowDuplicateEmailAlert && !showProfileDetails && (
          <CandidateTableAlert
            message={`Duplicate candidates found in Bullhorn for <b>${candidate?.email}</b>. Please merge candidates in Bullhorn before continuing.`}
            onClose={() => {
              setIsShowDuplicateEmailAlert(false);
            }}
          />
        )}
      </Box>
      <Box sx={stylesAlertWrapper}>
        {isShowInvalidEmailAlert && !showProfileDetails && (
          <CandidateTableAlert
            message={`<b>${candidate?.email}</b> is invalid. Please correct in Bullhorn before continuing.`}
            onClose={() => {
              setIsShowInvalidEmailAlert(false);
            }}
          />
        )}
      </Box>
      <Card sx={showProfileDetails ? stylesWrapperProfileDetails : stylesWrapper}>
        <Box sx={showProfileDetails ? stylesTopProfileDetails : stylesTop}>
          <Box display="flex" alignItems="flex-end" width="100%" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              {submissionId ? (
                <Typography sx={showProfileDetails ? stylesCompanyProfileDetails : stylesCompany}>{`${
                  currentSubmission?.job_title || ''
                } at ${currentSubmission?.company_name || 'Company'}`}</Typography>
              ) : (
                <Typography sx={showProfileDetails ? stylesCompanyProfileDetails : stylesCompany}>{`${
                  candidate?.first_name || ''
                } ${candidate?.last_name || ''}`}</Typography>
              )}
              <TabsStyles
                defaultValue={0}
                value={value}
                onChange={handleChange}
                sx={showProfileDetails ? stylesTabsProfileDetails : {}}
              >
                <TabStyles label={showProfileDetails ? 'Profile' : 'Dashboard'} {...a11yProps(0)} />
                {isShowCandidateScorecard && <TabStyles label="Candidate scorecard" {...a11yProps(1)} />}
              </TabsStyles>
            </Box>
            {!showProfileDetails && (
              <Box sx={isLoading ? stylesAnimated : {}}>
                <IconButton toolTip="Refresh Dashboard" onClick={refreshDashboard}>
                  <img src={RefreshIcon} alt={''} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>

        <TabPanel value={value} index={0}>
          {showProfileDetails ? (
            <ProfileDetails candidateId={candidateId || ''} toPdf={false} />
          ) : (
            <CandidateTable
              isDisabledMail={candidate?.user?.hasSeveralCandidates || !candidate?.user?.hasValidEmail}
              showAlert={showAlert}
            />
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <CandidateScorecard />
        </TabPanel>
      </Card>
    </>
  );
};

export default memo(Dashboard);

const Card = styled(MuiCard)(spacing);

const TabStyles = withStyles({
  root: {
    color: '#002855 !important',
    height: '100%',
    transition: 'all 0.7s',
  },
  selected: {
    borderRadius: 5,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: 'all 0.7s',
    boxShadow: '0 0 5px #d3d3d3',
  },
})(Tab);

const TabsStyles = withStyles({
  root: { minHeight: '100%' },
  flexContainer: { height: '100%' },
  indicator: { display: 'none' },
})(Tabs);

const stylesTabsProfileDetails = {
  height: '38px',
  button: {
    paddingTop: '2px',
    lineHeight: 1.57,
    letterSpacing: '0.5px',
  },
};

const stylesWrapper = {
  margin: '15px auto',
  padding: 0,
  borderColor: 'transparent',
  width: '100%',
  maxWidth: '1080px',
};

const stylesWrapperProfileDetails = {
  margin: '15px auto',
  padding: 0,
  borderColor: 'transparent',
  width: '100%',
  maxWidth: '1280px',
};

const stylesAlertWrapper = {
  margin: '15px auto 0',
  padding: 0,
  width: '100%',
  maxWidth: '1080px',
};

const stylesTop = {
  display: 'flex',
  padding: 5,
  paddingBottom: 0,
  minHeight: 80,
  backgroundColor: 'rgba(0, 177, 146, 0.1)',
};

const stylesTopProfileDetails = {
  display: 'flex',
  padding: '10px 10px 0',
  height: '48px',
  backgroundColor: 'rgba(0, 177, 146, 0.1)',
};

const stylesCompany = {
  fontSize: 24,
  fontWeight: 'bold',
  paddingRight: 5,
  paddingBottom: 5,
  color: '#002855',
};

const stylesCompanyProfileDetails = {
  alignSelf: 'start',
  fontSize: 24,
  lineHeight: 1.17,
  fontWeight: 'bold',
  paddingRight: 5,
  paddingBottom: 0,
  color: '#002855',
};

const stylesAnimated = {
  animation: 'rotate 2s infinite linear',

  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};
