import { RoleType } from '../../types/candidate';
import RoleDataModel from '../roleData.model';
import RoleValuesModel from '../roleValues.model';

class RolesAdapter {
  static mapRolesDtoToRolesValuesModel(roles: RoleType[]): RoleValuesModel[] {
    return roles.map(role => new RoleValuesModel(role));
  }

  static mapRolesValuesModelToRolesData(roles: RoleValuesModel[]): { histories: RoleDataModel[] } {
    return { histories: roles.map(role => new RoleDataModel(role)) };
  }
}

export default RolesAdapter;
