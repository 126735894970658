import { Breadcrumbs as MuiBreadcrumbs, Link, Button, Box, ClickAwayListener } from '@mui/material';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import * as MuiIcon from '@mui/icons-material';
import styled from 'styled-components/macro';
import CustomCheckbox from '../CustomCheckbox';
import CustomButton from '../CustomButton';
import { filterValues, filterValuesSpecial } from '../../utils/staticValues';
import { PROFILE_PATH } from '../../constants/constants';

const TopMenu = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id =
    params.get('candidate_id') !== null
      ? params.get('candidate_id')
      : params.get('id') !== null
      ? params.get('id')
      : '1';
  const submissionId = params.get('submissionId') !== null ? params.get('submissionId') : '';
  const profile = window.location.href.indexOf('profile-details') > -1;

  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const [filtersArr, setFiltersArr] = useState<Array<any>>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!!submissionId) {
      setFiltersArr(filterValuesSpecial.map(item => item.name));
    } else {
      setFiltersArr(filterValues.map(item => item.name));
    }
  }, [submissionId]);

  const downloadByUrl = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'download');

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);

    return true;
  };

  const handlePrintCandidate = () => {
    const token = localStorage.getItem('accessToken');
    if (id && token) {
      if (!!submissionId) {
        downloadByUrl(
          `${
            process.env.REACT_APP_SERVER_HOST
          }/pdf/candidate?id=${id}&token=${token}&submissionId=${submissionId}&filters=${filtersArr.join()}`,
        );
      } else {
        downloadByUrl(
          `${process.env.REACT_APP_SERVER_HOST}/pdf/candidate?id=${id}&token=${token}&filters=${filtersArr.join()}`,
        );
      }
    }
  };

  const changeFilters = (e: any, el: string) => {
    if (filtersArr && el) {
      let index = filtersArr.indexOf(el);
      if (e.target.checked) {
        index === -1 && setFiltersArr([...filtersArr, el]);
      } else {
        index !== -1 && setFiltersArr(filtersArr.filter((item: string) => item !== el));
      }
    }
  };

  const DownloadMenu = () => {
    return (
      <DownloadMenuContainer>
        <p>Include in Export</p>
        {!!submissionId
          ? filterValuesSpecial.map((item: any) => {
              return (
                <CustomCheckbox
                  key={item.name}
                  name={item.name}
                  label={item.label}
                  checked={filtersArr.includes(item.name)}
                  onChange={e => changeFilters(e, item.name)}
                />
              );
            })
          : filterValues.map((item: any) => {
              return (
                <CustomCheckbox
                  key={item.name}
                  name={item.name}
                  label={item.label}
                  checked={filtersArr.includes(item.name)}
                  onChange={e => changeFilters(e, item.name)}
                />
              );
            })}
        <CustomButton variant={'outlined'} onClick={handlePrintCandidate}>
          Download PDF
        </CustomButton>
      </DownloadMenuContainer>
    );
  };

  const changeLocation = (placeToGo: string) => {
    navigate(placeToGo, { replace: true });
    window.location.reload();
  };

  return (
    <ClickAwayListener onClickAway={() => setShowDownloadMenu(false)}>
      <BoxContainer>
        <Box>
          <Breadcrumbs aria-label="Breadcrumb">
            <Link
              component={NavLink}
              to={`/${PROFILE_PATH}?candidate_id=${id}`}
              onClick={() => changeLocation(`/${PROFILE_PATH}?candidate_id=${id}`)}
            >
              Dashboard
            </Link>
          </Breadcrumbs>
        </Box>
        <div style={{ position: 'relative' }}>
          <Button
            onClick={() => setShowDownloadMenu(!showDownloadMenu)}
            className="transparentButton"
            disabled={!profile}
          >
            <MuiIcon.FileDownload />
          </Button>
          {showDownloadMenu && <DownloadMenu />}
        </div>
      </BoxContainer>
    </ClickAwayListener>
  );
};

export default TopMenu;

const BoxContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* max-width: 1080px; */
  margin: auto;
  height: 30px;
  align-items: center;
`;

const Breadcrumbs = styled(MuiBreadcrumbs)`
  .MuiBreadcrumbs-separator {
    color: #2681db;
  }
  a {
    font-weight: 400;
    color: #2681db;
  }
  p {
    font-weight: 400;
  }
`;
const DownloadMenuContainer = styled(Box)`
  position: absolute;
  top: 25px;
  right: 0;
  height: 314px;
  width: 287px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  p {
    font-size: 16px;
    font-weight: 600;
    color: #002855;
  }
  label {
    margin: 5px 0 0 -2px;
  }
  button {
    margin-top: 10px;
  }
`;
