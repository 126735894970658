import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert as MuiAlert, Box, Button, Grid, Typography } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CustomInput from '../CustomInput';
import LinkedinLoginButton from './LinkedinLoginButton';
import Loader from '../../components/Loader';

import useAuth from '../../hooks/useAuth';
import { checkActiveEmail } from '../../redux/actions/user';
import { RootState } from '../../redux/store';

import { SubmitButton } from '../../styles/ButtonStyles';
import { ALERT_MESSAGES } from '../../constants/constants';

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUp, acceptInvite } = useAuth();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id') !== null ? params.get('id') : '';
  const email = params.get('email') !== null ? params.get('email') : '';
  const inviteLink = params.get('inviteLink') !== null ? params.get('inviteLink') : '';
  const submissionId = params.get('submissionId') !== null ? params.get('submissionId') : '';

  const { isActiveEmail, isLoading } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(checkActiveEmail({ email: email || '' }));
  }, [dispatch, email]);

  useEffect(() => {
    isActiveEmail &&
      navigate(
        `/auth/sign-in?${id ? `id=${id}` : ''}${!!inviteLink ? `&email=${email}&inviteLink=${inviteLink}` : ''}${
          !!submissionId ? `&submissionId=${submissionId}` : ''
        }`,
      );
  }, [isActiveEmail, inviteLink, navigate, submissionId]);

  const linkSignIn = useMemo(
    () =>
      `/auth/sign-in?id=${id}${!!inviteLink ? `&email=${email}&inviteLink=${inviteLink}` : ''}${
        !!submissionId ? `&submissionId=${submissionId}` : ''
      }`,
    [id, inviteLink, email, submissionId],
  );

  return (
    <Formik
      initialValues={{
        email: email || '',
        password: '',
        confirmPassword: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .max(255, 'Password must be at most 255 characters')
          .required('Required'),
        confirmPassword: Yup.string()
          .when('password', {
            is: (val: any) => !!(val && val.length > 0),
            then: Yup.string().oneOf([Yup.ref('password')], 'Password does not match'),
          })
          .required('Password does not match'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          !!inviteLink
            ? !!submissionId
              ? await acceptInvite({
                  email: values.email,
                  inviteLink: inviteLink,
                  password: values.password,
                  submissionId: submissionId,
                })
              : await acceptInvite({ email: values.email, inviteLink: inviteLink, password: values.password })
            : await signUp(values.email, values.password);

          // navigate(
          //   `/auth/sign-in?${id ? `id=${id}` : ''}${!!inviteLink ? `&email=${email}&inviteLink=${inviteLink}` : ''}`,
          // );
        } catch (error: any) {
          const message =
            typeof error.message === 'string'
              ? error.message
              : error.message?.email
              ? error.message?.email.toString()
              : error.error
              ? error.error
              : ALERT_MESSAGES.DEFAULT_ERROR;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}

          {isLoading && <Loader />}

          <CustomInput
            type="email"
            name="email"
            label="Email address"
            value={values.email}
            onChange={handleChange}
            error={touched.email && errors.email}
            disabled={!!email}
            sx={{ mb: 3 }}
          />

          <CustomInput
            type="password"
            name="password"
            label="Password"
            value={values.password}
            onChange={handleChange}
            error={touched.password && errors.password}
            sx={{ mb: 3 }}
          />

          <CustomInput
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            onChange={handleChange}
            error={touched.confirmPassword && errors.confirmPassword}
          />

          <Box width="100%" display="flex" justifyContent="center">
            <SubmitButton type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              Sign up
            </SubmitButton>
          </Box>

          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="body2" mt={3} mr={3}>
                or continue with
              </Typography>
            </Grid>

            <Grid item>
              <LinkedinLoginButton id={id || '1'} />
            </Grid>
          </Grid>

          <ButtonWrapper>
            <Typography variant="body2">Already have an account?</Typography>

            <Button component={Link} to={linkSignIn} style={{ color: '#2681DB' }}>
              Sign in
            </Button>
          </ButtonWrapper>
        </form>
      )}
    </Formik>
  );
}

export default SignUp;

const Alert = styled(MuiAlert)(spacing);

const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
