import React from 'react';

import WorkHistoryContent from '../WorkHistoryContent';
import LookingForNext from '../LookingForNext';
import MostImportantList from '../MostImportantList';
import HeaderContent from '../HeaderContent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { getCurrentJobTitle } from '../../../../../utils/functions';

type TProps = {
  ocp_answers: { top: {[key: string]: string}; bottom: {[key: string]: string} } | undefined;
  toPdf: boolean;
  PdfFilters: {
    showCompanyName: boolean | undefined;
    showCandidateName: boolean | undefined;
    showTargetSalary: boolean | undefined;
    showTargetRate: boolean | undefined;
    showMotivators: boolean | undefined;
    showCompanyCulture: boolean | undefined;
  };
};

const GeneralProfileDetails: React.FC<TProps> = ({ ocp_answers, PdfFilters, toPdf }) => {
  const { candidate } = useSelector((state: RootState) => state.candidate);

  return (
    <>
      <HeaderContent
        toPdf={toPdf}
        showCompanyName={PdfFilters.showCompanyName}
        showTargetSalary={PdfFilters.showTargetSalary}
        values={{
          first_name: candidate?.first_name || '',
          last_name: candidate?.last_name || '',
          designation: candidate?.designation || '',
          job_title: getCurrentJobTitle(candidate),
          salary_min: candidate?.salary_min || null,
          salary_max: candidate?.salary_max || null,
          location: candidate?.location || null,
          user: candidate?.user || null,
          about: candidate?.about || '',
        }}
      />
      <WorkHistoryContent />
      <LookingForNext
        toPdf={toPdf}
        showCandidateName={PdfFilters.showCandidateName}
        showTargetSalary={PdfFilters.showTargetSalary}
        showTargetRate={PdfFilters.showTargetRate}
        showMotivators={PdfFilters.showMotivators}
      />
      {(!toPdf || PdfFilters.showCompanyCulture) &&
        ((candidate?.is_full_culture && ocp_answers?.top && !!Object.keys(ocp_answers?.top).length) ||
          (candidate?.is_full_culture && ocp_answers?.bottom && !!Object.keys(ocp_answers?.bottom).length) ||
          (!candidate?.is_full_culture && !!candidate?.companyTraits?.length)) && (
          <MostImportantList
            mostImportant={ocp_answers?.top}
            leastImportant={ocp_answers?.bottom}
            companyTraits={candidate?.companyTraits}
            isFullCulture={candidate?.is_full_culture}
          />
        )}
    </>
  );
};

export default GeneralProfileDetails;
