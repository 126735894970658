import React, { ChangeEvent, memo, useState } from 'react';
import { MenuItem, ListSubheader } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import styled from 'styled-components/macro';

import ErrorText from './ErrorText';
import arrowIcon from '../images/icons/ic_keyboard_arrow_down_24px.svg';
import { StyledCheckbox, StyledChip } from '../styles/SelectStyles';
import { TextFieldC } from '../styles/InputStyles';

type TProps = {
  name: string;
  value: any;
  label: string;
  data: any;
  onChange: (e: string | ChangeEvent<any>) => void;
  onAdditionalChange?: (value: Array<any>) => void;
  error?: any;
  errorText?: any;
  disabled?: boolean;
  placeholder?: string;
  group?: boolean;
  creatable?: boolean;
  withoutSave?: boolean;
  dataWithoutCustom?: boolean;
};

const CustomMultipleSelect: React.FC<TProps> = ({
  name,
  value = [],
  label,
  data = [],
  onChange,
  onAdditionalChange,
  errorText,
  disabled,
  placeholder,
  group,
  creatable = false,
  withoutSave,
  dataWithoutCustom,
}) => {
  const filter = createFilterOptions<any>();

  let tmpArray: Array<number> = [];

  const itemCheck = (item: { id: number; title: string }) => {
    if (tmpArray.indexOf(item.id) === -1) {
      tmpArray.push(item.id);
      return true;
    }
  };

  const [customValuesLength, setCustomValuesLength] = useState(0);

  return (
    <>
      <Autocomplete
        multiple
        id={`tags-multiSelect-${name}`}
        options={!!data.length && dataWithoutCustom ? data.filter((item: any) => !item.is_custom) : data}
        value={value}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        filterOptions={
          creatable
            ? (options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(option => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `${inputValue}`,
                    id: options.length + customValuesLength,
                    create: true,
                  });
                }

                return filtered;
              }
            : undefined
        }
        disableClearable={!creatable}
        selectOnFocus={creatable || undefined}
        clearOnBlur={creatable || undefined}
        handleHomeEndKeys={creatable || undefined}
        disabled={disabled}
        onChange={(e: any, newValue: any, reason, details) => {
          const filteredValues = newValue.filter((item: any) => itemCheck(item));

          if (details?.option.create) {
            if (reason !== 'removeOption') {
              setCustomValuesLength(customValuesLength + 1);
            } else {
              setCustomValuesLength(customValuesLength - 1);
            }
          }

          onChange(filteredValues);
          !!onAdditionalChange && onAdditionalChange(filteredValues);
        }}
        renderInput={params => (
          <Input
            {...params}
            label={label}
            placeholder={placeholder}
            sx={{ input: { fontWeight: 400, minWidth: 0 } }}
            InputLabelProps={{
              style: { fontWeight: 400 },
            }}
          />
        )}
        renderTags={(value: any, getTagProps: any) =>
          value.map((option: any, index: number) => (
            <StyledChip variant="outlined" label={`${option.title}`} {...getTagProps({ index })} />
          ))
        }
        renderOption={(props, option, { selected }) => {
          return group ? (
            <>
              <ListSubheader>{option.title}</ListSubheader>
              {option.subFunctions.map((subItem: any) => {
                return (
                  <MenuItem {...props} key={subItem.id} value={subItem.id.toString()}>
                    {subItem.title}
                  </MenuItem>
                );
              })}
            </>
          ) : (
            <MenuItem {...props} key={option.id} value={option?.id?.toString()} style={{ fontWeight: 400 }}>
              <StyledCheckbox checked={selected} />
              {option.title}
            </MenuItem>
          );
        }}
        popupIcon={<img src={arrowIcon} alt="arrow-select" />}
        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
        disableCloseOnSelect
      />
      <ErrorText error={errorText} />
    </>
  );
};

export default memo(CustomMultipleSelect);

const Input = styled(TextFieldC)`
  .MuiAutocomplete-input {
    min-width: 0px !important;
  }
`;
