import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import IconItem from '../IconItem';
import MatchIcon from '../../../../../images/icons/ic_match_48px.svg';
import IndustryIcon from '../../../../../images/icons/ic_company_blue_48px.svg';
import EmployeesIcon from '../../../../../images/icons/ic_people_outline_blue_48px.svg';
import OwnershipIcon from '../../../../../images/icons/ic_Flag_blue_48px.svg';
import RevenueIcon from '../../../../../images/icons/ic_attach_money_blue_48px.svg';
import CircleIcon from '../../../../../images/circleMenu.svg';
import { arrayTitleToString, experienceToString } from '../../../../../utils/functions';

import { RoleType, SubmissionItemType } from '../../../../../types/candidate';
import { RootState } from '../../../../../redux/store';

type TProps = {
  currentSubmission: SubmissionItemType | undefined;
};

const HistoryCompanies: React.FC<TProps> = ({ currentSubmission }) => {
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const sortedWorkHistoryArray = useMemo(() => {
    if (!!candidate?.histories?.length) {
      const sortedArr = JSON.parse(JSON.stringify(candidate.histories));
      sortedArr.sort((a: RoleType, b: RoleType) => {
        const firstDate = a.end_year ? new Date(a.end_date).getTime() : new Date().getTime();
        const secondDate = b.end_year ? new Date(b.end_date).getTime() : new Date().getTime();
        return secondDate - firstDate;
      });
      return sortedArr;
    }
    return [];
  }, [candidate?.histories]);

  const getCompanyArray = (arr: Array<any>) => {
    const titles = arr.map(({ company }) => company);
    const companyArray = titles.filter((item: any, index: number, itemsArr: Array<any>) => {
      return index === itemsArr.indexOf(item);
    });
    return companyArray;
  };

  const companyArray = !!candidate?.histories.length ? getCompanyArray(sortedWorkHistoryArray) : [];

  const getShotMontName = (monthName: string) => {
    if (!monthName) {
      return '-';
    }
    if (monthName.includes('June') || monthName.includes('July')) {
      return monthName;
    }
    if (monthName.includes('September')) {
      return 'Sept';
    }
    return monthName.slice(0, 3);
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography sx={stylesSectionTitle} color="secondary">
          Companies
        </Typography>
        <Box sx={stylesIconWrap}>
          <img src={MatchIcon} alt={''} />
          <Typography component="span" sx={stylesMatchText}>
            Company Match
          </Typography>
        </Box>
      </Box>
      {companyArray?.map((companyTitle: string | null, index: number) => {
        if (companyTitle) {
          const companyHistoryArray =
            (!!sortedWorkHistoryArray?.length &&
              sortedWorkHistoryArray.filter((item: any) => item.company === companyTitle)) ||
            [];

          if (!!companyHistoryArray.length) {
            companyHistoryArray.sort((a: RoleType, b: RoleType) => {
              const firstDate = a.end_year ? new Date(a.end_date).getTime() : new Date().getTime();
              const secondDate = b.end_year ? new Date(b.end_date).getTime() : new Date().getTime();
              return secondDate - firstDate;
            });

            return (
              <React.Fragment key={index}>
                <BoxBorder>
                  <StyledTitle>
                    <Typography sx={stylesCompanyTitle}>
                      {companyHistoryArray[0].company || 'No information'}
                    </Typography>
                    <StyledLine />
                    <Typography fontWeight="400" fontSize="16px" color="secondary">
                      {(companyHistoryArray[0].start_date &&
                        experienceToString({
                          startDate: companyHistoryArray[companyHistoryArray.length - 1].start_date,
                          endDate: companyHistoryArray[0].end_year ? companyHistoryArray[0].end_date : '',
                        })) ||
                        'No information'}
                    </Typography>
                  </StyledTitle>

                  {(!!companyHistoryArray[0]?.industries?.length ||
                    !!companyHistoryArray[0]?.employee?.title ||
                    companyHistoryArray[0]?.ownership?.title ||
                    companyHistoryArray[0]?.revenue?.title) && (
                    <StyledIconSet>
                      {!!companyHistoryArray[0]?.industries?.length && (
                        <Box sx={styledFlexWrap}>
                          <IconItem
                            icon={IndustryIcon}
                            label={'Industry:'}
                            value={arrayTitleToString({ arr: companyHistoryArray[0].industries, symbol: ',' })}
                            sx={stylesIcon}
                          />
                          {!!currentSubmission?.industries?.find(
                            (el: any) => companyHistoryArray[0].industries[0]?.id === el.id,
                          ) && <IconItem icon={MatchIcon} sx={stylesMatchIcon} />}
                        </Box>
                      )}
                      {!!companyHistoryArray[0]?.employee?.title && (
                        <Box sx={styledFlexWrap}>
                          <IconItem
                            icon={EmployeesIcon}
                            label={'Employees:'}
                            value={companyHistoryArray[0].employee?.title || '-'}
                            sx={stylesIcon}
                          />
                          {currentSubmission?.employee_id === companyHistoryArray[0].employee_id && (
                            <IconItem icon={MatchIcon} sx={stylesMatchIcon} />
                          )}
                        </Box>
                      )}
                      {companyHistoryArray[0]?.ownership?.title && (
                        <Box sx={styledFlexWrap}>
                          <IconItem
                            icon={OwnershipIcon}
                            label={'Ownership:'}
                            value={companyHistoryArray[0].ownership?.title || '-'}
                            sx={stylesIcon}
                          />
                          {currentSubmission?.ownership_id === companyHistoryArray[0].ownership_id && (
                            <IconItem icon={MatchIcon} sx={stylesMatchIcon} />
                          )}
                        </Box>
                      )}
                      {companyHistoryArray[0]?.revenue?.title && (
                        <Box sx={styledFlexWrap}>
                          <IconItem
                            icon={RevenueIcon}
                            label={'Revenue:'}
                            value={companyHistoryArray[0].revenue?.title || '-'}
                            sx={stylesIcon}
                          />
                          {currentSubmission?.revenue_id === companyHistoryArray[0].revenue_id && (
                            <IconItem icon={MatchIcon} sx={stylesMatchIcon} />
                          )}
                        </Box>
                      )}
                    </StyledIconSet>
                  )}

                  <StyledLine sx={{ margin: '8px 0' }} />

                  {companyHistoryArray.map((el: RoleType, idx: number) => {
                    const { company, title, start_month, start_year, end_month, end_year } = el;
                    if (companyTitle === company) {
                      return (
                        <StyledRoleBox
                          key={idx}
                          showborder={idx === companyHistoryArray.length - 1 ? undefined : 'true'}
                        >
                          <StyledWrap>
                            <img src={CircleIcon} width="8px" height="8px" alt="" />
                            <StyledJobTitle>
                              <Typography sx={stylesJobTitle} color="secondary">
                                {title || 'No information'}
                              </Typography>
                              <Typography sx={stylesJobTitle} color="secondary" whiteSpace="nowrap">
                                {`${getShotMontName(start_month)} ${start_year} - ${
                                  end_year ? `${getShotMontName(end_month)} ${end_year}` : 'present'
                                }`}
                              </Typography>
                            </StyledJobTitle>
                          </StyledWrap>
                        </StyledRoleBox>
                      );
                    }
                    return null;
                  })}
                </BoxBorder>
              </React.Fragment>
            );
          }
        }
        return null;
      })}
    </Box>
  );
};

export default HistoryCompanies;

const stylesSectionTitle = {
  margin: '15px 0 10px',
  fontSize: '20px',
  lineHeight: '24px',
};

const stylesCompanyTitle = {
  fontSize: '16px',
  letterSpacing: 0.09,
  margin: '10px 0',
};

const stylesJobTitle = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.31,
  letterSpacing: '0.09px',
};

const StyledRoleBox = styled(Box)<{
  showborder?: string;
}>`
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    &::after {
      display: ${({ showborder }) => (!!showborder ? 'block' : 'none')};
      content: '';
      position: absolute;
      top: 17px;
      left: 3.5px;
      height: 100%;
      border-left: 0.5px dashed #5e5e5e;
    }
  }
`;

const StyledWrap = styled(Box)`
  display: flex;
  align-items: baseline;

  > :not(:first-child) {
    margin-top: -3px;
  }
`;

const StyledLine = styled(Box)`
  border-bottom: 1px solid;
  border-color: #e9e7e7;
  height: 1px;
  flex-grow: 1;
  margin: 8px;
`;

const BoxBorder = styled(Box)`
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  padding: 0 15px;
  margin-bottom: 10px;
  height: 100%;
`;

const StyledTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIconSet = styled(Box)`
  margin-bottom: 5px;

  > :not(:last-child) {
    margin-bottom: 5px;
  }
`;

const StyledJobTitle = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: start;
  padding: 0 5px 10px;
`;

const stylesMatchText = {
  color: '#191919',
  fontSize: '16px',
  fontWeight: 400,
  lineHight: 1.31,
};

const stylesIconWrap = {
  display: 'flex',
  alignItems: 'center',

  ' & img': {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
};

const styledFlexWrap = {
  display: 'flex',
  gap: '5px',
  alignItems: 'baseline',
};

const stylesMatchIcon = {
  display: 'inline-block',
  maxWidth: '20px',
  height: '20px',
};

const stylesIcon = {
  alignItems: 'start',
  flexGrow: 1,
  'span': {fontSize: '16px'},

  '.label': {
    width: '75px',
  },
};
