import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Card, Alert, Grid, SxProps } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../../../redux/store';
import { getDictionaries } from '../../../../redux/actions/dictionaries';
import { setActiveStep } from '../../../../redux/reducers/additional';
import { PROFILE_PATH, workplaceConfirmation } from '../../../../constants/constants';

import CustomInput from '../../../CustomInput';
import CustomButton from '../../../CustomButton';
import ToggleButtonGroupCheck from '../../../ToggleButtonGroupCheck';
import { styleCardSection } from '../../StylesCandidateProfile';
import CustomRadioGroup from '../../../CustomRadioGroup';

import SubmissionsAdapter from './../../../../models/adapters/submissions.adapter';
import { saveCandidate } from '../../../../redux/actions/candidate';
import { submissionSelector } from '../../../../redux/selectors/candidate.selectors';
import { SubmissionItemType } from '../../../../types/candidate';
import SubmissionDataModel from '../../../../models/SubmissionsData.model';

type TProps = {
  id: string;
  submissionId: string;
};

const Preferences: React.FC<TProps> = ({ id, submissionId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companyAndFunctions } = useSelector((state: RootState) => state.dictionaries.dictionaries);
  const { isCandidate } = useSelector((state: RootState) => state.user);
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const valuesSubmissions = useSelector(submissionSelector);

  const [newSubmissions, setNewSubmissions] = useState<Array<SubmissionItemType>>([]);
  const [currentSubmissionIndex, setCurrentSubmissionIndex] = useState(-1);
  const [isOtherAvailability, setIsOtherAvailability] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (valuesSubmissions.length) {
      setNewSubmissions(valuesSubmissions);
      setCurrentSubmissionIndex(
        valuesSubmissions.findIndex((submission: any) => submission.id.toString() === submissionId),
      );
    }
  }, [submissionId, valuesSubmissions]);

  useEffect(() => {
    if (valuesSubmissions[currentSubmissionIndex]?.noticePeriod?.title === 'Other') {
      setIsOtherAvailability(true);
    }
  }, [currentSubmissionIndex, valuesSubmissions]);

  useEffect(() => {
    if (!companyAndFunctions) {
      dispatch(getDictionaries('companyAndFunctions'));
    }
  }, [companyAndFunctions, dispatch]);

  useEffect(() => {
    if (isError) {
      setTimeout(()=>{
        const errorTextEl = document.querySelector('[data-label="errorText"]');
        !!errorTextEl && errorTextEl.scrollIntoView({block: 'center'}); 
      }, 100);
    }
  }, [isError]);

  return currentSubmissionIndex !== -1 ? (
    <Formik
      initialValues={{
        location_id: newSubmissions[currentSubmissionIndex].location_id || '',
        is_workplace_location:
          newSubmissions[currentSubmissionIndex].is_workplace_location !== null
            ? newSubmissions[currentSubmissionIndex].is_workplace_location
            : 1,
        salary_min: newSubmissions[currentSubmissionIndex].salary_min,
        salary_comment: newSubmissions[currentSubmissionIndex].salary_comment || '',
        notice_period_id: newSubmissions[currentSubmissionIndex].notice_period_id || '',
        notice_period_other: newSubmissions[currentSubmissionIndex].notice_period_other || '',
        interviewAvailabilities: newSubmissions[currentSubmissionIndex].interviewAvailabilities,
        availability_additional: newSubmissions[currentSubmissionIndex].availability_additional,
        submit: false,
      }}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const newData = newSubmissions.map((item: any, index) => {
          if (item.id === +submissionId) {
            const newItem = { ...item, ...values };
            item.notice_period_id = Number(values.notice_period_id);
            item.is_workplace_location = Number(values.is_workplace_location);
            return newItem;
          } else {
            return item;
          }
        });
        const data: Array<SubmissionDataModel & { is_completed?: number }> =
          SubmissionsAdapter.mapSubmissionsValuesModelToData(newData);
        try {
          const isOCPEmpty =
            (candidate?.ocp_answers?.top && !!Object.keys(candidate?.ocp_answers?.top).length) ||
            (candidate?.ocp_answers?.bottom && !!Object.keys(candidate?.ocp_answers?.bottom).length)
              ? false
              : true;
          if (currentSubmissionIndex !== -1 && (!isOCPEmpty || !isCandidate)) {
              data[currentSubmissionIndex] = {
                ...data[currentSubmissionIndex],
                is_completed: 1,
              };
            }
          dispatch(saveCandidate({ id: +id, values: { submissions: data } }));
        } catch (error: any) {
          const message = error.message || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {formik => {
        const { handleSubmit, errors, values, handleChange, setFieldValue, touched } = formik;

        !!Object.keys(errors).length && setIsError(true);
        setTimeout(()=>setIsError(false), 100);

        return (
          <form onSubmit={handleSubmit}>
            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
            <>
              <Card sx={styleCardSection}>
                <Typography variant="h5" gutterBottom color="secondary" mb={5}>
                  Preferences
                </Typography>
                <Card sx={styleCard}>
                  <Typography component="div" variant="h6" color="secondary" mb={5}>
                    Target Salary
                  </Typography>

                  <Grid container spacing={3} mb={5}>
                    <Grid item md={4} xs={12}>
                      <CustomInput
                        name="salary_min"
                        label="Target Salary"
                        value={values.salary_min}
                        type="number"
                        onChange={handleChange}
                        error={touched.salary_min && errors.salary_min}
                      />
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <CustomInput
                        name="salary_comment"
                        label="Compensation Notes"
                        placeholder="Anything else compensation-related you’d like to highlight?"
                        value={values.salary_comment}
                        onChange={handleChange}
                        error={touched.salary_comment && errors.salary_comment}
                      />
                    </Grid>
                  </Grid>
                  {!!valuesSubmissions[currentSubmissionIndex]?.location?.title && (
                    <>
                      <Typography component="div" variant="h6" gutterBottom color="secondary">
                        Workplace Location
                      </Typography>
                      <Typography component="div" variant="body2" color="#5E5E5E" mb={2}>
                        This opportunity requires the employee to work{' '}
                        <span style={{ textTransform: 'uppercase', fontWeight: 600 }}>
                          {valuesSubmissions[currentSubmissionIndex]?.location?.title}
                        </span>
                        . Does this work for you?
                      </Typography>
                      <Grid container mb={6}>
                        <Grid item xs={12}>
                          <CustomRadioGroup
                            name="is_workplace_location"
                            value={values.is_workplace_location !== null ? values.is_workplace_location : 1}
                            onChange={handleChange}
                            data={workplaceConfirmation}
                            labelStyle={{ fontWeight: 400 }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Typography component="div" variant="h6" gutterBottom color="secondary" mb={1}>
                    Availability
                  </Typography>
                  <Grid container mb={6} style={{ alignItems: 'center' }}>
                    <Grid item md={7} xs={12} style={{ maxWidth: '480px' }}>
                      <CustomRadioGroup
                        name="notice_period_id"
                        value={values.notice_period_id}
                        onChange={e => {
                          handleChange(e);
                          if (e.currentTarget.labels[0].textContent === 'Other') {
                            setIsOtherAvailability(true);
                          } else {
                            setIsOtherAvailability(false);
                          }
                        }}
                        data={companyAndFunctions?.notices}
                        labelStyle={{ fontWeight: 400 }}
                      />
                    </Grid>
                    {isOtherAvailability && (
                      <Grid item md={6} xs={12} ml="-15px" mb="-12px">
                        <CustomInput
                          name="notice_period_other"
                          label=""
                          placeholder="Please specify"
                          value={values.notice_period_other}
                          onChange={handleChange}
                          error={touched.notice_period_other && errors.notice_period_other}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid container mt={3}>
                    <Grid item xs={12}>
                      <Typography component="div" variant="h6" gutterBottom color="secondary" mb={1}>
                        Interview Availability
                      </Typography>

                      <Typography component="div" variant="body2" gutterBottom mb={3} color="#5E5E5E">
                        Select all that apply
                      </Typography>
                      <Box mb={6}>
                        <ToggleButtonGroupCheck
                          name="interviewAvailabilities"
                          value={values.interviewAvailabilities}
                          onChange={(event: any, value: any) => {
                            setFieldValue('interviewAvailabilities', value);
                          }}
                          ariaLabel="Interview Availability"
                          data={companyAndFunctions?.interviews}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="secondary" mb={1}>
                        Additional Notes
                      </Typography>
                      <CustomInput
                        name="availability_additional"
                        label=""
                        placeholder="Any upcoming commitments, vacations or other considerations that could impact your availability in the next 3 months?"
                        value={values.availability_additional}
                        onChange={handleChange}
                        error={touched.availability_additional && errors.availability_additional}
                      />
                    </Grid>
                  </Grid>
                </Card>

                <Box display="flex" gap={'15px'} justifyContent="right" alignItems="center" p={5}>
                  {!isCandidate && (
                    <Box mr={'10px'}>
                      <CustomButton
                        type="button"
                        variant="text"
                        onClick={() => navigate(`/${PROFILE_PATH}?candidate_id=${id}`)}
                      >
                        Return to dashboard
                      </CustomButton>
                    </Box>
                  )}
                  {!isCandidate && (
                    <CustomButton
                      type="submit"
                      variant={!candidate?.is_full_culture ? 'text' : 'contained'}
                      onClick={(e: any) => {
                        handleSubmit(e);
                        navigate(`/${PROFILE_PATH}/profile-details?candidate_id=${id}&submissionId=${submissionId}`);
                      }}
                    >
                      View Report
                    </CustomButton>
                  )}
                  {(isCandidate || !candidate?.is_full_culture) && (
                    <CustomButton variant="contained" type="submit">
                      Next
                    </CustomButton>
                  )}
                </Box>
              </Card>
            </>
          </form>
        );
      }}
    </Formik>
  ) : null;
};

const styleCard: SxProps = {
  padding: '15px 30px 18px',
  marginBottom: '10px',
};

export default React.memo(Preferences);
