import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Alert as MuiAlert, Box, Typography, Button } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../CustomInput';
import useAuth from '../../hooks/useAuth';
import { SubmitButton } from '../../styles/ButtonStyles';
import { ALERT_MESSAGES } from '../../constants/constants';

const Alert = styled(MuiAlert)(spacing);

function ResetPassword() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id') !== null ? params.get('id') : '';
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { resetPassword } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resetPassword(values.email, 1);
          setShowSuccessMessage(true);
        } catch (error: any) {
          const message = error.message || ALERT_MESSAGES.DEFAULT_ERROR;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <CustomInput
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={touched.email && errors.email}
            onChange={handleChange}
          />

          <SubmitButton type="submit" variant="contained" disabled={isSubmitting} style={{ marginTop: '10px' }}>
            Reset password
          </SubmitButton>

          <Box sx={{ height: '52px' }}>
            {showSuccessMessage && (
              <Alert mt={2} mb={3} p={2} severity="success">
                Password reset request was sent. Please check your email.
              </Alert>
            )}
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
          </Box>

          <Box sx={stylesFooter}>
            <Typography component="span" variant="body2">
              Don’t have an account yet?
            </Typography>

            <Button component={Link} to={`/auth/sign-up?id=${id}`} style={{ color: '#2681DB' }}>
              Sign up
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;

const stylesFooter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
