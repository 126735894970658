import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AlertType = {
  activeStep: number;
  numberRole: number;
  activeTab: number;
  isUploadResume: boolean;
  isInvitedCandidate: boolean;
};

const defaultState: AlertType = {
  activeStep: 0,
  numberRole: 0,
  activeTab: 1,
  isUploadResume: false,
  isInvitedCandidate: false,
};

const additionalSlice = createSlice({
  name: 'additional',
  initialState: defaultState,
  reducers: {
    setActiveStep: (state, { payload }: PayloadAction<any>) => {
      state.activeStep = payload;
    },
    addAnotherRole: (state, { payload }: PayloadAction<any>) => {
      state.numberRole = payload;
    },
    setActiveTab: (state, { payload }: PayloadAction<any>) => {
      state.activeTab = payload;
    },
    setAdditionalState(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
  },
  extraReducers: {},
});

export const { setActiveStep, addAnotherRole, setActiveTab, setAdditionalState } = additionalSlice.actions;

export default additionalSlice.reducer;

export const setActiveStepThunk =
  (index: number): any =>
  async (dispatch: any) => {
    dispatch(setActiveStep(index));
  };
