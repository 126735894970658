import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';

interface Props {
  error?: any;
}

const ErrorText = ({ error }: Props) => (
  <Box sx={stylesWrap}>
    <Typography color="error" variant="subtitle1" data-label={!!error ? 'errorText' : ''}>
      {error}
    </Typography>
  </Box>
);

export default ErrorText;

const stylesWrap: SxProps = {
  height: 12,
  width: '100%',
};
