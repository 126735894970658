import { Box, Typography } from '@mui/material';
import { CategoryItemContainer } from '../../styles/pages/JobDetail/JobCategoryStyles';
import locationIcon from '../../images/icons/ic_location_on_blue_48px.svg';
import { CategoryItemDetails } from '../../styles/components/CategoryFullDetailsStyles';
import { JobsType } from '../../types/jobs';
import { durations, employmentTypes } from '../../utils/staticValues';
import React from 'react';
import {getFullLocation} from "../../utils/functions";

type TProps = {
  item: JobsType;
  key: number;
  onClick: (val: any) => void;
  className?: string;
};

const JobCardMobile: React.FC<TProps> = ({ item, onClick, className }) => {
  const showDuration = (duration_id: any) => {
    return durations.filter(i => i.id === duration_id).map(i => i.title);
  };
  const showSalary = () => {
    if (item?.display_compensation) {
      return (
        <Typography variant={'body1'} color={'#002855'}>
          {item?.employment_type === employmentTypes.CONTRACT
            ? item?.published_rate
              ? `$${item?.published_rate?.toLocaleString('en-US', { maximumFractionDigits: 2 })} ${showDuration(
                  item?.duration_id,
                )}`
              : null
            : item?.salary_min
            ? `$${item?.salary_min?.toLocaleString('en-US', { maximumFractionDigits: 2 })}`
            : null}
        </Typography>
      );
    } else return null;
  };

  return (
    <CategoryItemContainer onClick={onClick} className={className ? className : ''}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant={'h3'} color={'#1565C0'}>
          {item?.title}
        </Typography>
      </Box>

      <Box display={'flex'} mt={'10px'}>
        <img src={locationIcon} alt={''} />
        <Typography variant={'body2'} ml={'3px'}>
          {getFullLocation(item)}
        </Typography>
        <Typography variant={'body2'} mr={'10px'} ml={'10px'}>
          &#x2022;
        </Typography>
        <Typography variant={'body2'}>
          {item?.employment_type === employmentTypes.CONTRACT ? employmentTypes.CONTRACT : employmentTypes.FULL_TIME}
        </Typography>
      </Box>
      <CategoryItemDetails
        dangerouslySetInnerHTML={{
          __html:
            item?.about_opportunity && item?.about_opportunity.length <= 145
              ? item?.about_opportunity
              : `${item?.about_opportunity.slice(0, 142)}...`,
        }}
      />
      <Box display={'flex'} justifyContent={'space-between'} mt={'5px'}>
        {showSalary()}
      </Box>
    </CategoryItemContainer>
  );
};

export default JobCardMobile;
