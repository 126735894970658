import { InitiativeType, ResponsibilityType, RoleType } from '../types/candidate';
import { objToId, onCheckEmptyDate } from '../utils/formatData';

class RoleValuesModel {
  id?: number;
  title: string;
  company: string;
  start_date: Date | null;
  start_year: string;
  end_date: Date | null;
  end_year: string;
  industry_id?: number | null;
  industries: Array<number>;
  revenue_id?: number | null;
  employee_id?: number | null;
  ownership_id?: number | null;
  departments: Array<number>;
  reports_to: string;
  level_id?: number | null;
  is_manage_team: number;
  direct_reports?: number | null;
  jobFunctions: Array<number>;
  responsibilities: Array<ResponsibilityType>;
  projects: Array<number>;
  initiatives: Array<InitiativeType>;
  additional: string;
  is_current: boolean;

  constructor(data: RoleType) {
    this.id = data.id;
    this.title = data.title || '';
    this.company = data.company || '';
    this.start_date = onCheckEmptyDate(data.start_date) ? new Date(data.start_date) : null;
    this.start_year = data.start_year;
    this.end_date = onCheckEmptyDate(data.end_date) ? new Date(data.end_date) : null;
    this.end_year = data.end_year;
    this.industry_id = data.industry_id || undefined;
    this.industries = data.industries && data?.industries.length > 1 && !!data.industries[0].title
    ? [...data.industries].sort((a: any, b: any) =>a.title.localeCompare(b.title))
    : data.industries || [];
    this.revenue_id = data.revenue_id || undefined;
    this.employee_id = data.employee_id || undefined;
    this.ownership_id = data.ownership_id || undefined;
    this.departments = data.departments || [];
    this.reports_to = data.reports_to || '';
    this.level_id = data.level_id || undefined;
    this.is_manage_team = data.is_manage_team ? 1 : 0;
    this.direct_reports = data.direct_reports || undefined;
    this.jobFunctions = objToId(data.jobFunctions) || [];
    this.responsibilities = data.responsibilities || [];
    this.projects = objToId(data.projects) || [];
    this.initiatives = data.initiatives || [];
    this.additional = data.additional || '';
    this.is_current = data.is_current || false;
  }
}

export default RoleValuesModel;
