import ScorecardItemValuesModel from './scorecardItemValues.model';

class ScorecardDataModel {
  candidate_id: number;
  comment: string | null;
  requirement_title_id: number;
  requirement_id: number;
  value: string | null;
  is_approved: number;

  constructor(data: ScorecardItemValuesModel) {
    this.candidate_id = data.candidate_id;
    this.comment = data.comment;
    this.requirement_title_id = data.requirement_title_id;
    this.requirement_id = data.requirement_id;
    this.value = data.value;
    this.is_approved = data.is_approved;
  }
}

export default ScorecardDataModel;
