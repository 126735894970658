import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components/macro';
import { COLORS } from '../theme';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import { EXPERIENCE_LEVELS } from '../constants/constants';
import { ScorecardRespType } from '../types/candidate';

type TProps = {
  type: string;
  data: ScorecardRespType;
  onChangeValue: (obj: ScorecardRespType) => void;
};

const cellStyle = (type: string, value: string | null) => {
  if (type === value) {
    switch (value) {
      case EXPERIENCE_LEVELS.EXCEEDS:
        return {
          backgroundColor: COLORS.successLight,
          border: 'solid 1px #00B192',
        };
      case EXPERIENCE_LEVELS.MEETS:
        return {
          backgroundColor: COLORS.successLight,
          border: 'solid 1px #00B192',
        };
      case EXPERIENCE_LEVELS.SOME:
        return {
          backgroundColor: COLORS.warningLight,
          border: 'solid 1px #FCC501',
        };
      case EXPERIENCE_LEVELS.NONE:
        return {
          backgroundColor: COLORS.errorLight,
          border: 'solid 1px #DC2547',
        };
      default:
        return {
          backgroundColor: COLORS.disabledLight,
          border: '',
        };
    }
  } else
    return {
      backgroundColor: COLORS.disabledLight,
      border: '',
    };
};

const showIcon = (type: string, value: string | null) => {
  switch (type) {
    case EXPERIENCE_LEVELS.EXCEEDS:
      return <DoneAllIcon color={value === type ? 'success' : 'disabled'} />;
    case EXPERIENCE_LEVELS.MEETS:
      return <CheckTwoToneIcon color={value === type ? 'success' : 'disabled'} />;
    case EXPERIENCE_LEVELS.SOME:
      return <RemoveIcon color={value === type ? 'warning' : 'disabled'} />;
    case EXPERIENCE_LEVELS.NONE:
      return <ClearIcon color={value === type ? 'error' : 'disabled'} />;
    default:
      return null;
  }
};

const ExperienceCell: React.FC<TProps> = ({ type, data, onChangeValue }) => {
  return (
    <Cell onClick={() => onChangeValue({ ...data, value: type })} style={cellStyle(type, data.value)}>
      {showIcon(type, data?.value)}
    </Cell>
  );
};

export default ExperienceCell;

const Cell = styled(Box)`
  display: flex;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  background: #f5f8fa;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  svg {
    width: 15px;
  }
`;
