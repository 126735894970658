import React, { memo, useState } from 'react';
import { Box, ClickAwayListener, Tooltip, Button } from '@mui/material';
import styled from 'styled-components/macro';
import { SxProps } from '@mui/system';

import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';

import { ScorecardRespType } from '../../../../types/candidate';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Editors from '../../../Editors';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconWrapper } from '../../../../styles/components/InfoModalStyles';

type TProps = {
  data: ScorecardRespType;
  onChangeValue: (values: ScorecardRespType) => void;
};

const Comment: React.FC<TProps> = ({ data, onChangeValue }) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState(data.comment);

  const onShowComment = () => {
    setOpen(!open);
  };

  const onSaveComment = () => {
    onChangeValue({ ...data, comment: comment });
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div style={{ position: 'relative' }}>
        <Cell onClick={onShowComment}>
          {!!data.comment ? (
            <Tooltip title={'Edit Comment'}>
              <AnnouncementIcon sx={{ color: '#2681DB' }} />
            </Tooltip>
          ) : (
            <Tooltip title={'Add Comment'}>
              <AddCommentOutlinedIcon color={'disabled'} sx={{ transform: 'scaleX(-1)' }} />
            </Tooltip>
          )}
        </Cell>
        {open && (
          <Box onClick={e => e.stopPropagation()} sx={styleCommentBox}>
            <CloseIconWrapper onClick={() => setOpen(false)}>
              <CloseIcon />
            </CloseIconWrapper>
            <Editors value={comment ? comment : ''} onChange={value => setComment(value)} hideClose={true} />

            <WrapButton>
              <Button type="button" variant={'text'} onClick={onSaveComment} sx={{ fontSize: 30 }}>
                Save
              </Button>
            </WrapButton>
          </Box>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default memo(Comment);

const Cell = styled(Box)`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background: #f5f8fa;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;

  &:hover {
    svg {
      opacity: 0.5;
    }
  }

  svg {
    width: 15px;
  }
`;

const WrapButton = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;

  button {
    padding: 0 5px;
    font-size: 14px;
    min-width: 0;
    margin-left: 5px;
  }
`;

const styleCommentBox: SxProps = {
  width: 500,
  position: 'absolute',
  top: -20,
  right: 50,
  zIndex: 1,
  borderRadius: '5px',
  p: 4,
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.14)',
};
