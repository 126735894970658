import styled from 'styled-components/macro';
import { Box, Button } from '@mui/material';

export const CategoryItemContainer = styled(Box)({
  width: '100%',
  minHeight: '182px',
  backgroundColor: '#FFF',
  padding: '15px',
  marginBottom: '15px',
  ':hover': {
    outline: '1px solid #2681DB',
  },
  h3: {
    fontSize: '18px',
    fontWeight: 500,
    '@media (max-width: 768px)': {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
});

export const MenuButton = styled(Button)({
  minWidth: '30px',
  padding: 0,
});
