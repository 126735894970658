import { Box, CardActions, CardContent, SxProps, Typography } from '@mui/material';
import { styled } from '@mui/system';

import IconItem from './IconItem';
import LocationIcon from '../../../../images/icons/ic_location_on_blue2_48px.svg';
import WorkplaceIcon from '../../../../images/icons/ic_workplace_blue2_48px.svg';
import DesignationIcon from '../../../../images/icons/ic_designation_blue_48px.svg';
import ProfessionIcon from '../../../../images/icons/ic_profession_blue_48px.svg';
import SalaryIcon from '../../../../images/icons/ic_salary_blue_48px.svg';
import LocationIconGreen from '../../../../images/icons/ic_location_on_48px.svg';
import WorkplaceIconGreen from '../../../../images/icons/ic_workspace_48px.svg';
import DesignationIconGreen from '../../../../images/icons/ic_designation_48px.svg';
import ProfessionIconGreen from '../../../../images/icons/ic_profession_48px.svg';
import SalaryIconGreen from '../../../../images/icons/ic_salary_48px.svg';

import React, { useState } from 'react';
import { UserType } from '../../../../types/candidate';
import { OVERALL_FIT } from '../../../../constants/constants';

type TProps = {
  toPdf: boolean;
  showCompanyName?: boolean;
  showTargetSalary?: boolean;
  submissionId?: number;
  values: {
    why_interested?: string;
    first_name: string;
    last_name: string;
    designation: string;
    job_title: string;
    salary_min: number | null;
    salary_max: number | null;
    location: { id: number; title: string } | null;
    user: UserType | null;
    about: string;
    overallFit?: number;
  };
};

const HeaderContent: React.FC<TProps> = ({ toPdf, showCompanyName, showTargetSalary, submissionId, values }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {!toPdf && !submissionId && (
        <Typography sx={styledCandidateTitle} color="secondary" mb={'10px'}>
          {values.first_name} {values.last_name}
        </Typography>
      )}
      <BoxBorder>
        {!toPdf && submissionId && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={styledCandidateTitle} color="secondary" mb={'10px'}>
              {values.first_name} {values.last_name}
            </Typography>
            {values.overallFit && (
              <OverallFitBox overallfit={values.overallFit > 80 ? 'high' : values.overallFit > 60 ? 'medium' : 'low'}>
                {values.overallFit > 80
                  ? OVERALL_FIT.HIGH
                  : values.overallFit > 60
                  ? OVERALL_FIT.MEDIUM
                  : OVERALL_FIT.LOW}
              </OverallFitBox>
            )}
          </Box>
        )}
        <StyledIconSet>
          {!!values.designation && (
            <IconItem
              sx={submissionId ? stylesIcon : ''}
              icon={submissionId ? DesignationIcon : DesignationIconGreen}
              label={'Designation'}
              value={values.designation || '-'}
            />
          )}
          {!!values.job_title && (!toPdf || showCompanyName) && (
            <IconItem
              sx={submissionId ? stylesIcon : ''}
              icon={submissionId ? ProfessionIcon : ProfessionIconGreen}
              label={'Current Title'}
              value={values.job_title || '-'}
            />
          )}
          {(!!values.salary_min || !!values.salary_max) && (!toPdf || showTargetSalary) && (
            <IconItem
              sx={submissionId ? stylesIcon : ''}
              icon={submissionId ? SalaryIcon : SalaryIconGreen}
              label={'Target Salary'}
              value={
                values.salary_min
                  ? `${
                      values.salary_min > 1000
                        ? `${Math.round(values.salary_min / 1000).toLocaleString()}K`
                        : values.salary_min?.toLocaleString()
                    }`
                  : values.salary_max
                  ? `${
                      values.salary_max > 1000
                        ? `${Math.round(values.salary_max / 1000).toLocaleString()}K`
                        : values.salary_max?.toLocaleString()
                    }`
                  : '-'
              }
            />
          )}
          {!!values.user?.city && (
            <IconItem
              sx={submissionId ? stylesIcon : ''}
              icon={submissionId ? LocationIcon : LocationIconGreen}
              label={'Location'}
              value={
                `${values.user?.city}${values.user?.province?.title ? `, ${values.user?.province?.title}` : ''} ` || '-'
              }
            />
          )}
        </StyledIconSet>

        {!!values.about?.length && (
          <Box>
            <StyledCardContent>
              {values.about && <Typography sx={stylesSubheader}>Candidate Summary</Typography>}
              {expanded || toPdf ? (
                <Typography component="span" sx={stylesText}>
                  {values.about}
                </Typography>
              ) : (
                <Typography component="span" sx={stylesText}>
                  {values.about?.length > 950 ? values.about?.slice(0, 950) + '...' : values.about}
                </Typography>
              )}

              {!toPdf && values.about.length > 950 && (
                <StyledCardActions>
                  {expanded && !toPdf ? (
                    <StyledLink component="a" onClick={() => setExpanded(!expanded)}>
                      Hide
                    </StyledLink>
                  ) : (
                    <StyledLink component="a" onClick={() => setExpanded(!expanded)}>
                      Show More
                    </StyledLink>
                  )}
                </StyledCardActions>
              )}
              {values.why_interested && (
                <Box>
                  <Typography sx={stylesSubheader}>Why is the candidate interested?</Typography>
                  <Typography sx={stylesText}>{values.why_interested}</Typography>
                </Box>
              )}
            </StyledCardContent>
          </Box>
        )}
      </BoxBorder>
    </>
  );
};

const StyledIconSet = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 8px;
  align-items: center;
  margin-bottom: 15px;

  > :not(last-child) {
    padding-right: 8px;
    border-right: 1px solid;
    border-color: #e7e7e7;
  }

  > :last-child {
    border-right: none;
  }
`;

const BoxBorder = styled(Box)`
  border: 1px solid #e9e7e7;
  border-radius: 5px;
  padding: 20px;
  height: 100%;
`;

const styledCandidateTitle: SxProps = {
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '120%',
  letterSpacing: 0.04,
};

const StyledLink = styled(Typography)`
  display: inline;
  color: #2681db;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
`;

const OverallFitBox = styled(Box)<{
  overallfit: 'high' | 'medium' | 'low';
}>`
  display: inline-block;
  text-align: center;
  align-items: center;
  color: #f8f8f8;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: ${({ overallfit }) =>
    overallfit === 'high' ? '#00B192' : overallfit === 'medium' ? '#FCC501' : '#DC2547'};
  margin-bottom: 10px;
`;

const StyledCardActions = styled(CardActions)`
  display: inline;
`;

const StyledCardContent = styled(CardContent)`
  text-align: justify;
  padding: 0;

  &:last-child {
    padding: 0;
  }
`;

const stylesText: SxProps = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.1px',
};

const stylesSubheader: SxProps = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 1.5,
  marginTop: '10px',
};

const stylesIcon = {
  span: {
    fontSize: '16px',
  },
};

export default HeaderContent;
