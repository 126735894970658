import { createSelector } from '@reduxjs/toolkit';
import RolesAdapter from '../../models/adapters/roles.adapter';
import ScorecardAdapter from '../../models/adapters/scorecard.adapter';
import RoleValuesModel from '../../models/roleValues.model';
import Step1ValuesModel from '../../models/step1Values.model';
import Step3ValuesModel from '../../models/step3Values.model';
import { CandidateType, ScorecardValuesType, ScorecardType } from '../../types/candidate';
import { initRoleCandidate } from '../../utils/staticValues';
import { RootState } from '../store';
import SubmissionValuesModel from './../../models/SubmissionsValues.model';
import SubmissionsAdapter from './../../models/adapters/submissions.adapter';

export const rolesSelector = createSelector(
  (state: RootState) => state.candidate.candidate,
  (candidate: CandidateType | null): RoleValuesModel[] =>
    candidate?.histories?.length
      ? RolesAdapter.mapRolesDtoToRolesValuesModel(candidate.histories)
      : [initRoleCandidate],
);

export const step1Selector = createSelector(
  (state: RootState) => state.candidate.candidate,
  (candidate: CandidateType | null): Step1ValuesModel => new Step1ValuesModel(candidate),
);

export const step3Selector = createSelector(
  (state: RootState) => state.candidate.candidate,
  (candidate: CandidateType | null): Step3ValuesModel => new Step3ValuesModel(candidate),
);

export const scorecardSelector = createSelector(
  (state: RootState) => state.candidate.scorecard,
  (scorecard: ScorecardType | null): ScorecardValuesType | null =>
    !!scorecard ? ScorecardAdapter.mapScorecardDtoToScorecardValues(scorecard) : null,
);

export const submissionSelector = createSelector(
  (state: RootState) => state.candidate.candidate,
  (candidate: CandidateType | null): SubmissionValuesModel[] =>
    candidate?.submissions?.length
      ? SubmissionsAdapter.mapSubmissionToValuesModel(candidate.submissions)
      : []
);
