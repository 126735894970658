import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Grid, Typography, Box } from '@mui/material';

import CustomSelect from '../../../CustomSelect';
import CustomCheckbox from '../../../CustomCheckbox';
import CustomSelectSalary from '../../../CustomSelectSalary';

import { RootState } from '../../../../redux/store';
import CustomMultipleSelect from '../../../CustomMultipleSelect';

type TProps = { formik: any };

const LookingForNext: React.FC<TProps> = ({ formik: { values, handleChange, setFieldValue, errors, touched } }) => {
  const { companyAndFunctions } = useSelector((state: RootState) => state.dictionaries.dictionaries);

  return (
    <>
      {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}

      <Grid container spacing={3} mb={5}>
        <Grid item md={4} xs={12}>
          <CustomSelect
            labelId="level_id"
            name="level_id"
            value={values.level_id}
            label="Target Level"
            onChange={handleChange}
            data={companyAndFunctions?.levels}
            error={Boolean(touched.level_id && errors.level_id)}
            errorText={touched.level_id && errors.level_id}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <CustomMultipleSelect
            name="industries"
            label="Target Industries"
            value={values.industries}
            onChange={value => {
              setFieldValue('industries', value);
            }}
            data={companyAndFunctions?.industries}
            error={Boolean(touched.industries && errors.industries)}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <CustomSelect
            labelId="location_id"
            name="location_id"
            label="Workplace"
            value={values.location_id}
            onChange={handleChange}
            data={companyAndFunctions?.locations}
            error={Boolean(touched.location_id && errors.location_id)}
          />
        </Grid>
      </Grid>

      <Typography variant="body1" gutterBottom color="secondary">
        Job Type
      </Typography>

      <Grid container spacing={3} mb={5}>
        <Grid item md={6} xs={12}>
          <Box p={2}>
            <CustomCheckbox
              name="employmentTypes_fullTime"
              label="Full-Time"
              value={values.employmentTypes_fullTime}
              checked={values.employmentTypes_fullTime}
              onChange={e => {
                handleChange(e);
                if (!e.target.checked) {
                  setFieldValue('salary_min', null);
                  setFieldValue('salary_max', null);
                }
              }}
            />
          </Box>

          <CustomSelectSalary
            labelId="salary"
            name="salary"
            label="Target salary"
            value={{ min: values.salary_min, max: values.salary_max }}
            data={[]}
            onChange={handleChange}
            disabled={!values.employmentTypes_fullTime}
            error={Boolean(touched.salary && errors.salary)}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Box p={2}>
            <CustomCheckbox
              name="employmentTypes_contract"
              label="Target Contract rate"
              value={values.employmentTypes_contract}
              checked={values.employmentTypes_contract}
              onChange={e => {
                handleChange(e);
                if (!e.target.checked) {
                  setFieldValue('rate_min', null);
                  setFieldValue('rate_max', null);
                }
              }}
            />
          </Box>

          <CustomSelectSalary
            labelId="rate"
            name="rate"
            label="Contract Rate"
            value={{ min: values.rate_min, max: values.rate_max }}
            data={[]}
            onChange={handleChange}
            disabled={!values.employmentTypes_contract}
            error={Boolean(touched.rate && errors.rate)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(LookingForNext);
