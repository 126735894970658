import { CandidateType } from '../types/candidate';
import { objToId } from '../utils/formatData';

class Step3ValuesModel {
  level_id?: number;
  industries: Array<any>;
  location_id?: number;
  employmentTypes: Array<{
    id: number;
    title: string;
  }> | null;
  employmentTypes_fullTime: boolean;
  employmentTypes_contract: boolean;
  salary_max: number | null;
  salary_min: number | null;
  rate_max: number | null;
  rate_min: number | null;
  functions: Array<any>;
  projects: Array<any>;
  reasons: Array<any>;
  comment?: string;

  constructor(data: CandidateType | null) {
    this.level_id = data?.level?.id;
    this.industries = data?.industries || [];
    this.location_id = data?.location?.id;
    this.employmentTypes = data?.employmentTypes || null;
    this.employmentTypes_fullTime = !!data?.employmentTypes?.find(type => type.id === 1);
    this.employmentTypes_contract = !!data?.employmentTypes?.find(type => type.id === 2);
    this.salary_max = data?.salary_max || null;
    this.salary_min = data?.salary_min || null;
    this.rate_max = data?.rate_max || null;
    this.rate_min = data?.rate_min || null;
    this.functions = objToId(data?.functions) || [];
    this.projects = objToId(data?.projects) || [];
    this.reasons = objToId(data?.reasons) || [];
    this.comment = data?.comment;
  }
}

export default Step3ValuesModel;
