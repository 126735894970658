import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ResetPasswordComponent from '../../components/Auth/ResetPassword';
import { HeaderText } from '../../styles/TextStyles';
import { Wrapper, Brand } from '../../styles/pages/AuthPages/SignInStyles';

function ResetPassword() {
  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      <Card>
        <CardContent>
          <Brand />

          <HeaderText style={{ textAlign: 'center' }}>Forgot Password</HeaderText>

          <Typography variant="body2" mb={10}>
            Enter your email to reset your password
          </Typography>

          <Box sx={{ padding: '0px 38px' }}>
            <ResetPasswordComponent />
          </Box>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default ResetPassword;
