import React from 'react';
import styled from 'styled-components/macro';
import { CircularProgress } from '@mui/material';

const Loader: React.FC = () => (
  <Root>
    <CircularProgress color="secondary" />
  </Root>
);

export default Loader;

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #ffffffb3;
`;
