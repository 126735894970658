import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import { StyledCheckbox } from '../../../styles/SelectStyles';

type TProps = {
  title: string;
  handleChange: () => void;
  checked: boolean;
  parent?: boolean;
};

const ResponsibilitiesItem: React.FC<TProps> = ({ title, handleChange, checked, parent }) => (
  <Box
    display="flex"
    justifyContent="left"
    alignItems="center"
    style={{ float: 'left', width: '50%' }}
  >
    <StyledCheckbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      style={{ paddingBottom: '4px' }}
      checkedIcon={parent ? <IndeterminateCheckBoxIcon /> : <CheckBoxIcon />}
    />
    <Typography variant="body1" gutterBottom fontWeight={400}>
      {title}
    </Typography>
  </Box>
);

export default React.memo(ResponsibilitiesItem);
