import React, { useState, useEffect, CSSProperties } from 'react';
import { TextField as MuiTextField, Select, MenuItem, Box, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components/macro';
import { makeStyles } from '@mui/styles';
import { spacing } from '@mui/system';

type TProps = {
  name: string;
  value: any;
  label: string;
  onChange: (valueSelect: any, inputValue: string) => void;
  error?: any;
  type?: string;
  multiline?: boolean;
  rows?: number;
  nameSelect: string;
  data: Array<any>;
};

const CustomPhoneInput: React.FC<TProps> = ({
  name,
  value,
  label,
  onChange,
  error,
  type = 'text',
  multiline = false,
  rows = 5,
  nameSelect,
  data,
}) => {
  const [selectValue, setSelectValue] = useState<any>('mobile_phone');
  const [inputValue, setInputValue] = useState<any>('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInputValue(value[selectValue]);
  }, [value, selectValue]);

  const CustomIcon = () => {
    const classes = useStyles();

    return (
      <ButtonArrow open={open} onClick={() => setOpen(!open)}>
        <KeyboardArrowDownIcon className={classes.iconStyle} />
      </ButtonArrow>
    );
  };

  return (
    <Box display="flex">
      <Select
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        name={nameSelect}
        value={selectValue}
        placeholder={'Mobile'}
        onChange={(val: any) => {
          onChange(val.target.name, inputValue);
          setSelectValue(val.target.value);
        }}
        error={error}
        IconComponent={CustomIcon}
        sx={stylesSelect}
      >
        {data.map((item: any) => (
          <MenuItem key={item.id} value={item.id} style={{ fontWeight: 400 }}>
            {item.title}
          </MenuItem>
        ))}
      </Select>

      <TextField
        fullWidth
        multiline={multiline}
        rows={multiline ? rows : undefined}
        name={name}
        label={label}
        value={inputValue || ''}
        onChange={e => {
          onChange(selectValue, e.target.value);
          setInputValue(e.target.value);
        }}
        error={error}
        InputLabelProps={{
          style: { fontWeight: 400 },
        }}
        InputProps={{
          style: { fontWeight: 400, borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderLeftWidth: 0 },
        }}
        type={type}
      />
    </Box>
  );
};

export default React.memo(CustomPhoneInput);

const TextField = styled(MuiTextField)<{ company?: string }>(spacing);

const stylesSelect: CSSProperties = {
  fontWeight: 400,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  borderRightWidth: 0,
  minWidth: 100,
};

const ButtonArrow = styled(Button)<{
  open?: boolean;
}>`
  position: absolute;
  top: 12px;
  right: 0;
  min-width: auto;
  border-radius: 50%;
  padding: 3px;
  margin-right: 7px;
  ${props => (props.open ? 'transform: rotate(180deg)' : '')};

  &:hover {
    background-color: #0000000a !important;
  }

  svg {
    fill: #19a0fb;
  }
`;

const useStyles = makeStyles({
  iconButtonStyle: {
    position: 'absolute',
    top: '15px',
    right: 0,
    minWidth: 'auto',
    borderRadius: '50%',
    padding: '2px',
    marginRight: '7px',

    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  iconStyle: {},
});
