import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { commonConfig } from '../../config';
import ProfileDetails from '../CandidateProfile/Dashboard/ProfileDetails/ProfileDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setCandidate } from '../../redux/reducers/candidate';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import LogoClarity from '../../images/logo/LogoNewClarity.svg';
import { RootState } from '../../redux/store';
import styles from './Table.module.scss';
import { SubmissionItemType } from '../../types/candidate';

type TProps = {
  componentRef?: any;
};

const CandidatePDF: React.FC<TProps> = ({ componentRef }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const id = params.get('id');
  const submissionId = params.get('submissionId') || '';
  const filtersString = params.get('filters');
  const filters = filtersString?.split(',');
  const { candidate } = useSelector((state: RootState) => state.candidate);
  const showCandidateName = filters?.includes('candidateName');

  const currentSubmission = candidate?.submissions.find((item: SubmissionItemType) => item.id === +submissionId);

  const getCandidate = useCallback(async () => {
    const axiosInstance = axios.create({
      baseURL: `${commonConfig.serverHost}/api/v1`,
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = await axiosInstance.get(`/candidates/${id}`);
    dispatch(setCandidate(data));
  }, [token, id, dispatch]);

  useEffect(() => {
    if (token && id) {
      getCandidate();
    }
  }, [token, id, getCandidate]);

  return (
    <div ref={componentRef}>
      <table ref={componentRef} className={styles.pdf}>
        <thead>
          <tr>
            <td style={{ height: '30px' }} />
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <Box
                sx={{
                  margin: '0 20',
                  border: '1px solid rgba(0,0,0,0.12)',
                  borderRadius: '6px',
                }}
              >
                <Header
                  style={{
                    paddingBottom: '1rem',
                    borderRadius: '6px',
                    // maxWidth: '2048px',
                  }}
                >
                  <HeaderInner>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={TitleProfileStyles}>
                        CANDIDATE PROFILE
                        {!!submissionId && !!currentSubmission
                          ? ` FOR ${currentSubmission.job_title} AT ${currentSubmission.company_name}`
                          : ''}
                      </Box>
                      {showCandidateName && (
                        <div style={{ fontWeight: 600, fontSize: '28px', lineHeight: '42px' }}>
                          {candidate?.first_name} {candidate?.last_name}
                        </div>
                      )}
                    </div>

                    <Box component="img" sx={{ height: 50 }} alt={'logo clarity'} src={LogoClarity} />
                  </HeaderInner>
                </Header>

                <Box
                  sx={{
                    padding: '0',
                    marginBottom: 0,
                    border: 'none',
                    borderCollapse: 'unset',
                  }}
                >
                  <ProfileDetails toPdf={true} candidateId={id || ''} filters={filters} />
                </Box>
              </Box>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td style={{ height: '120px' }} />
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default CandidatePDF;

const Header = styled('div')`
  display: flex;
  background: #fff;
  padding: 10px 20px 0;
  height: 90px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #002855;
`;

const HeaderInner = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleProfileStyles = {
  fontWeight: 600, 
  fontSize: '16px', 
  lineHeight: '22px', 
  textTransform: 'uppercase',
};
