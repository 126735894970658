import { CandidateType } from '../types/candidate';

class Step1ValuesModel {
  first_name: string;
  last_name: string;
  email: string;
  phone: { mobile_phone: string | null; landline_phone: string | null };
  country_id?: number;
  city: string;
  province_id?: number;
  years_of_experience?: number;
  designations: Array<any>;
  about: string;

  constructor(data: CandidateType | null) {
    this.first_name = data?.user?.first_name || '';
    this.last_name = data?.user?.last_name || '';
    this.phone = {
      mobile_phone: data?.user?.mobile_phone || null,
      landline_phone: data?.user?.landline_phone || null,
    };
    this.email = data?.user?.email || '';
    this.country_id = data?.user?.country_id || undefined;
    this.city = data?.user?.city || '';
    this.province_id = data?.user?.province_id || undefined;
    this.years_of_experience = data?.years_of_experience || undefined;
    this.designations = data?.designations || [];
    this.about = data?.about || '';
  }
}

export default Step1ValuesModel;
