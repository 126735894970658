import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  internalNotes: '',
};

const savedDataSlice = createSlice({
  name: 'savedData',
  initialState,
  reducers: {
    setSavedData(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    resetSavedData() {
      return {};
    },
  },
  extraReducers: {},
});

export const { setSavedData, resetSavedData } = savedDataSlice.actions;

export default savedDataSlice.reducer;
