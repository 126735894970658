import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Card } from '@mui/material';

import Environment from './EnvironmentNew';
import circleArrow from '../../../../images/icons/circleArrow.svg';
import CustomButton from '../../../CustomButton';

import { initRoleCandidate } from '../../../../utils/staticValues';
import RoleValuesModel from '../../../../models/roleValues.model';
import { getDictionaries } from '../../../../redux/actions/dictionaries';
import {
  deleteCandidateWorkHistory,
  setCandidateWorkHistoryArr,
} from '../../../../redux/reducers/candidate';
import { saveRoleCandidate } from '../../../../redux/actions/candidate';
import { RootState } from '../../../../redux/store';
import { PROFILE_PATH } from '../../../../constants/constants';

import { Accordion, AccordionDetails, AccordionSummary } from '../../../../styles/AccordionStyles';
import { styleCardSection, styleAccordionArrow, styleAccordion } from '../../StylesCandidateProfile';
import Modal from '../../../Modal';
import ModalRoleDelete from '../../FormSections/Step2/ModalRoleDelete';

type TProps = {
  id: string;
  handleNext: (values: any) => void;
  submissionId: string;
  formik: any;
  values: any;
  setFieldValue: any;
  errors: any;
  handleSubmit: any;
  dataPrepareToSave: any;
  idUnknownValue: number;
};

const JobSpecificProfileWorkHistory: React.FC<TProps> = ({
  id,
  handleNext,
  submissionId,
  formik,
  values,
  handleSubmit,
  setFieldValue,
  errors,
  dataPrepareToSave,
  idUnknownValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isCandidate } = useSelector((state: RootState) => state.user);
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const [expandedPanel, setExpandedPanel] = useState(['panel0']);
  const [showModal, setShowModal] = useState(false);
  const [deletingHistoryId, setDeletingHistoryId] = useState(0);
  const [deletingHistoryIndex, setDeletingHistoryIndex] = useState(-1);

  useEffect(() => {
    dispatch(getDictionaries('companyAndFunctions'));
  }, [dispatch]);

const [isAddRole, setIsAddRole] = useState(false);

useEffect(() => {
  if (candidate?.histories.length && isAddRole) {
    setTimeout(() => {
      const elem = document.querySelector(`#role-${!!candidate?.histories ? candidate?.histories.length - 1 : 1}`)!;
      const top = elem.getBoundingClientRect().top;
      window.scrollBy({
        top: top - 70,
        behavior: 'smooth',
      });
      setIsAddRole(false);
    }, 500);
  }
}, [candidate?.histories, candidate?.histories.length, isAddRole]);

  const handleAccordionChange = (panel: any) => (event: any, isExpanded: any) => {
    if (isExpanded) {
      setExpandedPanel([panel]);

      const panelElement = document.getElementById(panel);
      window.scrollTo(0, panelElement!.offsetTop - 560);
    } else {
      setExpandedPanel(expandedPanel.filter(el => el !== panel));
      const panelElement = document.getElementById(panel);
      window.scrollTo(0, panelElement!.offsetTop - 100);
    }
  };

  const setPanels = useCallback(
    (panel: string) => {
      if (!expandedPanel.includes(panel)) {
        setExpandedPanel([panel]);
      }
    },
    [expandedPanel],
  );

  const onAddAnotherRole = useCallback(
    (values: any, errors?: boolean, handleSubmit?: any) => {
      if (!errors) {
        setPanels(`panel${!!candidate?.histories.length ? candidate?.histories.length : 1}`); 
        const data = dataPrepareToSave(values);
        dispatch(
          setCandidateWorkHistoryArr({
            data: !!data.histories.length ? [...data.histories, initRoleCandidate] : [initRoleCandidate],
          }),
        );
        setIsAddRole(true);       
      } else {
        handleSubmit();
      }
    },
    [dataPrepareToSave, dispatch, setPanels, candidate?.histories],
  );

  const onDone = useCallback(
    (values: any, errors?: any, handleSubmit?: any) => {
      if (!errors || !errors.length) {
        const data = dataPrepareToSave(values);
        dispatch(saveRoleCandidate({ id: +id, values: data }));
        navigate(`/${PROFILE_PATH}/profile-details?candidate_id=${id}&submissionId=${submissionId}`);
      } else {
        handleSubmit();
      }
    },
    [dataPrepareToSave, dispatch, id, navigate, submissionId],
  );

  const onDelete = () => {
    if (!!candidate?.histories) {
      if (candidate?.histories.length <= 1) {
        dispatch(setCandidateWorkHistoryArr({ data: [] }));
        setDeletingHistoryId(0);
        setDeletingHistoryIndex(-1);
      } else {
        dispatch(deleteCandidateWorkHistory({ id: deletingHistoryId, index: deletingHistoryIndex }));
      }
    }
    setPanels('panel0');
    setShowModal(false);
  };

  return (
    <Card sx={styleCardSection} className="styleCardSection">
      <Typography variant="h5" gutterBottom color="secondary" mb={5}>
        Work History
      </Typography>

      {values?.histories.map((role: RoleValuesModel, index: number) => (
        <Accordion
          key={`panel${index}`}
          id={`panel${index}`}
          expanded={expandedPanel.includes(`panel${index}`)}
          sx={styleAccordion}
          onChange={handleAccordionChange(`panel${index}`)}
        >
          <AccordionSummary expandIcon={<Box component="img" sx={styleAccordionArrow} src={circleArrow} />}>
            <Box sx={stylesEnvironment} id={`role-${index + 1}`}>
              <Typography component="div" variant="h6" color="secondary">
                {`Role #${index + 1}`}
              </Typography>

              {!expandedPanel.includes(`panel${index}`) && (
                <Box display="flex" alignItems="center">
                  {!!role.title && (
                    <Typography component="div" variant="body1" mr={1} color="primary">
                      {role.title}
                    </Typography>
                  )}

                  {!!role.company && (
                    <Typography component="div" variant="body2" mr={2} color="secondary">
                      {role.company}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Environment
              formik={formik}
              role={role}
              name={`histories[${index}]`}
              index={index}
              idUnknownValue={idUnknownValue}
              isJobSpecific={!!submissionId}
              onDelete={({ id, index }: { id: number | undefined; index: number }) => {
                setShowModal(true);
                !!id && setDeletingHistoryId(id);
                setDeletingHistoryIndex(index);
              }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      {!!submissionId && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <ModalRoleDelete onConfirm={onDelete} />
        </Modal>
      )}
      <Box display="flex" gap={'15px'} justifyContent="right" alignItems="center" p={5} pr={0}>
        {!isCandidate && (
          <>
            <CustomButton
              type="button"
              variant="text"
              onClick={() =>
                navigate(`/${PROFILE_PATH}?candidate_id=${id}`)
              }
            >
              Return to dashboard
            </CustomButton>
            <CustomButton type="button" variant="text" onClick={() => onDone(values, errors.histories, handleSubmit)}>
              View Report
            </CustomButton>
          </>
        )}
        <CustomButton
          type="button"
          variant="outlined"
          onClick={() => onAddAnotherRole(values, errors.histories, handleSubmit)}
        >
          Add another role
        </CustomButton>
        <CustomButton variant="contained" type="submit" onClick={handleSubmit}>
          Next
        </CustomButton>
      </Box>
    </Card>
  );
};

export default React.memo(JobSpecificProfileWorkHistory);

const stylesEnvironment = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};
