import { SxProps } from '@mui/system';
import { grey } from '@mui/material/colors';

export const styleCard: SxProps = {
  borderRadius: '5px',
  boxShadow: '0 0 5px rgba(32, 39, 101, 0.05)',
  mb: '15px',
};

export const styleAccordionArrow: SxProps = {
  height: 24,
  width: 24,
  margin: 0,
};

export const styleSwitchTitle: SxProps = {
  border: 1,
  borderRadius: '2px',
  borderColor: grey[200],
  marginTop: '10px',
  width: '100%',
  paddingLeft: '10px',
};

export const fileUploader: SxProps = {
  backgroundColor: '#F5F8FA',
  width: '100%',
  height: 90,
  padding: '15px',
  marginTop: 5,

  label: {
    width: '100%',
    border: 'none',
    position: 'relative',
  },

  '.drag-file-element': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

export const styleAccordion = {
  borderRadius: '2px !important',
  paddingLeft: 0,
  paddingRight: 0,
};

export const styleCardSection: SxProps = {
  background: '#f5f8fa',
  padding: '20px 40px 20px 15px',
  boxShadow: 'none',
  border: 'none',
};

export const styleCardComment: SxProps = {
  background: '#f5f8fa',
  padding: '0 40px 20px 15px',
  boxShadow: 'none',
  border: 'none',
};

export const styleCardStep3: SxProps = {
  borderRadius: '5px',
  boxShadow: '0 0 5px rgba(32, 39, 101, 0.05)',
  mb: '15px',
  padding: '20px',
  overflow: 'visible !important',
};

export const styleFinishCard: SxProps = {
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  background: '#FFFFFF',
  boxShadow: '0 0 5px rgba(32, 39, 101, 0.05)',
  borderRadius: '5px',
  padding: '90px',
};
