import { Card as MuiCard } from '@mui/material';
import { styled, spacing } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { RootState } from '../../../../redux/store';
import { setShowProfileDetails } from '../../../../redux/reducers/candidate';

import JobSpecificProfileDetails from './JobSpecificProfileDetails/JobSpecificProfileDetails';
import GeneralProfileDetails from './GeneralProfileDetails/GeneralProfileDetails';

import { PROFILE_PATH } from '../../../../constants/constants';
import Loader from '../../../Loader';

type TProps = {
  candidateId: string;
  toPdf: boolean;
  filters?: Array<string>;
};

const ProfileDetails: React.FC<TProps> = ({ candidateId, filters, toPdf }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const submissionId = params.get('submissionId');

  const { candidate, isLoading } = useSelector((state: RootState) => state.candidate);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const onBackButtonEvent = (e: any) => {
      e.preventDefault();
      dispatch(setShowProfileDetails(false));
      navigate(`/${PROFILE_PATH}?candidate_id=${candidateId}`);
    };

    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', onBackButtonEvent);
      }, 0);
    };
  }, [candidateId, dispatch, navigate]);

  const showCandidateName = filters?.includes('candidateName');
  const showCompanyName = filters?.includes('companyName');
  const showTargetSalary = filters?.includes('targetSalary');
  const showTargetRate = filters?.includes('targetRate');
  const showMotivators = filters?.includes('motivators');
  const showCompanyCulture = filters?.includes('companyCulture');
  const showDesiredCulture = filters?.includes('desiredCulture');
  const showAvailability = filters?.includes('availability');

  return (<>
    {isLoading && <Loader />}
    <Card sx={[stylesWrapper, 
    // { marginBottom: toPdf ? '120px' : 0 }
    ]}>
      {!!submissionId ? (
        <JobSpecificProfileDetails
          candidateId={candidateId}
          submissionId={submissionId}
          ocp_answers={candidate?.ocp_answers || null}
          toPdf={toPdf}
          PdfFilters={{
            showCandidateName,
            showCompanyName,
            showTargetSalary,
            showDesiredCulture,
            showAvailability,
          }}
        />
      ) : (
        <GeneralProfileDetails
          ocp_answers={candidate?.ocp_answers}
          toPdf={toPdf}
          PdfFilters={{
            showCompanyName,
            showCandidateName,
            showTargetSalary,
            showTargetRate,
            showMotivators,
            showCompanyCulture,
          }}
        />
      )}
    </Card>
    </>
  );
};

const Card = styled(MuiCard)(spacing);

const stylesWrapper = {
  // margin: 'auto',
  padding: '15px 30px',
  borderColor: 'transparent',
  width: '100%',
  // maxWidth: '2480px',
  overflow: 'visible',
};

export default ProfileDetails;
