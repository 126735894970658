import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import RolesAdapter from '../../../models/adapters/roles.adapter';
import { getDictionaries } from '../../../redux/actions/dictionaries';
import { rolesSelector } from '../../../redux/selectors/candidate.selectors';
import { RootState } from '../../../redux/store';
import { ALERT_MESSAGES } from '../../../constants/constants';

import Modal from '../../Modal';
import ModalRoleDelete from '../FormSections/Step2/ModalRoleDelete';
import GeneralProfileWorkHistory from '../FormSections/Step2/GeneralProfileWorkHistory';
import JobSpecificProfileWorkHistory from '../FormSections/Step2/JobSpecificProfileWorkHistory';
import { RoleType } from '../../../types/candidate';

type TProps = {
  id: string;
  submissionId?: string | null;
  handleNext: (values: any) => void;
};

const Step2: React.FC<TProps> = ({ id, submissionId, handleNext }) => {
  const dispatch = useDispatch();

  const roles = useSelector(rolesSelector);

  const [showModal, setShowModal] = useState(false);
  const [sortedRolesArray, setSortedRolesArray] = useState([]);
  const [isError, setIsError] = useState(false);

  const idUnknownValue = 99999;

  useEffect(() => {
    dispatch(getDictionaries('companyAndFunctions'));
  }, [dispatch]);

  useEffect(() => {
    if (!!roles.length) {
      const sortedArr = JSON.parse(JSON.stringify(roles));
      sortedArr.sort((a: RoleType, b: RoleType) => {
        const firstDate = a.start_year ? new Date(a.start_date).getTime() : new Date().getTime();
        const secondDate = b.start_year ? new Date(b.start_date).getTime() : new Date().getTime();
        return firstDate - secondDate;
      });
      setSortedRolesArray(sortedArr);
    }
  }, [roles]);

  useEffect(() => {
    if (isError) {
      setTimeout(()=>{
        const errorTextEl = document.querySelector('[data-label="errorText"]');
        !!errorTextEl && errorTextEl.scrollIntoView({block: 'center'}); 
      }, 100);
    }
  }, [isError]);

  const dataPrepareToSave = (values: any) => {
    const valuesSave = RolesAdapter.mapRolesValuesModelToRolesData(values?.histories);
    const data = {
      histories: valuesSave.histories.map((item, index) => {
        item.employee_id = item.employee_id !== idUnknownValue ? item.employee_id : null;
        item.ownership_id = item.ownership_id !== idUnknownValue ? item.ownership_id : null;
        item.revenue_id = item.revenue_id !== idUnknownValue ? item.revenue_id : null;
        item.end_date = !item.is_current ? item.end_date : null;
        return item;
      }),
    };
    return data;
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Formik
      initialValues={{
        histories: sortedRolesArray,
        submit: false,
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        histories: Yup.array().of(
          Yup.object().shape({
            company: Yup.string().max(60, 'Company must be at most 60 characters').required('Company is required'),
            title: Yup.string().max(60, 'Job title must be at most 60 characters').required('Job title is required'),
            start_date: Yup.string().nullable().required('This field is required'),
          }),
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = dataPrepareToSave(values);

        try {
          await handleNext(data);
        } catch (error: any) {
          const message = error.message || ALERT_MESSAGES.DEFAULT_ERROR;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {formik => {
        const { values, handleSubmit, setFieldValue, errors } = formik;
        !!Object.keys(errors)?.filter(Boolean).length && setIsError(true);
        setTimeout(()=>setIsError(false), 100);

        return (
          <form onSubmit={handleSubmit}>
            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
            {!submissionId ? (
              <GeneralProfileWorkHistory
                id={id}
                handleNext={handleNext}
                formik={formik}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                handleSubmit={handleSubmit}
                dataPrepareToSave={dataPrepareToSave}
                idUnknownValue={idUnknownValue}
              />
            ) : (
              <JobSpecificProfileWorkHistory
                submissionId={submissionId}
                id={id}
                handleNext={handleNext}
                formik={formik}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                handleSubmit={handleSubmit}
                dataPrepareToSave={dataPrepareToSave}
                idUnknownValue={idUnknownValue}
              />
            )}

            {!!submissionId && (
              <Modal open={showModal} onClose={onClose}>
                <ModalRoleDelete onConfirm={onClose} />
              </Modal>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(Step2);
