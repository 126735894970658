import styled from 'styled-components/macro';
import { Box } from '@mui/material';

export const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const BoxSpaceBetween = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
