export const setSubItemStep2 = ({
  arrayItems,
  checkItems,
  defaultItem,
}: {
  arrayItems: Array<any>;
  checkItems: Array<any>;
  defaultItem: any;
}) => {
  return checkItems.includes(defaultItem.id) && !!arrayItems?.find((el: any) => el.id === defaultItem.id)
    ? arrayItems.find((el: any) => el.id === defaultItem.id)
    : defaultItem.is_custom
    ? undefined
    : defaultItem;
};

export const objToId = (array: Array<any> = []): Array<number> | undefined =>
  array?.length ? array.map((el: any) => (typeof el === 'object' ? el.id : el)) : undefined;

export const setEmploymentTypes = ({
  employmentTypes_fullTime,
  employmentTypes_contract,
}: {
  employmentTypes_fullTime: boolean;
  employmentTypes_contract: boolean;
}) => {
  let employmentTypes: Array<number> = [];

  employmentTypes_fullTime && employmentTypes.push(1);
  employmentTypes_contract && employmentTypes.push(2);

  return employmentTypes;
};

export const onCheckEmptyDate = (date: string) => (!!date && !!date.trim() ? date : null);
