import {Box, Grid, Alert} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import JobCardFullDetails from '../../components/jobDetail/JobCardFullDetails';
import ApplyNowModal from '../../components/jobDetail/ApplyNowModal';
import ApplicationSent from '../../components/jobDetail/ApplicationSent';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {JobsType} from '../../types/jobs';
import Loader from '../../components/Loader';
import {showApplicationSent, setErrorAlert} from '../../redux/reducers/candidate';
import BackButtonMobile from '../../components/BackButtonMobile';
import {getJob} from "../../redux/actions/jobs";
import EmptySearch from "../../components/jobDetail/EmptySearch";
import {postRemoveMessage} from "../../utils/functions";
import JobPostingJsonLd from "../../components/jobDetail/JobPostingJsonLd";

const JobCard = () => {
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const jobId = Number(params.get('job-id'));
  const utm_source = params.get('utm_source') !== null ? params.get('utm_source') : '';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {searchJobResult, isSearching, searchOneJobResult} = useSelector((state: RootState) => state.jobs);
  const {candidateApplied, isApplying, applyingError} = useSelector((state: RootState) => state.candidate);
  const [showCard, setShowCard] = useState<any>({});
  const [showApplyNowModal, setShowApplyNowModal] = useState(false);

  const handleShowApplyNowModal = (show: boolean) => {
    setShowApplyNowModal(show);
  };

  /**
   * This method posts messages to the parent window in order for the parent to be able to read these messages and use them
   * for updating the URLs. This is useful on the web pages where the site is used in iFrame
   */
  useEffect(() => {
    window.parent.postMessage({jobID: jobId, type: 'add'}, '*');
  }, [dispatch, jobId]);

  useEffect(() => {
    if (searchJobResult?.jobs?.length) {
      const job = searchJobResult.jobs.filter((job: JobsType) => job.id === jobId)[0];
      setShowCard(job);
    } else {
      dispatch(getJob(jobId));
    }
  }, [searchJobResult, dispatch]);

  useEffect(() => {
    if (!searchJobResult?.jobs?.length && searchOneJobResult) {
      setShowCard(searchOneJobResult?.jobs[0]);
    }
  }, [searchOneJobResult, dispatch]);

  return (
    <>
      {(isSearching || isApplying) && <Loader/>}
      {!isSearching && !isApplying && !showCard?.id ? (
        <Grid item xs={12}>
          <EmptySearch/>
        </Grid>
      ) : (
        <div>
          <BackButtonMobile title={'All Jobs'}/>
          <Box display={'flex'} justifyContent={'center'}>
            <Box width={'100%'} p={window.innerWidth > 768 ? '20px' : '10px'}>
              <ApplyNowModal
                showApplyNowModal={showApplyNowModal}
                setShowApplyNowModal={handleShowApplyNowModal}
                jobId={showCard?.id}
                utm_source={utm_source}
              />
              <Grid container spacing={{xs: '10px', md: '15px'}}>
                {(
                  <Grid item xs={12} md={12}>
                    {candidateApplied ? (
                      <ApplicationSent
                        onClick={() => {
                          postRemoveMessage();
                          navigate('/');
                          dispatch(showApplicationSent(false));
                        }}
                      />
                    ) : (
                      <>
                        {applyingError && (
                          <Alert severity="error" onClose={() => dispatch(setErrorAlert(''))}>
                            {applyingError}
                          </Alert>
                        )}
                        <Box>
                          <JobCardFullDetails item={showCard} showApplyNowModal={handleShowApplyNowModal}/>
                        </Box>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
          {showCard && showCard.id && <JobPostingJsonLd job={showCard}/>}
        </div>
      )}
    </>
  );
};

export default JobCard;
