import React, { ChangeEvent, useState } from 'react';
import { FormControl, InputLabel, ListSubheader, Select, Button, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components/macro';
import { makeStyles } from '@mui/styles';

import ErrorText from './ErrorText';
import CustomInput from './CustomInput';

type TProps = {
  labelId: string;
  name: string;
  value: any;
  label: string;
  data: Array<any>;
  onChange: (e: string | ChangeEvent<any> | any) => void;
  onDelete?: (e: any) => void;
  error?: any;
  errorText?: any;
  group?: boolean;
  disabled?: boolean;
};

const CustomSelectSalary: React.FC<TProps> = ({ data = [], ...props }) => {
  const [open, setOpen] = useState(false);

  const CustomIcon = () => {
    const classes = useStyles();
    return (
      <ButtonArrow open={open} onClick={() => setOpen(!open)} sx={{ top: '12px' }}>
        <KeyboardArrowDownIcon className={classes.iconStyle} />
      </ButtonArrow>
    );
  };

  return (
    <Root disabled={props.disabled}>
      <FormControl fullWidth>
        <InputLabel id={props.labelId} sx={stylesInputLabel}>
          {props.label}
        </InputLabel>

        <Select
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          labelId={props.labelId}
          name={props.name}
          value={[1]}
          multiple
          label={props.label}
          error={props.error}
          IconComponent={CustomIcon}
          disabled={props.disabled}
          style={{ fontWeight: '400' }}
          renderValue={() =>
            `${props.value.min || (props.value.max ? 0 : '')}${props.value.max ? `-${props.value.max}` : ''}`
          }
        >
          <ListSubheader>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CustomInput
                  name={`${props.name}_min`}
                  label="From"
                  value={props.value.min}
                  onChange={props.onChange}
                  type="number"
                />
              </Grid>

              <Grid item xs={6}>
                <CustomInput
                  name={`${props.name}_max`}
                  label="To"
                  value={props.value.max}
                  onChange={props.onChange}
                  type="number"
                />
              </Grid>
            </Grid>
          </ListSubheader>
        </Select>

        <ErrorText error={props.errorText} />
      </FormControl>
    </Root>
  );
};

export default CustomSelectSalary;

const Root = styled('div')<{
  disabled?: boolean;
}>`
  justify-content: center;
  align-items: center;
  display: flex;

  img {
    ${props => (props.disabled ? 'display: none' : '')};
  }
`;

const ButtonArrow = styled(Button)<{
  open?: boolean;
}>`
  position: absolute;
  right: 0;
  min-width: auto;
  border-radius: 50%;
  padding: 3px;
  margin-right: 7px;
  ${props => (props.open ? 'transform: rotate(180deg)' : '')};

  &:hover {
    background-color: transparent !important;
  }

  svg {
    fill: #19a0fb;
  }
`;

const useStyles = makeStyles({
  iconButtonStyle: {
    position: 'absolute',
    top: '15px',
    right: 0,
    minWidth: 'auto',
    borderRadius: '50%',
    padding: '2px',
    marginRight: '7px',

    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  iconStyle: {},
});

const stylesInputLabel = {
  fontWeight: 400,
  color: '#5E5E5E',
  transform: 'translate(14px, 16px) scale(1)',

  '&.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.9)',
  },
};
