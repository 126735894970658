import React, { useEffect } from 'react';

import { Table, THead, TH, TR } from '../FormSections/StylesTable';

const AppliedJobsTable: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <Table>
      <THead>
        <TR>
          <TH width="20%">Role</TH>
          <TH width="40%">Location</TH>
          <TH width="10%">Type</TH>
          <TH width="10%">Date</TH>
          <TH width="10%">Status</TH>
          <TH width="10%"></TH>
        </TR>
      </THead>
    </Table>
  );
};

export default AppliedJobsTable;
