import React from 'react';
import { Typography, Box, SxProps } from '@mui/material';
import { styled } from '@mui/system';

import { SubmissionCategoriesType } from '../../../../../types/candidate';
import ListItemWithExtends from './ListItemWithExtends';

type TProps = {
  submissionCategories: Array<SubmissionCategoriesType> | undefined;
  toPdf: boolean;
};

const AdditionalDetails: React.FC<TProps> = ({ submissionCategories, toPdf = true }) => {
  const CreateCommentsList = (submissionCategories: any) => {
    return submissionCategories
      .map((el: any, index: number) => {
        if (!!el.comment && el.comment !== '<p><br></p>') {
          return (
            <ListItemWithExtends
              key={index}
              title={el.title}
              comment={el.comment}
              toPdf={toPdf}
              numberOfCharacters={350}
            />
          );
        }
        return null;
      })
      .filter(Boolean);
  };

  return (
    <Box sx={stylesWrapper}>
      <Typography sx={stylesSectionTitle} color="secondary">
        Additional Details
      </Typography>
      {submissionCategories && <List>{CreateCommentsList(submissionCategories)}</List>}
    </Box>
  );
};

const stylesWrapper: SxProps = {
  padding: '15px 15px 20px',
  backgroundColor: '#F8F8F8',
  borderRadius: '5px',
};

const stylesSectionTitle: SxProps = {
  marginBottom: '10px',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: 1.2,
};

const List = styled('ul')`
  padding: 0 0 0 20px;
  li {
    text-align: justify;
    font-weight: 600;
    font-size: 16px; 
    color: #191919;
    :not(:last-child) {
      padding-bottom: 5px;
    }
    p {
      margin: 2px;
      display: inline;
      font-size: 16px; 
    }
    span {
      font-weight: 400;
      color: #4f4f4f;
      font-size: 16px; 
    }
  }
`;

export default AdditionalDetails;
