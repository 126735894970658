import React from 'react';
import { Box, Card as MuiCard, CardContent, Grid, Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';

import SignUpComponent from '../../components/Auth/SignUp';
import { ReactComponent as Logo } from '../../images/logo/LogoClarity.svg';
import { ReactComponent as SignUpImage } from '../../images/SignUpImage.svg';

import { HeaderText } from '../../styles/TextStyles';

function SignUp() {
  return (
    <Wrap>
      <Helmet title="Create a Profile" />

      <Grid
        item
        flexDirection="column"
        justifyContent="space-between"
        sx={{ display: { md: 'flex', xs: 'none' }, height: '100%' }}
        pl={10}
      >
        <Logo />
        <Brand width="90%" />
      </Grid>

      <Grid item>
        <WrapperCard>
          <Card>
            <CardContent>
              <HeaderText style={{ textAlign: 'center' }}>Create a Profile</HeaderText>

              <Box sx={{ padding: '15px 38px' }}>
                <SignUpComponent />
              </Box>
            </CardContent>
          </Card>
        </WrapperCard>
      </Grid>
    </Wrap>
  );
}

export default SignUp;

const Card = styled(MuiCard)(spacing);

const Wrap = styled(Grid)`
  display: flex;
  background-color: #f5f8fa;
  align-items: center;
`;

const Brand = styled(SignUpImage)`
  height: 100%;
`;

const WrapperCard = styled(Paper)`
  justify-content: center;
  background-color: #f5f8fa;
  width: 580px;
`;
