import { ROUTES, ROUTES_SPECIFIC } from '../constants/constants';

export const getNameRoute = (step: number, isCandidate: boolean) =>
  ({
    0: ROUTES.GENERAL,
    1: ROUTES.WORK_HISTORY,
    2: ROUTES.WHAT_ARE_YOU_LOOKING_FOR,
    3: ROUTES.WORKPLACE_CULTURE,
    4: ROUTES.COMPLETE,
  }[step]);

export const getNameRouteSpecific = (step: number) =>
  ({
    0: ROUTES_SPECIFIC.GENERAL,
    1: ROUTES_SPECIFIC.WORK_HISTORY,
    2: ROUTES_SPECIFIC.RELEVANT_EXPERIENCE,
    3: ROUTES_SPECIFIC.PREFERENCES,
    4: ROUTES_SPECIFIC.DESIRED_COMPANY_CULTURE,
  }[step]);

export const getNameRouteSpecificShort = (step: number) =>
  ({
    0: ROUTES_SPECIFIC.GENERAL,
    1: ROUTES_SPECIFIC.WORK_HISTORY,
    2: ROUTES_SPECIFIC.RELEVANT_EXPERIENCE,
    3: ROUTES_SPECIFIC.PREFERENCES,
  }[step]);

export const getCurrentStep = (url: string) =>
  ({
    [ROUTES.GENERAL]: 0,
    [ROUTES.WORK_HISTORY]: 1,
    [ROUTES.WHAT_ARE_YOU_LOOKING_FOR]: 2,
    [ROUTES.WORKPLACE_CULTURE]: 3,
    [ROUTES.COMPLETE]: 4,
  }[url]);

export const getCurrentStepSpecific = (url: string) =>
  ({
    [ROUTES_SPECIFIC.GENERAL]: 0,
    [ROUTES_SPECIFIC.WORK_HISTORY]: 1,
    [ROUTES_SPECIFIC.RELEVANT_EXPERIENCE]: 2,
    [ROUTES_SPECIFIC.PREFERENCES]: 3,
    [ROUTES_SPECIFIC.DESIRED_COMPANY_CULTURE]: 4,
  }[url]);

