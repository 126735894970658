import * as React from 'react';
import styled from 'styled-components/macro';

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
} from '@mui/material';
import Logo from '../images/logo/LogoBlack.svg';

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: string;
  href?: string;
}

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} container item xs={12} md={5}>
          <List>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Support" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Privacy" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Terms of Service" />
            </ListItemButton>
          </List>
        </Grid>

        <Grid item xs={6} md={2} sx={{ justifyContent: { xs: 'flex-start', md: 'center' } }}>
          <List>
            <ListItemButton>
              <ListItemText primary={`© ${new Date().getFullYear()} - Clarity Recruiment`} />
            </ListItemButton>
          </List>
        </Grid>

        <Grid item display="flex" justifyContent="flex-end" alignItems="center" xs={6} md={5}>
          <span style={{ color: '#9e9e9e' }}>Powered by</span>
          <img src={Logo} alt="logo" loading="lazy" width={100} height={35} />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(0.25)} ${props => props.theme.spacing(4)};
  background: ${props => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${props => props.theme.footer.color};
  }
`;
