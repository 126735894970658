import { Box, Typography } from '@mui/material';
import { ShadowContainer } from '../../styles/components/CategoryFullDetailsStyles';
import { ContainedButton } from '../../styles/ButtonStyles';
import NoResults from '../../images/NoResults.svg';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {searchJob} from "../../redux/actions/jobs";

const EmptySearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ShadowContainer container justifyContent={'center'} alignContent={'center'} height={'400px'}>
      <Box textAlign={'center'}>
        <img src={NoResults} alt={''} />
        <Typography variant={'h2'} mb={'5px'}>
          Opps!
        </Typography>
        <Typography variant={'body2'} width={'276px'} mb={'20px'}>
          We didn’t find any jobs matching your criteria.
        </Typography>
        <ContainedButton
          variant={'contained'}
          onClick={() => {
            window.parent.postMessage({type: 'remove'}, '*');
            dispatch(searchJob({}));
            navigate('/');
          }}
        >
          View all Jobs
        </ContainedButton>
      </Box>
    </ShadowContainer>
  );
};

export default EmptySearch;
