import {ALERT_MESSAGES} from '../constants/constants';
import {CandidateType, RoleType} from '../types/candidate';
import {JobsType} from '../types/jobs';
import {durations, employmentTypes} from "./staticValues";

export const getProgressValue = (step: number) => {
  switch (step) {
    case 1:
      return 25;
    case 2:
      return 50;
    case 3:
      return 75;
    case 4:
      return 100;
    default:
      return 0;
  }
};

export const errorHandler = (err: any, thunkAPI: any) => {
  let message = '';

  if (err.status >= 500) {
    message = ALERT_MESSAGES.DEFAULT_ERROR;
  } else if (err.status < 500 && err.status >= 400 && !!err.message) {
    message = err.message;
  } else if (err.success === false && !!err.message) {
    message = err.message;
  } else {
    message = err.message.original || err.message;
  }

  if (!err.message) {
    throw err;
  }

  return thunkAPI.rejectWithValue(message);
};

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const createURLWithoutParam = (param: string) => {
  const search = window.location.search;
  const pathname = window.location.pathname;
  const originHost = window.location.origin;

  const params = new URLSearchParams(search);
  params.delete(param);

  const newURL = originHost + pathname + '?' + params.toString();
  return newURL;
};

export const arrayTitleToString = ({arr, symbol}: { arr: Array<any> | undefined; symbol: string }) => {
  if (!arr?.length) {
    return '-';
  }
  const newArr = arr.map(({title}: { title: string }) => {
    return title;
  });
  return newArr.join(`${symbol} `);
};

export const experienceToString = ({startDate, endDate}: { startDate: string; endDate: string }) => {
  const startTime = startDate ? new Date(startDate).getTime() : new Date('0').getTime();
  const endTime = endDate ? new Date(endDate).getTime() : new Date().getTime();

  const monthsExperience = (endTime - startTime) / (1000 * 60 * 60 * 24 * (365 / 12));

  if (monthsExperience * (365 / 12) < 28) {
    return '< 1 month';
  } else if (monthsExperience * (365 / 12) < 30) {
    return '~ 1 month';
  }

  const roundMonthsExperience = Math.round(monthsExperience);

  const countYears = Math.floor(roundMonthsExperience / 12) > 0 ? Math.floor(roundMonthsExperience / 12) : 0;
  const countMonth = Math.floor(roundMonthsExperience % 12) ? Math.floor(roundMonthsExperience % 12) : 0;

  const newString = `${countYears > 0 ? `${countYears} years ` : ''} ${countMonth ? `${countMonth} months` : ''}`;

  return newString !== ' ' ? newString : 'No information';
};

export const calculateCountAllUniq = (submissionRequirements: any, weight: number) => {
  return submissionRequirements
    ?.filter((el: any) => el.requirement.importance_weight_id === weight)
    .map((el: any) => {
      let name;
      if (el.requirement.operator === 'OR') {
        name = el.requirement.requirement;
      } else {
        name = el.requirement.requirement + el.id;
      }
      return name;
    })
    .filter((el: any, index: any, self: any) => {
      return self.indexOf(el) === index;
    }).length;
};

export const calculateCountChecked = (submissionRequirements: any, weight: number, value: string) =>
  submissionRequirements
    ?.filter((el: any) => el.requirement.importance_weight_id === weight && el.value === value)
    .map((el: any) => {
      let name;
      if (el.requirement.operator === 'OR') {
        name = el.requirement.requirement;
      } else {
        name = el.requirement.requirement + el.id;
      }
      return name;
    })
    .filter((el: any, index: any, self: any) => {
      return self.indexOf(el) === index;
    }).length;

export const calculatingOverallFit = (submissionRequirements: any, weight: number = 3) => {
  const allCount = calculateCountAllUniq(submissionRequirements, 3);
  const countExceeds = calculateCountChecked(submissionRequirements, weight, 'Exceeds');
  const countMeets = calculateCountChecked(submissionRequirements, weight, 'Meets');
  const countSome = calculateCountChecked(submissionRequirements, weight, 'Some');

  const valuePercent = 100 / allCount;
  const result = (countExceeds + countMeets) * valuePercent + (countSome * valuePercent) / 2;
  return result > 0 ? result : 5;
};

export const getCurrentJobTitle = (candidate: CandidateType | null) => {
  if (!!candidate && !!candidate.histories?.length) {
    const sortedArr = JSON.parse(JSON.stringify(candidate.histories));
    sortedArr.sort((a: RoleType, b: RoleType) => {
      const firstDate = a.end_year ? new Date(a.end_date).getTime() : new Date().getTime();
      const secondDate = b.end_year ? new Date(b.end_date).getTime() : new Date().getTime();
      return secondDate - firstDate;
    });
    return sortedArr[0].title;
  }
  return '';
};

export const getFullLocation = (item: JobsType) => {
  return item?.city && item?.province
    ? `${item?.city}, ${item?.province?.title}`
    : item?.city
      ? `${item?.city}`
      : item?.province
        ? `${item?.province?.title}`
        : '';
};

export const postRemoveMessage = () => {
  window.parent.postMessage({type: 'remove'}, '*');
}

export const getSalary = (item: JobsType) => {
  if (item.employment_type === employmentTypes.CONTRACT) {
    if (item.published_rate) {
      if (item.display_compensation) {
        return `$${item?.published_rate?.toLocaleString('en-US', {maximumFractionDigits: 2})} ${showDuration(
          item?.duration_id,
        )}`;
      } else return null;
    }
  } else {
    if (item.salary_min || item.salary_max) {
      if (item.display_compensation) {
        return `$${item.salary_min?.toLocaleString('en-US', {maximumFractionDigits: 2})} 
              - ${item.salary_max?.toLocaleString('en-US', {maximumFractionDigits: 2})}`;
      } else return null;
    }
  }
};

export const showDuration = (duration_id: any) => {
  return durations.filter(i => i.id === duration_id).map(i => i.title);
};
