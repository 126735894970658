import styled from 'styled-components/macro';
import { Stepper } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export const WrapperSteps = styled('div')`
  width: 100%;
  max-width: 1080px;
  margin: auto;
  display: flex;
  padding-bottom: 15px;
`;

export const StyledStepper = styled(Stepper)<{ isCandidate?: string }>(({ theme, isCandidate }) => ({
  width: !!isCandidate ? '100%' : 'max-content',
  minWidth: !!isCandidate ? '1000px' : '600px',

  '& .MuiStepLabel-iconContainer': {
    text: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
    },
  },
}));

export const ColorConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    width: 30,
  },
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#2681DB',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#2681DB',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#9E9E9E',
    borderRadius: 1,
  },
}));
