import axios from '../utils/axios';
import {
  ApplyCandidateType,
  SaveCandidateDataType,
  InviteCandidateRequestType,
  ScorecardRespType,
} from '../types/candidate';

export const candidate = {
  getCandidate: async (id: number) => {
    const { data } = await axios.get(`/candidates/${id}`);
    return data;
  },
  saveCandidate: async ({ id, values }: SaveCandidateDataType) => {
    const { data } = await axios.put(`/candidates/${id}`, values);
    return data;
  },
  uploadResume: async (value: { file: string }) => {
    const { data } = await axios.post('/candidates/upload-resume', value);
    return data;
  },
  applyCandidate: async (values: ApplyCandidateType) => {
    const { data } = await axios.post('/candidates/apply', values);
    return data;
  },
  inviteCandidate: async (value: InviteCandidateRequestType) => {
    const { data } = await axios.post('/candidates/send-invite', value);
    return data;
  },
  getScorecard: async (id: string) => {
    const { data } = await axios.get(`candidates/generalScorecard/${id}`);
    return data;
  },
  saveScorecard: async ({ id, values }: { id: number; values: Array<ScorecardRespType> }) => {
    const { data } = await axios.post(`/candidates/generalScorecard/${id}`, values);
    return data;
  },
  approveCandidate: async (id: number) => {
    const { data } = await axios.put(`candidateRequirements/approve/${id}`);
    return data;
  },
};
