import { ItemScorecardType } from '../types/candidate';

class ScorecardItemValuesModel {
  id: number;
  candidate_id: number;
  requirement_title_id: number;
  requirement_id: number;
  requirement: string;
  experience: string;
  comment: string | null;
  value: string | null;
  is_approved: number;

  constructor(data: ItemScorecardType) {
    this.id = data.id;
    this.candidate_id = data.candidate_id;
    this.requirement_title_id = data.requirement_title_id;
    this.requirement_id = data.requirement_id;
    this.requirement = data.requirement_title ? data.requirement_title.requirement : '';
    this.experience = data.requirement_title ? data.requirement_title.experience : '';
    this.comment = data.comment;
    this.value = data.value;
    this.is_approved = data.is_approved;
  }
}

export default ScorecardItemValuesModel;
