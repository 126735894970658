import { Box, Typography } from '@mui/material';
import { ShadowContainer } from '../../styles/components/CategoryFullDetailsStyles';
import { ContainedButton } from '../../styles/ButtonStyles';
import Checked from '../../images/icons/Сhecked.svg';

interface Props {
  onClick?: () => void;
}

const ApplicationSent = (props: Props) => (
  <ShadowContainer container justifyContent={'center'} alignContent={'center'} height={'400px'}>
    <Box textAlign={'center'}>
      <img src={Checked} alt={''} />
      <Typography variant={'h2'} color={'#002855'}>
        Application Sent!
      </Typography>

      <Typography variant={'body1'} width={'276px'} mt={'5px'} mb={'20px'} color={'#002855'}>
        Thank you for applying.
      </Typography>

      <ContainedButton variant={'contained'} onClick={props.onClick}>
        View all Jobs
      </ContainedButton>
    </Box>
  </ShadowContainer>
);

export default ApplicationSent;
