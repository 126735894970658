import styled from 'styled-components/macro';
import { Button, Grid, Link, MenuItem } from '@mui/material';

export const WrapHeader = styled(Grid)`
  width: 100%;
  height: 124px;
`;

export const WrapUserMenu = styled.div`
  background: #002855;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 60px;
`;

export const WrapNavbar = styled.div`
  background: #fff;
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
`;

export const WrapMenu = styled.div`
  display: flex;
  height: 76px;
  align-items: center;
  justify-content: flex-end;
`;

export const MenuButton = styled(Button)({
  zIndex: 2,
  color: '#002855',
  textTransform: 'capitalize',
  fontSize: '17px',
  padding: '0 15px',
  '&:hover': {
    color: '#2681db',
    backgroundColor: 'transparent',
  },
});

export const MenuItemStyled = styled(MenuItem)({
  backgroundColor: '#fff',
});

export const MenuItemLink = styled(Link)({
  color: '#002855',
  fontFamily: 'Source Sans Pro',
  '&:hover': {
    color: '#2681db',
  },
});

export const WrapHeaderProfile = styled(Grid)`
  height: 75px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 80px;
  margin-bottom: 20px;

  h1, h2, h3 {
    text-align: center;
  }
`;
