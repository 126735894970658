import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';

import ToggleButtonGroupCheck from '../../../ToggleButtonGroupCheck';

import { RootState } from '../../../../redux/store';

type TProps = { formik: any };

const MotivatorsToMove: React.FC<TProps> = ({ formik: { values, setFieldValue } }) => {
  const { reasons } = useSelector((state: RootState) => state.dictionaries.dictionaries);

  return (
    <Box mb={5}>
      <ToggleButtonGroupCheck
        name="reasons"
        value={values.reasons}
        onChange={(event: any, value: any) => {
          setFieldValue('reasons', value);
        }}
        ariaLabel="reasons"
        data={reasons?.reasons}
      />
    </Box>
  );
};

export default memo(MotivatorsToMove);
