import React from 'react';
import LinkedinLogo from '../../images/linkedinLogo.svg';
import { OutlinedButton } from '../../styles/ButtonStyles';
interface Props {
  id: string | null;
}

const LinkedinLoginButton = (props: Props) => {
  return (
    <OutlinedButton onClick={() => {}} variant="outlined" style={{ marginTop: '15px' }}>
      Linkedin
      <span>
        <img
          src={LinkedinLogo}
          alt="linkedin-logo"
          loading="lazy"
          width={30}
          style={{ marginLeft: '10px', verticalAlign: 'middle' }}
        />
      </span>
    </OutlinedButton>
  );
};

export default LinkedinLoginButton;
