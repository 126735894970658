import { ScorecardRespType } from '../../../../types/candidate';
import React, { useState, useEffect } from 'react';
import { Box, Button, ClickAwayListener, Grid, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SxProps } from '@mui/system';
import { StyledCheckbox, StyledChip } from '../../../../styles/SelectStyles';
import styled from 'styled-components/macro';

type TProps = {
  data: ScorecardRespType;
  histories: any;
  onChangeValue: (values: ScorecardRespType) => void;
};

const FromWhere: React.FC<TProps> = ({ data, histories, onChangeValue }) => {
  const [open, setOpen] = useState(false);
  const [checkedCompanies, setCheckedCompanies] = useState<Array<any>>([]);

  const companies = histories?.map((item: any) => item.company);
  const uniqueCompanies = companies?.filter((val: any, index: any, self: boolean[]) => self.indexOf(val) === index);

  useEffect(() => {
    if (data?.from_where) {
      setCheckedCompanies(data?.from_where);
    }
  }, [data]);

  const addChecked = (e: any, item: any) => {
    if (checkedCompanies && item) {
      const index = checkedCompanies.indexOf(item);
      if (e.target.checked) {
        index === -1 ? setCheckedCompanies([...checkedCompanies, item]) : checkedCompanies.splice(index, 1);
      } else {
        index !== -1
          ? setCheckedCompanies(checkedCompanies.filter((i: string) => i !== item))
          : checkedCompanies.push(item);
      }
    }
  };

  const deleteFromWhere = (item: string) => {
    setCheckedCompanies(checkedCompanies.filter((i: string) => i !== item));
    onChangeValue({ ...data, from_where: checkedCompanies.filter((i: string) => i !== item) });
  };

  const onSaveFromWhere = () => {
    onChangeValue({ ...data, from_where: checkedCompanies });
    setOpen(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      {open && (
        <Box sx={styleFromWhereBox} onClick={e => e.stopPropagation()}>
          <Typography mt={0} mb={'5px'} fontSize={'16px'}>
            From where?
          </Typography>
          {uniqueCompanies.map((item: any) => {
            return (
              <Box key={item} display={'flex'}>
                <StyledCheckbox checked={checkedCompanies?.includes(item)} onClick={e => addChecked(e, item)} />
                {item}
              </Box>
            );
          })}
          <WrapButton>
            <Button type="button" variant={'text'} onClick={onSaveFromWhere} sx={{ fontSize: 30 }}>
              Save
            </Button>
          </WrapButton>
        </Box>
      )}
      <Box display={'flex'} alignItems={'center'}>
        <Box>
          {data?.from_where?.length ? (
            <Box sx={{ maxWidth: '100%' }}>
              {data?.from_where?.map((item: any, index: number) => {
                return (
                  <Box display={'inline-block'} mr={'3px'} mb={'5px'} key={index}>
                    <StyledChip variant="outlined" label={`${item}`} onDelete={() => deleteFromWhere(item)} />
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Typography fontSize={'12px'} color={'#9E9E9E'}>
              FROM WHERE?
            </Typography>
          )}
        </Box>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box pl={'7px'} height={'18px'}>
            <AddCircleOutlineIcon
              style={{ color: '#9E9E9E', width: '18px', height: '18px', cursor: 'pointer' }}
              onClick={() => setOpen(true)}
            />
          </Box>
        </ClickAwayListener>
      </Box>
    </div>
  );
};

export default FromWhere;

const styleFromWhereBox: SxProps = {
  width: 300,
  position: 'absolute',
  top: -20,
  right: 50,
  zIndex: 1,
  borderRadius: '5px',
  p: '10px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.14)',
};

const WrapButton = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;

  button {
    padding: 0 5px;
    font-size: 14px;
    min-width: 0;
    margin-left: 5px;
  }
`;
