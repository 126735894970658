import { Modal, Typography, Grid, FormControlLabel, Button, Box } from '@mui/material';
import { ModalContainer, FileInput, FileInputContainer } from '../../styles/components/CategoryFullDetailsStyles';
import * as Yup from 'yup';
import { TextFieldC } from '../../styles/InputStyles';
import { ContainedButton } from '../../styles/ButtonStyles';
import { Formik } from 'formik';
import React from 'react';
import { StyledCheckbox } from '../../styles/SelectStyles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { toBase64 } from '../../utils/functions';
import { useDispatch } from 'react-redux';
import { applyCandidate } from '../../redux/actions/candidate';
import CloseIcon from '@mui/icons-material/Close';
import { DEFAULT_UTM_SOURCE } from '../../constants/constants';

interface Props {
  showApplyNowModal: boolean;
  setShowApplyNowModal: (showModal: boolean) => void;
  jobId?: number | null;
  utm_source?: string | null;
}
type ApplyFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  resume: { name: string } | null;
  checkbox: boolean;
};

const ApplyNowModal = (props: Props) => {
  const dispatch = useDispatch();

  const initialValues: ApplyFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    resume: null,
    checkbox: false,
  };
  return (
    <Modal open={props.showApplyNowModal} onClose={() => props.setShowApplyNowModal(false)}>
      <ModalContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            resume: Yup.mixed().required('Resume is required').nullable(),
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
          })}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            try {
              props.setShowApplyNowModal(false);
              const data = {
                email: values.email,
                first_name: values.firstName,
                last_name: values.lastName,
                job_id: props.jobId ? props.jobId : null,
                is_subscribed: values.checkbox,
                utm_source: props.utm_source || DEFAULT_UTM_SOURCE,
              };

              toBase64(values.resume).then((res: any) => {
                dispatch(applyCandidate({ ...data, file: res.toString() }));
              });
            } catch (error: any) {
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container width={'100%'} rowSpacing={'10px'} columnSpacing={'15px'} margin={0}>
                  <CloseIcon
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                    onClick={() => props.setShowApplyNowModal(false)}
                  />
                  <Grid item xs={12}>
                    <Typography variant="h2" mb={'20px'}>
                      Apply now
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldC
                      type="text"
                      name="firstName"
                      label="First name"
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldC
                      type="text"
                      name="lastName"
                      label="Last name"
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldC
                      type="email"
                      name="email"
                      label="Email address"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FileInputContainer
                      container
                      style={{
                        border:
                          touched.resume && errors.resume && !values.resume?.name
                            ? '1px solid #DC2547'
                            : '1px solid #B7B8B9',
                      }}
                    >
                      <Typography
                        width={'70%'}
                        style={{ color: errors.resume && !values.resume?.name ? '#DC2547' : '#5E5E5E' }}
                      >
                        {values.resume ? values.resume?.name : 'Upload your resume'}
                      </Typography>
                      <Box width={'30%'}>
                        <label htmlFor="resume">
                          <FileInput
                            id="resume"
                            type="file"
                            accept={'.doc,.docx,.pdf,.txt'}
                            name={'resume'}
                            onChange={e => {
                              setFieldValue('resume', e.target.files && e.target.files[0] ? e.target.files[0] : null);
                            }}
                            onBlur={handleBlur}
                          />
                          <Button variant="text" component="span">
                            <>
                              Upload
                              <FileUploadIcon />
                            </>
                          </Button>
                        </label>
                      </Box>
                    </FileInputContainer>
                    {errors.resume && !values.resume?.name && (
                      <div style={{ color: '#DC2547', fontSize: '14px', margin: '3px 14px 0 14px' }}>
                        {errors.resume}
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} p={'5px'}>
                    <FormControlLabel
                      name={'checkbox'}
                      control={<StyledCheckbox name={'checkbox'} onChange={handleChange} />}
                      label="Yes, I’d like to receive new job alerts,
                    career advice and invitations to Clarity events (you can opt out at any time)"
                      sx={{ margin: 0, padding: '10px 0', span: { fontWeight: 400 } }}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign={window.innerWidth > 768 ? 'end' : 'center'}>
                    <ContainedButton variant={'contained'} type="submit" disabled={isSubmitting}>
                      Submit Application
                    </ContainedButton>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </ModalContainer>
    </Modal>
  );
};

export default ApplyNowModal;
