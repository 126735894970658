import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { PROFILE_PATH } from '../../constants/constants';
import { setStateUser } from '../../redux/reducers/user';
import { RootState } from '../../redux/store';
import { getNameRoute } from '../../utils/stepsRouting';
import React, { useEffect } from 'react';

interface CandidateGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by candidates
function CandidateGuard({ children }: CandidateGuardType) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const candidateId = params.get('candidate_id') || params.get('id');
  const submissionId = params.get('submissionId');

  const {
    profile: { candidate_id },
    isCandidate,
    isLoading,
  } = useSelector((state: RootState) => state.user);
  const { activeStep } = useSelector((state: RootState) => state.additional);

  useEffect(() => {
    if (
      (!isLoading && !isCandidate && !!candidate_id && candidateId === (candidate_id?.toString() || '')) ||
      (!isLoading && !isCandidate && !!candidate_id && !candidateId)
    ) {
      dispatch(setStateUser({ type: 'isCandidate', data: true }));
    }
  }, [isCandidate, candidate_id, candidateId, dispatch, isLoading]);

  if (!isLoading && isCandidate && !candidateId) {
    return (
      <Navigate
        to={`/${PROFILE_PATH}/${getNameRoute(activeStep, isCandidate)}?id=${candidate_id?.toString() || ''}${
          !!submissionId ? `&submissionId=${submissionId}` : ''
        }`}
      />
    );
  }

  return <>{children}</>;
}

export default CandidateGuard;
