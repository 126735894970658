import { createSlice } from '@reduxjs/toolkit';
import { postCompanies, getDictionaries, getCandidateDictionaries } from '../actions/dictionaries';

type DictionariesStateType = {
  companies: any;
  dictionaries: any;
  isLoading: boolean;
  errors: any;
};

const initialState: DictionariesStateType = {
  companies: {},
  dictionaries: {},
  isLoading: false,
  errors: null,
};

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    setDictionary(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
  },
  extraReducers: {
    [getDictionaries.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [getDictionaries.fulfilled.toString()]: (state, { payload }) => {
      state.dictionaries[payload.type] = payload.data;
      state.isLoading = false;

      if (payload.type === 'functions') {
        state.dictionaries.subFunctions = payload.data.functions?.reduce(
          (acc: Array<any>, item: any) => [...acc, ...item.subFunctions],
          [],
        );
      }
    },
    [getDictionaries.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [postCompanies.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [postCompanies.fulfilled.toString()]: (state, { payload }) => {
      state.companies = payload;
      state.isLoading = false;
    },
    [postCompanies.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [getCandidateDictionaries.pending.toString()]: state => {
      state.isLoading = true;
      state.errors = null;
    },
    [getCandidateDictionaries.fulfilled.toString()]: (state, { payload }) => {
      state.dictionaries[payload.type] = payload.data;
      state.isLoading = false;
    },
    [getCandidateDictionaries.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
  },
});

export const { setDictionary } = dictionariesSlice.actions;

export default dictionariesSlice.reducer;
