import React, { memo, CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { copyrightActiveIcon } from '../../../../constants/icons';
import { ScorecardRespType } from '../../../../types/candidate';
import { StyledTooltip } from '../../../../styles/ButtonStyles';
import { approveCandidate } from '../../../../redux/actions/candidate';

type TProps = {
  data: ScorecardRespType;
};

const VerifyIcon: React.FC<TProps> = ({ data }) => {
  const dispatch = useDispatch();

  return (
    <StyledTooltip
      title={
          <Box p={2}>
            <Typography mb={2}>Recruiter action</Typography>
            <Button variant="outlined" onClick={() => dispatch(approveCandidate(data.id))}>
              Mark as verified
            </Button>
          </Box>
      }
    >
      <div style={stylesIcon}>{copyrightActiveIcon}</div>
    </StyledTooltip>
  );
};

export default memo(VerifyIcon);

const stylesIcon: CSSProperties = { cursor: 'pointer' };
