import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Card, Alert } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ContactInformation from './ContactInformation';
import Background from './Background';
import ModalResume from '../../Components/ModalResume';
import circleArrow from '../../../../images/icons/circleArrow.svg';
import CustomButton from '../../../CustomButton';
import Modal from '../../../Modal';

import { toBase64 } from '../../../../utils/functions';
import { ALERT_MESSAGES, PROFILE_PATH } from '../../../../constants/constants';
import StepsAdapter from '../../../../models/adapters/steps.adapter';
import { step1Selector } from '../../../../redux/selectors/candidate.selectors';
import { getCandidateDictionaries } from '../../../../redux/actions/dictionaries';
import { saveCandidate, uploadResume } from '../../../../redux/actions/candidate';
import { setCandidateState } from '../../../../redux/reducers/candidate';
import { setActiveStep } from '../../../../redux/reducers/additional';
import { setAlert } from '../../../../redux/reducers/alert';
import { RootState } from '../../../../redux/store';

import { Accordion, AccordionDetails, AccordionSummary } from '../../../../styles/AccordionStyles';
import { styleCardSection, styleAccordionArrow, styleAccordion } from '../../StylesCandidateProfile';

type TProps = {
  id: string;
  submissionId?: string | null;
  handleNext: (values: any) => void;
};

const Step1GeneralProfile: React.FC<TProps> = ({ id, submissionId, handleNext }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isCandidate, profile } = useSelector((state: RootState) => state.user);
  const { isUploadedResume, isUploading, uploadError, candidate, showUploadResume } = useSelector(
    (state: RootState) => state.candidate,
  );
  const valuesStep1 = useSelector(step1Selector);

  const [showModal, setShowModal] = useState(true);
  const [expandedPanel, setExpandedPanel] = useState(['panel1', 'panel2']);
  const [isError, setIsError] = useState(false);

  const handleAccordionChange = (panel: any) => (event: any, isExpanded: any) => {
    if (isExpanded) {
      setExpandedPanel([...expandedPanel, panel]);
    } else {
      setExpandedPanel(expandedPanel.filter(el => el !== panel));
    }

    window.scrollTo(0, isExpanded.offsetTop - 100);
  };

  useEffect(() => {
    if (Object.keys(profile).length) {
      dispatch(getCandidateDictionaries('general'));
    }
  }, [profile, dispatch]);

  useEffect(() => {
    if (isUploadedResume) {
      setShowModal(false);
      dispatch(setCandidateState({ type: 'isUploadedResume', data: true }));
      //  dispatch(setAlert({ type: 'success', message: ALERT_MESSAGES.UPLOAD_RESUME_SUCCESS }));
    }
  }, [isUploadedResume, dispatch]);

  useEffect(() => {
    if (isError) {
      setTimeout(()=>{
        const errorTextEl = document.querySelector('[data-label="errorText"]');
        !!errorTextEl && errorTextEl.scrollIntoView({block: 'center'}); 
      }, 100);
    }
  }, [isError]);

  useEffect(() => {
    if (!!uploadError) {
      dispatch(setAlert({ type: 'error', message: uploadError || ALERT_MESSAGES.UPLOAD_RESUME_FAIL }));
    }
  }, [uploadError, dispatch]);

  const onProceed = useCallback(
    (file: any) => {
      !!file
        ? toBase64(file).then((res: any) => {
            dispatch(uploadResume({ file: res.toString() }));
          })
        : setShowModal(false);
      dispatch(setCandidateState({ type: 'showUploadResume', data: false }));
    },
    [dispatch],
  );

  const onClose = useCallback(() => {
    setShowModal(false);
    dispatch(setCandidateState({ type: 'showUploadResume', data: false }));
  }, [dispatch]);

  const onDone = useCallback(
    (values: any, errors: any, handleSubmit: any) => {
      if (!errors.length) {
        const data = StepsAdapter.mapStep1ValuesDtoToStep1DataModel(values);
        dispatch(saveCandidate({ id: +id, values: data }));
        setActiveStep(0);
        navigate(
          `/${PROFILE_PATH}/profile-details?candidate_id=${id}`,
        );
      } else {
        handleSubmit();
      }
    },
    [dispatch, id, navigate],
  );

  return (
    <Formik
      initialValues={{
        first_name: valuesStep1?.first_name,
        last_name: valuesStep1?.last_name,
        phone: valuesStep1?.phone,
        email: valuesStep1?.email,
        country_id: valuesStep1?.country_id,
        city: valuesStep1?.city,
        province_id: valuesStep1?.province_id,
        years_of_experience: valuesStep1?.years_of_experience,
        designations: valuesStep1?.designations,
        about: valuesStep1?.about,
        submit: false,
      }}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={Yup.object().shape({
        first_name: Yup.string()
          .max(255, 'First name must be at most 255 characters')
          .required('First name is required'),
        last_name: Yup.string().max(255, 'Last name must be at most 255 characters').required('Last name is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        country_id: Yup.string().required('Country is required'),
        city: Yup.string().required('City is required'),
        province_id: Yup.string().when('country_id', {
          is: (value: any) => value === '40' || value === '239',
          then: Yup.string().required('Province is required'),
          otherwise: Yup.string().notRequired(),
        }),
        years_of_experience: Yup.string()
          .max(255, 'Year Start must be at most 255 characters')
          .required('Year Start is required'),
        about: Yup.string().max(255).required('Description is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = StepsAdapter.mapStep1ValuesDtoToStep1DataModel(values);

        try {
          await handleNext(data);
          dispatch(setCandidateState({ type: 'showUploadResume', data: false }));
        } catch (error: any) {
          const message = error.message || ALERT_MESSAGES.DEFAULT_ERROR;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {formik => {
        const { handleSubmit, errors, values } = formik;

        const errorsPanel1 =
          errors.first_name ||
          errors.last_name ||
          errors.email ||
          errors.country_id ||
          errors.city ||
          errors.province_id;
        const errorsPanel2 = errors.years_of_experience || errors.about;
        !!Object.keys(errors).length && setIsError(true);
        setTimeout(()=>setIsError(false), 100);

        return (
          <form onSubmit={handleSubmit}>
            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}

            <Card sx={styleCardSection}>
              <Typography variant="h5" gutterBottom color="secondary" mb={5}>
                General info
              </Typography>

              <Accordion
                key={'panel1'}
                expanded={expandedPanel.includes('panel1')}
                sx={[
                  styleAccordion,
                  { border: errorsPanel1 && expandedPanel.includes('panel1') ? '1px solid #DC2547 !important' : null },
                ]}
                onChange={handleAccordionChange('panel1')}
              >
                <AccordionSummary expandIcon={<Box component="img" sx={styleAccordionArrow} src={circleArrow} />}>
                  <Typography variant="h6" gutterBottom color="secondary">
                    Contact Information
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <ContactInformation formik={formik} disabledEmailInput={isCandidate} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                key={'panel2'}
                expanded={expandedPanel.includes('panel2')}
                sx={[
                  styleAccordion,
                  { border: errorsPanel2 && expandedPanel.includes('panel2') ? '1px solid #DC2547 !important' : null },
                ]}
                onChange={handleAccordionChange('panel2')}
              >
                <AccordionSummary expandIcon={<Box component="img" sx={styleAccordionArrow} src={circleArrow} />}>
                  <Typography variant="h6" gutterBottom color="secondary">
                    Background
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Background formik={formik} />
                </AccordionDetails>
              </Accordion>

              <Box display="flex" gap={'15px'} justifyContent="right" alignItems="center" p={5}>
                {!isCandidate && (
                  <CustomButton type="button" variant="text" onClick={() => onDone(values, errors, handleSubmit)}>
                    Done
                  </CustomButton>
                )}
                <CustomButton variant="contained">Next</CustomButton>
              </Box>
            </Card>

            {isCandidate && !candidate?.has_recent_resume && showUploadResume && (
              <Modal open={showModal} onClose={onClose}>
                <ModalResume onProceed={onProceed} onClose={onClose} loading={isUploading} />
              </Modal>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(Step1GeneralProfile);
