import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card as MuiCard, CardContent, Grid } from '@mui/material';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import { FormikProps } from 'formik';

import ResponsibilitiesItem from '../../Components/ResponsibilitiesItem';
import SubFunctionsItem from '../../Components/SubFunctionsItem';

import { objToId } from '../../../../utils/formatData';
import { setSubItemStep2 } from '../../../../utils/formatData';
import RoleValuesModel from '../../../../models/roleValues.model';
import { IsBuildTypeR } from '../../../../types/dictionaries';
import { SubFunctionsType } from '../../../../types/dictionaries';
import { RootState } from '../../../../redux/store';

import { styleSwitchTitle } from '../../StylesCandidateProfile';

type TProps = { name: string; role: RoleValuesModel; formik: FormikProps<any> };

const Responsibilities: React.FC<TProps> = ({ name, role, formik }) => {
  const { companyAndFunctions } = useSelector((state: RootState) => state.dictionaries.dictionaries);
  const { selectedRole } = useSelector((state: RootState) => state.candidate);

  const [checkedSF, setCheckedSF] = useState<Array<number>>([]);
  const [checkedResp, setCheckedResp] = useState<Array<number>>([]);
  const [checkedRespArr, setCheckedRespArr] = useState<IsBuildTypeR[]>([]);

  useEffect(() => {
    if (role?.jobFunctions?.length) {
      setCheckedSF(role.jobFunctions);

      if (role.responsibilities?.length) {
        setCheckedResp(role.responsibilities?.map((el: any) => el.id));

        let responsibilities = role.responsibilities.map((el: any) => ({
          id: el.id,
          function_id: el.function_id,
          title: el.title,
          build_type_id: el.build_type_id,
        }));

        setCheckedRespArr(responsibilities);
      }
    }
  }, [role]);

  const handleSwitchChange = useCallback(
    (subFunctionId: number) => {
      let selectedSubFunctions = checkedSF;
      const buf = selectedSubFunctions.filter((i: number) => i === subFunctionId);

      if (!buf.length) {
        setCheckedSF([...checkedSF, subFunctionId]);
        formik.setFieldValue(`${name}.jobFunctions`, [...checkedSF, subFunctionId]);
      } else {
        const filteredSubFunctions = selectedSubFunctions.filter((i: number) => i !== subFunctionId);

        setCheckedSF(filteredSubFunctions);
        formik.setFieldValue(`${name}.jobFunctions`, filteredSubFunctions);
      }
    },
    [checkedSF, name],
  );

  const handleChangeCheckbox = useCallback(
    (Resp: any, function_id: number) => {
      let selectedCB = checkedResp;
      const buf = selectedCB.filter((i: number) => i === Resp.id);

      if (!buf.length) {
        setCheckedResp([...checkedResp, Resp.id]);

        const newResponsibility = {
          id: Resp.id,
          function_id: function_id,
          title: Resp.title,
          build_type_id: null,
        };

        setCheckedRespArr([...checkedRespArr, newResponsibility]);

        formik.setFieldValue(`${name}.responsibilities`, [...checkedRespArr, newResponsibility]);
      } else {
        const newArray = checkedRespArr.filter(item => item.id !== Resp.id);

        setCheckedResp(selectedCB.filter((i: number) => i !== Resp.id));
        setCheckedRespArr(newArray);

        formik.setFieldValue(`${name}.responsibilities`, newArray);

        if (!!Resp.responsibilities?.length) {
          const checkArray = objToId(Resp.responsibilities) || [];

          let newArray = checkedRespArr.reduce((acc: Array<any>, item) => {
            if (![...checkArray, Resp.id]?.includes(item.id)) acc.push(item);
            return acc;
          }, []);

          setCheckedResp(objToId(newArray) || []);

          setCheckedRespArr(newArray);
          formik.setFieldValue(`${name}.responsibilities`, newArray);
        }
      }
    },
    [checkedRespArr, checkedResp, name],
  );

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        <Grid container>
          {companyAndFunctions?.subFunctions
            ?.filter((item: SubFunctionsType) =>
              formik.values.histories[selectedRole].departments
                .map((el: SubFunctionsType) => el.id)
                .includes(item.department_id),
            )
            ?.map((item: SubFunctionsType) => (
              <Box key={item.id} alignItems="center" sx={styleSwitchTitle}>
                <SubFunctionsItem
                  title={item.title}
                  checked={checkedSF.includes(item.id)}
                  onChange={() => handleSwitchChange(item.id)}
                />
                {checkedSF.includes(item.id) &&
                  item.responsibilities.map(subItem => {
                    const currentSubItem = setSubItemStep2({
                      arrayItems: role?.responsibilities,
                      checkItems: checkedResp,
                      defaultItem: subItem,
                    });

                    if (!currentSubItem) return null;
                    return (
                      <React.Fragment key={subItem.id}>
                        <ResponsibilitiesItem
                          title={currentSubItem.title}
                          handleChange={() => handleChangeCheckbox(subItem, item.id)}
                          checked={checkedResp.includes(currentSubItem.id)}
                          parent={!!subItem.responsibilities?.length}
                        />
                        {checkedResp.includes(subItem.id) && subItem.responsibilities.length > 0 && (
                          <Box
                            style={{
                              paddingLeft: '30px',
                              float: subItem.responsibilities.some((subItem2: any) => !subItem2.is_custom)
                                ? 'left'
                                : 'none',
                              width: '100%',
                            }}
                          >
                            {subItem.responsibilities.map(subItem2 => {
                              const currentSubItem2 = setSubItemStep2({
                                arrayItems: role?.responsibilities,
                                checkItems: checkedResp,
                                defaultItem: subItem2,
                              });

                              if (!currentSubItem2) return null;
                              return (
                                <React.Fragment key={subItem2.id}>
                                  <ResponsibilitiesItem
                                    title={currentSubItem2.title}
                                    key={subItem2.id}
                                    handleChange={() => handleChangeCheckbox(subItem2, item.id)}
                                    checked={checkedResp.includes(currentSubItem2.id)}
                                    parent={!!subItem2.responsibilities?.length}
                                  />
                                  {checkedResp.includes(subItem2.id) && subItem2.responsibilities.length > 0 && (
                                    <Box 
                                    style={{
                                      paddingLeft: '30px',
                                      float: subItem.responsibilities.some((subItem2: any) => !subItem2.is_custom)
                                        ? 'left'
                                        : 'none',
                                      width: '100%',
                                    }} 
                                    >
                                      {subItem2.responsibilities.map(subItem3 => {
                                        const currentSubItem3 = setSubItemStep2({
                                          arrayItems: role?.responsibilities,
                                          checkItems: checkedResp,
                                          defaultItem: subItem3,
                                        });

                                        if (!currentSubItem3) return null;
                                        return (
                                          <ResponsibilitiesItem
                                            title={currentSubItem3.title}
                                            key={subItem3.id}
                                            handleChange={() => handleChangeCheckbox(subItem3, item.id)}
                                            checked={checkedResp.includes(currentSubItem3.id)}
                                          />
                                        );
                                      })}
                                    </Box>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </Box>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Box>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default React.memo(Responsibilities);

const Card = styled(MuiCard)(spacing);
