import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MostImportantInWorkPlace from '../FormSections/Step4/MostImportantInWorkPlace';

import { saveCandidate } from '../../../redux/actions/candidate';
import { RootState } from '../../../redux/store';
import { commonConfig } from '../../../config';
import { Card, SxProps } from '@mui/material';
import { getCandidateDictionaries } from '../../../redux/actions/dictionaries';
import CompanyTraits from '../FormSections/Step5/CompanyTraits';

const Step5: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const { candidate } = useSelector((state: RootState) => state.candidate);
  const { isCandidate, profile } = useSelector((state: RootState) => state.user);

  const id = isCandidate ? candidate?.id?.toString() : params.get('id');

  const submissionId = params.get('submissionId');

  const ocpHost = commonConfig.ocpHost;

  useEffect(() => {
    if (Object.keys(profile).length) {
      dispatch(getCandidateDictionaries('general'));
    }
  }, [profile, dispatch]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      console.log('event.data', event.data);
      if (event.data.success === 1) {
        const newSubmissions = candidate?.submissions?.length ? candidate?.submissions : [];
        const currentSubmissionIndex = candidate?.submissions?.length
          ? candidate?.submissions?.findIndex((submission: any) => submission.id.toString() === submissionId)
          : -1;

        let submissionsComplete = [...newSubmissions];
        if (currentSubmissionIndex !== -1) {
          submissionsComplete[currentSubmissionIndex] = {
            ...submissionsComplete[currentSubmissionIndex],
            is_completed: 1,
          };
        }

        const data = { submissions: submissionsComplete };

        if (!!id) {
          if (!!submissionId) {
            dispatch(saveCandidate({ id: +id, values: data }));
          } else dispatch(saveCandidate({ id: +id, values: { is_completed: 1 } }));
        }
      }
    };

    if (candidate?.submissions?.length) {
      window.addEventListener('message', handleMessage, false);
    }
    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, [candidate?.submissions, dispatch, id, submissionId]);

  const showData = () => {
    if (!!candidate) {
      if (submissionId) {
        if (candidate?.is_full_culture) {
          return (
            <Card sx={styleCardSectionStep5}>
              <iframe
                src={`${ocpHost}?type=iframe&email=${candidate?.user?.email}&first_name=${candidate?.user?.first_name}&last_name=${candidate?.user?.last_name}`}
                title="findingclarity"
                width="100%"
                height="800px"
              />
            </Card>
          );
        } else return <CompanyTraits id={id} submissionId={submissionId} />;
      } else return <MostImportantInWorkPlace link={candidate?.schedule_link} />;
    } else return null;
  };

  return <>{showData()}</>;
};

export default memo(Step5);

const centerMargin = -(window.innerWidth - 1080) / 2;

const styleCardSectionStep5: SxProps = {
  background: '#f5f8fa',
  boxShadow: 'none',
  border: 'none',
  padding: '0 20px',
  width: window.innerWidth,
  position: 'relative',
  marginLeft: window.innerWidth > 1080 ? `${centerMargin}px` : 0,
};
