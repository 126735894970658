import React, { memo } from 'react';
import Step3General from './Step3General';
import Step3Specific from './Step3Specific';

type TProps = {
  id: string;
  handleNext: (values: any) => void;
  submissionId?: string | null;
};

const Step3: React.FC<TProps> = ({ id, handleNext, submissionId }) => {
  return (
    <>
      {submissionId ? (
        <Step3Specific id={id} handleNext={handleNext} submissionId={submissionId} />
      ) : (
        <Step3General id={id} handleNext={handleNext} />
      )}
    </>
  );
};

export default memo(Step3);
