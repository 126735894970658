import * as Yup from 'yup';
import { ALERT_MESSAGES } from '../../../../constants/constants';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { Alert, Box, Card, Typography } from '@mui/material';
import { styleCardSection, styleCardStep3 } from '../../StylesCandidateProfile';
import ToggleButtonGroupCheck from '../../../ToggleButtonGroupCheck';
import CustomButton from '../../../CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { getCandidateDictionaries } from '../../../../redux/actions/dictionaries';
import { saveCandidate } from '../../../../redux/actions/candidate';

type TProps = {
  id: any;
  submissionId: string;
};

const CompanyTraits: React.FC<TProps> = ({ id, submissionId }) => {
  const dispatch = useDispatch();

  const { general } = useSelector((state: RootState) => state.dictionaries.dictionaries);
  const { candidate } = useSelector((state: RootState) => state.candidate);
  const { profile, isCandidate } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (Object.keys(profile).length) {
      dispatch(getCandidateDictionaries('general'));
    }
  }, [profile, dispatch]);

  const candidateCompanyTraits = candidate?.companyTraits ? candidate?.companyTraits?.map((item: any) => item.id) : [];

  return (
    <Formik
      initialValues={{
        companyTraits: candidateCompanyTraits,
        submit: false,
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        companyTraits: Yup.array().min(5, 'Please select 5 traits').max(5, 'Please select no more than 5 traits'),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const newSubmissions = candidate?.submissions?.length ? candidate?.submissions : [];
          const currentSubmissionIndex = candidate?.submissions?.length
            ? candidate?.submissions?.findIndex((submission: any) => submission.id.toString() === submissionId)
            : -1;

          let submissionsComplete = [...newSubmissions];
          if (currentSubmissionIndex !== -1) {
            submissionsComplete[currentSubmissionIndex] = {
              ...submissionsComplete[currentSubmissionIndex],
              is_completed: 1,
            };
          }
          const newCompanyTraits = values?.companyTraits ? values.companyTraits : [];
          const data = { companyTraits: newCompanyTraits, submissions: submissionsComplete };
          id && dispatch(saveCandidate({ id: +id, values: data }));
        } catch (error: any) {
          const message = error.message || ALERT_MESSAGES.DEFAULT_ERROR;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {formik => {
        const { handleSubmit, values, errors, setFieldValue, touched } = formik;
        return (
          <form onSubmit={handleSubmit}>
            <div style={{ position: 'relative' }}>
              {touched.companyTraits && errors.companyTraits && typeof errors.companyTraits === 'string' && (
                <Alert severity="error">{errors.companyTraits}</Alert>
              )}
              <Card sx={styleCardSection}>
                <Typography variant="h5" gutterBottom color="secondary" mb={5}>
                  Desired Company Culture
                </Typography>

                <Typography variant="body2" gutterBottom mb={5} color="secondary">
                  Select the Top 5 traits below which are most important for you to see in workplace
                </Typography>
                <Card sx={styleCardStep3}>
                  <Typography variant="h6" gutterBottom color="secondary" mb={5}>
                    What is most important to you in a company's culture?
                  </Typography>
                  <ToggleButtonGroupCheck
                    name="companyTraits"
                    value={values.companyTraits}
                    onChange={(event: any, value: any) => {
                      setFieldValue('companyTraits', value);
                    }}
                    ariaLabel="companyTraits"
                    data={general?.companyTraits}
                  />
                  <Box display="flex" justifyContent="right" alignItems="center" p={5}>
                    <CustomButton type={'submit'} variant="contained">
                      {isCandidate ? 'Submit' : 'View Report'}
                    </CustomButton>
                  </Box>
                </Card>
              </Card>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CompanyTraits;
