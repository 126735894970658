import { SubmissionCategoriesType, SubmissionItemType, UserType } from '../types/candidate';
import { objToId } from '../utils/formatData';

class SubmissionValuesModel {
  id: number;
  about: string;
  availability_additional: string;
  comment: string;
  designation: string | null;
  designations: Array<{ id: number; title: string }>;
  interviewAvailabilities: Array<number> | undefined;
  location_id: number | '';
  is_workplace_location: number | null;
  notice_period_id: number | null;
  notice_period_other: string | null;
  rate_min: number | null;
  rate_max: number | null;
  salary_min: number | null;
  salary_max: number | null;
  salary_comment: string | null;
  submissionRequirements: any;
  years_of_experience: number | null;
  candidate_id: number;
  company_name: string;
  created_at: string;
  include_schedule: boolean;
  invite_status: string;
  is_hidden: boolean;
  job_order_id: number;
  job_title: string;
  location: { id: number; title: string } | null;
  noticePeriod: { id: number; title: string } | null;
  order: number | null;
  schedule_link: string | null;
  status: string;
  updated_at: string;
  has_recent_resume: boolean;
  link: string | null;
  ocp_answers: Array<any>;
  province: string | null;
  user: UserType;
  user_id: number;
  is_completed: number;
  submissionCategories: Array<SubmissionCategoriesType>;
  employee_id: number | null;
  industries: Array<any> | null;
  ownership_id: number | null;
  revenue_id: number | null;
  why_interested: string;

  constructor(data: SubmissionItemType) {
    this.id = data?.id;
    this.about = data?.about || '';
    this.availability_additional = data?.availability_additional || '';
    this.comment = data?.comment || '';
    this.designation = data?.designation || '';
    this.designations = data?.designations || [];
    this.interviewAvailabilities = data?.interviewAvailabilities ? objToId(data.interviewAvailabilities) : [];
    this.location_id = data?.location_id || '';
    this.is_workplace_location = data?.is_workplace_location !== null ? Number(data?.is_workplace_location) : null;
    this.notice_period_id = data?.notice_period_id || null;
    this.notice_period_other = data?.notice_period_other || null;
    this.rate_min = data?.rate_min || null;
    this.rate_max = data?.rate_max || null;
    this.salary_min = data?.salary_min || null;
    this.salary_max = data?.salary_max || null;
    this.salary_comment = data?.salary_comment || null;
    this.rate_min = data?.rate_min || null;
    this.submissionRequirements = data?.submissionRequirements || [];
    this.years_of_experience = data?.years_of_experience || null;
    this.candidate_id = data?.candidate_id;
    this.company_name = data?.company_name;
    this.created_at = data?.created_at;
    this.include_schedule = data?.include_schedule;
    this.invite_status = data?.invite_status;
    this.is_hidden = data?.is_hidden;
    this.job_order_id = data?.job_order_id;
    this.job_title = data?.job_title;
    this.location = data?.location;
    this.noticePeriod = data?.noticePeriod;
    this.order = data?.order;
    this.schedule_link = data?.schedule_link;
    this.status = data?.status;
    this.updated_at = data?.updated_at;
    this.has_recent_resume = data?.has_recent_resume;
    this.link = data?.link;
    this.ocp_answers = data?.ocp_answers;
    this.province = data?.province;
    this.user = data?.user;
    this.user_id = data?.user_id;
    this.is_completed = data?.is_completed;
    this.submissionCategories = data?.submissionCategories;
    this.employee_id = data?.employee_id;
    this.industries = data?.industries;
    this.ownership_id = data?.ownership_id;
    this.revenue_id = data?.revenue_id;
    this.why_interested = data?.why_interested;
  }
}

export default SubmissionValuesModel;
