import { createContext, ReactNode, useEffect, useReducer } from 'react';

import { JWTContextType, ActionMap, AuthState, AuthUser } from '../types/auth';

import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { user } from '../api/user';
import { getProfile } from '../redux/actions/user';
import { useDispatch } from 'react-redux';
import { AcceptInviteRequestType } from '../types/candidate';
import { setCandidateState } from '../redux/reducers/candidate';
import { setStateUser } from '../redux/reducers/user';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';
const RESET_PASS = 'RESET_PASS';
const NEW_PASS = 'NEW_PASS';

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
  };
  [RESET_PASS]: {
    resetPassResponse: any;
  };
  [NEW_PASS]: {
    newPassResponse: any;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case RESET_PASS:
      return {
        ...state,
        resetPassResponse: action.payload.resetPassResponse,
      };

    case NEW_PASS:
      return {
        ...state,
        newPassResponse: action.payload.newPassResponse,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const dispatched = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          await setSession(accessToken);
          // const response = await axios.get("/me");

          let user: any = {};
          //@ts-ignore
          dispatched(getProfile()).then(response => {
            user = response?.data?.data;
          });

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [dispatched]);

  const signIn = async (email: string, password: string) => {
    const response = await axios.post('auth/login', {
      email,
      password,
    });
    const { access_token, user } = response.data;

    setSession(access_token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
    dispatched(getProfile());
  };

  const signInWithLinkedin = async (code: string, state: string) => {
    const response = await axios.post('auth/linkedin-login', {
      code,
      state,
    });
    const { access_token, user } = response.data;

    setSession(access_token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signOut = async () => {
    try {
      await user.signOut();
      setSession(null);
      dispatch({ type: SIGN_OUT });
    } catch (err) {
      throw err;
    } finally {
      dispatched(setCandidateState({type: 'candidate', data: null})); 
      dispatched(setStateUser({type: 'profile', data: {}})); 
    }
  };

  const signUp = async (email: string, password: string) => {
    const response = await axios.post('auth/register', {
      email,
      password,
    });
    const { accessToken, user, access_token } = response.data;

    setSession(access_token);
    !!accessToken && window.localStorage.setItem('accessToken', accessToken);
    !!user &&
      dispatch({
        type: SIGN_UP,
        payload: {
          user,
        },
      });
      dispatched(getProfile());
  };

  const acceptInvite = async (values: AcceptInviteRequestType) => {
    const response = await axios.post('candidates/accept-invite', values);
    const { accessToken, user, access_token } = response.data;

    setSession(access_token);
    !!accessToken && window.localStorage.setItem('accessToken', accessToken);
    !!user &&
      dispatch({
        type: SIGN_UP,
        payload: {
          user,
        },
      });
    dispatched(getProfile()); 
  };

  const resetPassword = async (email: string, is_clarity: number) => {
    const response = await axios.post('password/forgot-password', { email, is_clarity });
    dispatch({
      type: RESET_PASS,
      payload: {
        resetPassResponse: response,
      },
    });
  };

  const newPassword = async (email: string, token: string, password: string) => {
    const response = await axios.post('password/reset', {
      email,
      token,
      password,
    });
    dispatch({
      type: NEW_PASS,
      payload: {
        newPassResponse: response,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signIn,
        signInWithLinkedin,
        signOut,
        signUp,
        resetPassword,
        newPassword,
        acceptInvite,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
