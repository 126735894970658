import React, { useEffect, useMemo, useCallback, CSSProperties, useState } from 'react';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Step, StepButton, CssBaseline } from '@mui/material';
import { SxProps } from '@mui/system';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import HeaderProfile from './Header/HeaderProfile';
import { ReactComponent as ArrowMenu } from '../images/ArrowMenu.svg';
import { ReactComponent as CircleMenu } from '../images/circleMenu.svg';
import { sidebarList } from '../utils/staticValues';
import { getCurrentStep, getCurrentStepSpecific } from '../utils/stepsRouting';
import { setActiveStep } from '../redux/reducers/additional';
import { setCandidateState } from '../redux/reducers/candidate';
import { RootState } from '../redux/store';
import { StyledStepper, ColorConnector, WrapperSteps } from '../styles/StepperStyles';
import Footer from '../components/Footer';
import { RoleType } from '../types/candidate';
import { INVITE_STATUS } from '../constants/constants';
import CompleteForm from '../components/CandidateProfile/FormSections/CompleteForm';

interface TProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<TProps> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { category } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const submissionId = params.get('submissionId') || '';

  const { isCandidate, profile } = useSelector((state: RootState) => state.user);
  const { activeStep, activeTab } = useSelector((state: RootState) => state.additional);
  const { candidate, openRolesMenu } = useSelector((state: RootState) => state.candidate);

  const heightMain = useMemo(() => (isCandidate ? 'calc(100vh - 0px)' : '100vh'), [isCandidate]);

  const roles = useMemo(
    () =>
      candidate
        ? JSON.parse(JSON.stringify(candidate.histories)).sort((a: RoleType, b: RoleType) => {
            const firstDate = a.start_year ? new Date(a.start_date).getTime() : new Date().getTime();
            const secondDate = b.start_year ? new Date(b.start_date).getTime() : new Date().getTime();
            return firstDate - secondDate;
          })
        : [],
    [candidate],
  );

  const [isCompletedProfile, setIsCompletedProfile] = useState(false);
  const [isOCPComplete, setOCPComplete] = useState(true);
  const [isFullCulture, setIsFullCulture] = useState(true);

  useEffect(() => {
    if (candidate?.ocp_answers?.top) {
      const ocpTop = candidate?.ocp_answers?.top;
      if (!Object.keys(ocpTop).length) {
        setOCPComplete(false);
      }
    }
    if (!candidate?.is_full_culture) {
      setIsFullCulture(false);
    }
  }, [candidate]);

  const steps = useMemo(
    () => sidebarList(isCandidate, !!submissionId, isOCPComplete, isFullCulture),
    [isCandidate, submissionId, isOCPComplete, isFullCulture],
  );

  const currentSubmission = candidate?.submissions.find((el: any) => el.id === +submissionId) || null;

  useEffect(() => {
    isCandidate &&
      currentSubmission &&
      currentSubmission.invite_status === INVITE_STATUS.COMPLETED &&
      setIsCompletedProfile(true);
  }, [currentSubmission, isCandidate]);

  const title = useMemo(() => {
    if (!!currentSubmission) {
      return !isCandidate && candidate?.first_name
        ? `${candidate?.first_name}'s Profile for ${
            currentSubmission
              ? `${currentSubmission?.job_title} at ${currentSubmission?.company_name}`
              : `${candidate?.first_name}'s Job Specific Profile`
          }`
        : `Your Profile for ${currentSubmission?.job_title} at ${currentSubmission?.company_name}`;
    }
    return !isCandidate && candidate?.first_name ? `${candidate?.first_name}'s Profile` : 'My Profile';
  }, [currentSubmission, isCandidate, candidate?.first_name]);

  useEffect(() => {
    category && dispatch(setActiveStep(submissionId ? getCurrentStepSpecific(category) : getCurrentStep(category)));
  }, [category, dispatch, isCandidate, submissionId]);

  const handleChangeStep = useCallback(
    (url: string, index: number) => {
      /*if (index !== 3 && index !== 4) {*/
      navigate(`${url}?id=${profile?.candidate_id || id}${submissionId ? `&submissionId=${submissionId}` : ''}`);
      dispatch(setActiveStep(index));
      /*}*/
    },
    [id, profile, dispatch, navigate, submissionId],
  );

  const onSelectRole = useCallback(
    (value: number) => {
      dispatch(setCandidateState({ type: 'selectedRole', data: value }));
      dispatch(setCandidateState({ type: 'openRolesMenu', data: false }));
    },
    [dispatch],
  );

  return (
    <Box sx={stylesContainer} className="MainLayout">
      <WrapperHeader>
        <HeaderProfile title={title} />

        {!isCompletedProfile && activeStep !== 5 && (
          <WrapperSteps>
            <StyledStepper
              activeStep={activeStep}
              connector={<ColorConnector />}
              isCandidate={isCandidate ? 'true' : undefined}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};

                return (
                  <Step key={label?.text} {...stepProps} disabled={false} sx={{ lineHeight: '0 !important' }}>
                    <StepButton
                      {...labelProps}
                      icon={
                        index === 4 &&
                        activeStep === 4 && (
                          <div className="iconStep5">
                            <CheckIcon />
                          </div>
                        )
                      }
                      onClick={() => handleChangeStep(label?.url, index)}
                      /*style={index === 3 || index === 4 ? stylesCursorBlock : {}}*/
                    >
                      <Box sx={stylesWrapLabel}>
                        <Typography sx={stylesLabel}>
                          {label?.text}
                          {index === 1 && !!roles.length && !submissionId && (
                            <ArrowMenu
                              style={openRolesMenu ? stylesArrowUpMenu : stylesArrowDownMenu}
                              onClick={() =>
                                !!roles.length &&
                                dispatch(setCandidateState({ type: 'openRolesMenu', data: !openRolesMenu }))
                              }
                            />
                          )}
                        </Typography>

                        {index === 1 && openRolesMenu && !!roles.length && !submissionId && (
                          <Box sx={stylesRolesList}>
                            <Box sx={stylesRolesListHeader}>Relevant work history</Box>

                            <Box sx={stylesRolesListBody}>
                              {roles.map((role: { title: any; company: any }, idx: number) => (
                                <Box sx={stylesRoleWrap} key={idx}>
                                  <CircleMenu style={stylesCircle} />

                                  <Role
                                    show={idx === roles.length - 1 ? undefined : 'true'}
                                    onClick={() => onSelectRole(idx)}
                                  >
                                    <Box sx={stylesRow}>
                                      <div className="roleTitle">{role.title || 'job title'}</div>
                                    </Box>

                                    <div className="roleCompany">{role.company || 'company'}</div>
                                  </Role>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </StepButton>
                  </Step>
                );
              })}
            </StyledStepper>
          </WrapperSteps>
        )}
      </WrapperHeader>

      {activeTab === 1 ? (
        <Box sx={stylesWrapper}>
          <CssBaseline />

          <Main component="main">
            {isCompletedProfile ? (
              <CompleteForm />
            ) : (
              <>
                {children}
                <Outlet />
              </>
            )}
          </Main>
        </Box>
      ) : (
        <Box sx={stylesWrapper}>
          <Box component="main" width="100%" height={heightMain}>
            {isCompletedProfile ? (
              <CompleteForm />
            ) : (
              <>
                {children}
                <Outlet />
              </>
            )}
          </Box>
        </Box>
      )}

      <Footer />
    </Box>
  );
};

export default MainLayout;

const stylesContainer: SxProps = { height: '100vh', width: '100%', display: 'flex', flexDirection: 'column' };

const Main = styled(Box)<any>(() => ({
  flexGrow: 1,
  background: '#f5f8fa',
  // overflow: 'auto',
  // height: '100vh',
}));

const WrapperHeader = styled(Box)`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1250;
  display: flex;
  flex-direction: column;
  background: #f5f8fa;
`;

export const stylesListItemIcon: SxProps = {
  minWidth: 0,
  mr: 3,
  justifyContent: 'center',
};

export const stylesWrapper: SxProps = {
  display: 'flex',
  // minHeight: '100%',
  width: '100%',
  flex: 1,
  position: 'relative',
  maxWidth: 1080,
  margin: 'auto',
};

export const stylesLabel: SxProps = {
  color: '#002855',
  textTransform: 'uppercase',
  letterSpacing: 1,
  fontWeight: 600,
};

const stylesWrapLabel: SxProps = {
  position: 'relative',
};

const stylesRolesList: SxProps = {
  position: 'absolute',
  top: '20px',
  left: 0,
  zIndex: 9,
  background: '#fff',
  boxShadow: '0 2px 10px #00000024',
  borderRadius: '2px',
  maxHeight: 'min-content',
  minWidth: '260px',
  maxWidth: '300px',
  width: '100%',
};

const stylesRolesListHeader: SxProps = {
  width: '100%',
  padding: '5px 10px',
  background: '#FaF8F5',
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 0.54)',
};

const stylesRolesListBody: SxProps = {
  width: '100%',
  padding: '10px',
};

const stylesRoleWrap: SxProps = {
  width: '100%',
  display: 'flex',
};

const stylesRow: SxProps = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
};

const Role = styled(Box)<{
  show?: string;
}>`
  color: #0000008a;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
  position: relative;
  width: 90%;

  &:after {
    display: ${({ show }) => (!!show ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 3px;
    height: 130%;
    border-left: 1px dashed;
    left: -14.5px;
  }

  .roleTitle,
  .roleCompany {
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  .roleTitle {
    color: #1e88e5;
  }

  .roleCompany {
    color: #757678;
  }
`;

const stylesArrowDownMenu: CSSProperties = {
  marginLeft: 5,
  transform: 'rotate(180deg)',
};

const stylesArrowUpMenu: CSSProperties = {
  marginLeft: 5,
};

const stylesCircle: CSSProperties = {
  marginTop: 3,
  zIndex: 1,
};

const stylesCursorBlock: CSSProperties = {
  cursor: 'not-allowed',
};
