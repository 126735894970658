import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card as MuiCard, CardContent, Grid, TextField as MuiTextField, Typography } from '@mui/material';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import * as Yup from 'yup';

import CustomButton from '../../components/CustomButton';

import { updateProfile } from '../../redux/actions/user';
import { setAlert } from '../../redux/reducers/alert';
import { AppDispatch, RootState } from '../../redux/store';
import { ALERT_MESSAGES } from '../../constants/constants';

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Public = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { profile, errors: apiErrors } = useSelector((state: RootState) => state.user);

  const handleInfoSubmit = async (
    values: FormikValues,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
    };

    dispatch(updateProfile(data)).then((res: any) => {
      window.scrollTo(0, 0);
      if (res.error) {
        const message = res.error.message || ALERT_MESSAGES.DEFAULT_ERROR;
        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
        dispatch(
          setAlert({
            type: 'error',
            message: message,
            time: 3000,
          }),
        );
      } else {
        dispatch(
          setAlert({
            message: res?.data?.message || 'User profile has been successfully updated',
            time: 3000,
          }),
        );
      }
    });
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Public info
        </Typography>

        <Formik
          initialValues={{
            first_name: profile?.first_name || '',
            last_name: profile?.last_name || '',
            email: profile?.email || '',
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string()
              .max(255, 'First name must be at most 255 characters')
              .required('First name is required'),
            last_name: Yup.string()
              .max(255, 'Last name must be at most 255 characters')
              .required('Last name is required'),
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          })}
          onSubmit={handleInfoSubmit}
          enableReinitialize={true}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <TextField
                    id="first_name"
                    name="first_name"
                    label="First name"
                    value={values.first_name}
                    error={Boolean(touched.first_name && errors.first_name) || Boolean(apiErrors?.first_name)}
                    helperText={(touched.first_name && errors.first_name) || apiErrors?.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    my={2}
                  />

                  <TextField
                    id="last_name"
                    name="last_name"
                    label="Last name"
                    value={values.last_name}
                    error={Boolean(touched.last_name && errors.last_name) || Boolean(apiErrors?.last_name)}
                    helperText={(touched.last_name && errors.last_name) || apiErrors?.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    my={2}
                  />

                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email) || Boolean(apiErrors?.email)}
                    helperText={(touched.email && errors.email) || apiErrors?.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

              <Box mt={2}>
                <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                  Save changes
                </CustomButton>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

function Private() {
  const dispatch = useDispatch<AppDispatch>();

  const { errors: apiErrors } = useSelector((state: RootState) => state.user);

  const handlePasswordSubmit = async (
    values: FormikValues,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      old_password: values.old_password,
      password: values.new_password,
    };

    dispatch(updateProfile(data)).then((res: any) => {
      window.scrollTo(0, 0);
      if (res.error) {
        const message = res.payload.old_password || ALERT_MESSAGES.DEFAULT_ERROR;
        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
        dispatch(
          setAlert({
            type: 'error',
            message: message,
            time: 3000,
          }),
        );
      } else {
        dispatch(
          setAlert({
            message: res?.data?.message || 'User profile has been successfully updated',
            time: 3000,
          }),
        );
      }
    });
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Change password
        </Typography>

        <Formik
          initialValues={{
            old_password: '',
            new_password: '',
            password_confirmation: '',
          }}
          validationSchema={Yup.object().shape({
            old_password: Yup.string()
              .label('Old password')
              .min(8, 'Old password must be at least 8 characters')
              .required('Old password is required'),
            new_password: Yup.string()
              .label('New password')
              .min(8, 'New password must be at least 8 characters')
              .max(255, 'New password must be at most 255 characters')
              .required('New password is required'),
            password_confirmation: Yup.string()
              .label('Password confirmation')
              .oneOf([Yup.ref('new_password'), ''], 'Passwords must match')
              .required('Password confirmation is required'),
          })}
          onSubmit={handlePasswordSubmit}
          enableReinitialize={true}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <TextField
                    id="old_password"
                    name="old_password"
                    type="password"
                    label="Old password"
                    value={values.old_password}
                    error={Boolean(touched.old_password && errors.old_password) || Boolean(apiErrors?.old_password)}
                    helperText={(touched.old_password && errors.old_password) || apiErrors?.old_password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    my={2}
                  />

                  <TextField
                    id="new_password"
                    name="new_password"
                    type="password"
                    label="New password"
                    value={values.new_password}
                    error={Boolean(touched.new_password && errors.new_password) || Boolean(apiErrors?.new_password)}
                    helperText={(touched.new_password && errors.new_password) || apiErrors?.new_password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    my={2}
                  />

                  <TextField
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    label="Confirm password"
                    value={values.password_confirmation}
                    error={
                      Boolean(touched.password_confirmation && errors.password_confirmation) ||
                      Boolean(apiErrors?.password_confirmation)
                    }
                    helperText={
                      (touched.password_confirmation && errors.password_confirmation) || apiErrors?.new_password
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>

              <Box mt={2}>
                <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                  Save password
                </CustomButton>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

const Settings: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet title="Profile" />

      <Grid container spacing={6} flexDirection="column" p={5}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom display="inline">
            Profile
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Public />
            </Grid>

            <Grid item xs={12} md={6}>
              <Private />
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex" padding="0 20px">
          <CustomButton type="button" variant="contained" onClick={() => navigate(-1)}>
            Go back
          </CustomButton>
        </Box>
      </Grid>
    </>
  );
};

export default Settings;
