import { Typography, Box, SxProps } from '@mui/material';

import { styled } from '@mui/system';
import IconItem from './IconItem';
import ArrowsUpIcon from '../../../../images/icons/ic_3_arrows_up_48px.svg';
import ArrowsDownIcon from '../../../../images/icons/ic_3_arrows_down_48px.svg';

import React from 'react';

type TProps = {
  mostImportant: {[key: string]: string} | undefined;
  leastImportant?: {[key: string]: string} | undefined;
  companyTraits?: [{id: number, title: string}] | undefined;
  isFullCulture: boolean | undefined;
}

const MostImportantList: React.FC<TProps> = ({mostImportant, leastImportant, companyTraits, isFullCulture = true}) => {

  return (
    <Box>
      <Typography sx={stylesSectionTitle} color="secondary">
        Desired Company Culture
      </Typography>

      <StyledWrap>
        <BoxBorder style={{width: !!leastImportant ? 'calc((100% - 15px) / 2)' : '100%'}}>
          <StyledTitle>
            <IconItem icon={ArrowsUpIcon} />
            <Typography fontSize='16px' color="secondary">Most Important</Typography>
          </StyledTitle>
          <StyledList component="ul">
            {isFullCulture ? (mostImportant && Object.keys(mostImportant).map((el: string, idx: number) => {
              return (
                <Box component="li" key={idx}>
                  <Typography component="span" sx={stylesText}>
                    {mostImportant[el]}
                  </Typography>
                </Box>
              );
            })) : (!!companyTraits?.length && companyTraits.map((el: {id: number, title: string} , idx: number) => {
              return (
                <Box component="li" key={idx}>
                  <Typography component="span" sx={stylesText}>
                    {el.title}
                  </Typography>
                </Box>
              );
            }))}
          </StyledList>
        </BoxBorder>

       {isFullCulture && !!leastImportant && <BoxBorder>
          <StyledTitle>
            <IconItem icon={ArrowsDownIcon} />
            <Typography fontSize='16px' color="secondary">Least Important</Typography>
          </StyledTitle>

          <StyledList component="ul">
            {leastImportant && Object.keys(leastImportant).map((el: string, idx: number) => {
              return (
                <Box component="li" key={idx}>
                  <Typography component="span" sx={stylesText}>
                    {leastImportant[el]}
                  </Typography>
                </Box>
              );
            })}
          </StyledList>
        </BoxBorder>}
      </StyledWrap>
    </Box>
  );
};

const BoxBorder = styled(Box)`
  width: calc((100% - 15px) / 2);
  border: 1px solid #e9e7e7;
  border-radius: 5px;
  height: 100%;
`;

const StyledList = styled(Box)`
  list-style: disc;
  margin: 0;
  padding: 8px 8px 8px 36px;
`;

const StyledWrap = styled(Box)`
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
`;

const StyledTitle = styled(Box)`
  display: flex;
  align-items: center;
  padding: 15px 10px 10px;
  background-color: #f5f8fa;
`;

const stylesSectionTitle: SxProps = {
  margin: '15px 0 10px',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
};

const stylesText: SxProps = {
  fontWeight: 400,
  fontSize: '16px'
};

export default MostImportantList;
