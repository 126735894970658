import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './reducers/user';
import alertReducer from './reducers/alert';
import additionalReducer from './reducers/additional';
import dictionariesReducer from './reducers/dictionaries';
import savedDataReducer from './reducers/savedData';
import candidateReducer from './reducers/candidate';
import jobsReducer from './reducers/jobs';

export const store = configureStore({
  reducer: {
    user: userReducer,
    alert: alertReducer,
    additional: additionalReducer,
    dictionaries: dictionariesReducer,
    savedData: savedDataReducer,
    candidate: candidateReducer,
    jobs: jobsReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const setHeader = () => {
  const state = store.getState();
  const token = (state.user && state.user.profile) || localStorage.getItem('token');

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};
