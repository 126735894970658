import React from 'react';
import { Tooltip, IconButton as MuiIconButton } from '@mui/material';

type TProps = {
  toolTip?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

const IconButton: React.FC<TProps> = ({ toolTip, onClick, children }): JSX.Element => (
  <Tooltip title={toolTip || ''}>
    <MuiIconButton onClick={onClick}>{children}</MuiIconButton>
  </Tooltip>
);

export default IconButton;
