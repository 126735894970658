import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, ClickAwayListener } from '@mui/material';
import { ScorecardRespType } from '../../../../types/candidate';
import { CloseIconWrapper } from '../../../../styles/components/InfoModalStyles';
import CloseIcon from '@mui/icons-material/Close';
import Editors from '../../../Editors';
import styled from 'styled-components/macro';
import { SxProps } from '@mui/system';

type TProps = {
  data: ScorecardRespType;
  onChangeValue: (values: ScorecardRespType) => void;
};

const CommentNew: React.FC<TProps> = ({ data, onChangeValue }) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState(data.comment);
  const maxStringLength = 115;

  let regexForHTML = /(<([^>]+)>)/gi;

  const dataComment = data?.comment?.replace(regexForHTML, '');

  useEffect(() => {
    if (data?.comment) {
      setComment(data?.comment);
    }
  }, [data]);

  const onSaveComment = () => {
    onChangeValue({ ...data, comment: comment });
    setOpen(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      {open && (
        <Box onClick={e => e.stopPropagation()} sx={styleCommentBox}>
          <CloseIconWrapper onClick={() => setOpen(false)}>
            <CloseIcon />
          </CloseIconWrapper>
          <Editors value={comment ? comment : ''} onChange={value => setComment(value)} hideClose={true} />

          <WrapButton>
            <Button type="button" variant={'text'} onClick={onSaveComment} sx={{ fontSize: 30 }}>
              Save
            </Button>
          </WrapButton>
        </Box>
      )}

      <Box display={'flex'} alignItems={'center'}>
        <Box>
          {data?.comment && dataComment ? (
            <Box>
              <CommentWrapper
                showDots={data?.comment?.length > maxStringLength}
                dangerouslySetInnerHTML={{
                  __html:
                    data?.comment?.length < maxStringLength ? data.comment : data.comment.substr(0, maxStringLength),
                }}
              />

              {data?.comment && (
                <Box sx={showEditText} onClick={() => setOpen(true)}>
                  {data?.comment?.length > maxStringLength ? 'Show More' : 'Edit'}
                </Box>
              )}
            </Box>
          ) : (
            <Typography fontSize={'12px'} color={'#9E9E9E'}>
              ADD COMMENT
            </Typography>
          )}
        </Box>
        {!dataComment && (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box pl={'5px'} height={'18px'}>
              <AddCircleOutlineIcon
                style={{ color: '#9E9E9E', width: '18px', height: '18px', cursor: 'pointer' }}
                onClick={() => setOpen(true)}
              />
            </Box>
          </ClickAwayListener>
        )}
      </Box>
    </div>
  );
};

export default CommentNew;

const WrapButton = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;

  button {
    padding: 0 5px;
    font-size: 14px;
    min-width: 0;
    margin-left: 5px;
  }
`;

const CommentWrapper = styled('div')<{
  showDots?: boolean;
}>`
  p {
    margin: 0;
  }
  p::after {
    ${props => (props.showDots ? 'content: "..."' : 'content: "" ')};
    color: rgba(0, 0, 0, 0.26);
  }
`;

const styleCommentBox: SxProps = {
  width: 500,
  position: 'absolute',
  top: -20,
  right: 50,
  zIndex: 1,
  borderRadius: '5px',
  p: 4,
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.14)',
};

const showEditText: SxProps = {
  fontSize: '12px',
  margin: 0,
  color: '#2681DB',
  cursor: 'pointer',
};
