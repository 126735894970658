import React, { ReactElement, CSSProperties } from 'react';
import { Box, Fade, Modal as MuiModal } from '@mui/material';
import styled from 'styled-components/macro';
import CloseIcon from '@mui/icons-material/Close';

type TProps = {
  open: boolean;
  onClose: () => void;
  children: ReactElement<any, any>;
  small?: boolean;
};

const Modal: React.FC<TProps> = ({ open, onClose, children, small }) => (
  <MuiModal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    closeAfterTransition
    BackdropProps={{
      timeout: 500,
    }}
    sx={styleModal}
  >
    <Fade in={open}>
      <ModalBox style={small ? styleSmallModalBox : styleModalBox}>
        <CloseIcon color="primary" style={styleClosIcon} onClick={onClose} />
        <Box>{children}</Box>
      </ModalBox>
    </Fade>
  </MuiModal>
);

export default Modal;

const styleModal = { padding: 20, width: '100%' };

const styleSmallModalBox: CSSProperties = {
  width: '300px',
  height: '150px',
  minWidth: '250px',
  padding: '15px',
  textAlign: 'center',
};

const styleModalBox: CSSProperties = { width: 'max-content' };

const styleClosIcon: CSSProperties = { position: 'absolute', right: '10px', top: '10px' };

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 980px;
  // min-height: 300px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
