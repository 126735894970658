import React, {useEffect} from 'react';
import {Grid, Hidden, Typography} from '@mui/material';
import {FinanceContainer} from '../../styles/pages/JobDetail/JobDetailHomepageStyles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {searchJob} from '../../redux/actions/jobs';
import {RootState} from '../../redux/store';
import {JobsType} from '../../types/jobs';
import Loader from '../../components/Loader';
import {commonConfig} from '../../config';
import SearchJobs from "../../components/jobDetail/SearchJobs";
import {ContainedButton} from "../../styles/ButtonStyles";
import {getDictionaries} from "../../redux/actions/dictionaries";
import {JobsHeaderStyle, JobsCellStyle, JobsTitleCellStyle, JobsCellButtonStyle} from "../../styles/TableStyles";
import {ShadowContainer2} from "../../styles/components/CategoryFullDetailsStyles";
import JobCardMobile from "../../components/jobDetail/JobCardMobile";
import EmptySearch from "../../components/jobDetail/EmptySearch";
import {getFullLocation, getSalary} from "../../utils/functions";

const JobsHomepage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {searchJobResult, isLoading, isSearching} = useSelector((state: RootState) => state.jobs);

  useEffect(() => {
    dispatch(searchJob({}));
    dispatch(getDictionaries('jobTypes'));
    dispatch(getDictionaries('functions'));
    dispatch(getDictionaries('levels'));
    dispatch(getDictionaries('search-locations'));
  }, [dispatch]);

  useEffect(() => {
    const trackingSrc = commonConfig.trackingSrc;
    if (!!trackingSrc) {
      const script = document.createElement('script');
      script.src = trackingSrc;
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.id = "hs-script-loader"
      document.body.appendChild(script);
    }
    const HSScriptElement = document.querySelector('#hs-script-loader');
    return (() => {
      if (!!HSScriptElement) HSScriptElement.remove();
    });
  }, []);

  const handleViewJob = (jobId: number) => {
    navigate('/details/?job-id=' + jobId);
  };

  const getDuration = (job: JobsType): string => {
    return job.contract_duration ? `(${job.contract_duration} months)` : '';
  }

  return (
    <>
      {(isLoading || isSearching) && <Loader/>}

      <FinanceContainer>
        <Grid item xs={12} mt={'20px'} className={'hideForMobile'}>
          <SearchJobs
            onSearch={(e: any) => {
              dispatch(
                searchJob({
                  categoryId: null,
                  keyword: e.keyword ? e.keyword : '',
                  levelId: e.levelId ? e.levelId : null,
                  jobTypeId: e.jobTypeId ? e.jobTypeId : null,
                  functionId: e.functionId ? e.functionId : null,
                  province: e.province ? e.province : null,
                  city: e.city ? e.city : null,
                  location: e.location ? e.location : null,
                }),
              );
            }}
          />
        </Grid>
        {isSearching ? (
          <div></div>
        ) : !isLoading && !searchJobResult?.jobs?.length ? (
          <Grid item xs={12}>
            <EmptySearch />
          </Grid>
        ) : (
          searchJobResult?.jobs?.length > 0 && (
            <div>
              <ShadowContainer2 className={'hideForMobile'}>
                <div style={{ border: '1px solid #F5F8FA', overflow: 'hidden' }}>
                  <Grid container spacing={2} rowSpacing={{ xs: 1, sm: 3 }} sx={{ paddingLeft: '15px' }}>
                    <Grid item xs={12} sx={{ background: '#F5F8FA' }}>
                      <Grid container>
                        <Grid item sm={5} md={5}>
                          <Typography sx={JobsHeaderStyle}>Job Title</Typography>
                        </Grid>
                        <Grid item sm={3} md={2}>
                          <Typography sx={JobsHeaderStyle}>Salary</Typography>
                        </Grid>
                        <Hidden mdDown>
                          <Grid item md={2}>
                            <Typography sx={JobsHeaderStyle}>Type</Typography>
                          </Grid>
                        </Hidden>
                        <Grid item sm={2} md={2}>
                          <Typography sx={JobsHeaderStyle}>Location</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {searchJobResult.jobs.map((job: JobsType, index: number) => (
                    <Grid item xs={12} key={job.id} sx={{ background: index % 2 ? '#F5F8FA' : '#fff', paddingLeft: '15px' }}>
                      <Grid container>
                        <Grid item sm={5} md={5}>
                          <Typography component="a" sx={JobsTitleCellStyle} onClick={() => handleViewJob(job.id)}>{`${job.title} ${getDuration(job)}`}</Typography>
                        </Grid>
                        <Grid item sm={3} md={2}>
                          <Typography sx={JobsCellStyle}>{getSalary(job)}</Typography>
                        </Grid>
                        <Hidden mdDown>
                          <Grid item md={2}>
                            <Typography sx={JobsCellStyle}>{job.employment_type === 'Executive Search' ? 'Full-Time Hire' : job.employment_type}</Typography>
                          </Grid>
                        </Hidden>
                        <Grid item sm={2} md={2}>
                          <Typography sx={JobsCellStyle}>{getFullLocation(job)}</Typography>
                        </Grid>
                        <Grid item sm={1} md={1} sx={JobsCellButtonStyle}>
                          <ContainedButton variant={'contained'} onClick={() => handleViewJob(job.id)}>
                            View
                          </ContainedButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </ShadowContainer2>
              <Grid item xs={12} className={'showForMobile'}>
                <div className={'categoryItemsWrap'}>
                  {searchJobResult?.jobs?.map((item: JobsType) => (
                    <JobCardMobile item={item} key={item.id} onClick={() => handleViewJob(item.id)}/>
                  ))}
                </div>
              </Grid>
            </div>
          )
        )}
      </FinanceContainer>
    </>
  );
};

export default JobsHomepage;
