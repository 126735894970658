import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TextFieldC } from '../../styles/InputStyles';
import { SubmitButton } from '../../styles/ButtonStyles';
import { Typography, Button, Box } from '@mui/material';
import { Alert as MuiAlert } from '@mui/material';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';
import { SuccessMessage } from '../../styles/pages/AuthPages/SignInStyles';
import { ALERT_MESSAGES } from '../../constants/constants';

const Alert = styled(MuiAlert)(spacing);

const NewPasswordComponent = (props: any) => {
  const navigate = useNavigate();
  const { newPassword } = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const emailFromLink = params.get('email') !== null ? params.get('email') : '';
  const [showLoginButton, setShowLoginButton] = useState(false);

  return (
    <Formik
      initialValues={{
        email: emailFromLink ? emailFromLink : '',
        token: props.token,
        password: '',
        confirmPassword: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().min(8, 'Must be at least 8 characters').max(255).required('Required'),
        confirmPassword: Yup.string().when('password', {
          is: (val: any) => !!(val && val.length > 0),
          then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await newPassword(values.email, values.token, values.password);
          setShowLoginButton(true);
        } catch (error: any) {
          const message = error.message || ALERT_MESSAGES.DEFAULT_ERROR;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextFieldC
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ minHeight: '80px' }}
            disabled={showLoginButton}
          />
          <TextFieldC
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ minHeight: '80px', marginTop: '5px' }}
            disabled={showLoginButton}
          />
          <SubmitButton
            type="submit"
            fullWidth
            variant="contained"
            disabled={isSubmitting || showLoginButton}
            style={{ marginTop: '20px', width: '200px' }}
          >
            Reset password
          </SubmitButton>
          <Box sx={{ height: '52px' }}>
            {showLoginButton && (
              <SuccessMessage>
                <Typography>
                  “Success! You've reset your password. Please click the link in the original email we sent inviting you
                  to create your profile.”
                </Typography>
                {/* <Button onClick={() => navigate('/auth/sign-in')}>Login</Button> */}
              </SuccessMessage>
            )}
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default NewPasswordComponent;
